<template>
  <v-container class="pa-3" fluid v-if="projectId != null && projectId != '' && projectId != NaN">
    <h3 class="text--primary font-weight-black">Project Reports</h3>
    <div style="background: #eceff1; border-radius: 0.5rem" class="px-5 py-2 mt-3 inset-shadow">
      <v-row class="mt-1 mb-3">
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.bom.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="reports.bom.isHovering || reports.bom.isFocusing ? 'report-card-hovering' : ''"
            :elevation="reports.bom.isHovering ? 9 : 2"
            @mouseenter="reports.bom.isHovering = true"
            @mouseleave="reports.bom.isHovering = false"
            @focus="reports.bom.isFocusing = true"
            @blur="reports.bom.isFocusing = false"
            @click="generate(reports.bom.value)"
            ref="firstReportBtn"
          >
            <div class="report-icon">
              <i class="fad fa-file-invoice-dollar"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-invoice-dollar"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">BOM</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                Bill of Materials
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>BOM:</b> "Bill of Materials"<br />
                  The intent of the BOM is to communicate to the client the equipment that is to be
                  used in each space. It is not intended to provide for pricing analysis. The
                  audience is intended to be purely concerned about the functionality of the
                  systems, and not the cost.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.extendedBom.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="
              reports.extendedBom.isHovering || reports.extendedBom.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.extendedBom.isHovering ? 9 : 2"
            @mouseenter="reports.extendedBom.isHovering = true"
            @mouseleave="reports.extendedBom.isHovering = false"
            @focus="reports.extendedBom.isFocusing = true"
            @blur="reports.extendedBom.isFocusing = false"
            @click="generate(reports.extendedBom.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-invoice-dollar fa-swap-opacity"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-invoice-dollar fa-swap-opacity"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">Extended BOM</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                Extended Bill of Materials
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>Extended BOM:</b> "Extended Bill of Materials"<br />
                  Similar to the “BOM”, but includes more equipment info such as weight, power, etc.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.areaGroupsBOM.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="
              reports.areaGroupsBOM.isHovering || reports.areaGroupsBOM.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.areaGroupsBOM.isHovering ? 9 : 2"
            @mouseenter="reports.areaGroupsBOM.isHovering = true"
            @mouseleave="reports.areaGroupsBOM.isHovering = false"
            @focus="reports.areaGroupsBOM.isFocusing = true"
            @blur="reports.areaGroupsBOM.isFocusing = false"
            @click="generate(reports.areaGroupsBOM.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-invoice"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-invoice"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold"
                >Area Groups BOM</v-card-title
              >
              <v-card-subtitle class="text-left font-weight-bold">
                Bill of Materials with Area Groups
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>Area Groups BOM:</b> "Area Groups Bill of Materials"<br />
                  Similar to the “BOM”, but includes more equipment area groups.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.pif.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="reports.pif.isHovering || reports.pif.isFocusing ? 'report-card-hovering' : ''"
            :elevation="reports.pif.isHovering ? 9 : 2"
            @mouseenter="reports.pif.isHovering = true"
            @mouseleave="reports.pif.isHovering = false"
            @focus="reports.pif.isFocusing = true"
            @blur="reports.pif.isFocusing = false"
            @click="generate(reports.pif.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-chart-pie"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-chart-pie"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">PIF</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                Price Intake Form
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>PIF:</b> "Price Intake Form"<br />
                  This workbook is intended to provide the customer with the means to request
                  detailed pricing for the project on a room-by-room basis from each contractor.
                  This form would have the appropriate “Grey” fields filled out by the contractor
                  and the workbook is designed to tabulate data for total project pricing.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.opc.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="reports.opc.isHovering || reports.opc.isFocusing ? 'report-card-hovering' : ''"
            :elevation="reports.opc.isHovering ? 9 : 2"
            @mouseenter="reports.opc.isHovering = true"
            @mouseleave="reports.opc.isHovering = false"
            @focus="reports.opc.isFocusing = true"
            @blur="reports.opc.isFocusing = false"
            @click="generate(reports.opc.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-exclamation"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-exclamation"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">OPC</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                Opinion of Probable Cost
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>OPC:</b> "Opinion of Probable Cost"<br />
                  This workbook is intended to provide the customer anticipated pricing for the
                  overall project. This is accomplished by applying a "installation factor” to the
                  total equipment cost. This factor is used as a means to predict potential labor
                  costs as a “opinion of probable cost” for the entire project.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.proposal.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="
              reports.proposal.isHovering || reports.proposal.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.proposal.isHovering ? 9 : 2"
            @mouseenter="reports.proposal.isHovering = true"
            @mouseleave="reports.proposal.isHovering = false"
            @focus="reports.proposal.isFocusing = true"
            @blur="reports.proposal.isFocusing = false"
            @click="generate(reports.proposal.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-contract"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-contract"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold"
                >Client Proposal</v-card-title
              >
              <v-card-subtitle class="text-left font-weight-bold">
                Client Proposal for a Contract
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>BIN:</b> "Bid Instruction Narrative"<br />
                  This report is quite different from the reports which represent filtered views of
                  project information. This is primarily a text document (in paragraph from) meant
                  to be a starting point for the DNA Associate Consultant to create the project Bid
                  Instructions. This document holds key standards that should be included in each
                  project narrative. The document then needs to be reviewed and adjusted according
                  to the project and the requirements of the bidding process.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.bin.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="reports.bin.isHovering || reports.bin.isFocusing ? 'report-card-hovering' : ''"
            :elevation="reports.bin.isHovering ? 9 : 2"
            @mouseenter="reports.bin.isHovering = true"
            @mouseleave="reports.bin.isHovering = false"
            @focus="reports.bin.isFocusing = true"
            @blur="reports.bin.isFocusing = false"
            @click="generate(reports.bin.value)"
          >
            <div class="report-icon">
              <i class="fad fa-file-lines"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-file-lines"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">BIN</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                Bid Instruction Narrative
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>BIN:</b> "Bid Instruction Narrative"<br />
                  This report is quite different from the reports which represent filtered views of
                  project information. This is primarily a text document (in paragraph from) meant
                  to be a starting point for the DNA Associate Consultant to create the project Bid
                  Instructions. This document holds key standards that should be included in each
                  project narrative. The document then needs to be reviewed and adjusted according
                  to the project and the requirements of the bidding process.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.spaceList.isHovering ? 'info' : 'white'"
            class="report-card"
            :class="
              reports.spaceList.isHovering || reports.spaceList.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.spaceList.isHovering ? 9 : 2"
            @mouseenter="reports.spaceList.isHovering = true"
            @mouseleave="reports.spaceList.isHovering = false"
            @focus="reports.spaceList.isFocusing = true"
            @blur="reports.spaceList.isFocusing = false"
            @click="generate(reports.spaceList.value)"
          >
            <div class="report-icon">
              <i class="fad fa-ballot"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-ballot"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">Space List</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                List of Project's Spaces
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>Space List:</b> "List of Project's Spaces"<br />
                  This is a report which shows all the spaces in the project along with the Space
                  Description, associated Level, and associated Building.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.drawingSheetList.isHovering ? 'red' : 'white'"
            class="report-card"
            :class="
              reports.drawingSheetList.isHovering || reports.drawingSheetList.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.drawingSheetList.isHovering ? 9 : 2"
            @mouseenter="reports.drawingSheetList.isHovering = true"
            @mouseleave="reports.drawingSheetList.isHovering = false"
            @focus="reports.drawingSheetList.isFocusing = true"
            @blur="reports.drawingSheetList.isFocusing = false"
            @click="generate(reports.drawingSheetList.value)"
          >
            <div class="report-icon">
              <i class="fad fa-drafting-compass"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-drafting-compass"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold"
                >Drawing Sheet List</v-card-title
              >
              <v-card-subtitle class="text-left font-weight-bold">
                List of Drawing Documents
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>Drawing Sheet List:</b> "List of Drawing Documents/Sheets"<br />
                  This is a report which shows all the drawing documents/sheets in the project.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="10" lg="4" md="4" sm="12" class="pa-2" style="overflow: visible !important">
          <v-card
            :color="reports.revitFamilies.isHovering ? 'red' : 'white'"
            class="report-card"
            :class="
              reports.revitFamilies.isHovering || reports.revitFamilies.isFocusing
                ? 'report-card-hovering'
                : ''
            "
            :elevation="reports.revitFamilies.isHovering ? 9 : 2"
            @mouseenter="reports.revitFamilies.isHovering = true"
            @mouseleave="reports.revitFamilies.isHovering = false"
            @focus="reports.revitFamilies.isFocusing = true"
            @blur="reports.revitFamilies.isFocusing = false"
            @click="generate(reports.revitFamilies.value)"
          >
            <div class="report-icon">
              <i class="fad fa-square-r"></i>
            </div>
            <div class="report-icon-ghost">
              <i class="fad fa-square-r"></i>
            </div>
            <div class="card-text">
              <v-card-title class="text-left d-block font-weight-bold">Revit Families</v-card-title>
              <v-card-subtitle class="text-left font-weight-bold">
                List of Revit Families
              </v-card-subtitle>
            </div>
            <v-card-actions>
              <v-tooltip bottom nudge-top="20" max-width="320px">
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-2 text--secondary" v-bind="attrs" v-on="on">
                    <i class="far fa-info"></i>
                  </div>
                </template>
                <span style="font-size: 12px">
                  <b>Drawing Sheet List:</b> "List of Drawing Documents/Sheets"<br />
                  This is a report which shows all the drawing documents/sheets in the project.
                </span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <slideout
      dock="right"
      size="1200px"
      :visible.sync="slideouts.createReport.active"
      @close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <i class="fad fa-plus-square mr-2"></i>
          Generate a Report
        </h3>
        <v-btn @click="discardGenerate()" icon><i class="far fa-times"></i></v-btn>
      </template>
      <v-container full class="px-3 pt-3" style="height: 100%">
        <v-row no-gutters style="height: 100%; display: flex">
          <v-col cols="12" md="3" style="overflow: auto">
            <div class="pr-4">
              <v-row class="mt-0" no-gutters v-if="selectedReport">
                <v-col cols="12" md="12" class="d-flex justify-center">
                  <div class="report-title-icon">
                    <i :class="selectedReport.icon"></i>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <h3 class="pa-1 text-center">
                    <b>{{ selectedReport.text }}</b> Report
                    <br />
                    <span class="text--secondary font-weight-medium text-center"
                      >"{{ selectedReport.fullText }}"</span
                    >
                  </h3>
                </v-col>
              </v-row>
              <v-divider class="mt-3"></v-divider>
              <v-row class="mt-0" justify="space-between">
                <v-col
                  cols="12"
                  v-if="
                    selectedReport &&
                    selectedReport.value != enums.REPORT_TYPE.DrawingSheetList.value &&
                    selectedReport.value != enums.REPORT_TYPE.Proposal.value
                  "
                >
                  <h4 class="secondary--text">Include Spaces:</h4>
                  <v-radio-group
                    v-model="reportSelectionScope"
                    :disabled="slideouts.createReport.reportGenerated"
                    mandatory
                    hide-details
                    class="mt-1"
                    row
                  >
                    <v-radio :value="ScopeOptions.AllSpaces" color="info">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reportSelectionScope == ScopeOptions.AllSpaces
                              ? 'info--text'
                              : 'secondary--text opacity-72'
                          "
                        >
                          All Spaces
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="ScopeOptions.SelectedSpaces" color="info" class="mt-2">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reportSelectionScope == ScopeOptions.SelectedSpaces
                              ? 'info--text'
                              : 'secondary--text opacity-72'
                          "
                        >
                          Selected Spaces
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="ScopeOptions.SelectedLevels" color="info" class="mt-2">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reportSelectionScope == ScopeOptions.SelectedLevels
                              ? 'info--text'
                              : 'secondary--text opacity-72'
                          "
                        >
                          Selected Buildings/Levels
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <v-col
                  cols="12"
                  v-else-if="
                    selectedReport &&
                    selectedReport.value == enums.REPORT_TYPE.DrawingSheetList.value
                  "
                >
                  <h4 class="secondary--text">Export a Drawing Set Associated with:</h4>
                  <v-radio-group
                    v-model="reportAssociateType"
                    :disabled="slideouts.createReport.reportGenerated"
                    mandatory
                    class="mt-1"
                    column
                    hide-details
                  >
                    <v-radio :value="null">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text': reportAssociateType == null,
                          }"
                        >
                          All Drawing Documents
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text':
                              reportAssociateType == enums.ASSOCIATE_TYPE.NotAssociated.value,
                          }"
                        >
                          Not Associated
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Space.value">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text':
                              reportAssociateType == enums.ASSOCIATE_TYPE.Space.value,
                          }"
                        >
                          Space
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text':
                              reportAssociateType == enums.ASSOCIATE_TYPE.Level.value,
                          }"
                        >
                          Level
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text':
                              reportAssociateType == enums.ASSOCIATE_TYPE.Building.value,
                          }"
                        >
                          Building
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  v-if="selectedReport && selectedReport.value != enums.REPORT_TYPE.Proposal.value"
                >
                  <h4 class="secondary--text">Included List Sheet:</h4>

                  <v-radio-group
                    v-model="includedListSheet"
                    :disabled="slideouts.createReport.reportGenerated"
                    mandatory
                    class="mt-1"
                    column
                    hide-details
                  >
                    <v-radio :value="0">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text': reportAssociateType == 0,
                          }"
                        >
                          Space List Sheet
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="1">
                      <template v-slot:label>
                        <div
                          class="v-switch-label d-block"
                          :class="{
                            'secondary--text': reportAssociateType == 1,
                          }"
                        >
                          Area Groups List Sheet
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>

                <!-- Installers  -->
                <v-col
                  cols="12"
                  v-if="
                    selectedReport &&
                    (selectedReport.value == enums.REPORT_TYPE.PIF.value ||
                      selectedReport.value == enums.REPORT_TYPE.OPC.value)
                  "
                >
                  <h4 class="secondary--text mb-2">Included Installers:</h4>
                  <template>
                    <v-container
                      fluid
                      style="padding: 0px; margin: 0px; gap: 0.5rem"
                      class="d-flex flex-row flex-wrap align-center"
                    >
                      <v-checkbox
                        class="check-box-installer"
                        v-for="item in allInstallers"
                        :key="item.id"
                        v-model="selectedInstallers"
                        :label="item.name"
                        :value="item.name"
                      ></v-checkbox>
                    </v-container>
                  </template>
                </v-col>

                <v-divider class="my-4"></v-divider>
                <v-col cols="12" v-if="selectedReport">
                  <h4 class="secondary--text">Report Target:</h4>
                  <v-radio-group
                    v-if="
                      selectedReport && selectedReport.value != enums.REPORT_TYPE.Proposal.value
                    "
                    v-model="reportExternalTarget"
                    :disabled="
                      selectedReport.value != enums.REPORT_TYPE.Proposal.value &&
                      (slideouts.createReport.reportGenerated ||
                        (selectedReport.value != enums.REPORT_TYPE.DrawingSheetList.value &&
                          selectedReport.value != enums.REPORT_TYPE.AreaGroupsBOM.value))
                    "
                    mandatory
                    class="mt-1"
                    column
                    hide-details
                  >
                    <v-radio :value="false" color="info">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            !reportExternalTarget ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          Internal
                        </div>
                      </template>
                    </v-radio>
                    <v-radio :value="true" color="info">
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reportExternalTarget ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          External Client
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>

                  <v-radio-group
                    v-model="reporProposalType"
                    v-if="
                      selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                    "
                    mandatory
                    class="mt-1 mb-0"
                    column
                    hide-details
                  >
                    <v-radio
                      v-if="
                        selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                      "
                      :value="1"
                      color="info"
                    >
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reporProposalType == 1 ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          AV Proposal
                        </div>
                      </template>
                    </v-radio>

                    <v-radio
                      v-if="
                        selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                      "
                      :value="2"
                      color="info"
                    >
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reporProposalType == 2 ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          Telecom Proposal
                        </div>
                      </template>
                    </v-radio>

                    <v-radio
                      v-if="
                        selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                      "
                      :value="3"
                      color="info"
                    >
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reporProposalType == 3 ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          MT.EN Proposal
                        </div>
                      </template>
                    </v-radio>

                    <v-radio
                      v-if="
                        selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                      "
                      :value="4"
                      color="info"
                    >
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reporProposalType == 4 ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          Fee Tables Proposal
                        </div>
                      </template>
                    </v-radio>

                    <v-radio
                      v-if="
                        selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value
                      "
                      :value="5"
                      color="info"
                    >
                      <template v-slot:label>
                        <div
                          class="font-weight-medium fs-15px"
                          :class="
                            reporProposalType == 5 ? 'info--text' : 'secondary--text opacity-72'
                          "
                        >
                          Combined Proposal
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  v-if="selectedReport && selectedReport.value == enums.REPORT_TYPE.PIF.value"
                  class="pl-3"
                >
                  <h4 class="secondary--text"></h4>
                  <v-checkbox v-model="isConsolidated" color="info" class="ma-0 pa-0" hide-details>
                    <template v-slot:label>
                      <label class="v-switch-label ma-0">Consolidated Report</label>
                    </template></v-checkbox
                  >
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-divider
            vertical
            v-if="slideouts.createReport.isLoading && !slideouts.createReport.reportGenerated"
          >
          </v-divider>
          <v-col cols="12" md="9" style="height: 100%; display: flex">
            <v-fade-transition mode="out-in">
              <div
                key="spaces-selector"
                v-if="!slideouts.createReport.isLoading && !slideouts.createReport.reportGenerated"
                style="
                  background: #eceff1;
                  border-radius: 0.5rem;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  flex: 1 1 auto;
                  overflow: hidden;
                "
                class="py-5 px-3 inset-shadow"
              >
                <div
                  class="d-flex align-center justify-center"
                  style="height: 100%"
                  v-if="
                    selectedReport &&
                    selectedReport.value == enums.REPORT_TYPE.DrawingSheetList.value
                  "
                >
                  <v-scroll-y-transition mode="out-in">
                    <p
                      v-if="reportAssociateType == null"
                      key="ASSOCIATE_TYPE.All"
                      class="text-center pa-4 d-flex flex-column justify-content-center align-center"
                    >
                      <img width="350" src="/img/art/_clip-science.png" />
                      <span class="font-weight-bold fs-16px">
                        All Project Drawing Documents Will Be Included in This Report!
                      </span>
                    </p>
                    <p
                      v-else-if="reportAssociateType == enums.ASSOCIATE_TYPE.NotAssociated.value"
                      key="ASSOCIATE_TYPE.NotAssociated"
                      class="text-center pa-4 d-flex flex-column justify-content-center align-center"
                    >
                      <i class="fad fa-link-slash mb-6" style="font-size: 4rem"></i>
                      <span class="font-weight-bold fs-16px">
                        Only Non Associated Drawing Documents Will Be Included in This Report!
                      </span>
                    </p>
                    <div
                      key="ASSOCIATE_TYPE.Space"
                      v-else-if="reportAssociateType == enums.ASSOCIATE_TYPE.Space.value"
                    >
                      <div class="text-center">
                        <i class="fad fa-table-pivot mb-6" style="font-size: 4rem"></i>
                      </div>
                      <p class="font-weight-bold fs-16px">
                        Only Drawing Set Documents for the Selected Space Will Be Included in This
                        Report!
                      </p>
                      <project-space-selector
                        :label="`<i class='fad fa-table-pivot mr-1'></i> Associated Space`"
                        :project-id="projectId"
                        v-model="reportAssociateTypeId"
                        ref="projectSpaceSelector"
                      ></project-space-selector>
                    </div>
                    <div
                      key="ASSOCIATE_TYPE.Level"
                      v-else-if="reportAssociateType == enums.ASSOCIATE_TYPE.Level.value"
                    >
                      <div class="text-center">
                        <i class="fad fa-layer-group mb-6" style="font-size: 4rem"></i>
                      </div>
                      <p class="font-weight-bold fs-16px">
                        Only Drawing Set Documents for the Selected Level Will Be Included in This
                        Report!
                      </p>
                      <project-level-selector
                        :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                        :project-id="projectId"
                        v-model="reportAssociateTypeId"
                        ref="projectLevelSelector"
                      ></project-level-selector>
                    </div>
                    <div
                      key="ASSOCIATE_TYPE.Building"
                      v-else-if="reportAssociateType == enums.ASSOCIATE_TYPE.Building.value"
                    >
                      <div class="text-center">
                        <i class="fad fa-building mb-6" style="font-size: 4rem"></i>
                      </div>
                      <p class="font-weight-bold fs-16px">
                        Only Drawing Set Documents for the Selected Building Will Be Included in
                        This Report!
                      </p>
                      <project-building-selector
                        :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                        :project-id="projectId"
                        v-model="reportAssociateTypeId"
                        ref="projectBuildingSelector"
                      ></project-building-selector>
                    </div>
                  </v-scroll-y-transition>
                </div>
                <div
                  class="d-flex align-center justify-center"
                  style="height: 100%"
                  v-else-if="
                    selectedReport && selectedReport.value != enums.REPORT_TYPE.Proposal.value
                  "
                >
                  <v-scroll-y-transition mode="out-in">
                    <p
                      v-if="reportSelectionScope == ScopeOptions.AllSpaces"
                      key="reportSelectionScope-AllSpaces"
                      class="text-center pa-4 d-flex flex-column justify-content-center align-center"
                    >
                      <img width="350" src="/img/art/_clip-science.png" />
                      <span class="font-weight-bold fs-16px">
                        All project spaces will be included in this report!
                      </span>
                    </p>
                    <v-container
                      v-else-if="reportSelectionScope == ScopeOptions.SelectedSpaces"
                      key="reportSelectionScope-SelectedSpaces"
                      class="pa-0"
                      style="height: 100%; display: flex; flex-direction: column"
                    >
                      <v-row justify="space-between">
                        <v-col cols="12" md="4">
                          <h4 class="ma-0 fs-16px font-size-bold">
                            <b class="ml-1 info--text">
                              {{ selectedSpaces.length }}
                            </b>
                            Selected Spaces
                          </h4>
                        </v-col>
                        <v-col
                          cols="12"
                          md="8"
                          class="align-center flex-wrap justify-end py-0"
                          :class="{
                            'd-flex': reportSelectionScope == ScopeOptions.SelectedSpaces,
                          }"
                          style="gap: 0.5rem"
                        >
                          <filter-menu
                            id="reportBuildings"
                            title="Building"
                            v-model="options.buildingIds"
                            :items="buildings"
                          >
                            <template #[`item`]="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </filter-menu>
                          <filter-menu
                            id="levels"
                            title="Level"
                            :disabled="!levels.length"
                            v-model="options.levelIds"
                            :items="levels"
                          >
                            <template #[`item`]="{ item }">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </filter-menu>
                          <filter-menu
                            id="SpaceTemplates"
                            title="Space Template"
                            v-model="options.spaceTemplateIds"
                            baseUrl="SpaceTemplates"
                          >
                            <template #[`item`]="{ item }">
                              <v-list-item-avatar class="mr-3 my-0" rounded>
                                <v-img
                                  height="25"
                                  width="25"
                                  v-if="item.imageUrl"
                                  :src="item.imageUrl"
                                  class="img"
                                  position="center center"
                                  contain
                                ></v-img>
                                <i v-else :class="'fad fa-vector-square'"></i>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </filter-menu>

                          <v-sheet
                            height="28"
                            width="1"
                            color="blue-grey lighten-4"
                            class="mx-1"
                          ></v-sheet>
                          <v-text-field
                            v-model="options.search"
                            ref="mainSearch"
                            class="table-search-field reports-spaces-search"
                            @focus="mainSearchInFocus = true"
                            @blur="mainSearchInFocus = false"
                            :style="{
                              'max-width':
                                mainSearchInFocus ||
                                (options.search != '' && options.search != null)
                                  ? '200px'
                                  : '110px',
                            }"
                            label="Search"
                            dense
                            solo
                            clearable
                            prepend-inner-icon="far fa-search"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        class="mt-0 pt-2"
                        justify="space-between"
                        style="height: 100%; display: flex"
                      >
                        <v-col cols="12" md="12" style="height: 100%; display: flex">
                          <v-data-table
                            style="
                              overflow: auto;
                              overflow: overlay;
                              height: 100%;
                              flex: 1 1 auto;
                              display: flex;
                              flex-direction: column;
                            "
                            dense
                            v-show="reportSelectionScope == ScopeOptions.SelectedSpaces"
                            :headers="headers"
                            v-model="selectedSpaces"
                            :items="entities"
                            show-select
                            :options.sync="options"
                            :server-items-length="total"
                            :items-per-page="15"
                            :loading="loadingStates.table"
                            class="elevation-2 table-sticky-header-exclude-footer"
                            :footer-props="{
                              showFirstLastPage: true,
                              firstIcon: 'far fa-arrow-to-left',
                              lastIcon: 'far fa-arrow-to-right',
                              prevIcon: 'far fa-angle-left',
                              nextIcon: 'far fa-angle-right',
                              itemsPerPageOptions: [15, 30, 50, 100],
                            }"
                            item-key="id"
                          >
                            <template v-slot:[`header.data-table-select`]="{ props, on }">
                              <v-tooltip top z-index="999" nudge-top="-4px">
                                <template v-slot:activator="{ on: ont, attrs }">
                                  <div v-bind="attrs" v-on="ont">
                                    <v-simple-checkbox
                                      color="info"
                                      :ripple="false"
                                      v-bind="props"
                                      v-on="on"
                                      key="user-header-select"
                                    ></v-simple-checkbox>
                                  </div>
                                </template>
                                <span>Select all spaces on THIS PAGE ONLY!</span>
                              </v-tooltip>
                            </template>

                            <template v-slot:[`item.imageUrl`]="{ item }">
                              <v-badge
                                color="info"
                                left
                                :value="selectedSpaces.id == item.id"
                                offset-x="15px"
                                offset-y="38px"
                              >
                                <template v-slot:badge>
                                  <i class="fas fa-wrench mb-0"></i>
                                </template>
                                <v-tooltip right z-index="999" nudge-right="-4px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                      <div class="space-img">
                                        <v-img
                                          v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                          class="img"
                                          :src="item.imageUrl"
                                          height="100%"
                                          width="100%"
                                          contain
                                        ></v-img>
                                        <i v-else :class="'fad fa-vector-square'"></i>
                                      </div>
                                    </div>
                                  </template>
                                  <span>
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="250px"
                                      width="250px"
                                      contain
                                    ></v-img>
                                    <i
                                      v-else
                                      :class="'fad fa-vector-square'"
                                      style="font-size: 32px; padding: 2rem"
                                    ></i>
                                  </span>
                                </v-tooltip>
                              </v-badge>
                            </template>

                            <template v-slot:[`item.description`]="{ item }">
                              <p class="ma-0 mt-2">
                                <code class="text-caption">#{{ item.id }}</code>
                              </p>
                              <strong>{{ item.name }}</strong>
                              <p>{{ item.description }}</p>
                            </template>

                            <template v-slot:[`item.createdBy`]="{ item }">
                              <user-avatar :user="item.createdBy" icon></user-avatar>
                            </template>

                            <template v-slot:[`item.createDate`]="{ item }">
                              <dater :date="item.createDate" dateonly></dater>
                            </template>

                            <template v-slot:[`item.updateDate`]="{ item }">
                              <dater :date="item.updateDate" dateonly></dater>
                            </template>

                            <template v-slot:[`item.equipmentsCount`]="{ item }">
                              <equipment-count :count="getEquipmentsCount(item)" />
                            </template>

                            <template v-slot:[`item.equipmentCount`]="{ item }">
                              <equipment-count :count="item.equipmentCount" />
                            </template>

                            <template v-slot:[`item.updatedBy`]="{ item }">
                              <span
                                >{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</span
                              >
                            </template>

                            <template v-slot:no-data>
                              <img width="300" src="/img/art/fogg-no-connection-2.png" />
                              <p class="font-weight-bold">No Data Available!</p>
                            </template>

                            <template v-slot:loading>
                              <video
                                width="250"
                                muted
                                loop
                                autoplay
                                style="padding: 0.5rem; opacity: 0.64"
                              >
                                <source
                                  src="/img/art/astronaut-loves-music-4980476-4153140.mp4"
                                  type="video/mp4"
                                />
                              </video>
                              <p class="font-weight-bold">Searching the Cosmos...</p>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container
                      v-else-if="reportSelectionScope == ScopeOptions.SelectedLevels"
                      key="reportSelectionScope-SelectedLevels"
                      style="height: 100%; display: flex; flex-direction: column"
                    >
                      <v-scroll-y-transition mode="out-in">
                        <p
                          class="mb-0 d-flex align-center"
                          key="scope-view-sheets-loading"
                          v-if="selectedLevelsData.loading"
                        >
                          <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                          <span class="font-weight-bold fs-14px"> Processing... </span>
                        </p>
                        <div key="scope-view-sheets-tree" v-else>
                          <p class="mb-0 font-weight-bold fs-16px mb-2">
                            <b class="info--text">{{ selectedLevelsData.levelIds.length }}</b>
                            Selected Levels
                          </p>
                          <div
                            style="border-radius: 0.5rem"
                            class="pa-2 buildings-tree-wrapper white elevation-2"
                          >
                            <v-treeview
                              v-model="selectedLevelsData.levelIds"
                              dense
                              open-on-click
                              transition
                              selectable
                              selected-color="orange darken-2"
                              :items="selectedLevelsData.tree"
                            >
                              <template v-slot:prepend="{ item }">
                                <i
                                  class="fad fa-building tree-building"
                                  v-if="item.type == 'Building'"
                                ></i>
                                <i class="fad fa-layer-group tree-level" v-else></i>
                              </template>
                              <template v-slot:label="{ item }">
                                <span class="tree-building" v-if="item.type == 'Building'"
                                  >{{ item.name }}
                                  <v-chip
                                    label
                                    v-if="item.area != null && item.area != 0"
                                    x-small
                                    color="secondary"
                                    class="ml-2 font-weight-bold px-1 justify-center d-inline-flex fs-11px"
                                    style="line-height: 0"
                                  >
                                    {{ item.area | numberWithCommas }}
                                    <span class="ml-1">SQFT</span>
                                  </v-chip>
                                </span>
                                <span class="tree-level" v-else>{{ item.name }}</span>
                              </template>
                            </v-treeview>
                          </div>
                        </div>
                      </v-scroll-y-transition>
                    </v-container>
                  </v-scroll-y-transition>
                </div>

                <div
                  v-if="selectedReport && selectedReport.value == enums.REPORT_TYPE.Proposal.value"
                >
                  <v-scroll-y-transition mode="out-in" v-if="reporProposalType == 5">
                    <v-container>
                      <v-row align="center">
                        <!-- Adjust alignment as needed -->
                        <v-col cols="auto">
                          <v-checkbox v-model="combinedProposal.av.checked"> </v-checkbox>
                        </v-col>

                        <v-col cols="3">
                          <div class="font-weight-medium fs-15px secondary--text opacity-72'">
                            AV Proposal
                          </div>
                        </v-col>

                        <v-col>
                          <project-contract-selector
                            :project-id="projectId"
                            :readonly="!combinedProposal.av.checked"
                            v-model="combinedProposal.av.contractId"
                            ref="projectSpaceSelector"
                          >
                          </project-contract-selector>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-scroll-y-transition>

                  <v-scroll-y-transition mode="out-in" v-if="reporProposalType == 5">
                    <v-container>
                      <v-row align="center">
                        <!-- Adjust alignment as needed -->
                        <v-col cols="auto">
                          <v-checkbox v-model="combinedProposal.telecom.checked"> </v-checkbox>
                        </v-col>

                        <v-col cols="3">
                          <div class="font-weight-medium fs-15px secondary--text opacity-72'">
                            Telecom Proposal
                          </div>
                        </v-col>

                        <v-col>
                          <project-contract-selector
                            :readonly="!combinedProposal.telecom.checked"
                            :project-id="projectId"
                            v-model="combinedProposal.telecom.contractId"
                            ref="projectSpaceSelector"
                          >
                          </project-contract-selector>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-scroll-y-transition>

                  <v-scroll-y-transition mode="out-in" v-if="reporProposalType == 5">
                    <v-container>
                      <v-row align="center">
                        <!-- Adjust alignment as needed -->
                        <v-col cols="auto">
                          <v-checkbox v-model="combinedProposal.security.checked"> </v-checkbox>
                        </v-col>

                        <v-col cols="3">
                          <div class="font-weight-medium fs-15px secondary--text opacity-72'">
                            Security Proposal
                          </div>
                        </v-col>

                        <v-col>
                          <project-contract-selector
                            :project-id="projectId"
                            :readonly="!combinedProposal.security.checked"
                            v-model="combinedProposal.security.contractId"
                            ref="projectSpaceSelector"
                          >
                          </project-contract-selector>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-scroll-y-transition>
                  <v-scroll-y-transition mode="out-in">
                    <v-container key="reportSelectionScope-SelectedContract">
                      <project-contract-selector
                        :project-id="projectId"
                        v-if="reporProposalType != 5"
                        v-model="reportAssociateContractId"
                        ref="projectSpaceSelector"
                      ></project-contract-selector>

                      <company-contact-selector
                        v-if="reporProposalType != 3"
                        v-model="reportAssociateContactId"
                        :project-id="projectId"
                        ref="projectSpaceSelector"
                      ></company-contact-selector>

                      <v-text-field-alt
                        v-if="reporProposalType == 3"
                        :rules="[
                          allRules.noWhiteSpaces,
                          allRules.required,
                          allRules.numberMin(0.01),
                          allRules.number,
                        ]"
                        label="AV Design Fees"
                        id="proposalFees"
                        v-model="propsalContractFee"
                        dense
                      >
                      </v-text-field-alt>

                      <v-row>
                        <v-col cols="12" md="4" v-if="reporProposalType != 4">
                          <v-menu
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :nudge-top="20"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field-alt
                                :rules="[allRules.required]"
                                prepend-inner-icon="mdi-calendar"
                                id="Project-kickoff"
                                label="Project Kickoff Date"
                                placeholder="Project Kickoff Date"
                                v-model="projectKickoffDate"
                                style="cursor: pointer !important"
                                class="calendar-input"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                hide-details
                              ></v-text-field-alt>
                            </template>
                            <v-date-picker
                              :first-day-of-week="1"
                              v-model="projectKickoffDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" md="4" v-if="reporProposalType != 4">
                          <v-menu
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :nudge-top="20"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field-alt
                                :rules="[allRules.required]"
                                prepend-inner-icon="mdi-calendar"
                                id="Project-completion"
                                label="Project Completion Date"
                                placeholder="Project Completion Date"
                                v-model="projectCompletionDate"
                                style="cursor: pointer !important"
                                class="calendar-input"
                                v-bind="attrs"
                                v-on="on"
                                readonly
                                hide-details
                              ></v-text-field-alt>
                            </template>
                            <v-date-picker
                              :first-day-of-week="1"
                              v-model="projectCompletionDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" :md="reporProposalType == 4 ? 12 : 4">
                          <v-menu
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :nudge-top="20"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field-alt
                                :rules="[allRules.required]"
                                prepend-inner-icon="mdi-calendar"
                                id="Proposal-Date"
                                label="Proposal Date"
                                placeholder="Proposal Date"
                                style="cursor: pointer !important"
                                v-model="proposalDate"
                                class="calendar-input"
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field-alt>
                            </template>
                            <v-date-picker
                              :first-day-of-week="1"
                              v-model="proposalDate"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-scroll-y-transition>
                </div>
              </div>
              <div
                key="loading-anim"
                v-if="slideouts.createReport.isLoading && !slideouts.createReport.reportGenerated"
                class="d-flex flex-column align-center justify-center py-5"
                style="width: 100%; height: 100%"
              >
                <video width="320" muted loop autoplay style="padding: 0.5rem; opacity: 0.64">
                  <source
                    src="/img/art/astronaut-mines-coins-4979111-4153144.mp4"
                    type="video/mp4"
                  />
                </video>
                <p class="font-weight-bold">Preparing Report...</p>
              </div>
              <div
                key="result-viewer"
                v-if="slideouts.createReport.reportGenerated && !slideouts.createReport.isLoading"
                class="text-center py-7 mt-3 d-flex justify-center align-center flex-column"
              >
                <div class="report-file-icon" :class="reportFileExt">
                  <i class="fad fa-file-excel" v-if="reportFileExt == 'xlsx'"></i>
                  <i class="fad fa-file-word" v-if="reportFileExt == 'docx'"></i>
                </div>
                <h3 class="report-result mt-2">Report Generated</h3>
                <h3 class="report-name mt-2 text--secondary">
                  {{ reportFileName }}
                </h3>
                <v-btn
                  text
                  color="info"
                  class="report-download-link mt-2"
                  :href="reportUrl"
                  :download="reportFileName"
                >
                  <i class="fad fa-arrow-alt-to-bottom mr-2"></i>Download
                </v-btn>
              </div>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discardGenerate()" v-if="slideouts.createReport.reportGenerated">
            <i class="fal fa-chevron-left mr-2"></i>CLOSE
          </v-btn>
          <v-btn @click="discardGenerate()" v-else>
            <i class="fal fa-chevron-left mr-2"></i>CANCEL
          </v-btn>
          <v-btn
            color="info"
            :disabled="
              slideouts.createReport.isLoading ||
              slideouts.createReport.reportGenerated ||
              (reportSelectionScope == ScopeOptions.SelectedSpaces && selectedSpaces.length == 0) ||
              (reportSelectionScope == ScopeOptions.SelectedLevels &&
                selectedLevelsData.levelIds.length == 0) ||
              !validDrawingSheetSelection ||
              !validInstallerList ||
              !validContractSelection
            "
            @click="generateConfirmed()"
            :loading="slideouts.createReport.isLoading"
          >
            <i class="fal fa-sync mr-2"></i> Generate
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </v-container>
</template>

<script>
import Enums from "../../../plugins/enums";
import projectService from "../services/projects-service";
import installerService from "../../Equipments/services/installers-service";
import buildingsAPI from "../services/buildings-service";
import reportsService from "../services/reports-service";
import spaceService from "../../Spaces/services/spaces-service";
import reportSpaceHeader from "../../Spaces/config/tables/reportSpace.header";
import { eventBus } from "../../../main";
import ProjectSpaceSelector from "../../Shared/components/ProjectSpaceSelector.vue";
import ProjectContractSelector from "../../Contracts/components/ProjectContractSelector.vue";
import CompanyContactSelector from "./CompanyContactSelector.vue";
import ProjectBuildingSelector from "../../Shared/components/ProjectBuildingSelector.vue";
import ProjectLevelSelector from "../../Shared/components/ProjectLevelSelector.vue";

let ScopeOptions = {
  AllSpaces: 1,
  SelectedSpaces: 2,
  SelectedLevels: 3,
};

export default {
  components: {
    ProjectSpaceSelector,
    ProjectBuildingSelector,
    ProjectLevelSelector,
    ProjectContractSelector,
    CompanyContactSelector,
  },
  name: "project-reports",
  data() {
    return {
      datePickerDialog: false,
      projectKickoffDate: new Date().toISOString().split("T")[0],
      projectCompletionDate: new Date().toISOString().split("T")[0],
      proposalDate: new Date().toISOString().split("T")[0],
      ScopeOptions,
      enums: Enums,
      entities: [],
      selectedSpaces: [],
      selectedInstallers: [],
      allInstallers: [],
      selectedReport: null,
      reportFileName: null,
      reportFileExt: null,
      reportUrl: null,
      reportSelectionScope: ScopeOptions.AllSpaces,
      reportAssociateType: null,
      reportAssociateTypeId: null,
      reportAssociateContractId: null,
      reportAssociateContactId: null,
      propsalContractFee: "",
      total: 0,
      combinedProposal: {
        av: {
          checked: false,
          contractId: "",
          combinedProposalType: 1,
        },
        telecom: {
          checked: false,
          contractId: "",
          combinedProposalType: 2,
        },
        security: {
          checked: false,
          contractId: "",
          combinedProposalType: 3,
        },
      },
      REPORT_TYPE_LIST: this.$options.filters.EnumToList(Enums.REPORT_TYPE.PIF, true),
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        buildingIds: [],
        levelIds: [],
        spaceTemplateIds: [],
      },
      loadingStates: {
        table: false,
      },
      loadingInstallers: {
        table: false,
      },
      reports: {
        bom: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.BOM.value,
        },
        extendedBom: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.ExtendedBOM.value,
        },
        pif: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.PIF.value,
        },
        opc: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.OPC.value,
        },
        bin: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.BIN.value,
        },
        spaceList: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.SpaceList.value,
        },
        drawingSheetList: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.DrawingSheetList.value,
        },
        areaGroupsBOM: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.AreaGroupsBOM.value,
        },
        proposal: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.Proposal.value,
        },
        revitFamilies: {
          showDetails: false,
          isHovering: false,
          isFocusing: false,
          value: Enums.REPORT_TYPE.RevitFamilies.value,
        },
      },
      slideouts: {
        createReport: {
          active: false,
          isLoading: false,
          reportGenerated: false,
        },
      },
      sortBy: {
        options: [
          { name: "Space Name", id: "name" },
          { name: "Space Number", id: "spaceNumber" },
        ],
        option: null,
      },
      headers: reportSpaceHeader,
      buildings: [],
      levels: [],
      selectedLevelsData: {
        loading: false,
        levelIds: [],
        tree: [],
      },
      reportExternalTarget: false,
      reporProposalType: 1,
      includedListSheet: 0,
      isConsolidated: false,
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    //get project spaces
    this.sortBy.option = this.sortBy.options[0];
    this.$log("REPORTS FOR", this.projectId);
    this.getBuildingsAndLevels();
  },
  methods: {
    generate(reportType) {
      this.$log("generate >> ", this.getReportEnum(reportType));
      this.selectedReport = this.getReportEnum(reportType);
      this.reportSelectionScope = ScopeOptions.AllSpaces;
      if (this.selectedReport.value == this.enums.REPORT_TYPE.DrawingSheetList.value)
        this.reportExternalTarget = false;
      else this.reportExternalTarget = true;

      this.slideouts.spaceComponentsIsActive = true;
      if (
        this.selectedReport.value == this.enums.REPORT_TYPE.PIF.value ||
        this.selectedReport.value == this.enums.REPORT_TYPE.OPC.value
      )
        this.getInstallers();
      this.isConsolidated = false;
      this.includedListSheet = 0;
      this.includeAreaGroupList = false;
      this.slideouts.createReport.active = true;
    },
    getReportEnum(reportType) {
      return Object.entries(Enums.REPORT_TYPE).find((r) => r[1].value == reportType)[1];
    },
    getProjectSpaces() {
      this.$log("getProjectSpaces()", this.projectId);
      this.loadingStates.table = true;
      projectService
        .getSpaces(this.projectId, this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("getProjectSpaces", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getInstallers() {
      this.$log("getInstallers()", this.projectId);
      this.loadingInstallers.table = true;
      installerService
        .typeHead(null, true)
        .then((resp) => {
          this.allInstallers = resp.data;
          this.selectedInstallers = this.allInstallers.map((e) => e.name);
          this.$log("getInstallers", this.entities);
          this.installersTotal = resp.data.total;
          this.loadingInstallers.table = false;
        })
        .catch((err) => {
          this.loadingInstallers.table = false;
          this.$handleError(err);
        });
    },
    generateConfirmed() {
      this.slideouts.createReport.isLoading = true;
      var selectedCombinedProposal = JSON.parse(JSON.stringify(this.combinedProposal));
      if (!selectedCombinedProposal.av.checked) selectedCombinedProposal.av = null;
      if (!selectedCombinedProposal.telecom.checked) selectedCombinedProposal.telecom = null;
      if (!selectedCombinedProposal.security.checked) selectedCombinedProposal.security = null;

      let toSend = {
        projectId: this.projectId,
        isExternal: this.reportExternalTarget,
        isConsolidated: this.isConsolidated,
        reportSelectionScope: this.reportSelectionScope,
        associateType: this.reportAssociateType,
        associateTypeId: this.reportAssociateTypeId,
        includedListSheet: this.includedListSheet,
        includeAreaGroupList: this.includeAreaGroupList,
        combinedProposal: selectedCombinedProposal,
        spaceIds: [],
        levelIds: [],
        Installers: this.selectedInstallers,
        spacesSortBy: this.sortBy.option ? this.sortBy.option.id : null,
        contractId: this.reportAssociateContractId,
        proposalType: this.reporProposalType,
        projectKickoffDate: this.projectKickoffDate,
        projectCompletionDate: this.projectCompletionDate,
        proposalDate: this.proposalDate,
        propsalContractFee: isNaN(this.propsalContractFee) ? 0 : this.propsalContractFee,

        companyContactId: this.reportAssociateContactId,
      };

      if (
        this.reportSelectionScope == ScopeOptions.SelectedSpaces &&
        this.selectedSpaces.length > 0
      ) {
        toSend.spaceIds = this.selectedSpaces.map((space) => {
          return space.id;
        });
      } else if (
        this.reportSelectionScope == ScopeOptions.SelectedLevels &&
        this.selectedLevelsData.levelIds.length > 0
      ) {
        toSend.levelIds = this.cloneDeep(this.selectedLevelsData.levelIds);
      }
      this.$log("generateConfirmed >> toSend", toSend);

      switch (this.selectedReport.value) {
        case Enums.REPORT_TYPE.BOM.value:
          reportsService.BOM(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.ExtendedBOM.value:
          reportsService.ExtendedBOM(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.AreaGroupsBOM.value:
          reportsService.AreaGroupsBOM(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.PIF.value:
          reportsService.PIF(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.OPC.value:
          reportsService.OPC(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.SpaceList.value:
          reportsService.SpaceList(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.BIN.value:
          reportsService.BIN(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.Proposal.value:
          reportsService.ContractProposal(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.DrawingSheetList.value:
          reportsService.DrawingSheetList(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        case Enums.REPORT_TYPE.RevitFamilies.value:
          reportsService.RevitFamilies(toSend).then(this.onResponse).catch(this.onCatch);
          break;
        default:
          this.$dialog.notify.error("Unsupported Report Type", {
            position: "top-right",
            timeout: 3000,
          });
          this.discardGenerate();
          break;
      }
    },
    onResponse(resp) {
      this.$log(">>> reportsService", resp, resp.data.type, resp.headers["x-file-name"]);
      if (resp.data instanceof Blob) {
        let url = URL.createObjectURL(
          new Blob([resp.data], {
            type: resp.data.type,
          })
        );
        this.$log(">>> reportsService Blob url", url);
        this.reportUrl = url;
        this.reportFileName = resp.headers["x-file-name"];
        this.reportFileExt = resp.headers["x-file-name"].split(".").pop();
        this.slideouts.createReport.isLoading = false;
        this.slideouts.createReport.reportGenerated = true;
        // const link = document.createElement("a");
        // link.href = url;
        // link.download = resp.headers["x-file-name"];
        // link.click();
        // link.remove();
      } else {
        this.$log("report generate resp.data", resp.data);
        this.$notify(resp.data, "info");
        eventBus.$emit("add-to-notification-center", resp.data);
        this.discardGenerate();
      }
    },
    onCatch(err) {
      this.$log(">>> err - reportsService Bom", err);
      this.slideouts.createReport.isLoading = false;
      this.slideouts.createReport.reportGenerated = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
    discardGenerate() {
      this.slideouts.createReport.active = false;
      this.slideouts.createReport.isLoading = false;
      this.slideouts.createReport.reportGenerated = false;
      this.selectedSpaces = [];
      this.selectedLevelsData.levelIds = [];
      this.selectedReport = null;
      this.reportSelectionScope = ScopeOptions.AllSpaces;
      this.reportExternalTarget = false;
      this.isConsolidated = false;
      this.includedListSheet = 0;
      this.includeAreaGroupList = false;
      this.reportUrl = null;
      this.reportFileName = null;
      this.reportFileExt = null;
      this.sortBy.option = null;
    },
    getEquipmentsCount(item) {
      if (item != null && item.areaGroups != null)
        return item.areaGroups.reduce((total, cur) => {
          // return total + cur.count + cur.count * this.getEquipmentAccessoriesCount(cur.equipment);
          return total + this.getAreaGroupEquipmentsCount(cur);
        }, 0);
      else return 0;
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    getEquipmentAccessoriesCount(item) {
      if (item != null && item.accessories != null)
        return item.accessories.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    focus() {
      this.$refs.firstReportBtn.$el.focus();
    },
    onSlideoutClosing() {
      this.discardGenerate();
    },
    // getBuildingsAndLevels() {
    //   spaceService.getBuildingsAndLevels(this.projectId).then((resp) => {
    //     this.buildings = resp.data;
    //   });
    // },
    getBuildingsAndLevels() {
      this.selectedLevelsData.loading = true;
      buildingsAPI
        .getProjectBuildings(this.projectId)
        .then((resp) => {
          this.$log("getBuildingsAndLevels", resp.data.items);
          this.buildings = resp.data.items;
          this.treePrepare(resp.data.items);
          this.selectedLevelsData.loading = false;
        })
        .catch((err) => {
          this.selectedLevelsData.loading = false;
          this.$handleError(err);
        });
    },
    treePrepare(data) {
      this.selectedLevelsData.tree = data.map((elm) => this.getTreeObject(elm));
    },
    getTreeObject(elm) {
      return {
        id: elm.id,
        name: elm.name,
        shortName: elm.shortName,
        area: elm.area,
        description: elm.description,
        type: "Building",
        children: [...elm.levels],
      };
    },
  },
  watch: {
    options: {
      handler() {
        this.getProjectSpaces();
      },
      deep: true,
    },
    reportSelectionScope: {
      handler(newVal) {
        this.options.search = null;
        this.selectedSpaces = [];
        this.selectedLevelsData.levelIds = [];
        this.options.search = null;
      },
    },
    reportAssociateType: {
      handler(newVal) {
        this.reportAssociateTypeId = null;
      },
    },
    "options.buildingIds"(ids) {
      this.options.levelIds = [];
      this.levels = this.buildings
        .filter((elm) => ids.includes(elm.id))
        .map((elm) => elm.levels)
        .flat();
    },
  },
  computed: {
    validInstallerList() {
      if (!this.selectedReport) return false;
      if (
        this.selectedReport.value == this.enums.REPORT_TYPE.PIF.value ||
        this.selectedReport.value == this.enums.REPORT_TYPE.OPC.value
      )
        return this.selectedInstallers.length > 0;
      else return true;
    },
    validDrawingSheetSelection() {
      if (
        this.selectedReport &&
        this.selectedReport.value == this.enums.REPORT_TYPE.DrawingSheetList.value &&
        this.reportAssociateType != null &&
        this.reportAssociateType != this.enums.ASSOCIATE_TYPE.NotAssociated.value &&
        this.reportAssociateTypeId == null
      )
        return false;
      else return true;
    },
    validContractSelection() {
      if (
        !this.selectedReport ||
        this.selectedReport.value != this.enums.REPORT_TYPE.Proposal.value
      )
        return true;
      // AV Proposal
      if (
        this.reporProposalType == 1 &&
        (!this.reportAssociateContactId || !this.reportAssociateContractId)
      )
        return false;

      // Telecom
      if (
        this.reporProposalType == 2 &&
        (!this.reportAssociateContactId || !this.reportAssociateContractId)
      )
        return false;

      // Meta
      if (
        this.reporProposalType == 3 &&
        (!this.reportAssociateContractId || !this.validProposalFees)
      )
        return false;
      // Fee Tables Proposals
      if (
        this.reporProposalType == 4 &&
        (!this.reportAssociateContactId || !this.reportAssociateContractId)
      )
        return false;

      if (this.reporProposalType == 5 && !this.validCombinedProposal) return false;

      return true;
    },
    validCombinedProposal() {
      var checkedReports = [];
      if (this.combinedProposal.av.checked) checkedReports.push(this.combinedProposal.av);
      if (this.combinedProposal.telecom.checked) checkedReports.push(this.combinedProposal.telecom);
      if (this.combinedProposal.security.checked)
        checkedReports.push(this.combinedProposal.security);
      if (checkedReports.length < 2) return false;
      if (checkedReports.some((e) => !e.contractId)) return false;
      if (!this.reportAssociateContactId) return false;
      return true;
    },
    validProposalFees() {
      return (
        this.propsalContractFee &&
        !isNaN(this.propsalContractFee) &&
        !this.propsalContractFee.endsWith(".")
      );
    },
  },
};
</script>

<style lang="scss">
.v-data-table-header {
  th:first-child .v-icon.mdi-minus-box,
  th:first-child .v-icon.mdi-checkbox-marked {
    color: $primary-base !important;
  }
}

.v-data-table__selected {
  background: rgba($primary-base, 0.1) !important;

  .theme--light.v-icon {
    color: $primary-base !important;
  }

  .v-input--selection-controls__ripple {
    color: $primary-base !important;
  }
}

.report-card {
  overflow: hidden;
  transition: all 0.3s ease-out;
  border: 1.5px solid white;
  display: flex;
  flex-direction: row;
  border-radius: 5rem 2.5rem 2.5rem 5rem !important;
  border-radius: 5rem !important;

  .report-icon {
    width: 70px;
    height: 70px;
    // margin: 0 auto;
    // margin-top: 1rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    border-radius: 70px !important;
    background: rgba($shades-black, 0.06);
    color: rgba($shades-black, 0.87);
    border: 1px dashed rgba($shades-black, 0) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }

  .report-icon-ghost {
    position: absolute;
    // right: 1rem;
    // top: 1rem;
    // font-size: 10rem;
    right: 1rem;
    top: -1rem;
    font-size: 8rem;
    opacity: 0.03;
    user-select: none;
    pointer-events: none;
    z-index: 0;
    transition: all 0.3s ease-out;
  }

  .v-card__title,
  .v-card__subtitle {
    transition: all 0.3s ease-out;
    margin: 0;
    padding: 0;
  }

  .v-card__title {
    font-weight: 800;
  }

  .v-card__subtitle {
    opacity: 0.54;
  }

  .card-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.report-card-hovering,
  &:focus {
    border-color: rgba($info-base, 0.54) !important;
    background: var(--v-info-base) !important;
    // color: #fff;

    &:before {
      opacity: 0 !important;
    }

    .v-card__title {
      color: #fff;
    }

    .v-card__subtitle {
      color: rgba(#fff, 1);
    }

    .report-icon {
      background: rgba($info-base, 0.08) !important;
      border-color: rgba($info-base, 0.54) !important;
      color: rgba($info-base, 1);

      background: rgba(#fff, 0.2) !important;
      border-color: rgba(#fff, 0.54) !important;
      color: #fff;
    }

    .report-icon-ghost {
      font-size: 10rem;
      opacity: 0.16;
      color: rgba($info-base, 1);
      color: #fff;
    }
  }

  .v-card__actions {
    display: none !important;
  }
}

.reports-spaces-search .v-text-field__details {
  display: none !important;
}
.check-box-installer {
  margin: 0px;
  padding: 0px;
}
// .check-box-installer:first-child {
//   padding-top: 10px;
// }
.check-box-installer .v-messages {
  display: none;
}
</style>
