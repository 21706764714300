<template>
  <v-tooltip top z-index="999" nudge-top="-4px" v-if="header != null && header.tooltip != null">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        {{ header.text }}
      </span>
    </template>
    <span class="d-flex align-center">{{ header.tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "header-with-tooltip",
  props: {
    header: {
      type: Object,
      default: null,
    },
  },
};
</script>
