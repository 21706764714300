<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1100px'"
    :min-size="nested ? 400 : 1100"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.activityLog.active"
  >
    <template v-slot:header>
      <h4 v-if="spaceId != null" class="font-weight-bold pa-1" small>
        <span>
          <i class="mr-2 fad fa-history"></i>Activity Logs: "<b
            >{{ space.spaceNumber }} {{ space.name }}</b
          >"
        </span>
      </h4>
      <div class="d-flex flex-row align-center">
        <v-btn @click="slideouts.activityLog.active = false" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>
    <div style="background: #eceff1; height: 100%; overflow: auto" class="pr-5 inset-shadow">
      <div style="position: sticky; top: 0; z-index: 1">
        <v-sheet
          elevation="2"
          height="40"
          class="d-flex flex-row align-center px-1"
          style="width: 100%"
        >
          <v-scroll-y-transition mode="out-in">
            <div
              key="range"
              elevation="2"
              height="40"
              class="d-flex flex-row align-center px-1"
              style="width: 100%"
            >
              <h4 class="fs-14px ma-0 ml-1 mr-2" style="font-weight: 600">
                Show Space Changes Since
              </h4>
              <v-menu
                v-model="dateStartMonthMenu"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :nudge-top="20"
                z-index="999999999"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field-alt
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    short
                    class="blue-grey lighten-5 px-0"
                    style="width: 160px; min-width: 160px; border-radius: 0.25rem"
                    id="ChangesSince"
                    placeholder="Select a Date"
                    v-model="options.startDate"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field-alt>
                </template>
                <v-date-picker
                  :first-day-of-week="1"
                  v-model="options.startDate"
                  color="accent"
                  header-color="info darken-2"
                  elevation="4"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-scroll-y-transition>
        </v-sheet>
      </div>
    </div>
    <template v-slot:footer>
      <v-card-actions>
        <v-btn text @click="slideouts.activityLog.active = false">
          <i class="fal fa-times mr-2"></i>
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import Enums from "../../../../plugins/enums";
import spaceService from "../../services/spaces-service";
import AreaGroupsEntryLog from "./AreaGroupsEntryLog.vue";

export default {
  name: "space-changes-since",
  components: {
    // AreaGroupsEntryLog
  },
  data() {
    return {
      ActivityLogType: Enums.ActivityLogType,
      dateStartMonthMenu: false,
      isLoading: false,
      spaceId: null,
      space: null,
      slideouts: {
        activityLog: {
          active: false,
        },
      },
      options: {
        startDate: null,
        endDate: null,
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.spaceId = this.cloneDeep(this.value);
  },
  computed: {
    spaceUrl() {
      if (this.spaceId == null) return "";
      return `space/${this.spaceId}`;
    },
  },
  methods: {
    open(space) {
      this.space = space;
      this.spaceId = space.id;
      this.options = {
        startDate: null,
        endDate: null,
      };
      this.options.endDate = this.formatDate(Date.now());
      this.slideouts.activityLog.active = true;
    },
    getData() {
      this.$log("getData", this.options);
      if (!this.slideouts.activityLog.active || this.isLoading) return;
      let optionsToSend = this.cloneDeep(this.options);
      this.isLoading = true;
      spaceService
        .getFirstAndLastActivityLogInRange(this.spaceId, this.$clean(optionsToSend, true))
        .then((resp) => {
          this.$log("resp.data", resp.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    compare(oldGroups, newGroups) {
      //check old groups
      for (let i = 0; i < oldGroups.length; i++) {
        const oldGroup = oldGroups[i];
        const matchingNewGroup = newGroups.find((ng) => ng.id == oldGroup.id);
        oldGroup.diffs = {};
        if (matchingNewGroup == null) oldGroup.diffs["deleted"] = true;
        else {
          //check for deleted equipment
          for (let j = 0; j < oldGroup.equipments.length; j++) {
            const oldEquip = oldGroup.equipments[j];
            const matchingNewEquip = matchingNewGroup.equipments.find((ne) => ne.id == oldEquip.id);
            oldEquip.diffs = {};
            if (matchingNewEquip == null) {
              // this is a new equipment
              oldEquip.diffs["deleted"] = true;
            }
          }
        }
      }

      //check new groups
      for (let i = 0; i < newGroups.length; i++) {
        const newGroup = newGroups[i];
        const matchingOldGroup = oldGroups.find((og) => og.id == newGroup.id);
        newGroup.diffs = {};
        if (matchingOldGroup == null) {
          // this is a new group
          newGroup.diffs["new"] = true;
        } else {
          //check if group name changed
          if (newGroup.name != matchingOldGroup.name) newGroup.diffs["nameChanged"] = true;

          //check for equipment changes
          var updateCount = 0;
          for (let j = 0; j < newGroup.equipments.length; j++) {
            const newEquip = newGroup.equipments[j];
            const matchingOldEquip = matchingOldGroup.equipments.find((oe) => oe.id == newEquip.id);
            newEquip.diffs = {};
            if (matchingOldEquip != null) {
              //check if the count changed
              if (newEquip.count != matchingOldEquip.count) {
                newEquip.diffs["countChanged"] = true;
                updateCount++;
              }
            } else {
              // this is a new equipment
              newEquip.diffs["new"] = true;
              updateCount++;
            }
          }
          if (updateCount > 0) newGroup.diffs["equipmentUpdated"] = true;

          // add deleted equipments
          var deletedOldEquipment = matchingOldGroup.equipments.filter((e) => e.diffs.deleted);
          if (deletedOldEquipment.length > 0) newGroup.equipments.push(...deletedOldEquipment);
        }
      }

      // add deleted equipments
      var deletedOldGroups = oldGroups.filter((e) => e.diffs.deleted);
      if (deletedOldGroups.length > 0) newGroups.push(...deletedOldGroups);

      return newGroups;
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss"></style>
