export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    width: "40px",
    order: 0,
    icon: "fa-cog",
    class: "px-2",
    cellClass: "px-2",
  },
  {
    text: "Name",
    value: "name",
    width: "200px",
    class: "px-2",
    cellClass: "px-2",
    order: 1,
  },
  // {
  //   text: "Type",
  //   value: "type",
  //   order: 2,
  //   hidable: true,
  //   hidden: true,
  // },
  {
    // text: "Date Scope",
    text: "Scope",
    value: "startDate", //dateRangeType
    width: "100px",
    class: "px-2",
    cellClass: "px-2",
    order: 3,
    sortable: true,
  },
  // {
  //   text: "Start Date",
  //   value: "startDate",
  //   order: 4,
  //   hidable: true,
  //   hidden: true,
  // },
  // {
  //   text: "End Date",
  //   value: "endDate",
  //   order: 5,
  //   hidable: true,
  //   hidden: true,
  // },
  {
    text: "AC",
    value: "totalAC",
    order: 7,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Associate Consultant",
    sortable: false,
  },
  {
    text: "SC",
    value: "totalSC",
    order: 8,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Senior Consultant",
    sortable: false,
  },
  {
    text: "C",
    value: "totalC",
    order: 9,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Consultant",
    sortable: false,
  },
  {
    text: "VI",
    value: "totalVI",
    order: 10,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Virtual Integrator",
    sortable: false,
  },
  {
    text: "BIM",
    value: "totalBIM",
    order: 11,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "BIM Specialist",
    sortable: false,
  },
  {
    text: "MGT",
    value: "totalMGT",
    order: 12,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Management",
    sortable: false,
  },
  {
    text: "TCN",
    value: "totalTCN",
    order: 13,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "Technical",
    sortable: false,
  },
  {
    text: "SCD",
    value: "totalSCD",
    order: 14,
    width: "40px",
    class: "px-2",
    cellClass: "px-2",
    tooltip: "SCD",
    sortable: false,
  },
  {
    text: "Σ Labor Hours",
    value: "totalHours",
    width: "100px",
    class: "px-2",
    cellClass: "px-2",
    order: 15,
    sortable: false,
  },
  {
    text: "Σ Labor Cost",
    value: "totalLaborCost",
    width: "120px",
    class: "px-2",
    cellClass: "px-2",
    order: 16,
    sortable: false,
  },
  {
    text: "Σ Labor Revenue",
    value: "totalLaborRevenue",
    width: "120px",
    class: "px-2",
    cellClass: "px-2",
    order: 17,
    sortable: false,
  },
  {
    text: "Σ Direct Exp Cost",
    value: "totalDirectExpensesCost",
    width: "120px",
    class: "px-2",
    cellClass: "px-2",
    order: 18,
    sortable: false,
  },
  {
    text: "Σ Direct Exp Revenue",
    value: "totalDirectExpensesRevenue",
    width: "140px",
    class: "px-2",
    cellClass: "px-2",
    order: 19,
    sortable: false,
  },
];
