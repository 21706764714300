<template>
  <v-container fluid class="pa-3" style="height: 100%">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      "
      class="pa-3 inset-shadow"
    >
      <div class="d-flex justify-space-between align-center mb-2">
        <h3 class="text--primary font-weight-black mr-3 d-inline-block">Project Variables</h3>
        <transition name="fade" mode="out-in" appear v-if="canEdit">
          <div key="project-info-vars-btns" class="d-flex" style="gap: 0.5rem">
            <v-btn
              color="secondary"
              @click="discard()"
              :disabled="!hasChanges || loading || saveLoading"
            >
              <i class="fal fa-clock-rotate-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              @click="saveVariables()"
              :loading="saveLoading"
              :disabled="!valid || loading || saveLoading || !hasChanges"
            >
              <i class="fal fa-check mr-2"></i>Save Variables
            </v-btn>
          </div>
        </transition>
      </div>
      <v-form
        v-model="valid"
        class="pa-2"
        ref="projectVariablesForm"
        style="height: 100%; overflow: hidden; margin: -0.5rem"
      >
        <div class="d-flex justify-center align-center flex-column" v-if="loading">
          <video
            width="275"
            muted
            loop
            autoplay
            class="inset-shadow-video"
            style="padding: 0.5rem; border-radius: 10rem; background: #fff"
          >
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold mt-2">Searching the Cosmos...</p>
        </div>
        <v-simple-table
          dense
          class="elevation-2 table-sticky-header-exclude-footer"
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
          v-else
        >
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-start" style="width: 275px; min-width: 275px">Variable</th>
                <th class="text-start">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.Number.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.Number.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Installation Factor</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        This helps determine a project installation cost, and it's used in the
                        project overview section and in some reports.
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <!-- <v-text-field-alt
                    v-if="canEdit"
                    :rules="[allRules.number, allRules.requiredNumber]"
                    placeholder="Add valid number"
                    v-model.number="selected.installationFactor"
                    class="d-inline-block"
                  >
                  </v-text-field-alt> -->
                  <counter
                    v-if="canEdit"
                    v-model="selected.installationFactor"
                    :min="0"
                    :max="100"
                    :step="0.05"
                    class="d-inline-block"
                  />
                  <div v-else>{{ selected.installationFactor }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.Number.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.Number.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Project Complexity</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        This is a rough indecation of how complex a project is!
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <!-- <v-text-field-alt
                    v-if="canEdit"
                    :rules="[allRules.number, allRules.requiredNumber]"
                    placeholder="Add valid number"
                    v-model.number="selected.projectComplexity"
                    class="d-inline-block"
                  >
                  </v-text-field-alt> -->
                  <counter
                    v-if="canEdit"
                    v-model="selected.projectComplexity"
                    :min="0.5"
                    :max="100"
                    :step="0.5"
                    class="d-inline-block"
                  />
                  <div v-else class="">{{ selected.projectComplexity }}</div>
                </td>
              </tr>
              <!-- <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.Boolean.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.Boolean.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Auto Create IAV</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        Auto Create IAV Documents when a space is created!
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <v-switch v-if="canEdit" v-model="selected.autoCreateIAV"> </v-switch>
                  <div v-else class="font-weight-medium">
                    {{ selected.autoCreateIAV ? "Yes" : "No" }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.Boolean.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.Boolean.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Auto Create SCD</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        Auto Create SCD Documents when a space is created!
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <v-switch v-if="canEdit" v-model="selected.autoCreateSCD"> </v-switch>
                  <div v-else class="font-weight-medium">
                    {{ selected.autoCreateSCD ? "Yes" : "No" }}
                  </div>
                </td>
              </tr> -->
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fal" :class="variableTypes.RichText.icon"></i>
                        </div>
                      </template>
                      <span>{{ variableTypes.RichText.text }}</span>
                    </v-tooltip>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Description for Reports</h4>
                      <h5 class="mb-1" style="opacity: 0.64; margin-top: 2px">
                        To be used as Project Description for Reports
                      </h5>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <v-scroll-y-transition mode="out-in">
                    <div :key="'editor-block'">
                      <rich-text-editor
                        v-model="selected.systemDescription"
                        title="System Description"
                        :readonly="!canEdit"
                        allowExpand
                      />
                    </div>
                  </v-scroll-y-transition>
                </td>
              </tr>

              <tr>
                <td>
                  <div>
                    <h4 class="mt-1 font-weight-bold">Lead AC</h4>
                  </div>
                </td>
                <td class="py-3">
                  <user-all-selector
                    :users="users"
                    label="Lead AC"
                    hideLabel
                    hideDetails
                    hideBorder
                    :isUsersLoading="isUsersLoading"
                    :readonly="!canEdit"
                    v-model="selected.leadACId"
                    style="width: 100%"
                    class="ml-0"
                  ></user-all-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <h4 class="mt-1 font-weight-bold">Lead VI</h4>
                  </div>
                </td>
                <td class="py-3">
                  <user-all-selector
                    :users="users"
                    label="Lead VI"
                    hideLabel
                    hideDetails
                    hideBorder
                    :readonly="!canEdit"
                    :isUsersLoading="isUsersLoading"
                    v-model="selected.leadVIId"
                    style="width: 100%"
                    class="ml-0"
                  ></user-all-selector>
                </td>
              </tr>

              <tr>
                <td colspan="2">
                  <h2 class="font-weight-black py-3">Naming Templates</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.IAV.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">IAV</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.iavNamingTemplateId"
                    :obj.sync="selected.iavNamingTemplate"
                    :document-type="DocumentType.IAV.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SCD.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">SCD</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.scdNamingTemplateId"
                    :obj.sync="selected.scdNamingTemplate"
                    :document-type="DocumentType.SCD.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.CoverSheet.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Cover Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.coverSheetNamingTemplateId"
                    :obj.sync="selected.coverSheetNamingTemplate"
                    :document-type="DocumentType.CoverSheet.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Plan.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Plan Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.planNamingTemplateId"
                    :obj.sync="selected.planNamingTemplate"
                    :document-type="DocumentType.Plan.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Overview.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Overview Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.overviewNamingTemplateId"
                    :obj.sync="selected.overviewNamingTemplate"
                    :document-type="DocumentType.Overview.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.Detail.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Detail Sheet</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.detailNamingTemplateId"
                    :obj.sync="selected.detailNamingTemplate"
                    :document-type="DocumentType.Detail.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.ScopeView.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Scope View</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.scopeViewNamingTemplateId"
                    :obj.sync="selected.scopeViewNamingTemplate"
                    :document-type="DocumentType.ScopeView.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SitePlan.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Site Plan</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.sitePlanNamingTemplateId"
                    :obj.sync="selected.sitePlanNamingTemplate"
                    :document-type="DocumentType.SitePlan.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.SpecSheet.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">SPEC</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.specNamingTemplateId"
                    :obj.sync="selected.specNamingTemplate"
                    :document-type="DocumentType.SpecSheet.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.TypicalIAV.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Typical IAV</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.typicalIavNamingTemplateId"
                    :obj.sync="selected.typicalIavNamingTemplate"
                    :document-type="DocumentType.TypicalIAV.value"
                  ></naming-template-selector>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="d-flex flex-row justify-start align-center" style="gap: 1rem">
                    <document-type :type="DocumentType.TypicalSCD.value"></document-type>
                    <div>
                      <h4 class="mt-1 font-weight-bold">Typical SCD</h4>
                    </div>
                  </div>
                </td>
                <td class="py-3">
                  <naming-template-selector
                    hide-label
                    hide-details
                    v-model="selected.typicalScdNamingTemplateId"
                    :obj.sync="selected.typicalScdNamingTemplate"
                    :document-type="DocumentType.TypicalSCD.value"
                  ></naming-template-selector>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import Enums from "../../../plugins/enums";
import projectsAPI from "../services/projects-service";
import perms from "../../../plugins/permissions";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import NamingTemplateSelector from "../../DrawingDocuments/components/NamingTemplateSelector.vue";
import DocumentType from "../../DrawingDocuments/components/DocumentType.vue";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
import usersAPI from "../../Admin/services/StaffService";

export default {
  name: "project-variables-static",
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      hasChanges: false,
      loading: false,
      saveLoading: false,
      users: [],
      isUsersLoading: false,
      selected: {
        installationFactor: 0,
        systemDescription: null,
        projectComplexity: 0,
        leadVIId: null,
        leadACId: null,
      },
      selectedCemented: {},
      variableTypes: Enums.PROJECT_VARIABLE_TYPES,
      DocumentType: Enums.DOCUMENT_TYPES,
    };
  },
  created() {
    this.getProjectVariables();
    this.getAllUsersData();
  },
  methods: {
    getAllUsersData() {
      this.isUsersLoading = true;
      usersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.users = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    getProjectVariables() {
      this.loading = true;
      projectsAPI
        .getProjectVariables(this.projectId)
        .then((resp) => {
          this.selected = resp.data;
          this.cementVariable();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveVariables() {
      this.saveLoading = true;
      projectsAPI
        .saveVariables(this.projectId, this.selected)
        .then((resp) => {
          this.selected = resp.data;
          this.cementVariable();
          this.$dialog.notify.success("Variables updated!", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementVariable() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkChanges();
        this.$emit("change", this.selected);
      },
      deep: true,
    },
    hasChanges() {
      this.$emit("has-changes", this.hasChanges);
    },
    valid() {
      this.$emit("valid-changes", this.valid);
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectVariables.Update);
    },
  },
  components: { RichTextEditor, NamingTemplateSelector, DocumentType, UserAllSelector },
};
</script>
