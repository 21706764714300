<template>
  <apexcharts type="rangeBar" height="350" :options="chartOptions" :series="series"></apexcharts>
</template>
<script>
export default {
  name: "project-timeline-chart-range",
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: "rangeBar",
        },
        title: {
          text: "Time range for project schedules",
          align: "left",
          style: {
            fontSize: "16px",
            fontFamily: '"Karla", serif;',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: "datetime",
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          name: "Start to Construction Date",
          data: [
            {
              x: "Lead Timeline",
              y: [
                this.selected.leadTimeline.potentialStartDate
                  ? new Date(this.selected.leadTimeline.potentialStartDate).getTime()
                  : null,
                this.selected.leadTimeline.potentialConstructionStartDate
                  ? new Date(this.selected.leadTimeline.potentialConstructionStartDate).getTime()
                  : null,
              ],
            },
            {
              x: "Opportunity Timeline",
              y: [
                this.selected.opportunityTimeline.potentialStartDate
                  ? new Date(this.selected.opportunityTimeline.potentialStartDate).getTime()
                  : null,
                this.selected.opportunityTimeline.potentialConstructionStartDate
                  ? new Date(
                      this.selected.opportunityTimeline.potentialConstructionStartDate
                    ).getTime()
                  : null,
              ],
            },
            {
              x: "Active Timeline",
              y: [
                this.selected.activeTimeline.potentialStartDate
                  ? new Date(this.selected.activeTimeline.potentialStartDate).getTime()
                  : null,
                this.selected.activeTimeline.potentialConstructionStartDate
                  ? new Date(this.selected.activeTimeline.potentialConstructionStartDate).getTime()
                  : null,
              ],
            },
          ],
        },
        {
          name: "Construction to Complete Date",
          data: [
            {
              x: "Lead Timeline",
              y: [
                this.selected.leadTimeline.potentialConstructionStartDate
                  ? new Date(this.selected.leadTimeline.potentialConstructionStartDate).getTime()
                  : null,
                this.selected.leadTimeline.potentialConstructionCompleteDate
                  ? new Date(this.selected.leadTimeline.potentialConstructionCompleteDate).getTime()
                  : null,
              ],
            },
            {
              x: "Opportunity Timeline",
              y: [
                this.selected.opportunityTimeline.potentialConstructionStartDate
                  ? new Date(
                      this.selected.opportunityTimeline.potentialConstructionStartDate
                    ).getTime()
                  : null,
                this.selected.opportunityTimeline.potentialConstructionCompleteDate
                  ? new Date(
                      this.selected.opportunityTimeline.potentialConstructionCompleteDate
                    ).getTime()
                  : null,
              ],
            },
            {
              x: "Active Timeline",
              y: [
                this.selected.activeTimeline.potentialConstructionStartDate
                  ? new Date(this.selected.activeTimeline.potentialConstructionStartDate).getTime()
                  : null,
                this.selected.activeTimeline.potentialConstructionCompleteDate
                  ? new Date(
                      this.selected.activeTimeline.potentialConstructionCompleteDate
                    ).getTime()
                  : null,
              ],
            },
          ],
        },
      ];
    },
  },
  methods: {
    getDayDiff(start, end) {
      if (end) {
        const diffTime = Math.abs(new Date(end).getTime() - start.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
      return null;
    },
  },
};
</script>
