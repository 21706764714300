<template>
  <!-- Create Area Group Modal -->
  <v-dialog v-model="modals.active" max-width="350px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Construction Phase
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneProjectForm">
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.noWhiteSpaces]"
                label="Phase Name"
                id="phaseName"
                ref="phaseName"
                placeholder="Phase Name"
                v-model="modals.data.name"
              >
              </v-text-field-alt>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-select-alt
                :rules="[allRules.required]"
                label="Phase Type"
                id="ConstructionPhaseType"
                placeholder="Construction Phase Type"
                v-model="modals.data.type"
                :items="constructionPhaseTypes"
                dense
                filled
                item-value="value"
                item-text="text"
              >
                <template v-slot:selection="{ item }">
                  <construction-phase-type :type="item.value"></construction-phase-type>
                  <p class="ma-0 ml-2 text--secondary">
                    {{ getEnumMember(enums.CONSTRUCTION_PHASE_TYPE, item.value).description }}
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <construction-phase-type :type="item.value"></construction-phase-type>
                  <p class="ma-0 ml-2 text--secondary">
                    {{ getEnumMember(enums.CONSTRUCTION_PHASE_TYPE, item.value).description }}
                  </p>
                </template>
              </v-select-alt>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-menu
                transition="scale-transition"
                :close-on-content-click="false"
                offset-y
                min-width="auto"
                :nudge-top="20"
                v-model="startMonthMenu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    class="calendar-input"
                    prepend-inner-icon="mdi-calendar"
                    label="Target Month"
                    id="startMonthValue"
                    placeholder="Target Month"
                    v-model="modals.data.startDate"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field-alt>
                </template>
                <v-date-picker
                  v-model="modals.data.startDate"
                  @input="startMonthMenu = false"
                  type="month"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneConstructionPhaseDiscard()">
            <i class="fal fa-chevron-left mr-2"></i>Discard
          </v-btn>
          <v-btn
            color="info"
            @click="cloneProjectConfirmed()"
            :disabled="!modals.valid"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i>
            Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import constructionPhaseService from "../services/constructionPhases-service";
import enums from "../../../plugins/enums";
import ConstructionPhaseType from "./ConstructionPhaseType.vue";

export default {
  components: { ConstructionPhaseType },
  props: {},
  data() {
    return {
      enums: enums,
      startMonthMenu: false,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          contractId: "",
          type: "",
          startDate: "",
          constructionPhaseId: "",
        },
      },
      constructionPhaseTypes: this.$options.filters.EnumToList(enums.CONSTRUCTION_PHASE_TYPE, true),
      DateRangeTypes: this.$options.filters.EnumToList(
        enums.CONSTRUCTION_PHASE_DATE_RANGE_TYPE,
        true
      ),
    };
  },
  computed: {
    projectStage() {
      return this.getEnumMember(enums.PROJECT_STAGE, this.modals.data.stage);
    },
    projectStatusList() {
      if (!this.projectStage) return [];
      return this.projectStage.PROJECT_STATUS;
    },
    projectStagesList() {
      return enums.PROJECT_STAGE_LIST;
    },
  },
  methods: {
    stageChange() {
      this.modals.data.status = null;
    },
    converToPhaseDate(date) {
      const dateObj = new Date(date);
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // months from 1-12
      const year = dateObj.getUTCFullYear();
      return `${year}-${month}`;
    },
    getLastDayOfMonth(year, month) {
      const nextMonthFirstDay = new Date(year, month + 1, 1);
      // Subtract one day from the first day of the next month to get the last day of the current month
      const lastDay = new Date(nextMonthFirstDay - 1);
      return lastDay;
    },
    open(phase) {
      this.$log(phase);
      let startDate = this.converToPhaseDate(phase.startDate);
      this.modals.active = true;
      this.modals.data.name = this.phaseNameConvention(phase.name);
      this.modals.data.contractId = phase.contractId;
      this.modals.data.type = phase.type;
      this.modals.data.startDate = startDate;
      this.modals.data.constructionPhaseId = phase.id;
    },
    phaseNameConvention(name) {
      return name + " [Clone]";
    },
    cloneConstructionPhaseDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          contractId: "",
          type: "",
          startDate: "",
          constructionPhaseId: "",
        },
      };
    },
    cloneProjectConfirmed() {
      this.modals.loading = true;
      let d = new Date(this.modals.data.startDate);
      this.modals.data.endDate = this.getLastDayOfMonth(d.getUTCFullYear(), d.getUTCMonth());
      constructionPhaseService
        .cloneConstructionPhase(this.modals.data.constructionPhaseId, this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Construction Phase cloned successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.cloneConstructionPhaseDiscard();
          this.$emit("save", this.modals.data.constructionPhaseId);
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
