export default [
  {
    text: "Active Key",
    value: "isActive",
    width: "100px",
    sortable: false,
  },
  {
    text: "User",
    value: "userId",
    class: "px-1",
    cellClass: "py-2 px-0",
    sortable: false,
    // sort: (a, b) => a.user.firstName.localeCompare(b.user.firstName),
  },
  // {
  //   text: "Can View",
  //   value: "canView",
  // },
  // {
  //   text: "Can Update",
  //   value: "canUpdate",
  // },
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "100px",
    sortable: false,
  },
];
