<template>
  <v-dialog v-model="modals.active" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Space
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneSpaceForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12" class="mb-3">
                <single-image-uploader
                  id="clone-space-image-uploader"
                  :apiUrl="imageApiUrl"
                  v-model="modals.data.imageUrl"
                  label="Space Image"
                ></single-image-uploader>
              </v-col>
              <v-col sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.noWhiteSpaces]"
                  label="Space Name"
                  placeholder="Space Name"
                  v-model="modals.data.name"
                  autofocus
                >
                </v-text-field-alt>
              </v-col>
              <v-col sm="12">
                <v-text-field-alt
                  label="Space Number"
                  id="spaceNumber"
                  ref="spaceNumber"
                  placeholder="Space Number"
                  v-model="modals.data.spaceNumber"
                  @blur="checkIfSpaceNumberExist"
                  :error-messages="modals.errorMessage"
                  :loading="modals.checkNumberLoading"
                >
                </v-text-field-alt>
              </v-col>
              <v-col sm="12">
                <space-building-level-editor
                  v-if="modals.data.projectId"
                  :projectId="modals.data.projectId"
                  v-model="modals.data.level"
                  :allowAdding="true"
                ></space-building-level-editor>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneSpaceDiscard()"> <i class="fal fa-xmark mr-2"></i>Cancel </v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.checkNameLoading"
            @click="cloneSpaceConfirmed()"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i> Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import spacesService from "../services/spaces-service";
import SpaceBuildingLevelEditor from "./SpaceBuildingLevelEditor.vue";
export default {
  components: { SpaceBuildingLevelEditor },
  data() {
    return {
      imageApiUrl: `Spaces/Image`,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        checkNumberLoading: false,
        data: {
          name: "",
          imageUrl: "",
          spaceId: "",
          projectId: "",
          spaceNumber: "",
          level: "",
        },
      },
    };
  },
  methods: {
    open(space) {
      this.modals.active = true;
      this.modals.data.spaceId = space.id;
      this.modals.data.imageUrl = space.imageUrl;
      this.modals.data.projectId = space.projectId;
      this.modals.data.spaceNumber = space.spaceNumber;
      this.modals.data.level = space.level;
      this.modals.data.name = this.spaceNameConvention(space.name);
    },
    spaceNameConvention(name) {
      return name + " [Clone]";
    },
    cloneSpaceDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        checkNumberLoading: false,
        data: {
          name: "",
          imageUrl: "",
          spaceId: "",
          projectId: "",
          spaceNumber: "",
          level: "",
        },
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name) {
        this.modals.checkNameLoading = true;
        spacesService
          .checkIfSpaceNameExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `(${this.modals.data.name}) Space name is already exist`;
          })
          .finally(() => (this.modals.checkNameLoading = false));
      }
    },
    checkIfSpaceNumberExist() {
      if (this.modals.data.name) {
        this.modals.checkNumberLoading = true;
        spacesService
          .checkIfSpaceNumberExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `A space with the same number (${this.modals.data.spaceNumber}) already exists in the same level!`;
            else this.modals.errorMessage = "";
          })
          .finally(() => (this.modals.checkNumberLoading = false));
      }
    },
    cloneSpaceConfirmed() {
      this.modals.loading = true;
      spacesService
        .cloneSpace(this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Space cloned successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.cloneSpaceDiscard();
          this.$emit("done", res.data);
          this.$log(res.data);
          this.$router.push({
            name: `view-project-space`,
            params: { id: res.data.projectId, spaceId: res.data.id },
          });
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    "modals.data.level": {
      handler() {
        this.checkIfSpaceNumberExist();
      },
      deep: true,
    },
  },
};
</script>
