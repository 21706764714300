<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Contract Details ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span>
          <i class="fad fa-memo-circle-info mr-2"></i>
          Contract Details: <b>{{ selected.name }}</b>
        </span>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a new contract
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon>
          <i class="far fa-times"></i>
        </v-btn>
      </div>
    </template>

    <v-form v-model="slideouts.update.valid" ref="updateForm">
      <v-container>
        <v-row class="my-0" dense>
          <v-col cols="12" md="3">
            <v-select-alt
              :rules="[allRules.required]"
              label="Contract Type"
              id="contractType"
              placeholder="Contract Type"
              v-model="selected.type"
              :items="ContractTypes"
              :readonly="readonly"
              dense
              filled
              item-value="value"
              item-text="text"
            >
              <template v-slot:selection="{ item }">
                <contract-type :type="item.value"></contract-type>
              </template>
              <template v-slot:item="{ item }">
                <contract-type :type="item.value"></contract-type>
              </template>
            </v-select-alt>
          </v-col>
          <v-col cols="12" md="9">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Contract Name"
              id="contractName"
              ref="contractName"
              placeholder="Contract Name"
              v-model="selected.name"
              :readonly="readonly"
              hide-details
            ></v-text-field-alt>
          </v-col>
        </v-row>
        <h4 class="text--primary font-weight-black mt-6">PO/PR Information</h4>
        <v-divider class="my-2"></v-divider>
        <v-row class="my-0" dense>
          <v-col cols="12" md="6">
            <v-text-field-alt
              :rules="[allRules.noWhiteSpaces]"
              label="PR Number"
              id="contractPrNumber"
              ref="contractPrNumber"
              placeholder="PR Number"
              v-model="selected.prNumber"
              :readonly="readonly"
              prefix="PR #"
            ></v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6">
            <div
              class="px-2 rounded"
              :class="{ 'green lighten-5': selected.noticeToProceed }"
              style="margin-top: 26px; padding-top: 6px; padding-bottom: 6px"
              :style="
                selected.noticeToProceed
                  ? 'border: 1.5px dashed #4CAF50 !important'
                  : 'border: 1.5px dashed #b0bec5'
              "
            >
              <v-checkbox
                color="green darken-1"
                v-model="selected.noticeToProceed"
                class="ma-0 pa-0"
                :readonly="readonly"
                hide-details
              >
                <template v-slot:label>
                  <label
                    class="v-switch-label fs-15px"
                    :class="{ 'green--text text--darken-1': selected.noticeToProceed }"
                  >
                    Notice to Proceed
                  </label>
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field-alt
              :rules="[allRules.noWhiteSpaces]"
              label="PO Number"
              id="contractPoNumber"
              ref="contractPoNumber"
              placeholder="PO Number"
              v-model="selected.poNumber"
              :readonly="readonly"
              prefix="PO #"
            ></v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="poReceivedDateMenu"
              :disabled="readonly"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :nudge-top="20"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field-alt
                  :rules="
                    selected.poNumber != null &&
                    selected.poNumber != '' &&
                    selected.poNumber.trim() != ''
                      ? [allRules.required]
                      : []
                  "
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :class="{ 'calendar-input': !readonly }"
                  label="PO Received Date"
                  id="poReceivedDate"
                  placeholder="PO Received Date"
                  v-model="selected.poReceivedDate"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                >
                </v-text-field-alt>
              </template>
              <v-date-picker
                :first-day-of-week="1"
                :rules="
                  selected.poNumber != null &&
                  selected.poNumber != '' &&
                  selected.poNumber.trim() != ''
                    ? [allRules.required]
                    : []
                "
                v-model="selected.poReceivedDate"
                @input="poReceivedDateMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="12">
            <rich-text-editor
              v-model="selected.notes"
              title="Notes"
              showLabel
              :readonly="readonly"
              allowExpand
            >
            </rich-text-editor>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && $has(perms.ProjectContracts.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                v-if="!selected.isLocked"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save" : "Create" }}
              <kbd class="light ml-1 fs-12px"> CTRL<span class="opacity-54">+</span>S </kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center">
              {{ selected.id ? "Save & Close" : "Create & Close" }} Panel
              <kbd class="light ml-2 fs-12px">
                CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S
              </kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchContractDetails()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Contract Details</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="del" v-if="$has(perms.ProjectContracts.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>fal fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.ProjectContracts.Update) && !$has(perms.ProjectContracts.Delete)"
              >No Actions Available!</v-subheader
            >
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import contractsService from "../services/contracts-service";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import ContractType from "./ContractType.vue";

export default {
  name: "edit-contract-details",
  components: { PanelSizeControl, RichTextEditor, ContractType },
  data() {
    return {
      perms,
      enums,
      projectId: null,
      contractId: null,
      isFetching: false,
      ContractTypes: this.$options.filters.EnumToList(enums.ContractType, true),
      ContractOrigins: this.$options.filters.EnumToList(enums.ContractOrigin, true),
      imageApiUrl: `layout-views/Image`,
      readonly: false,
      poReceivedDateMenu: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          valid: false,
          active: false,
          fullWidth: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "800px";
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        !this.selected.isLocked &&
        this.$has(this.perms.ProjectContracts.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementContractDetails();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewContract) {
      this.$emit("save", this.selected, isNewContract);
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      contractsService
        .update(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Contract Details updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          if (this.selected.poReceivedDate != null && this.selected.poReceivedDate != "")
            this.selected.poReceivedDate = this.selected.poReceivedDate.substr(0, 10);

          this.cementContractDetails();
          var isNewContract = false;
          if (!toSend.id) {
            isNewContract = true;
            this.contractId = this.selected.id;
            message = "Contract added successfully!";
          }
          this.announceChange(isNewContract);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.projectId = null;
      this.slideouts.update.active = false;
    },
    create(projectId) {
      this.projectId = projectId;
      this.selected = {
        id: null,
        name: null,
        poNumber: null,
        poReceivedDate: null,
        prNumber: null,
        noticeToProceed: false,
        notes: null,
        projectId: this.projectId,
      };
      this.readonly = false;
      this.isFetching = false;
      this.contractId = null;
      this.cementContractDetails();
      this.slideouts.update.active = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
        this.$refs.contractName.focus();
        this.slideouts.update.tab = 0;
      }, 250);
    },
    open(id, editMode = false) {
      this.$log(">>>>>>> open", id, editMode);
      this.readonly = !editMode;
      this.contractId = id;
      this.fetchContractDetails();

      this.slideouts.update.active = true;
      setTimeout(() => {
        this.$refs.updateForm.resetValidation();
        this.$refs.contractName.focus();
        this.slideouts.update.tab = 0;
      }, 250);
    },
    fetchContractDetails() {
      this.isFetching = true;
      contractsService
        .getContractDetails(this.contractId)
        .then((resp) => {
          this.$log("getContractDetails >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          if (this.selected.poReceivedDate != null && this.selected.poReceivedDate != "")
            this.selected.poReceivedDate = this.selected.poReceivedDate.substr(0, 10);
          this.cementContractDetails();
        })
        .catch((err) => {
          this.$log("getContractDetails >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    cementContractDetails() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.$log("########>>>>>>> cementContractDetails()");
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    del() {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this contract?<br/><h4>${this.selected.name}</h4>`,
          title: `Delete Contract?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return contractsService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.onDeleteContractSuccess(this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Contract deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error deleting the Contract!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteContractSuccess(id) {
      this.$emit("delete", id);
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>
