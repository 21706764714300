<template>
  <v-dialog v-model="modals.active" max-width="350px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Drawing Document
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneProjectForm">
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.noWhiteSpaces]"
                label="Document Name"
                id="ddName"
                ref="ddName"
                placeholder="Document Name"
                v-model="modals.data.name"
              >
              </v-text-field-alt>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.noWhiteSpaces]"
                label="Document Number"
                id="ddNumber"
                ref="ddNumber"
                placeholder="Document Number"
                v-model="modals.data.number"
              >
              </v-text-field-alt>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cloneDrawingDocumentDiscard()">
            <i class="far fa-xmark mr-2"></i>Cancel
          </v-btn>
          <v-btn
            color="info"
            @click="cloneDrawingDocumentConfirmed()"
            :disabled="!modals.valid"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i>
            Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import drawingDocumentService from "../services/drawing-documents-service";
import enums from "../../../plugins/enums";

export default {
  components: {},
  props: {},
  data() {
    return {
      enums: enums,
      startMonthMenu: false,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          number: "",
          id: "",
        },
      },
    };
  },
  methods: {
    open(drawingDocument) {
      this.$log(drawingDocument);
      this.modals.active = true;
      this.modals.data.name = this.drawingDocumentNameConvention(drawingDocument.name);
      this.modals.data.number = this.drawingDocumentNameConvention(drawingDocument.number);
      this.modals.data.id = drawingDocument.id;
    },
    drawingDocumentNameConvention(name) {
      return name + " [Clone]";
    },
    cloneDrawingDocumentDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          id: "",
        },
      };
    },
    cloneDrawingDocumentConfirmed() {
      this.modals.loading = true;
      drawingDocumentService
        .cloneDocument(this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Drawing Document cloned successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.cloneDrawingDocumentDiscard();
          this.$emit("save", this.modals.data.id);
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
