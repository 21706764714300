<template>
  <big-board-viewer
    :type="enums.BIG_BOARD_TYPE.SmallBoard.value"
    :projectId="projectId"
  ></big-board-viewer>
</template>

<script>
import BigBoardViewer from "../../BigBoard/components/BigBoardViewer.vue";
import enums from "../../../plugins/enums";

export default {
  name: "project-small-board",
  data() {
    return {
      enums: enums,
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: { BigBoardViewer },
};
</script>
