<template>
  <!-- Create Area Group Modal -->
  <v-dialog v-model="modal" max-width="760px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fad fa-gauge-circle-bolt mr-2"></i>Equipment Insta Tally
      </v-card-title>
      <v-container fluid class="pa-0">
        <v-card-text class="pa-0">
          <v-container class="pa-0 text-center" v-if="isLoading">
            <video
              width="320"
              muted
              loop
              autoplay
              style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
            >
              <source src="/img/art/astronaut-mines-coins-4979111-4153144.mp4" type="video/mp4" />
              <!-- <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" /> -->
            </video>
            <p class="font-weight-bold">Calcuating ...</p>
          </v-container>
          <v-container
            style="background: #eceff1"
            class="inset-shadow pa-6"
            v-else-if="data != null"
          >
            <v-row align="center" justify="center">
              <v-col cols="12" md="4">
                <v-sheet elevation="2" rounded="lg" class="text-center py-4 px-2">
                  <p>
                    <i class="fad fa-weight-hanging" style="font-size: 3rem"></i>
                  </p>
                  <h2 class="mb-3 opacity-87">Total Weight</h2>
                  <h4 class="fs-20px font-weight-bold">
                    <span class="info--text">{{ data.totalWeights }}</span>
                    <span class="fs-14px opacity-72 ml-2">(Lbs.)</span>
                  </h4>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="4">
                <v-sheet elevation="2" rounded="lg" class="text-center py-4 px-2">
                  <p>
                    <i class="fad fa-transformer-bolt" style="font-size: 3rem"></i>
                  </p>
                  <h2 class="mb-3 opacity-87">Total Wattage</h2>
                  <h4 class="fs-20px font-weight-bold">
                    <span class="info--text">{{ data.totalPowerConsumptions }}</span>
                    <span class="fs-14px opacity-72 ml-2">(Watts)</span>
                  </h4>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="4">
                <v-sheet elevation="2" rounded="lg" class="text-center py-4 px-2">
                  <p>
                    <i class="fad fa-temperature-list" style="font-size: 3rem"></i>
                  </p>
                  <h2 class="mb-3 opacity-87">Total Heatload</h2>
                  <h4 class="fs-20px font-weight-bold">
                    <span class="info--text">{{ data.totalBTUs }}</span>
                    <span class="fs-14px opacity-72 ml-2">(BTU/Hr)</span>
                  </h4>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="4">
                <v-sheet elevation="2" rounded="lg" class="text-center py-4 px-2">
                  <p>
                    <i class="fad fa-air-conditioner" style="font-size: 3rem"></i>
                  </p>
                  <h2 class="mb-3 opacity-87">Total HVAC Tonnage</h2>
                  <h4 class="fs-20px font-weight-bold">
                    <span class="info--text">{{ data.totalTonnages }}</span>
                    <span class="fs-14px opacity-72 ml-2">(Tons)</span>
                  </h4>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="4">
                <v-sheet elevation="2" rounded="lg" class="text-center py-4 px-2">
                  <p>
                    <i class="fad fa-container-storage fa-rotate-90" style="font-size: 3rem"></i>
                  </p>
                  <h2 class="mb-3 opacity-87">Total Rack Units</h2>
                  <h4 class="fs-20px font-weight-bold">
                    <span class="info--text">{{ data.totalRackUnits }}</span>
                    <span class="fs-14px opacity-72 ml-2">(Units)</span>
                  </h4>
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discard()"> <i class="fal fa-chevron-left mr-2"></i>close</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import instaTallyAPI from "../services/instaTally-service";

export default {
  components: {},
  data() {
    return {
      modal: false,
      isLoading: false,
      data: null,
    };
  },
  methods: {
    calculate(equipmentList) {
      this.modal = true;
      this.isLoading = true;
      instaTallyAPI
        .equipment(equipmentList)
        .then((resp) => {
          this.data = resp.data;
          this.$log("insta tally", resp.data);
          this.isLoading = false;
        })
        .catch((err) => {
          this.$handleError(err);
          this.discard();
        });
    },
    discard() {
      this.modal = false;
      this.isLoading = false;
    },
  },
};
</script>
