<template>
  <div
    class="project-overview-item"
    :class="{ 'batch-item': space.isBatch }"
    @click="onItemClicked"
  >
    <v-row align-content="center" justify="center" no-gutters>
      <v-col cols="12" sm="7" class="space-details">
        <v-tooltip color="deep-purple" left z-index="999" nudge-left="-4px" v-if="space.isBatch">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-flex align-center">
              <v-chip style="height: 20px" color="deep-purple" class="mr-1" dark label small>
                <i class="fas fa-grid-2-plus fs-12px mr-2"></i>{{ space.batchCount }}
              </v-chip>
            </div>
          </template>
          <span>
            Batch of <b>{{ space.batchCount }}</b> spaces
          </span>
        </v-tooltip>
        <h5 class="space-name">
          {{ space.name }}
        </h5>
        <h6 class="space-number" v-if="space.spaceNumber != null && space.spaceNumber != ''">
          <i class="fas fa-hashtag mr-1"></i>{{ space.spaceNumber }}
        </h6>
        <h6 class="space-number" v-else>
          <i class="fas fa-hashtag mr-1"></i><i class="fal fa-horizontal-rule"></i>
        </h6>
        <div class="space-location">
          <span class="space-equips warning--text mr-2">
            <i class="far fa-plug mr-1"></i>
            {{ space.equipmentCount }}
          </span>
          <span class="space-building info--text mr-2" v-if="space.level.building">
            <i class="far fa-building mr-1"></i> {{ space.level.building.name }}
          </span>
          <span class="space-level success--text">
            <i class="far fa-layer-group mr-1"></i>
            {{ space.level.name }}
          </span>
        </div>
      </v-col>
      <v-col cols="12" sm="5" class="space-content">
        <h5 class="space-installation-cost">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-block">
                {{ (installationCost * (space.isBatch ? space.batchCount : 1)) | usdFormat
                }}<i class="fad fa-wrench ml-1"></i>
              </div>
            </template>
            <span>
              Installation Factor
              <b class="info--text ml-1 fs-12px"> {{ installationFactor }}x </b>
              <b v-if="space.isBatch">
                <br />
                - Per Space: {{ installationCost | usdFormat }}
              </b>
            </span>
          </v-tooltip>
        </h5>
        <h5 class="space-total-msrp">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-block">
                {{
                  (space.equipmentsTotalMsrpPrice * (space.isBatch ? space.batchCount : 1))
                    | usdFormat
                }}
                <i class="fad fa-usd-square ml-1"></i>
              </div>
            </template>
            <span>
              Total MSRP
              <b v-if="space.isBatch">
                <br />
                - Per Space: {{ space.equipmentsTotalMsrpPrice | usdFormat }}
              </b>
            </span>
          </v-tooltip>
        </h5>
        <h5 class="space-total-installed-cost">
          <v-tooltip left z-index="999" nudge-left="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-block">
                {{
                  (totalMSRPWithInstallCost * (space.isBatch ? space.batchCount : 1)) | usdFormat
                }}
              </div>
            </template>
            <span>
              Total Cost
              <b v-if="space.isBatch">
                <br />
                - Per Space: {{ totalMSRPWithInstallCost | usdFormat }}
              </b>
            </span>
          </v-tooltip>
        </h5>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "project-overview-item",
  props: {
    space: {
      type: Object,
      default: null,
    },
    installationFactor: {
      type: Number,
      default: null,
    },
  },
  computed: {
    installationCost() {
      return this.space.equipmentsTotalMsrpPrice * this.installationFactor;
    },
    totalMSRPWithInstallCost() {
      return (
        this.space.equipmentsTotalMsrpPrice +
        this.space.equipmentsTotalMsrpPrice * this.installationFactor
      );
    },
  },
  methods: {
    onItemClicked() {
      this.$emit("click", this.space.id);
    },
  },
  mounted() {},
  watch: {
    space: {
      handler() {
        // this.getProjectOverview();
      },
      deep: true,
    },
  },
  components: {},
};
</script>

<style lang="scss">
.project-overview-item {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 2px solid rgba($shades-black, 0.24);
  transition: all 0.15s ease-out;
  flex-shrink: 0;
  cursor: pointer;

  .space-number {
    white-space: nowrap;
    font-size: 13px;
    color: rgba($shades-black, 0.54);
    margin-bottom: 0.25rem;

    i {
      font-size: 11px;
    }
  }
  .space-level {
    white-space: nowrap;
  }

  .space-equips {
    white-space: nowrap;
  }

  .space-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem !important;

    .space-name {
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-bottom: 0rem;
    }

    .space-location {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .space-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0.5rem !important;
    font-size: 16px;
    border-left: 1px solid rgba($shades-black, 0.16);
    // background: rgba($shades-black, 0.05);
    background: #f8f9f9;
    transition: all 0.15s ease-out;
  }

  .space-total-msrp {
    margin-bottom: 0.15rem;
    border-bottom: 1px solid rgba($shades-black, 0.12);
    padding-bottom: 0.25rem;
    // width: 100%;
    text-align: right;
    opacity: 0.75;
  }

  .space-installation-cost {
    opacity: 0.54;
    font-size: 12px;
  }

  .space-total-installed-cost {
    // margin-bottom: 0.15rem;
    // border-bottom: 1px solid rgba($shades-black, 0.12);
    // padding-bottom: 0.25rem;
    // // width: 100%;
    // text-align: right;
    color: $shades-black;
  }

  &.batch-item {
    border: 2px solid rgba($deep-purple-base, 0.4);

    .space-content {
      border-left: 1px solid rgba($deep-purple-base, 0.4);
      background: rgba($deep-purple-base, 0.1);
    }
  }

  &:hover {
    border: 2px solid $info-base;
    background: rgba($info-base, 0.16);

    .space-content {
      color: #fff;
      background: rgba($info-base, 1);
    }

    .space-total-msrp {
      opacity: 0.87;
    }

    .space-installation-cost {
      opacity: 0.75;
    }

    .space-total-installed-cost {
      color: #fff;
    }
  }

  &.batch-item:hover {
    border: 2px solid $deep-purple-base;
    background: rgba($deep-purple-base, 0.16);

    .space-content {
      color: #fff;
      background: rgba($deep-purple-base, 1);
    }

    .space-total-msrp {
      opacity: 0.87;
    }

    .space-installation-cost {
      opacity: 0.75;
    }

    .space-total-installed-cost {
      color: #fff;
    }
  }
}
</style>
