<template>
  <div :id="`projectPhase${projectId}`">
    <v-container
      fluid
      class="pa-5 pt-2"
      v-if="projectId != null && projectId != '' && projectId != NaN"
    >
      <h3 class="text--primary font-weight-black">
        Total Phases:
        <v-chip class="ml-2 font-weight-bold" small label :loading="loadingStates.table">{{
          total
        }}</v-chip>
      </h3>
      <div
        style="background: #eceff1; border-radius: 0.5rem"
        class="px-5 pt-2 pb-5 mt-3 inset-shadow"
      >
        <v-row class="mt-0 mb-1" justify="space-between" align="center">
          <v-col lg="3" sm="12" cols="12" class="d-flex align-start" style="gap: 0.5rem">
            <v-btn color="info" @click="add" v-if="!readonly"
              ><i class="fal fa-plus mr-2"></i>New Phase</v-btn
            >
          </v-col>
          <v-col
            lg="9"
            md="12"
            sm="12"
            cols="12"
            class="d-flex flex-row align-center justify-end flex-wrap"
            style="gap: 0.5rem"
          >
            <refresh
              :loading="loadingStates.table"
              @refresh="getProjectPhasesDebounced()"
            ></refresh>
            <columns-visibility-control
              :defaults="defaultHeaders"
              :withColumnsOrder="true"
              v-model="headers"
              :storageKey="storageKey"
            />

            <v-text-field
              v-model="options.search"
              label="Search"
              ref="mainSearch"
              class="table-search-field"
              @focus="mainSearchInFocus = true"
              @blur="mainSearchInFocus = false"
              :style="{
                'max-width':
                  mainSearchInFocus || (options.search != '' && options.search != null)
                    ? '200px'
                    : '110px',
              }"
              dense
              solo
              clearable
              hide-details
              prepend-inner-icon="far fa-search"
              :loading="loadingStates.table"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="tableHeader"
          :items="entities"
          :options.sync="options"
          :server-items-length="total"
          :loading="loadingStates.table"
          :items-per-page="options.itemsPerPage"
          class="elevation-2 project-phases-table"
          @click:row="rowClicked"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'far fa-arrow-to-left',
            lastIcon: 'far fa-arrow-to-right',
            prevIcon: 'far fa-angle-left',
            nextIcon: 'far fa-angle-right',
            itemsPerPageOptions: [15, 30, 50, 100],
          }"
          item-key="id"
        >
          <template v-slot:progress>
            <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <h4 class="ma-0">{{ item.name }}</h4>
          </template>
          <template v-slot:[`item.number`]="{ item }">
            <h4 class="ma-0">{{ item.number }}</h4>
          </template>
          <template v-slot:[`item.createdBy`]="{ item }">
            <user-avatar :user="item.createdBy" icon></user-avatar>
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <user-avatar :user="item.updatedBy" icon></user-avatar>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <dater :date="item.startDate" dateonly></dater>
          </template>

          <template v-slot:[`item.endDate`]="{ item }">
            <dater :date="item.endDate" dateonly></dater>
          </template>
          <template v-slot:[`item.poReceiveDate`]="{ item }">
            <dater :date="item.poReceiveDate" dateonly></dater>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <project-stage :small="true" :stage="item.status"></project-stage>
          </template>
          <template v-slot:[`item.totalAuthorizedFees`]="{ item }">
            <span>{{ item.totalAuthorizedFees | usdFormat }}</span>
          </template>
          <template v-slot:[`item.tempoPlannedTimeLink`]="{ item }">
            <v-tooltip bottom z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  target="_blank"
                  class="ma-1"
                  @click.stop=""
                  :href="item.tempoPlannedTimeLink"
                  ><v-icon>far fa-external-link</v-icon></v-btn
                >
              </template>
              <div>{{ item.tempoPlannedTimeLink }}</div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.tempoLoggedTimeLink`]="{ item }">
            <v-tooltip bottom z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop=""
                  target="_blank"
                  :href="item.tempoLoggedTimeLink"
                  ><v-icon>far fa-external-link</v-icon></v-btn
                >
              </template>
              <div>{{ item.tempoLoggedTimeLink }}</div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="text-left">
              <v-btn
                icon
                elevation="0"
                @click.stop="update(item.id)"
                class="mr-2"
                :disabled="readonly"
              >
                <i class="fal fa-pen"></i>
              </v-btn>
              <v-menu dense offset-x right>
                <template v-slot:activator="{ attrs, on }">
                  <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
                    <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                  </v-btn>
                </template>
                <v-list class="more-options-menu">
                  <v-list-item @click.stop="del(item.id)" :disabled="readonly">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                        >fal fa-trash-alt red--text</v-icon
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="red--text"
                        :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                        >Delete</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:no-data>
            <img width="300" src="/img/art/fogg-no-connection-2.png" />
            <p class="font-weight-bold">No Data Available!</p>
          </template>

          <template v-slot:loading>
            <video width="250" muted loop autoplay style="padding: 0.5rem">
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Searching the Cosmos...</p>
          </template>
        </v-data-table>
      </div>
      <edit-project-phase
        ref="editProjectPhase"
        :project-id="projectId"
        @save="onPhaseSaved"
        @close="onPhasePanelClosed"
      ></edit-project-phase>
    </v-container>
  </div>
</template>

<script>
import phaseService from "../services/phases-service";
import EditProjectPhase from "../components/EditPhase.vue";
import perms from "../../../plugins/permissions";
import projectPhaseHeader from "../config/tables/projectPhase.header";
import ProjectStage from "./ProjectStage.vue";

export default {
  name: "project-phases",
  data() {
    return {
      perms: perms,
      storageKey: "ProjectPhases",
      openPhaseInEditMode: false,
      entities: [],
      selected: {},
      total: 0,
      projectPhasesTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
      },
      loadingStates: {
        table: false,
      },
      slideouts: {},
      modals: {},
      headers: projectPhaseHeader,
      defaultHeaders: [],
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.$moveable(document.querySelector(".project-phases-table .v-data-table__wrapper"));
    this.checkForSinglePhaseRoute(this.$route);
  },
  methods: {
    onPhaseSaved(phaseData, isNewPhase) {
      this.$log("onPhaseSaved", phaseData, isNewPhase);
      this.$log("this.entities", this.cloneDeep(this.entities));
      if (isNewPhase) {
        // this.entities.push(this.cloneDeep(phaseData));
        this.addToArr(this.entities, phaseData, true);
        this.total = this.entities.length;
        // this.options.search = phaseData.id;
      } else {
        this.updateArr(this.entities, phaseData);
      }
      this.$log("this.entities", this.cloneDeep(this.entities));
    },
    onPhasePanelClosed() {
      // this.$log("onPhasePanelClosed", this.selected);
      const path = `/projects/${this.projectId}`;
      if (this.$route.path !== path) this.$router.push(path);
      setTimeout(() => {
        this.selected = {};
      }, 50);
    },
    focus() {
      this.$refs.mainFilter.focus();
    },

    rowClicked(row) {
      this.view(row.id);
    },
    getProjectPhasesDebounced() {
      if (this.projectPhasesTimerId == null) {
        this.projectPhasesTimerId = -1;
        this.getProjectPhases();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectPhasesTimerId);

      // delay new call 400ms
      this.projectPhasesTimerId = setTimeout(() => {
        this.getProjectPhases();
      }, 400);
    },
    getProjectPhases() {
      this.$backToTop(
        0,
        this.$vuetify.breakpoint.mdAndDown
          ? null
          : document.getElementById(`projectPhase${this.projectId}`).closest(".v-window")
      );
      this.loadingStates.table = true;
      phaseService
        .query(this.projectId, this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items || [];
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    closePhase() {
      this.$refs.editProjectPhase.closePhasePanel();
    },
    add() {
      this.selected = {};
      setTimeout(() => {
        this.open(null);
      });
    },
    view(id) {
      this.selected = { id: id };
      this.$log("view selected", this.selected);
      this.openPhaseInEditMode = false;
      setTimeout(() => {
        const path = `/projects/${this.projectId}/phases/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    update(id) {
      this.selected = { id: id };
      this.$log("update selected", this.selected);
      this.openPhaseInEditMode = true;
      setTimeout(() => {
        const path = `/projects/${this.projectId}/phases/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    open(id) {
      this.$log("OPEN =>>>", id, this.openPhaseInEditMode);
      setTimeout(() => {
        this.$refs.editProjectPhase.open(id, this.openPhaseInEditMode);
        this.openPhaseInEditMode = false;
      }, 400);
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this phase?`,
          title: `Delete Phase?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return phaseService
                  .delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("Phase deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the phase!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    checkForSinglePhaseRoute(route) {
      if (route.name == "view-project-phase") {
        this.open(route.params.phaseId);
        return;
      }
    },
    addToPhases(phase) {
      this.addToArr(this.entities, phase, true);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectPhasesDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.$log("PROJECT PHASES - oldRoute", oldRoute, "newRoute", newRoute);

        //from project view TO => phase view,
        //so just switch to the project phases tab and it will handle it
        if (oldRoute.name == "view-project" && newRoute.name == "view-project-phase") {
          this.open(newRoute.params.phaseId);
          return;
        }

        //from phase view TO => phase view,
        //so do nothing from here, and the active project phases tab will handle it
        if (oldRoute.name == "view-project-phase" && newRoute.name == "view-project-phase") {
          this.open(newRoute.params.phaseId);
          return;
        }
      },
      deep: true,
    },
  },
  components: { EditProjectPhase, ProjectStage },
};
</script>

<style lang="scss">
.project-phases-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.typeahead-phase-img {
  border: 1.5px solid rgba($shades-black, 0.24);
  // background: rgba($shades-black, 0.04);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 28px;
  width: 54px;
  padding: 0.25rem;
  margin: 0.15rem 0;

  .v-responsive__sizer {
    display: none !important;
  }

  i {
    font-size: 24px;
    opacity: 0.54;
  }
}

.typeahead-phase-name {
  font-size: 14px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
  margin-bottom: 0.15rem !important;
}

.typeahead-phase-description {
  font-size: 12px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0 !important;
  line-height: 1;
}
.template-link-alert .v-alert__icon {
  align-self: center;
}
</style>
