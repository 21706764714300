<template>
  <div class="ms-teams-selector comp-wrpr">
    <v-autocomplete-alt
      auto-select-first
      :label="
        label == null || label == ''
          ? `<img src='${defaultTeamsLogo}' style='height: 14px;' class='mr-2'> MS Team`
          : label
      "
      id="mircosoftTeams"
      placeholder="Select a Team"
      v-model="selected.teamId"
      @input="handleSelectedMSTeam"
      :items="msTeams"
      :filter="msTeamsFilter"
      dense
      filled
      item-value="id"
      item-text="name"
      clearable
      :loading="isMSTeamsLoading"
      :disabled="isMSTeamsLoading"
      :readonly="readonly"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="'teamSelector_' + item.id"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
          class="py-0"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-img
              :src="defaultTeamsLogo"
              height="28"
              width="28"
              position="center center"
              contain
            />
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column pl-2">
            <div class="ms-teams-top">
              <div class="ms-teams-name mr-2">
                {{ data.item.name }}
              </div>
              <ms-team-visibility
                small
                :visibility="data.item.visibility"
              ></ms-team-visibility>
            </div>
            <div class="ms-teams-desc" style="max-width: 100%">
              {{ data.item.description }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:label-append>
        <slot name="label-append"></slot>
      </template>
    </v-autocomplete-alt>

    <v-tooltip top nudge-top="-4" :disabled="teamMainLink == null">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          min-width="28px !important"
          width="28px !important"
          height="28px !important"
          color="secondary"
          outlined
          class="pa-0"
          small
          target="_blank"
          :href="teamMainLink"
          :loading="isTeamInfoLoading"
          :disabled="teamMainLink == null"
        >
          <i class="fad fa-arrow-up-right-from-square fs-14px"></i>
        </v-btn>
      </template>
      <span>Open in Microsoft Teams App</span>
    </v-tooltip>

    <v-tooltip top nudge-top="-4" :disabled="sharepointUrl == null">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          min-width="28px !important"
          width="28px !important"
          height="28px !important"
          color="secondary"
          outlined
          class="pa-0"
          small
          target="_blank"
          :href="sharepointUrl"
          :loading="isTeamInfoLoading"
          :disabled="sharepointUrl == null"
        >
          <i class="fad fa-folder-tree fs-14px"></i>
        </v-btn>
      </template>
      <span>Open SharePoint Folder</span>
    </v-tooltip>
  </div>
</template>

<script>
import msTeamsAPI from "../services/ms-teams-service";
import MsTeamVisibility from "./MsTeamVisibility.vue";

export default {
  components: { MsTeamVisibility },
  props: {
    teamId: {
      type: String,
      default: null
    },
    generalChannelId: {
      type: String,
      default: null
    },
    siteName: {
      type: String,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    autoGenerate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: { teamId: null, generalChannelId: null, siteName: null },
      msTeams: [],
      teamInfo: null,
      tenantId: "d2953fc7-9caa-4587-af20-9b336d870d12",
      isMSTeamsLoading: false,
      isTeamInfoLoading: false,
      defaultTeamsLogo: `https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg`
    };
  },
  mounted() {
    this.selected.teamId = this.teamId;
    this.selected.siteName = this.siteName;
    this.selected.generalChannelId = this.generalChannelId;
    this.$log("this.selected", this.selected);
  },
  methods: {
    handleSelectedMSTeam(val) {
      if (val != null && val != "") {
        this.getTeamInfo(val);
      } else {
        this.selected.generalChannelId = null;
        this.selected.siteName = null;
        this.isTeamInfoLoading = false;
        this.$emit("update:teamId", val);
        this.$emit("update:siteName", this.selected.siteName);
        this.$emit("update:generalChannelId", this.selected.generalChannelId);
      }
    },
    getMSTeams() {
      this.isMSTeamsLoading = true;
      msTeamsAPI
        .teams()
        .then(resp => {
          this.msTeams = resp.data.teams;
          this.$log("teams", resp.data.teams);
          this.isMSTeamsLoading = false;
        })
        .catch(err => {
          this.isMSTeamsLoading = false;
          this.$handleError(err);
        });
    },
    getTeamInfo(id) {
      this.isTeamInfoLoading = true;
      msTeamsAPI
        .team(id)
        .then(resp => {
          this.isTeamInfoLoading = false;
          if (this.autoGenerate) {
            this.selected.teamId = null;
            this.selected.siteName = null;
            this.selected.generalChannelId = null;
            return;
          }
          if (this.selected.teamId == null) return;

          this.teamInfo = resp.data.team;

          if (this.teamInfo.id == this.selected.teamId) {
            var generalChannel = this.teamInfo.channels.find(
              c => c.name == "General"
            );
            if (generalChannel != null)
              this.selected.generalChannelId = generalChannel.id;
            this.$emit(
              "update:generalChannelId",
              this.selected.generalChannelId
            );
          }
          if (this.teamInfo.id == this.selected.teamId) {
            this.selected.siteName = this.teamInfo.generalSharePointSiteName;
            this.$emit("update:siteName", this.selected.siteName);
          }

          this.$emit("update:teamId", this.selected.teamId);
        })
        .catch(err => {
          this.isTeamInfoLoading = false;
          if (this.selected.teamId == null) return;
          this.$handleError(err);
        });
    },
    msTeamsFilter(item, queryText, itemText) {
      const text1 = item.name ? item.name.toLowerCase() : "";
      const text2 = item.description ? item.description.toLowerCase() : "";
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1 || text2.indexOf(searchText) > -1;
    },
    refresh() {
      this.getMSTeams();
    }
  },
  created() {
    this.getMSTeams();
  },
  computed: {
    teamMainLink() {
      if (
        this.selected.teamId == null ||
        this.selected.teamId == "" ||
        this.selected.generalChannelId == null ||
        this.selected.generalChannelId == ""
      )
        return null;
      return `https://teams.microsoft.com/l/team/${this.selected.generalChannelId}/conversations?groupId=${this.selected.teamId}&tenantId=${this.tenantId}`;
    },
    teamGeneralChannelLink() {
      if (
        this.selected.teamId == null ||
        this.selected.teamId == "" ||
        this.selected.generalChannelId == null ||
        this.selected.generalChannelId == ""
      )
        return null;
      return `https://teams.microsoft.com/l/channel/${this.selected.generalChannelId}/General?groupId=${this.selected.teamId}&tenantId=${this.tenantId}`;
    },
    sharepointUrl() {
      if (this.selected.siteName == null || this.selected.siteName == "")
        return null;
      return `https://netorgft2704825.sharepoint.com/sites/${this.selected.siteName}/Shared Documents/Forms/AllItems.aspx?id=/sites/${this.selected.siteName}/Shared Documents/General&p=true&ga=1`;
    }
  },
  watch: {
    teamId() {
      if (this.teamId != this.selected.teamId)
        this.selected.teamId = this.teamId;
    },
    generalChannelId() {
      if (this.generalChannelId != this.selected.generalChannelId)
        this.selected.generalChannelId = this.generalChannelId;
    },
    siteName() {
      if (this.siteName != this.selected.siteName)
        this.selected.siteName = this.siteName;
    },
    autoGenerate() {
      if (this.autoGenerate) {
        this.selected.teamId = null;
        this.selected.siteName = null;
        this.selected.generalChannelId = null;
        return;
      }
    }
  }
};
</script>

<style lang="scss">
.ms-teams-top {
  display: flex;
  align-items: center;
}

.ms-teams-name {
  color: rgba($shades-black, 0.87);
  font-size: 14px;
  font-weight: 600;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-teams-desc {
  color: rgba($shades-black, 0.54);
  font-size: 12px;
  font-weight: 500;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1;
}

.ms-teams-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px - 40px);

    label {
      display: flex;
      align-items: center;
    }
  }
}
</style>
