<template>
  <v-row class="my-0">
    <v-col cols="6" md="6" sm="12" class="comp-wrpr">
      <v-autocomplete-alt
        auto-select-first
        style="width: 100%"
        :rules="[allRules.required]"
        label="Building"
        id="spaceBuilding"
        placeholder="Choose a Building"
        v-model="selected.building"
        @input="buildingChanged"
        :items="buildings"
        :filter="buildingsFilter"
        dense
        filled
        return-object
        item-text="name"
        item-value="id"
        :loading="isBuildingsLoading"
        :disabled="isBuildingsLoading"
        :readonly="readonly"
      >
        <!-- <template v-slot:selection="data">
          <h5 class="building-name">
            {{ data.item.name }}
          </h5>
          <p class="building-description text--secondary mb-0">
            {{ data.item.description }}
          </p>
        </template>
        <template v-slot:item="data">
          <h5 class="building-name">
            {{ data.item.name }}
          </h5>
          <p class="building-description text--secondary mb-0">
            {{ data.item.description }}
          </p>
        </template> -->
      </v-autocomplete-alt>
      <v-btn
        min-width="28px !important"
        width="28px !important"
        height="28px !important"
        color="secondary"
        class="pa-0"
        small
        @click="addBuildingOpen()"
        :disabled="isBuildingsLoading"
        v-if="allowAdding"
      >
        <i class="fal fa-plus"></i>
      </v-btn>
    </v-col>
    <v-col cols="6" md="6" sm="12" class="comp-wrpr">
      <v-autocomplete-alt
        auto-select-first
        style="width: 100%"
        :rules="[allRules.required]"
        label="Level"
        id="spaceLevel"
        placeholder="Choose a Level"
        v-model="selected.level"
        :items="levels"
        dense
        filled
        return-object
        item-text="name"
        item-value="id"
        :loading="isBuildingsLoading"
        :disabled="isBuildingsLoading || this.selected.building == null"
        :readonly="readonly"
      >
      </v-autocomplete-alt>
      <v-btn
        min-width="28px !important"
        width="28px !important"
        height="28px !important"
        color="secondary"
        class="pa-0"
        small
        @click="addLevelOpen()"
        :disabled="isBuildingsLoading || this.selected.building == null"
        v-if="allowAdding"
      >
        <i class="fal fa-plus"></i>
      </v-btn>
    </v-col>

    <!-- Add Level Modal -->
    <v-dialog v-model="modals.addLevel.active" max-width="360px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Add New Level
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.addLevel.valid" ref="addLevelForm">
          <v-card-text class="pa-2">
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12" class="mb-2">
                  <label class="mb-2 d-block" style="font-weight: 600 !important; font-size: 15px">
                    Building:
                  </label>
                  <h2>
                    <v-chip color="secondary" style="font-weight: 500 !important" label>
                      <i class="fal fa-building mr-2"></i>{{ modals.addLevel.data.buildingName }}
                    </v-chip>
                  </h2>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="LevelName"
                    placeholder="Level Name"
                    v-model="modals.addLevel.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-switch
                    color="info"
                    v-model="modals.addLevel.data.shortNameMatchesName"
                    @change="levelShortNameMatchesNameHandler"
                    class="mb-2 mt-0 pa-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label fs-14px"
                        :class="
                          modals.addLevel.data.shortNameMatchesName
                            ? 'info--text'
                            : 'secondary--text'
                        "
                      >
                        Short Name Matchs Name
                      </label>
                    </template>
                  </v-switch>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="
                      modals.addLevel.data.shortNameMatchesName
                        ? [allRules.required, allRules.noWhiteSpaces]
                        : [allRules.lengthMax(10), allRules.required, allRules.noWhiteSpaces]
                    "
                    label="Short Name"
                    id="LevelShortName"
                    placeholder="Short Name"
                    v-model="modals.addLevel.data.shortName"
                    :readonly="modals.addLevel.data.shortNameMatchesName"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Area"
                    id="LevelArea"
                    placeholder="Area"
                    suffix="SQFT"
                    v-model.number="modals.addLevel.data.area"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="Description"
                    id="LevelDescription"
                    placeholder="Level Description"
                    v-model="modals.addLevel.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addLevelDiscard()"
              ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
            >
            <v-btn
              color="info"
              :loading="modals.addLevel.isLoading"
              :disabled="!modals.addLevel.valid || modals.addLevel.isLoading"
              @click="addLevelConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Add Building Modal -->
    <v-dialog v-model="modals.addBuilding.active" max-width="360px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Add A New Building
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.addBuilding.valid" ref="addBuildingForm">
          <v-card-text class="pa-2">
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="BuildingName"
                    placeholder="Building Name"
                    v-model="modals.addBuilding.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-switch
                    color="info"
                    v-model="modals.addBuilding.data.shortNameMatchesName"
                    @change="buildingShortNameMatchesNameHandler"
                    class="mb-2 mt-0 pa-0"
                    hide-details
                  >
                    <template v-slot:label>
                      <label
                        class="v-switch-label fs-14px"
                        :class="
                          modals.addBuilding.data.shortNameMatchesName
                            ? 'info--text'
                            : 'secondary--text'
                        "
                      >
                        Short Name Matchs Name
                      </label>
                    </template>
                  </v-switch>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="
                      modals.addBuilding.data.shortNameMatchesName
                        ? [allRules.required, allRules.noWhiteSpaces]
                        : [allRules.lengthMax(10), allRules.required, allRules.noWhiteSpaces]
                    "
                    label="Short Name"
                    id="BuildingShortName"
                    placeholder="Short Name"
                    v-model="modals.addBuilding.data.shortName"
                    :readonly="modals.addBuilding.data.shortNameMatchesName"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.number]"
                    label="Area"
                    id="BuildingArea"
                    placeholder="Area"
                    suffix="SQFT"
                    v-model.number="modals.addBuilding.data.area"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="Description"
                    id="BuildingDescription"
                    placeholder="Building Description"
                    v-model="modals.addBuilding.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addBuildingDiscard()"
              ><i class="fal fa-chevron-left mr-2"></i>Discard</v-btn
            >
            <v-btn
              color="info"
              :loading="modals.addBuilding.isLoading"
              :disabled="!modals.addBuilding.valid || modals.addBuilding.isLoading"
              @click="addBuildingConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Add
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import buildingsAPI from "../../Projects/services/buildings-service";
import levelsAPI from "../../Projects/services/levels-service";

export default {
  name: "space-building-level-editor",
  data() {
    return {
      isBuildingsLoading: true,
      buildings: [],
      levels: [],
      selected: {
        building: null,
        level: null,
      },
      modals: {
        addLevel: {
          active: false,
          valid: false,
          isLoading: false,
          data: {
            name: null,
            buildingName: null,
            shortName: null,
            shortNameMatchesName: false,
            area: null,
            description: null,
          },
        },
        addBuilding: {
          active: false,
          valid: false,
          isLoading: false,
          data: {
            name: null,
            shortName: null,
            shortNameMatchesName: false,
            area: null,
            description: null,
          },
        },
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    level: {
      type: Object,
      default: null,
    },
    allowAdding: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: "level",
  },
  computed: {
    //   levels() {
    //     if (this.selected.building != null && this.selected.building.levels != null) {
    //       return this.selected.building.levels;
    //     }
    //     return null;
    //   },
  },
  methods: {
    levelShortNameMatchesNameHandler() {
      if (this.modals.addLevel.active && this.modals.addLevel.data.shortNameMatchesName) {
        this.modals.addLevel.data.shortName = this.modals.addLevel.data.name;
      } else if (this.modals.addLevel.active && !this.modals.addLevel.data.shortNameMatchesName) {
        this.modals.addLevel.data.shortName = null;
      }
    },
    buildingShortNameMatchesNameHandler() {
      if (this.modals.addBuilding.active && this.modals.addBuilding.data.shortNameMatchesName) {
        this.modals.addBuilding.data.shortName = this.modals.addBuilding.data.name;
      } else if (
        this.modals.addBuilding.active &&
        !this.modals.addBuilding.data.shortNameMatchesName
      ) {
        this.modals.addBuilding.data.shortName = null;
      }
    },
    addLevelOpen() {
      this.modals.addLevel.active = true;
      this.modals.addLevel.valid = false;
      this.modals.addLevel.isLoading = false;
      this.modals.addLevel.data.name = null;
      this.modals.addLevel.data.shortName = null;
      this.modals.addLevel.data.shortNameMatchesName = false;
      this.modals.addLevel.data.area = null;
      this.modals.addLevel.data.description = null;
      this.modals.addLevel.data.buildingName = this.selected.building.name;
      setTimeout(() => {
        this.$refs.addLevelForm.resetValidation();
      });
    },
    addLevelDiscard() {
      this.modals.addLevel.active = false;
      this.modals.addLevel.valid = false;
      this.modals.addLevel.isLoading = false;
      this.modals.addLevel.data.name = null;
      this.modals.addLevel.data.shortName = null;
      this.modals.addLevel.data.shortNameMatchesName = null;
      this.modals.addLevel.data.area = null;
      this.modals.addLevel.data.description = null;
      this.modals.addLevel.data.buildingName = null;
    },
    addLevelConfirmed() {
      var levelData = {
        projectId: this.projectId,
        buildingId: this.selected.building.id,
        name: this.modals.addLevel.data.name,
        shortName: this.modals.addLevel.data.shortName,
        shortNameMatchesName: this.modals.addLevel.data.shortNameMatchesName,
        area: this.modals.addLevel.data.area ? this.modals.addLevel.data.area : 0,
        description: this.modals.addLevel.data.description,
      };
      this.modals.addLevel.isLoading = true;
      levelsAPI
        .add(levelData)
        .then((resp) => {
          this.modals.addLevel.isLoading = false;
          var message = "Level added successfully!";
          // if (this.selected.building.levels == null) this.selected.building.levels = [];
          this.addToArr(this.selected.building.levels, resp.data);
          this.levels = this.selected.building.levels;
          this.selected.level = resp.data;

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.addLevelDiscard();
        })
        .catch((err) => {
          this.modals.addLevel.isLoading = false;
          this.$handleError(err);
        });
    },
    addBuildingOpen() {
      this.modals.addBuilding.active = true;
      this.modals.addBuilding.valid = false;
      this.modals.addBuilding.isLoading = false;
      this.modals.addBuilding.data.name = null;
      this.modals.addBuilding.data.shortName = null;
      this.modals.addBuilding.data.shortNameMatchesName = false;
      this.modals.addBuilding.data.area = null;
      this.modals.addBuilding.data.buildingName = this.selected.building.name;
      setTimeout(() => {
        this.$refs.addBuildingForm.resetValidation();
      });
    },
    addBuildingDiscard() {
      this.modals.addBuilding.active = false;
      this.modals.addBuilding.valid = false;
      this.modals.addBuilding.isLoading = false;
      this.modals.addBuilding.data.name = null;
      this.modals.addBuilding.data.shortName = null;
      this.modals.addBuilding.data.shortNameMatchesName = false;
      this.modals.addBuilding.data.area = null;
      this.modals.addBuilding.data.description = null;
    },
    addBuildingConfirmed() {
      var buildingData = {
        projectId: this.projectId,
        name: this.modals.addBuilding.data.name,
        shortName: this.modals.addBuilding.data.shortName,
        shortNameMatchesName: this.modals.addBuilding.data.shortNameMatchesName,
        area: this.modals.addBuilding.data.area ? this.modals.addBuilding.data.area : 0,
        description: this.modals.addBuilding.data.description,
      };
      this.modals.addBuilding.isLoading = true;
      buildingsAPI
        .add(buildingData)
        .then((resp) => {
          this.modals.addBuilding.isLoading = false;
          var message = "Building added successfully!";
          this.selected.building = resp.data;
          this.addToArr(this.buildings, resp.data);
          this.buildingChanged(resp.data);

          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.addBuildingDiscard();
        })
        .catch((err) => {
          this.modals.addBuilding.isLoading = false;
          this.$handleError(err);
        });
    },
    getBuildings() {
      this.isBuildingsLoading = true;
      buildingsAPI
        .getProjectBuildings(this.projectId)
        .then((resp) => {
          this.buildings = resp.data.items;
          // this.$log("this.buildings", this.buildings);
          this.refreshBuildingsAutocomplete();
          this.isBuildingsLoading = false;
        })
        .catch((err) => {
          this.isBuildingsLoading = false;
          this.$handleError(err);
        });
    },
    buildingsFilter(item, queryText, itemText) {
      const text1 = item.name.toLowerCase();
      const text2 = item.description.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text1.indexOf(searchText) > -1 || text2.indexOf(searchText) > -1;
    },
    refreshBuildingsAutocomplete() {
      // this.$log(">>>>>>>>>>>>> refreshBuildingsAutocomplete");
      if (this.level != null && this.buildings.length > 0) {
        this.selected.building = this.buildings.find((b) => b.id == this.level.buildingId);
        this.selected.level = this.selected.building.levels.find((l) => l.id == this.level.id);
        // this.selected.level.building = null;
      } else if (this.level == null) {
        this.selected.building = null;
        this.selected.level = null;
      }
      if (this.selected.building != null) this.levels = this.selected.building.levels;
      // this.$log(">>> this.selected.building :", this.selected.building);
      // this.$log(">>> this.selected.level    :", this.selected.level);
    },
    buildingChanged(val) {
      this.$log(">>>>>>>>> buildingChanged", this.selected.building);
      if (this.selected.building != null && this.selected.building.levels != null) {
        this.levels = this.selected.building.levels;
      } else {
        this.levels = null;
      }
      this.selected.level = null;
    },
    annouceUpdate() {
      // this.$log(">>>>>>>>> ANNOUNCE", this.cloneDeep(this.selected.level));
      var toAnnounce = this.cloneDeep(this.selected.level);
      // if (this.selected.level != null) toAnnounce.building = null;
      this.$emit("input", toAnnounce);
    },
  },
  mounted() {
    this.getBuildings();
  },
  watch: {
    projectId: {
      handler() {
        if (this.projectId != null) {
          this.getBuildings();
        }
      },
      deep: true,
    },
    "modals.addLevel.data.name": {
      handler() {
        if (this.modals.addLevel.active && this.modals.addLevel.shortNameMatchesName) {
          this.modals.addLevel.shortName = this.modals.addLevel.name;
        }
      },
      deep: true,
    },
    "modals.addBuilding.data.name": {
      handler() {
        if (this.modals.addBuilding.active && this.modals.addBuilding.shortNameMatchesName) {
          this.modals.addBuilding.shortName = this.modals.addBuilding.name;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        this.annouceUpdate();
      },
      deep: true,
    },
    level: {
      handler() {
        // this.$log(">>>>>>>>> WATCH LEVEL", this.cloneDeep(this.level));
        if (this.level == null && this.selected.building != null && this.selected.level == null)
          return;
        this.refreshBuildingsAutocomplete();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.comp-wrpr {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
