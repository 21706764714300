var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-3",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-2"},[_c('h3',{staticClass:"text--primary font-weight-black mr-3 d-inline-block"},[_vm._v("Project Contacts")]),(_vm.canEdit)?_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('div',{key:"project-info-vars-btns",staticClass:"d-flex",staticStyle:{"gap":"0.5rem"}},[_c('v-btn',{attrs:{"disabled":!_vm.hasChanges || _vm.loading || _vm.saveLoading},on:{"click":function($event){return _vm.discard()}}},[_c('i',{staticClass:"fal fa-redo mr-2"}),_vm._v("Discard ")]),_c('v-btn',{attrs:{"color":"info","loading":_vm.saveLoading,"disabled":_vm.loading || _vm.saveLoading || !_vm.hasChanges || !_vm.canEdit},on:{"click":function($event){return _vm.saveContacts()}}},[_c('i',{staticClass:"fal fa-check mr-2"}),_vm._v("Save Contacts ")])],1)]):_vm._e()],1),_c('div',{staticClass:"pa-5 mt-3 inset-shadow",staticStyle:{"background":"#eceff1","border-radius":"0.5rem"}},[(_vm.canEdit)?_c('v-btn',{ref:"addContact",attrs:{"color":"info","disabled":_vm.loading},on:{"click":function($event){return _vm.open()}}},[_c('i',{staticClass:"far fa-link mr-2"}),_vm._v("Link a Contact ")]):_vm._e(),_c('v-data-table',{staticClass:"contacts-table elevation-2 mt-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.selected,"items-per-page":-1,"loading":_vm.loading,"hide-default-footer":""},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.contactState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getTextByValue(item.contactState, _vm.states))+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item,"hide-roles":"","disable-open-link":""}})]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":"","elevation":"0","color":"error","disabled":!_vm.canEdit},on:{"click":function($event){$event.stopPropagation();return _vm.del(index)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('phone-number',{model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]}},{key:"item.addressLine1",fn:function(ref){
var item = ref.item;
return [_c('address-summary',{attrs:{"value":item}})]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","nudge-top":"-16px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-2",on:{"click":function($event){$event.stopPropagation();return _vm.viewClient(item.company.id)}}},'div',attrs,false),on),[(item.company.logoUrl != null && item.company.logoUrl != '')?_c('v-img',{attrs:{"src":item.company.logoUrl,"max-height":"28","max-width":"54","position":"left center","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","max-height":"28","max-width":"54","position":"left center","contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.company.name))])])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('add-contacts-to',{ref:"addContactToProject",attrs:{"selected":_vm.project,"name":"Project"},on:{"confirm":_vm.slideoutsConfirmed}}),_c('edit-contact',{ref:"editContact",attrs:{"disableEdit":""}}),_c('edit-client',{ref:"editClient"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }