<template>
  <v-container
    fluid
    style="text-align: center;"
    class="d-flex align-center flex-column"
  >
    <img
      width="500"
      style="max-width: 100%"
      src="/img/art/undraw_cancel_u1it.svg"
    />
    <h1 class="mt-3" style="font-size: min(4rem, 7ch)">Not Authorized!</h1>
    <h3
      class="font-weight-bold text--disabled"
      style="font-size: min(1.5rem, 3ch)"
    >
      Well, That's Awkward!
    </h3>
    <h3
      class="font-weight-bold text--disabled"
      style="font-size: min(1.5rem, 3ch)"
      id="mainText"
    >
      You don't have permissions to access this {{ label.toLowerCase() }}
      <span v-if="redirectedFrom">
        <v-btn
          color="error"
          text
          :to="redirectedFrom"
          style="padding-left: 8px !important; padding-right: 8px !important"
        >
          <i class="fad fa-link mr-2"></i>{{ redirectedFrom }}
        </v-btn>
      </span>
      <span v-else>resource...</span>
    </h3>
    <v-divider style="max-width: 50vw"></v-divider>
    <h3
      class="font-weight-bold text--disabled d-flex justify-center align-center mt-4"
    >
      This doesn't look right?
    </h3>
    <v-btn
      color="red"
      class="mt-1"
      small
      outlined
      id="reportThis"
      @click="report"
      style="padding: 0px 8px !important"
    >
      <i class="fad fa-flag-alt mr-2"></i>Request a Permission!
    </v-btn>
  </v-container>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    errorMessage() {
      return `I need a permission to access this ${this.label ||
        "resource..."}! \n— Reference: ${this.currentLocation}`;
    },
    redirectedFrom() {
      return this.$route.path ? this.$route.path : null;
    },
    currentLocation() {
      return window.location.href;
    }
  },
  methods: {
    report() {
      window.openPermissionRequest(
        `${this.label || "Resource"} Permission Request`,
        this.errorMessage
      );
    }
  }
};
</script>

<style lang="scss"></style>
