export default [
  {
    text: "Actions",
    value: "actions",
    width: "92px",
    align: "left",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
    hiddenInPrint: true,
  },
  {
    text: "Image",
    value: "name",
    width: "80px",
    order: 1,
    sortable: false,
    hidable: false,
    hidden: false,
  },
  // {
  //   text: "Category",
  //   value: "category",
  //   sortable: false,
  //   width: "120px",
  //   order: 2,
  //   hidable: false,
  //   hidden: false,
  // },
  { text: "Tag", value: "tag", width: "120px", order: 3, hidable: true, hidden: false },
  {
    text: "Provider",
    value: "provider",
    width: "100px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Installer",
    value: "installer",
    width: "100px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Manufacturer",
    width: "150px",
    value: "manufacture.name",
    order: 6,
    hidable: true,
    hidden: false,
  },
  {
    text: "Family",
    width: "150px",
    value: "productFamily.name",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "Quote Number",
    value: "quoteNumber",
    width: "140px",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Date Received",
    value: "dateReceived",
    width: "140px",
    order: 9,
    hidable: true,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 10,
    hidable: true,
    hidden: true,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 11,
    hidable: true,
    hidden: true,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 12,
    hidable: true,
    hidden: true,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 13,
    hidable: true,
    hidden: true,
  },
];
