import Api from "../../Shared/services/api";
import ApiUploader from "../../Shared/services/apiUploader";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "ExternalProjects";
export default {
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  get(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  getOverview(projectId) {
    return Api().get(`${baseUrl}/${projectId}/Overview`);
  },
  getSpacesLiteOverview(projectId) {
    return Api().get(`${baseUrl}/${projectId}/SpacesLiteOverview`);
  },
  getProjectVariables(projectId) {
    return Api().get(`${baseUrl}/${projectId}/Variables`);
  },
  getProjectItem(projectId, item) {
    return Api().get(`${baseUrl}/${projectId}/${item}`);
  },
  saveProjectItem(projectId, key, data) {
    return Api().post(`${baseUrl}/${projectId}/${key}`, data);
  },
  saveVariables(projectId, data) {
    return Api().post(`${baseUrl}/${projectId}/Variables`, data);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  updateAssignee(projectId, entity) {
    if (entity.id) return Api().put(baseUrl + `/${projectId}/Assignee`, entity);
    else return Api().post(baseUrl + `/${projectId}/Assignee`, entity);
  },
  getAccessList(projectId) {
    return Api().get(`${baseUrl}/${projectId}/AccessList`);
  },
  saveAccessList(projectId, data) {
    return Api().put(`${baseUrl}/${projectId}/AccessList`, data);
  },
};
