<template>
  <v-container fluid class="pa-3" v-if="projectId != null && projectId != '' && projectId != NaN">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        overflow: auto;
        overflow: overlay;
      "
      class="px-3 d-flex flex-column inset-shadow"
    >
      <v-timeline align-top dense style="display: flex; flex-direction: column; flex: 1 1 auto">
        <v-timeline-item v-for="(item, i) in entities" :key="i" color="white" right fill-dot>
          <template v-slot:icon>
            <user-avatar
              style="z-index: 100"
              :user="item.createdBy"
              icon
              :mini="false"
            ></user-avatar>
          </template>
          <v-card
            :color="item.type == ActivityLogType.Automatic.value ? 'blue-grey lighten-4' : 'white'"
          >
            <v-card-title class="fs-14px py-2">
              <span class="mr-2">
                <span v-if="item.type == ActivityLogType.Automatic.value">
                  <v-tooltip top z-index="999" nudge-top="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="text-center"
                        style="font-size: 16px; width: 24px"
                      >
                        <i class="fad fa-bolt-auto"></i>
                      </div>
                    </template>
                    <span>Auto logged by Concordia</span>
                  </v-tooltip>
                </span>
                <span v-else-if="item.type == ActivityLogType.Manual.value">
                  <v-tooltip top z-index="999" nudge-top="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="text-center"
                        style="font-size: 16px; width: 24px"
                      >
                        <i class="fad fa-square-quote"></i>
                      </div>
                    </template>
                    <span>This note is manually added by user</span>
                  </v-tooltip>
                </span>
                <span v-else-if="item.type == ActivityLogType.RelatedActions.value">
                  <v-tooltip top z-index="999" nudge-top="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="text-center"
                        style="font-size: 16px; width: 24px"
                      >
                        <i class="fad fa-cog"></i>
                      </div>
                    </template>
                    <span>This is a log for an action performed by user</span>
                  </v-tooltip>
                </span>
              </span>
              <dater :date="item.createDate" no-tooltip></dater>
            </v-card-title>
            <v-card-text v-if="item.type == ActivityLogType.Automatic.value">
              <v-expansion-panels multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                    <v-row no-gutters justify="space-between">
                      <v-col cols="auto" class="">
                        <span class="font-weight-bold d-flex align-center">
                          <v-chip
                            style="
                              height: 22px;
                              min-width: 24px;
                              justify-content: center;
                              font-size: 13px;
                            "
                            color="blue-grey"
                            text-color="white"
                            label
                            class="mr-2"
                            >{{ item.variances.length }}
                          </v-chip>
                          <span>Change{{ item.variances.length > 1 ? "s" : "" }}</span>
                        </span>
                      </v-col>
                      <v-col cols="auto" class="d-flex align-center"> </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="logged-changes-panel-content">
                    <div v-for="(changedProp, j) in item.variances" :key="j">
                      <v-divider class="mb-1"></v-divider>
                      <div class="py-2 px-4 mb-1">
                        <span class="d-flex align-center">
                          <!-- .split(/(?=[A-Z])/) -->
                          <v-chip small color="secondary" text-color="white" style="height: 22px">{{
                            changedProp.prop
                              .split(/([A-Z][a-z]+)/g)
                              .join(" ")
                              .replace(". ", " > ")
                          }}</v-chip>
                          <span class="font-weight-medium ml-1">was updated</span>
                        </span>
                        <v-row class="mt-1">
                          <v-col cols="12" md="6">
                            <div class="d-flex">
                              <span class="font-weight-bold mr-1">From:</span>
                              <image-url-logged-prop
                                v-if="changedProp.prop == 'Image'"
                                v-model="changedProp.oldValue"
                              ></image-url-logged-prop>
                              <description-logged-prop
                                v-else-if="changedProp.prop == 'Description'"
                                v-model="changedProp.oldValue"
                              ></description-logged-prop>
                              <description-logged-prop
                                v-else-if="changedProp.prop == 'SystemDescription'"
                                v-model="changedProp.oldValue"
                              ></description-logged-prop>
                              <type-logged-prop
                                v-else-if="changedProp.prop == 'Type'"
                                v-model="changedProp.oldValue"
                              ></type-logged-prop>
                              <stage-status-logged-prop
                                v-else-if="changedProp.prop == 'Stage/Status'"
                                v-model="changedProp.oldValue"
                              ></stage-status-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'Client'"
                                v-model="changedProp.oldValue"
                                title="Client"
                                url-prefix="clients"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'LeadVI'"
                                v-model="changedProp.oldValue"
                                title="User"
                                url-prefix="users"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'LeadAC'"
                                v-model="changedProp.oldValue"
                                title="User"
                                url-prefix="users"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop.endsWith('NamingTemplate')"
                                v-model="changedProp.oldValue"
                                title="Naming Template"
                                url-prefix="naming-templates"
                              ></object-logged-prop>
                              <default-logged-prop
                                v-else
                                v-model="changedProp.oldValue"
                              ></default-logged-prop>
                            </div>
                          </v-col>
                          <v-col cols="12" md="6">
                            <div class="d-flex">
                              <span class="font-weight-bold mr-1">To:</span>
                              <image-url-logged-prop
                                v-if="changedProp.prop == 'Image'"
                                v-model="changedProp.newValue"
                              ></image-url-logged-prop>
                              <stage-status-logged-prop
                                v-else-if="changedProp.prop == 'Stage/Status'"
                                v-model="changedProp.newValue"
                              ></stage-status-logged-prop>
                              <description-logged-prop
                                v-else-if="changedProp.prop == 'Description'"
                                v-model="changedProp.newValue"
                              ></description-logged-prop>
                              <description-logged-prop
                                v-else-if="changedProp.prop == 'SystemDescription'"
                                v-model="changedProp.newValue"
                              ></description-logged-prop>
                              <type-logged-prop
                                v-else-if="changedProp.prop == 'Type'"
                                v-model="changedProp.newValue"
                              ></type-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'Client'"
                                v-model="changedProp.newValue"
                                title="Client"
                                url-prefix="clients"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'LeadVI'"
                                v-model="changedProp.newValue"
                                title="User"
                                url-prefix="users"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop == 'LeadAC'"
                                v-model="changedProp.newValue"
                                title="User"
                                url-prefix="users"
                              ></object-logged-prop>
                              <object-logged-prop
                                v-else-if="changedProp.prop.endsWith('NamingTemplate')"
                                v-model="changedProp.newValue"
                                title="Naming Template"
                                url-prefix="naming-templates"
                              ></object-logged-prop>
                              <default-logged-prop
                                v-else
                                v-model="changedProp.newValue"
                              ></default-logged-prop>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-card-text v-else-if="item.type == ActivityLogType.Manual.value">
              <div
                class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 secondary--text"
                v-html="
                  item.body != null && item.body != ''
                    ? item.body
                    : `<i class='fad fa-empty-set opacity-64'></i>`
                "
              />
            </v-card-text>
            <v-card-text v-else-if="item.type == ActivityLogType.RelatedActions.value">
              <related-actions-logs v-model="item.body"></related-actions-logs>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item v-if="!isEndReached" :key="'end-not-reached'" color="white" right fill-dot>
          <template v-slot:icon>
            <i class="fad fa-spinner-third fa-spin"></i>
          </template>
          <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-0">
            Fetching more activity logs...
          </v-card-title>
        </v-timeline-item>
        <v-timeline-item v-else :key="'end-reached'" color="white" right fill-dot>
          <template v-slot:icon>
            <i class="fad fa-flag-pennant"></i>
          </template>
          <v-card-title v-if="entities.length == 0" class="fs-14px py-1 px-0 text-secondary">
            No logs recorded yet!
          </v-card-title>
          <v-card-title v-else class="fs-14px py-1 px-0 text-secondary">End of Logs</v-card-title>
        </v-timeline-item>
      </v-timeline>
    </div>
    <!-- <v-btn color="info" @click="openNoteModal">
      <i class="far fa-plus mr-2"></i>
      Add Note/Review
    </v-btn> -->
    <v-dialog v-model="modals.note.active" max-width="850px" style="z-index: 99999999">
      <v-card dense>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Add Note / Review
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.note.valid" ref="addManualNote">
          <v-card-text>
            <v-container fluid class="pa-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.note.body"
                    title="Note"
                    subtitle="Add your notes or review for this project"
                    showLabel
                    maxHeight="132px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="modals.note.active = false" :disabled="modals.note.isLoading">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :loading="modals.note.isLoading"
              :disabled="!validNoteString || modals.note.isLoading"
              @click="noteConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Enums from "../../../../plugins/enums";
import projectService from "../../services/projects-service";
import RichTextEditor from "../../../Shared/components/RichTextEditor.vue";
import DefaultLoggedProp from "./DefaultLoggedProp.vue";
import ImageUrlLoggedProp from "./ImageUrlLoggedProp.vue";
import StageStatusLoggedProp from "./StageStatusLoggedProp.vue";
import DescriptionLoggedProp from "./DescriptionLoggedProp.vue";
import RelatedActionsLogs from "./RelatedActionsLogs.vue";
import ObjectLoggedProp from "./ObjectLoggedProp.vue";
import TypeLoggedProp from "./TypeLoggedProp.vue";

export default {
  name: "project-activity-logs",
  components: {
    DefaultLoggedProp,
    ImageUrlLoggedProp,
    StageStatusLoggedProp,
    RelatedActionsLogs,
    RichTextEditor,
    DescriptionLoggedProp,
    ObjectLoggedProp,
    TypeLoggedProp,
  },
  data() {
    return {
      ActivityLogType: Enums.ActivityLogType,
      isLogsInitiallyLoaded: false,
      isEndReached: false,
      isIntersecting: false,
      isLoading: false,
      entities: [],
      modals: {
        note: {
          body: null,
          valid: false,
          active: false,
          isLoading: false,
        },
      },
      options: {
        search: null,
        includeProject: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      },
      storedSearch: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    project: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.prepare();
  },
  computed: {
    projectUrl() {
      if (this.projectId == null) return "";
      return `project/${this.projectId}`;
    },
    validNoteString() {
      if (!this.modals.note.active) return false;
      if (this.stripHtmlTags(this.modals.note.body) == "") return false;
      return true;
    },
  },
  methods: {
    openNoteModal() {
      this.modals.note = {
        body: null,
        valid: false,
        active: true,
        isLoading: false,
      };
    },
    noteConfirmed() {
      this.modals.note.isLoading = true;
      projectService
        .addActivityLog(this.projectId, this.modals.note.body)
        .then((resp) => {
          this.modals.note = {
            body: null,
            valid: false,
            active: false,
            isLoading: false,
          };
          resp.data.variances.forEach((variance) => {
            if (variance.prop == "ImageUrl") variance.prop = "Image";
          });
          this.entities.unshift(resp.data);
        })
        .catch((err) => {
          this.this.modals.note.isLoading = false;
          this.$handleError(err);
        });
    },
    stripHtmlTags(str) {
      if (str === null || str === "") return "";
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    prepare() {
      this.isEndReached = false;
      this.entities = [];
      this.options = {
        search: null,
        includeProject: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      };
      this.getData();
    },
    getData() {
      this.$log(">>>> getData - this.isEndReached", this.isEndReached);
      if (this.isEndReached || this.isLoading) return;
      this.options.page++;
      this.$log(">>>> getData > PROCEEDED > PAGE", this.options.page);
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      // if (!this.isLogsInitiallyLoaded) {
      //   optionsToSend.includeProject = true;
      // }
      this.isLoading = true;
      projectService
        .getActivityLog(this.projectId, this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isLogsInitiallyLoaded) {
            this.isLogsInitiallyLoaded = true;
            // this.project = resp.data.project;
          }
          if (resp.data.items.length < this.options.itemsPerPage) {
            this.isEndReached = true;
          }
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            element.variances.forEach((variance) => {
              if (variance.prop == "ImageUrl") variance.prop = "Image";
            });
            this.entities.push(element);
          });
          this.total = resp.data.total;
          this.isLoading = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getData();
            }
          }, 500);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getData();
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
.logged-changes-panel-content .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
