<template>
  <span class="d-inline-flex" style="gap: 0.25rem">
    <span v-if="value != null && value.trim() != '<p></p>' && value.trim() != ''">
      <div
        class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
        style="line-break: normal"
        v-html="value"
      ></div>
    </span>
    <span v-else> <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i></span>
  </span>
</template>

<script>
export default {
  components: {},
  name: "description-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return this.value.replaceAll("_", " ");
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
