<template>
  <v-container fluid class="pa-3" v-if="projectId != null && projectId != '' && projectId != NaN">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
      "
      class="pa-3 pt-0 inset-shadow"
    >
      <contracts-manager
        :projectId="projectId"
        :readonly="readonly"
        ref="contractsManager"
      ></contracts-manager>
    </div>
  </v-container>
</template>

<script>
import ContractsManager from "../../Contracts/components/ContractsManager.vue";

export default {
  name: "project-contracts",
  components: { ContractsManager },
  data() {
    return {};
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss"></style>
