var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.file != null)?_c('v-card',{staticClass:"file",class:{
    'uploading-failed': _vm.uploadError,
    'uploading-in-progress': _vm.uploadActive,
    'uploading-queue': _vm.uploadQueue,
  },attrs:{"elevation":"2","rounded":"lg"}},[_c('div',{staticClass:"file-thumbnail",attrs:{"data-ext":_vm.fileExt}},[_c('span',{staticClass:"file-ext elevation-1",attrs:{"data-ext":_vm.fileExt}},[_c('span',[_vm._v(_vm._s(_vm.fileExt))])])]),_c('div',{ref:"fileInfo",staticClass:"file-info"},[_c('v-tooltip',{attrs:{"top":"","z-index":"999","left":"","nudge-top":"-8px","disabled":!_vm.fileNameOverflow},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({ref:"FileName",staticClass:"file-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.fileName)+" ")])]}}],null,false,2628392437)},[_c('span',[_vm._v(" "+_vm._s(_vm.fileName))])]),_c('div',{staticClass:"file-details"},[_c('div',{staticClass:"file-size"},[_vm._v(_vm._s(_vm._f("fileSize")(_vm.file.size)))]),(!_vm.uploadQueue)?_c('i',{staticClass:"fad fa-circle",staticStyle:{"font-size":"6px"}}):_vm._e(),(!_vm.uploadQueue)?_c('div',{staticClass:"file-date"},[_c('dater',{attrs:{"date":_vm.file.createDate}})],1):_vm._e(),(!_vm.uploadQueue)?_c('i',{staticClass:"fad fa-circle",staticStyle:{"font-size":"6px"}}):_vm._e(),(!_vm.uploadQueue)?_c('user-avatar',{attrs:{"user":_vm.file.createdBy,"icon":"","mini":""}}):_vm._e(),(!_vm.uploadQueue && _vm.file.description != null && _vm.file.description.trim() != '')?_c('i',{staticClass:"fad fa-circle",staticStyle:{"font-size":"6px"}}):_vm._e(),(!_vm.uploadQueue && _vm.file.description != null && _vm.file.description.trim() != '')?_c('v-tooltip',{attrs:{"top":"","z-index":"999","nudge-top":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-1 d-flex"},'span',attrs,false),on),[_c('i',{staticClass:"fad fa-square-quote fs-14px secondary--text"})])]}}],null,false,1797934791)},[_c('span',{staticClass:"d-flex align-center"},[_c('b',{staticClass:"mr-1"},[_c('u',[_vm._v("Description")]),_vm._v(":")]),_vm._v(" "+_vm._s(_vm.file.description)+" ")])]):_vm._e()],1)],1),_c('div',{staticClass:"file-actions"},[(!_vm.uploadQueue && _vm.file.blobUrl != null && _vm.file.blobUrl != '')?_c('v-btn',{attrs:{"icon":"","color":"secondary"},on:{"click":_vm.view}},[_c('i',{staticClass:"fad fa-eye"})]):_vm._e(),(!_vm.uploadQueue && _vm.canDownload && _vm.file.blobUrl != null && _vm.file.blobUrl != '')?_c('v-btn',{attrs:{"icon":"","color":"secondary","href":_vm.file.blobUrl,"download":_vm.file.fileName,"target":"_blank"}},[_c('i',{staticClass:"fad fa-down-to-bracket"})]):_vm._e(),(!_vm.uploadQueue && (_vm.canUpdate || _vm.canDelete))?_c('v-menu',{attrs:{"dense":"","offset-x":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var attrs = ref.attrs;
  var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,false,1714223097)},[_c('v-list',{staticClass:"more-options-menu"},[(_vm.canUpdate)?_c('v-list-item',{on:{"click":_vm.update}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{staticClass:"secondary--text",attrs:{"small":""}},[_vm._v("fal fa-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"secondary--text"},[_vm._v("Update Description")])],1)],1):_vm._e(),(_vm.canDelete)?_c('v-list-item',{on:{"click":_vm.del}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-trash-alt red--text")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.uploadQueue)?_c('v-tooltip',{attrs:{"top":"","z-index":"999","nudge-top":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding":"0 !important"},attrs:{"dark":"","rounded":"","width":"28px","min-width":"28px","height":"28px","min-height":"28px"},on:{"click":_vm.cancelUpload}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-times"})])]}}],null,false,1733993136)},[_c('span',{staticClass:"d-flex align-center"},[_vm._v("Cancel Uploading!")])]):_vm._e(),(_vm.uploadQueue && _vm.uploadError)?_c('v-tooltip',{attrs:{"top":"","z-index":"999","nudge-top":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"padding":"0 !important"},attrs:{"color":"orange","dark":"","rounded":"","width":"28px","min-width":"28px","height":"28px","min-height":"28px"},on:{"click":_vm.retryUpload}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-redo"})])]}}],null,false,918848191)},[_c('span',{staticClass:"d-flex align-center"},[_vm._v("Retry")])]):_vm._e()],1),(_vm.uploadQueue)?_c('div',{staticClass:"uploading-progress"},[(_vm.uploadActive || _vm.uploadError)?_c('div',{staticClass:"bar",style:({ width: _vm.uploadProgress + '%' })}):_vm._e(),_c('span',{staticClass:"percentage"},[(!_vm.uploadActive && !_vm.uploadError)?_c('i',{staticClass:"fad fa-spinner-third fa-spin"}):(!_vm.uploadActive && _vm.uploadError)?_c('i',{staticClass:"fad fa-exclamation-triangle red--text opacity-32"}):(_vm.uploadActive && !_vm.uploadError)?_c('span',{staticClass:"opacity-72 green--text"},[_vm._v(" "+_vm._s(_vm.uploadProgress)+"% ")]):_vm._e()])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }