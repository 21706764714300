<template>
  <v-container fluid class="pa-3">
    <h3 class="text--primary font-weight-black mb-3">Project Budget</h3>
    <div class="d-flex justify-center align-center mt-3" v-if="loading">
      <v-progress-circular indeterminate color="info" :width="2"></v-progress-circular>
    </div>
    <v-form v-model="valid" ref="projectTimelineForm" v-else>
      <template v-for="stageItem in budgetStages">
        <div class="stage-wrapper" :class="stageItem.key" :key="stageItem.key">
          <v-row align="end">
            <v-col cols="12" class="pb-0" :key="`budget-divider${stageItem.key}`">
              <div class="d-flex justify-space-between align-center mb-2">
                <h3 class="stage-title font-weight-black mr-3 d-inline-block">
                  {{ stageItem.title }}
                </h3>
                <transition
                  name="fade"
                  mode="out-in"
                  appear
                  v-if="stageItem.projectStage == projectStage && canEdit"
                >
                  <div key="project-info-vars-btns" class="d-flex" style="gap: 0.5rem">
                    <v-btn @click="discard()" :disabled="!hasChanges || loading || saveLoading">
                      <i class="fal fa-redo mr-2"></i>Discard
                    </v-btn>
                    <v-btn
                      color="info"
                      @click="saveBudget()"
                      :loading="saveLoading"
                      :disabled="!valid || loading || saveLoading || !hasChanges"
                    >
                      <i class="fal fa-check mr-2"></i>Save Budget
                    </v-btn>
                  </div>
                </transition>
              </div>
              <v-divider></v-divider>
            </v-col>
            <template>
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="input in stageItem.budget"
                :key="input.key + stageItem.key"
              >
                <v-text-field-alt
                  :rules="[allRules.number]"
                  :label="input.label"
                  :id="input.key"
                  :ref="input.key"
                  :placeholder="input.label"
                  v-model.number="selected[stageItem.key][input.key]"
                  prefix="$"
                  :readonly="
                    input.projectStage != projectStage || projectStage != initialStage || !canEdit
                  "
                  hide-details
                >
                </v-text-field-alt>
              </v-col>
            </template>
          </v-row>
        </div>
      </template>
      <project-budget-chart :selected="selected"></project-budget-chart>
    </v-form>
  </v-container>
</template>

<script>
import perms from "../../../plugins/permissions";
import projectsAPI from "../services/projects-service";
import ProjectBudgetChart from "./ProjectBudgetChart.vue";

export default {
  components: { ProjectBudgetChart },
  name: "project-budget",
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    projectStage: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      valid: false,
      hasChanges: false,
      loading: false,
      saveLoading: false,
      initialStage: 1,
      selected: {
        opportunityBudget: {
          laborBudget: null,
          materialsBudget: null,
          totalBudget: null,
        },
        leadBudget: {
          laborBudget: null,
          materialsBudget: null,
          totalBudget: null,
        },
        activeBudget: {
          laborBudget: null,
          materialsBudget: null,
          totalBudget: null,
        },
      },
      selectedCemented: {},
      budgetStages: {
        lead: {
          key: "leadBudget",
          title: "Lead Budget",
          projectStage: 0,
          budget: [
            {
              projectStage: 0,
              label: "Labor",
              key: "laborBudget",
            },
            {
              projectStage: 0,
              label: "Materials",
              key: "materialsBudget",
            },
            {
              projectStage: 0,
              label: "Total",
              key: "totalBudget",
            },
          ],
        },
        opportunity: {
          key: "opportunityBudget",
          title: "Opportunity Budget",
          projectStage: 1,
          budget: [
            {
              projectStage: 1,
              label: "Labor",
              key: "laborBudget",
            },
            {
              projectStage: 1,
              label: "Materials",
              key: "materialsBudget",
            },
            {
              projectStage: 1,
              label: "Total",
              key: "totalBudget",
            },
          ],
        },
        active: {
          key: "activeBudget",
          title: "Active Budget",
          projectStage: 2,
          budget: [
            {
              projectStage: 2,
              label: "Labor",
              key: "laborBudget",
            },
            {
              projectStage: 2,
              label: "Materials",
              key: "materialsBudget",
            },
            {
              projectStage: 2,
              label: "Total",
              key: "totalBudget",
            },
          ],
        },
      },
    };
  },
  created() {
    this.initialStage = this.projectStage;
    this.getProjectBudget();
  },
  methods: {
    updateTotal(target) {
      var labor =
        this.allRules.number(this.selected[target].laborBudget) != true
          ? 0
          : this.selected[target].laborBudget;
      var materials =
        this.allRules.number(this.selected[target].materialsBudget) != true
          ? 0
          : this.selected[target].materialsBudget;
      this.selected[target].totalBudget = labor + materials;
    },
    getProjectBudget() {
      this.loading = true;
      projectsAPI
        .getProjectItem(this.projectId, "Budget")
        .then((resp) => {
          this.setSelected(resp);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setSelected(resp) {
      this.selected = resp.data;
      for (const key in this.selected) {
        for (const child in this.selected[key]) {
          if (this.selected[key][child] == null) this.selected[key][child] = "";
        }
      }
      this.cementBudget();
    },

    getSelected() {
      let selected = null;
      switch (this.projectStage) {
        case 1:
          selected = this.selected.opportunityBudget;
          break;
        case 2:
          selected = this.selected.activeBudget;
          break;
        default:
          selected = null;
          break;
      }
      return selected;
    },
    saveBudget() {
      const dataToSend = this.getSelected();
      if (dataToSend) {
        this.saveLoading = true;
        projectsAPI
          .saveProjectItem(this.projectId, "Budget", dataToSend)
          .then((resp) => {
            this.setSelected(resp);
            this.$dialog.notify.success("Budget updated!", {
              position: "top-right",
              timeout: 3000,
            });
          })
          .catch((err) => {
            this.$handleError(err);
          })
          .finally(() => {
            this.saveLoading = false;
          });
      }
    },
    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementBudget() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkChanges();
      },
      deep: true,
    },
    //opportunityBudget
    "selected.opportunityBudget.laborBudget": {
      handler() {
        this.updateTotal("opportunityBudget");
      },
    },
    "selected.opportunityBudget.materialsBudget": {
      handler() {
        this.updateTotal("opportunityBudget");
      },
    },
    //activeBudget
    "selected.activeBudget.laborBudget": {
      handler() {
        this.updateTotal("activeBudget");
      },
    },
    "selected.activeBudget.materialsBudget": {
      handler() {
        this.updateTotal("activeBudget");
      },
    },
    hasChanges() {
      this.$emit("has-changes", this.hasChanges);
    },
    valid() {
      this.$emit("valid-changes", this.valid);
    },
    projectId: {
      handler(newRoute, oldRoute) {
        this.getProjectBudget();
      },
    },
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectBudget.Update);
    },
  },
};
</script>

<style lang="scss">
.stage-wrapper {
  border: 1px dashed rgba($shades-black, 0.4);
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &.leadBudget {
    background: rgba($info-base, 0.02);
    border-color: $info-base;

    .stage-title {
      color: $info-base;
      background: rgba($info-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
  &.opportunityBudget {
    background: rgba($orange-base, 0.02);
    border-color: $orange-base;

    .stage-title {
      color: $orange-base;
      background: rgba($orange-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
  &.activeBudget {
    background: rgba($success-base, 0.02);
    border-color: $success-base;

    .stage-title {
      color: $success-base;
      background: rgba($success-base, 0.1);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      padding: 0.25rem 0.75rem;
    }
  }
}
</style>
