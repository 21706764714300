var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.areaGroupName != null)?_c('h3',{staticClass:"mt-2 d-flex justify-space-between align-center"},[_c('span',{staticClass:"d-inline-flex align-center"},[_c('i',{staticClass:"fad fa-info-circle mr-2"}),_vm._v("Add to: "),_c('v-chip',{staticClass:"ml-2 elevation-1",attrs:{"color":"white","label":""}},[_c('i',{staticClass:"fad fa-shapes mr-2"}),_vm._v(" "+_vm._s(_vm.areaGroupName)+" ")])],1),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.createNewEquipment}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v(" Create New Equipment ")]),_c('edit-equipment',{ref:"createNewEquipmentComponent",attrs:{"equipment":_vm.toBeCreateEquipment,"nested":""},on:{"save":_vm.onEquipmentSaved}})],1):_vm._e(),_c('v-row',{staticClass:"mt-0",attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"d-flex justify-start align-center"},[_c('v-switch',{staticClass:"ma-0",staticStyle:{"font-size":"14px !important"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"v-switch-label ma-0"},[_vm._v("Only Show Selected")])]},proxy:true}]),model:{value:(_vm.selectedOnly),callback:function ($$v) {_vm.selectedOnly=$$v},expression:"selectedOnly"}}),_c('v-switch',{staticClass:"ma-0 ms-4",staticStyle:{"font-size":"14px !important"},attrs:{"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"v-switch-label ma-0"},[_vm._v("Show Deprecated & Discontinued Equipment")])]},proxy:true}]),model:{value:(_vm.showHidden),callback:function ($$v) {_vm.showHidden=$$v},expression:"showHidden"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"lg":"3","md":"3","sm":"12"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","hide-details":"","dense":"","solo":"","clearable":"","prepend-inner-icon":"far fa-search"},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedOnly),expression:"selectedOnly"}],staticClass:"elevation-2 mt-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.selectedEquipments,"item-class":_vm.rowClass,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.equipment.name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[((item.equipment.imageUrl != null) & (item.equipment.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.equipment.imageUrl,"height":"100%","width":"100%","contain":""}}):(item.equipment.category != null)?_c('i',{class:'fad ' + item.equipment.category.icon}):_c('i',{class:'fad fa-plug'})],1)])]}}],null,true)},[_c('span',[((item.equipment.imageUrl != null) & (item.equipment.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.equipment.imageUrl,"width":"250px","height":"250px","contain":""}}):(item.equipment.category != null)?_c('i',{class:'fad ' + item.equipment.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}}):_c('i',{class:'fad fa-plug',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.equipment.tag",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"equipment-tag"},[_vm._v(_vm._s(item.equipment.tag))])]}},{key:"item.equipment.category",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"sm":"auto"}},[(item.equipment.category)?_c('i',{class:'equipment-icon fad ' + item.equipment.category.icon}):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center"},[(item.equipment.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.equipment.category.name))]):_vm._e()])],1)]}},{key:"item.equipment.manufacture.name",fn:function(ref){
        var item = ref.item;
return [(item.equipment.manufacture != null)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(
                    item.equipment.manufacture.logoUrl != null &&
                    item.equipment.manufacture.logoUrl != ''
                  )?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.equipment.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.equipment.manufacture.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.equipment.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(
                item.equipment.manufacture.logoUrl != null &&
                item.equipment.manufacture.logoUrl != ''
              )?_c('v-img',{attrs:{"src":item.equipment.manufacture.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0"},[_vm._v(_vm._s(item.equipment.manufacture.name))])],1):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.equipment.model",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"equipment-model"},[_vm._v(_vm._s(item.equipment.model))])]}},{key:"item.equipment.availability",fn:function(ref){
              var item = ref.item;
return [_c('equipment-availability',{attrs:{"availability":item.equipment.availability,"mini":""}})]}},{key:"item.equipment.installerId",fn:function(ref){
              var item = ref.item;
return [_c('equipment-installer-selector-static',{attrs:{"installers-list":_vm.installersList,"readonly":""},model:{value:(item.equipment.installerId),callback:function ($$v) {_vm.$set(item.equipment, "installerId", $$v)},expression:"item.equipment.installerId"}})]}},{key:"item.equipment.providerId",fn:function(ref){
              var item = ref.item;
return [_c('equipment-provider-selector-static',{attrs:{"providers-list":_vm.providersList,"readonly":""},model:{value:(item.equipment.providerId),callback:function ($$v) {_vm.$set(item.equipment, "providerId", $$v)},expression:"item.equipment.providerId"}})]}},{key:"item.count",fn:function(ref){
              var item = ref.item;
return [_c('counter',{key:item.equipment.id,attrs:{"min":0,"max":1000},on:{"input":function($event){return _vm.onCountChangeFromSelectedOnly($event, item.equipment)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0"},on:{"click":function($event){return _vm.viewEquipment(item.equipment)}}},[_c('i',{staticClass:"fad fa-info"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.selectedOnly),expression:"!selectedOnly"}],staticClass:"elevation-2 mt-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.selectedEntities,"item-class":_vm.rowClass,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.equipment.name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[((item.equipment.imageUrl != null) & (item.equipment.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.equipment.imageUrl,"height":"100%","width":"100%","contain":""}}):(item.equipment.category != null)?_c('i',{class:'fad ' + item.equipment.category.icon}):_c('i',{class:'fad fa-plug'})],1)])]}}],null,true)},[_c('span',[((item.equipment.imageUrl != null) & (item.equipment.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.equipment.imageUrl,"width":"250px","height":"250px","contain":""}}):(item.equipment.category != null)?_c('i',{class:'fad ' + item.equipment.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}}):_c('i',{class:'fad fa-plug',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.equipment.tag",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"equipment-tag"},[_vm._v(_vm._s(item.equipment.tag))])]}},{key:"item.equipment.category",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"sm":"auto"}},[(item.equipment.category)?_c('i',{class:'equipment-icon fad ' + item.equipment.category.icon}):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center"},[(item.equipment.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.equipment.category.name))]):_vm._e()])],1)]}},{key:"item.equipment.availability",fn:function(ref){
    var item = ref.item;
return [_c('equipment-availability',{attrs:{"availability":item.equipment.availability,"mini":""}})]}},{key:"item.equipment.manufacture.name",fn:function(ref){
    var item = ref.item;
return [(item.equipment.manufacture != null)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(
                    item.equipment.manufacture.logoUrl != null &&
                    item.equipment.manufacture.logoUrl != ''
                  )?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.equipment.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.equipment.manufacture.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.equipment.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(
                item.equipment.manufacture.logoUrl != null &&
                item.equipment.manufacture.logoUrl != ''
              )?_c('v-img',{attrs:{"src":item.equipment.manufacture.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0"},[_vm._v(_vm._s(item.equipment.manufacture.name))])],1):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.equipment.model",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"equipment-model"},[_vm._v(_vm._s(item.equipment.model))])]}},{key:"item.count",fn:function(ref){
              var item = ref.item;
return [_c('counter',{attrs:{"min":0,"max":1000},on:{"input":function($event){return _vm.onCountChange($event, item.equipment)}},model:{value:(item.count),callback:function ($$v) {_vm.$set(item, "count", $$v)},expression:"item.count"}})]}},{key:"item.equipment.installerId",fn:function(ref){
              var item = ref.item;
return [_c('equipment-installer-selector-static',{attrs:{"installers-list":_vm.installersList,"readonly":""},model:{value:(item.equipment.installerId),callback:function ($$v) {_vm.$set(item.equipment, "installerId", $$v)},expression:"item.equipment.installerId"}})]}},{key:"item.equipment.providerId",fn:function(ref){
              var item = ref.item;
return [_c('equipment-provider-selector-static',{attrs:{"providers-list":_vm.providersList,"readonly":""},model:{value:(item.equipment.providerId),callback:function ($$v) {_vm.$set(item.equipment, "providerId", $$v)},expression:"item.equipment.providerId"}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0"},on:{"click":function($event){return _vm.viewEquipment(item.equipment)}}},[_c('i',{staticClass:"fad fa-info"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true}],null,true)}),_c('edit-equipment',{ref:"viewEquipment",attrs:{"nested":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }