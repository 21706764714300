import Api from "../../Shared/services/api";

const baseUrl = "MasterQuotes";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  getRecent(count) {
    return Api().get(`${baseUrl}/Recent?Count=${count}`);
  },

  getMasterQuoteById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getMakes() {
    return Api().get(baseUrl + `/Makes`);
  },
};
