<template>
  <v-card elevation="2" class="pa-2 mb-2" style="width: 100%">
    <v-row dense align="center" v-if="record != null">
      <v-col cols="12" md="7" class="d-flex flex-row">
        <label
          class="input-label mr-2 mb-0 d-none"
          style="align-self: center; flex none; white-space: nowrap;"
          >Assigned User</label
        >
        <v-btn-toggle
          class="mr-3 d-flex align-center"
          v-model="record.recordType"
          @change="onRecordTypeChange"
          mandatory
          color="deep-purple"
          dense
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="
                  readonly || contract.isLocked || record.laborType == enums.LABOR_TYPE.BIM.value
                "
                :value="0"
                color="white"
                style="
                  margin: 0 !important;
                  text-transform: none;
                  padding: 0 !important;
                  height: 32px !important;
                "
              >
                <i class="fad fa-user-tie fs-16px"></i>
              </v-btn>
            </template>
            <span>Assigned User</span>
          </v-tooltip>
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="
                  readonly || contract.isLocked || record.laborType == enums.LABOR_TYPE.BIM.value
                "
                :value="1"
                color="white"
                style="
                  margin: 0 !important;
                  text-transform: none;
                  padding: 0 !important;
                  height: 32px !important;
                "
              >
                <i class="fas fa-question fs-16px"></i>
              </v-btn>
            </template>
            <span>Generic Entry</span>
          </v-tooltip>
        </v-btn-toggle>
        <user-all-selector
          ref="userSelector"
          v-if="record.recordType == enums.ConstructionPhaseRecordType.AssignedUser.value"
          :users="users"
          required
          hideLabel
          hideDetails
          :readonly="
            readonly || contract.isLocked || record.laborType == enums.LABOR_TYPE.BIM.value
          "
          :isUsersLoading="isUsersLoading"
          v-model="record.userId"
          style="width: 100%"
        ></user-all-selector>
        <v-text-field-alt
          v-else
          :rules="[allRules.required]"
          placeholder="Generic Entry Name"
          style="width: 100%; height: 40px !important; align-items: center; display: flex"
          v-model.number="record.placeholderName"
          :readonly="readonly || contract.isLocked"
          hide-details
        >
        </v-text-field-alt>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-start pr-2">
        <v-text-field-alt
          :rules="[allRules.number, allRules.requiredNumber]"
          placeholder="User Hours"
          style="width: 150px"
          number-sign-color
          v-model.number="record.hours"
          :suffix="record.hours == 1 || record.hours == -1 ? 'hour' : 'hours'"
          :readonly="readonly || contract.isLocked"
          hide-details
        >
        </v-text-field-alt>
      </v-col>
      <v-col cols="12" md="1" class="d-flex justify-end pr-2">
        <v-btn
          color="red"
          icon
          elevation="0"
          :disabled="readonly"
          v-if="!contract.isLocked"
          @click="deleteRecord"
        >
          <i class="fal fa-trash-alt"></i>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";
export default {
  name: "construction-phase-record",
  data() {
    return {
      perms,
      enums,
      record: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.record = this.cloneDeep(this.value);
  },
  methods: {
    deleteRecord() {
      this.$emit("delete");
    },
    onRecordTypeChange() {
      if (this.record.recordType == enums.ConstructionPhaseRecordType.AssignedUser.value) {
        this.record.placeholderName = null;
      } else if (this.record.recordType == enums.ConstructionPhaseRecordType.GenericEntry.value) {
        this.record.userId = null;
      }
    },
    selectByEmail(email) {
      this.record.recordType = enums.ConstructionPhaseRecordType.AssignedUser.value;

      this.$refs.userSelector.selectByEmail(email);
    },
  },
  computed: {},
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.record, this.value)) {
          this.record = this.cloneDeep(this.value);
          // If placeholder null user
          if (this.value.recordType == 1) {
            this.record.user = null;
            this.record.userId = null;
          }
          // If user null placeholder
          else if (this.value.recordType == 0) {
            this.record.placeholderName = null;
          }
          this.$log("record", this.record);
        }
      },
      deep: true,
    },
    record: {
      handler() {
        if (!this.isEqual(this.record, this.value)) {
          this.$emit("input", this.record);
          this.$log("record", this.record);
        }
      },
      deep: true,
    },
  },
  created() {},
  components: {
    UserAllSelector,
  },
};
</script>

<style lang="scss">
.update-construction-phase-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}
</style>
