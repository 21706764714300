<template>
  <span class="d-inline-flex" style="gap: 0.25rem">
    <span v-if="value == null || value == ''">
      <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
    </span>
    <span v-else class="d-flex align-center" style="gap: 0.5rem">
      <project-stage class="project-stage-pointer" :stage="parsedValue.Stage.Value"></project-stage>
      <i class="fad fa-slash-forward fs-16px"></i>
      <project-status
        class="project-status-pointer"
        :status="parsedValue.Status.Value"
      ></project-status>
    </span>
  </span>
</template>

<script>
import ProjectStage from "../../components/ProjectStage.vue";
import ProjectStatus from "../../components/ProjectStatus.vue";
export default {
  components: { ProjectStage, ProjectStatus },
  name: "stage-status-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
