<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="1200"
    append-to="#app"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
    :class="{
      'slideout-readonly-content': readonly && space && !space.isBatch,
      'slideout-readonly-content-dark-header': readonly && space && space.isBatch,
      'slideout-deep-purple-header': space && space.isBatch,
    }"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Space ...
      </h3>
      <h3 v-else-if="space != null && space.id != null" class="font-weight-bold pa-1" small>
        <span v-if="!readonly && !space.isBatch">
          <i class="fad fa-pen-square mr-2"></i> Update:
          <space-info-line class="ml-2" :space="space"></space-info-line>
        </span>
        <span v-else-if="!readonly && space.isBatch">
          <i class="fad fa-grid-2-plus mr-2"></i> Update Batch:
          <space-info-line class="ml-2" :space="space"></space-info-line>
        </span>
        <span v-else-if="space.isBatch"
          ><i class="fad fa-grid-2-plus mr-2"></i> Batch:
          <space-info-line class="ml-2" :space="space"></space-info-line>
        </span>
        <span v-else
          ><i class="fad fa-draw-square mr-2"></i> Space:
          <space-info-line class="ml-2" :space="space"></space-info-line>
        </span>
      </h3>
      <div v-if="space != null">
        <code
          :dark="space.isBatch"
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control
          :dark="space.isBatch"
          v-model="slideouts.update.fullWidth"
        ></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon :dark="space.isBatch"
          ><i class="far fa-times"></i
        ></v-btn>
      </div>
    </template>
    <v-container
      class="pa-0 text-center inset-shadow"
      v-if="isFetching"
      fluid
      style="background: #eceff1; height: 100%"
    >
      <video
        width="275"
        muted
        loop
        autoplay
        class="inset-shadow-video mt-8 mb-2"
        style="padding: 0.5rem; margin: 0 auto; border-radius: 10rem; background: #fff"
      >
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Space Details ...</p>
    </v-container>
    <v-container fluid class="pa-0" v-else-if="slideouts.update.active">
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <space-area-groups-manager
          ref="spaceAreaGroupsManager"
          v-model="selected"
          :projectId="projectId"
          :readonly="readonly"
          :isLoading="slideouts.update.isLoading"
          :validSpace="slideouts.update.valid"
          :isSpaceTemplateAdded="isSpaceTemplateAdded"
          :isSpaceTemplateChanged="false"
          :installersList="installersList"
          :providersList="providersList"
          equipmentOnlyMode
          class="inset-shadow"
          style="height: 100%"
        >
          <template v-slot:equipment>
            <equipment-card :equipment="equipment" noBorder small />
          </template>
        </space-area-groups-manager>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-card-actions v-if="slideouts.update.active && space != null">
        <v-spacer></v-spacer>
        <div class="d-flex ml-2" v-if="!isFetching && readonly && $has(perms.Projects.Update)">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && space.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="space.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ space.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ space.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ space.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ space.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="space.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchSpace()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Space</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";
import projectAPI from "../../Projects/services/projects-service";
import PanelSizeControl from "@Shared/components/PanelSizeControl.vue";
import SpaceAreaGroupsManager from "./SpaceAreaGroupsManager.vue";
import SpaceInfoLine from "./SpaceInfoLine.vue";
import EquipmentCard from "../../Equipments/components/EquipmentCard.vue";

export default {
  name: "edit-project-equipment-space",
  components: {
    PanelSizeControl,
    SpaceAreaGroupsManager,
    SpaceInfoLine,
    EquipmentCard,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    installersList: {
      type: Array,
      default: () => [],
    },
    providersList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perms,
      enums,
      space: null,
      equipment: null,
      isFetching: false,
      bulk: {
        installerId: null,
        providerId: null,
      },
      selected: {},
      selectedCemented: {},
      templatesTimerId: null,
      readonly: false,
      slideouts: {
        update: {
          active: false,
          valid: false,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
          installersLoading: false,
          providersLoading: false,
          fullWidth: false,
        },
      },
    };
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.Projects.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    switchToEditMode() {
      this.readonly = false;
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
      this.slideouts.update.fullWidth = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementSpace();
      this.$releaseChanges();
      this.isFetching = false;
      this.expandedAreaGroupPanels = [];
      // (this.selectedEquipments = {}), this.$emit("close");

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewSpace) {
      this.$log("announceChange()", isNewSpace);
      this.$emit("save", this.cloneDeep(this.selected), isNewSpace);
    },
    sortAreaGroups() {
      this.selected.areaGroups.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
    fetchSpace() {
      this.isFetching = true;
      this.slideouts.update.isLoading = true;
      projectAPI
        .getAreaGroupsByEquipmentId(this.space.id, this.equipment.id)
        .then((resp) => {
          this.$emit("fetch", this.space.id);
          this.$log("getAreaGroupsByEquipmentId =>", this.space.id);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = { areaGroups: this.cloneDeep(resp.data) };
          this.sortAreaGroups();
          this.cementSpace();
        })
        .catch((err) => {
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    open(space, equipment, editMode = false) {
      this.$log("EDIT-PROJECT-EQUIPMENT-SPACE OPEN =>", space, equipment, editMode);
      this.readonly = !editMode;
      this.space = space;
      this.equipment = equipment;
      this.selected = {};
      this.selectedCemented = {};
      this.expandedAreaGroupPanels = [];
      this.fetchSpace();
      this.slideouts.update.active = true;
    },
    cementSpace() {
      this.$log("cementSpace()");
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      this.$log("checkForChanges()");
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let spaceToSend = this.cloneDeep(this.selected);
      this.$log("spaceToSend", this.cloneDeep(spaceToSend));
      spaceToSend.areaGroups = spaceToSend.areaGroups.map((areaGroup) => {
        return {
          id: areaGroup.id,
          areaEquipments: areaGroup.areaEquipments.map((areaEquipment) => {
            return {
              id: areaEquipment.id,
              equipmentId: areaEquipment.equipment.id,
              count: areaEquipment.count,
              installerId: areaEquipment.installerId,
              providerId: areaEquipment.providerId,
              isMarkedForDelete: areaEquipment.count == 0,
            };
          }),
        };
      });
      this.$log("spaceToSend", this.cloneDeep(spaceToSend));
      // if (spaceToSend.areaGroups.length == 0) spaceToSend.clearAllEquipments = true;
      // else {
      //   spaceToSend.areaGroups.forEach((areaGroup) => {
      //     areaGroup.areaEquipments = areaGroup.areaEquipments.filter(
      //       (areaEquip) => areaEquip.count > 0
      //     );
      //   });
      // }
      projectAPI
        .updateSpaceAreaGroups(this.space.id, spaceToSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Space is updated successfully!";
          this.selected = { areaGroups: this.cloneDeep(resp.data) };
          this.sortAreaGroups();
          this.cementSpace();
          this.announceChange(false);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    checkForSpaceChanges() {
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : "78%";
    },
    isSpaceTemplateAdded() {
      if (!this.slideouts.update.active) return false;
      return this.space.templateId != null;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.v-menu__content.provider-installer-select-menu {
  z-index: 111 !important;
}
.v-alert.v-alert--dense {
  padding: 0 !important;

  .v-alert__icon {
    &::after {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
  }
}
.space-area-groups .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}
</style>
