<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 1000"
    :allow-resize="nested ? false : false"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Contract User Labor Rates...
      </h3>
      <h3 v-else-if="selected != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-user-tie mr-2"></i> <b>{{ contract.name }}</b> User Labor Rates
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching Contract User Labor Rates ...</p>
    </v-container>

    <v-container fluid class="pa-5" v-else-if="selected != null && contract != null">
      <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
        <v-form v-model="slideouts.update.valid" ref="settingsForm">
          <v-expansion-panels
            ref="contractsEP"
            v-if="selected.length > 0"
            v-model="expandedUsersLaborRatesPanels"
          >
            <v-expansion-panel v-for="(userRate, i) in selected" :key="i" class="contractEps">
              <v-expansion-panel-header class="px-4 py-2" :ripple="true">
                <div class="d-inline-flex">
                  <user-avatar :user="userRate.user"></user-avatar>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="users-expansion-panel-content">
                <v-divider></v-divider>
                <div class="px-4 pt-2">
                  <v-row>
                    <v-col cols="12" md="12" class="d-flex align-center justify-start pb-0">
                      <v-menu dense offset-y left v-if="!contract.isLocked" z-index="99999">
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :dark="!readonly"
                            color="indigo"
                            style="flex: none !important"
                            :disabled="readonly || contract.isLocked"
                          >
                            <i class="fad fa-pen mr-2" style="font-size: 16px"></i>
                            Update Options
                          </v-btn>
                        </template>

                        <v-list class="more-options-menu">
                          <v-list-item
                            @click="copyLaborLaborRates(userRate)"
                            v-if="$has(perms.ProjectContracts.UpdateLaborCostRates)"
                          >
                            <v-list-item-icon class="mr-2 justify-center">
                              <i class="fad fa-clone"></i>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                Copy Labor <b>Cost</b> Rates from
                                <u>
                                  {{ userRate.user.firstName }}
                                  {{ userRate.user.lastName }}'s Default Labor Rates
                                </u>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            @click="copyLaborSellRates(userRate)"
                            v-if="$has(perms.ProjectContracts.UpdateLaborSellRates)"
                          >
                            <v-list-item-icon class="mr-2 justify-center">
                              <i class="fad fa-clone"></i>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>
                                Copy Labor <b>Sell</b> Rates from
                                <u>
                                  {{ userRate.user.firstName }}
                                  {{ userRate.user.lastName }}'s Default Labor Rates
                                </u>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" v-if="$has(perms.ProjectContracts.ViewLaborCostRates)">
                      <v-simple-table dense class="elevation-1">
                        <template v-slot:default>
                          <thead class="v-data-table-header">
                            <tr>
                              <th class="text-start" style="width: 175px; min-width: 175px">
                                Setting
                              </th>
                              <th class="text-start">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan="4">
                                <h2 class="font-weight-black py-3">Labor Cost Rates</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Associate Consultant</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.ac"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Senior Consultants</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.sc"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Consultants</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.c"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Virtual Integrator</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.vi"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">BIM Specialist</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.bim"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Management</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.management"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">Technical</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.technical"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4 class="font-weight-bold">SCD</h4>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  v-model="userRate.loadedLaborRates.scd"
                                  prefix="$"
                                  suffix="/hr"
                                  readonly
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12" md="6" v-if="$has(perms.ProjectContracts.ViewLaborSellRates)">
                      <v-simple-table dense class="elevation-1">
                        <template v-slot:default>
                          <thead class="v-data-table-header">
                            <tr>
                              <th class="text-start" style="width: 175px; min-width: 175px">
                                Setting
                              </th>
                              <th class="text-start">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colspan="4">
                                <h2 class="font-weight-black py-3">Labor Sell Rates</h2>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Associate Consultant</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.ac"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Senior Consultants</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.sc"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Consultants</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.c"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Virtual Integrator</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.vi"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">BIM Specialist</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.bim"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Management</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.management"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">Technical</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.technical"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  class="d-flex flex-row justify-start align-center"
                                  style="gap: 1rem"
                                >
                                  <div>
                                    <h4 class="font-weight-bold">SCD</h4>
                                  </div>
                                </div>
                              </td>
                              <td class="py-1">
                                <v-text-field-alt
                                  :rules="[allRules.number, allRules.requiredNumber]"
                                  v-model.number="userRate.sellRates.scd"
                                  prefix="$"
                                  suffix="/hr"
                                  :readonly="
                                    !$has(perms.ProjectContracts.UpdateLaborSellRates) || readonly
                                  "
                                  hide-details
                                >
                                </v-text-field-alt>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-else>nothing added yet</div>
        </v-form>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions v-if="contract != null">
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="
            !isFetching &&
            readonly &&
            ($has(perms.ProjectContracts.UpdateLaborCostRates) ||
              $has(perms.ProjectContracts.UpdateLaborSellRates)) &&
            !contract.isLocked
          "
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !contract.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && !contract.isLocked"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !contract.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :loading="slideouts.update.isLoading"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
              >
                <i class="fas fa-save mr-2"></i>
                Save
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Save
              <kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly && !contract.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                Save & Close
              </v-btn>
            </template>
            <span class="d-flex align-center">
              Save & Close Panel<kbd class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="false && !contract.isLocked">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchContractUsersRates()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch User Rates</span>
          </v-tooltip>
        </div>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import contractsService from "../services/contracts-service";
import usersService from "../../Admin/services/UserSettingsService";

export default {
  name: "labor-rates",
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      enums,
      perms,
      hasChanges: false,
      contract: null,
      readonly: false,
      isFetching: false,
      expandedUsersLaborRatesPanels: [],
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
      selected: null,
      selectedCemented: null,
    };
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    copyLaborLaborRates(userRate) {
      this.$dialog.info({
        text: `Are you sure you want to Copy Labor <b>Cost</b> Rates from
              <u>${userRate.user.firstName} ${userRate.user.lastName}'s Default Labor Rates</u>?`,
        title: `Update ${userRate.user.firstName} ${userRate.user.lastName}'s Labor Cost Rates?`,
        color: "info",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Copy",
            color: "info",
            handle: () => {
              return usersService
                .getLaborRates(userRate.user.id)
                .then((resp) => {
                  userRate.loadedLaborRates = this.cloneDeep(resp.data.loadedLaborRates);
                  this.$dialog.notify.success("Labor Cost Rates Copied Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during copying cost rates!";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
    copyLaborSellRates(userRate) {
      this.$dialog.info({
        text: `Are you sure you want to Copy Labor <b>Sell</b> Rates from 
              <u>${userRate.user.firstName} ${userRate.user.lastName}'s Default Labor Rates</u>?`,
        title: `Update ${userRate.user.firstName} ${userRate.user.lastName}'s Labor Sell Rates?`,
        color: "info",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Copy",
            color: "info",
            handle: () => {
              return usersService
                .getLaborRates(userRate.user.id)
                .then((resp) => {
                  userRate.sellRates = this.cloneDeep(resp.data.sellRates);
                  this.$dialog.notify.success("Labor Sell Rates Copied Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  var message = "an error occurred during copying sell rates!";
                  this.$handleError(err, message);
                });
            },
          },
        },
      });
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.readonly &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        !this.readonly &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        (this.$has(perms.ProjectContracts.UpdateLaborSellRates) ||
          this.$has(perms.ProjectContracts.UpdateLaborCostRates))
      ) {
        event.preventDefault();
        this.switchToEditMode();
      }
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = null;
      this.cementContractUsersRates();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    fetchContractUsersRates() {
      this.isFetching = true;
      contractsService
        .getContractUsersRates(this.contract.id)
        .then((resp) => {
          this.$log("getContractUsersRates >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementContractUsersRates();
        })
        .catch((err) => {
          this.$log("getContractUsersRates >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = this.cloneDeep(this.selected);
      this.$log("update >> toSend", toSend);
      contractsService
        .updateContractUsersRates(this.contract.id, toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "User rates updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementContractUsersRates();
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
          this.$emit("save");
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    cementContractUsersRates() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
      this.selected = null;
      this.cementContractUsersRates();
    },
    open(contract, editMode = false) {
      this.contract = contract;
      this.readonly = !editMode;
      this.fetchContractUsersRates();
      this.slideouts.update.active = true;
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.settingsForm, () => {})) return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
        this.$emit("change", this.selected);
      },
      deep: true,
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "1000px";
    },
  },
  components: { PanelSizeControl },
};
</script>
<style lang="scss">
.users-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
