var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-overview-item",class:{ 'batch-item': _vm.space.isBatch },on:{"click":_vm.onItemClicked}},[_c('v-row',{attrs:{"align-content":"center","justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"space-details",attrs:{"cols":"12","sm":"7"}},[(_vm.space.isBatch)?_c('v-tooltip',{attrs:{"color":"deep-purple","left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-flex align-center"},'div',attrs,false),on),[_c('v-chip',{staticClass:"mr-1",staticStyle:{"height":"20px"},attrs:{"color":"deep-purple","dark":"","label":"","small":""}},[_c('i',{staticClass:"fas fa-grid-2-plus fs-12px mr-2"}),_vm._v(_vm._s(_vm.space.batchCount)+" ")])],1)]}}],null,false,2461155255)},[_c('span',[_vm._v(" Batch of "),_c('b',[_vm._v(_vm._s(_vm.space.batchCount))]),_vm._v(" spaces ")])]):_vm._e(),_c('h5',{staticClass:"space-name"},[_vm._v(" "+_vm._s(_vm.space.name)+" ")]),(_vm.space.spaceNumber != null && _vm.space.spaceNumber != '')?_c('h6',{staticClass:"space-number"},[_c('i',{staticClass:"fas fa-hashtag mr-1"}),_vm._v(_vm._s(_vm.space.spaceNumber)+" ")]):_c('h6',{staticClass:"space-number"},[_c('i',{staticClass:"fas fa-hashtag mr-1"}),_c('i',{staticClass:"fal fa-horizontal-rule"})]),_c('div',{staticClass:"space-location"},[_c('span',{staticClass:"space-equips warning--text mr-2"},[_c('i',{staticClass:"far fa-plug mr-1"}),_vm._v(" "+_vm._s(_vm.space.equipmentCount)+" ")]),(_vm.space.level.building)?_c('span',{staticClass:"space-building info--text mr-2"},[_c('i',{staticClass:"far fa-building mr-1"}),_vm._v(" "+_vm._s(_vm.space.level.building.name)+" ")]):_vm._e(),_c('span',{staticClass:"space-level success--text"},[_c('i',{staticClass:"far fa-layer-group mr-1"}),_vm._v(" "+_vm._s(_vm.space.level.name)+" ")])])],1),_c('v-col',{staticClass:"space-content",attrs:{"cols":"12","sm":"5"}},[_c('h5',{staticClass:"space-installation-cost"},[_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("usdFormat")((_vm.installationCost * (_vm.space.isBatch ? _vm.space.batchCount : 1))))),_c('i',{staticClass:"fad fa-wrench ml-1"})])]}}])},[_c('span',[_vm._v(" Installation Factor "),_c('b',{staticClass:"info--text ml-1 fs-12px"},[_vm._v(" "+_vm._s(_vm.installationFactor)+"x ")]),(_vm.space.isBatch)?_c('b',[_c('br'),_vm._v(" - Per Space: "+_vm._s(_vm._f("usdFormat")(_vm.installationCost))+" ")]):_vm._e()])])],1),_c('h5',{staticClass:"space-total-msrp"},[_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("usdFormat")((_vm.space.equipmentsTotalMsrpPrice * (_vm.space.isBatch ? _vm.space.batchCount : 1))))+" "),_c('i',{staticClass:"fad fa-usd-square ml-1"})])]}}])},[_c('span',[_vm._v(" Total MSRP "),(_vm.space.isBatch)?_c('b',[_c('br'),_vm._v(" - Per Space: "+_vm._s(_vm._f("usdFormat")(_vm.space.equipmentsTotalMsrpPrice))+" ")]):_vm._e()])])],1),_c('h5',{staticClass:"space-total-installed-cost"},[_c('v-tooltip',{attrs:{"left":"","z-index":"999","nudge-left":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("usdFormat")((_vm.totalMSRPWithInstallCost * (_vm.space.isBatch ? _vm.space.batchCount : 1))))+" ")])]}}])},[_c('span',[_vm._v(" Total Cost "),(_vm.space.isBatch)?_c('b',[_c('br'),_vm._v(" - Per Space: "+_vm._s(_vm._f("usdFormat")(_vm.totalMSRPWithInstallCost))+" ")]):_vm._e()])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }