<template>
  <div class="comp-wrpr" :class="allowView ? 'allow-view' : ''">
    <v-autocomplete-alt
      auto-select-first
      :label="
        label == null || label == ''
          ? `<i class='fab fa-jira mr-1'></i> Jira Project`
          : label
      "
      id="projectJiraCode"
      placeholder="Jira Key ex: B22009"
      :value="value"
      @input="handleSelectedJiraProject"
      :items="jiraProjects"
      :filter="jiraProjectsFilter"
      dense
      filled
      item-text="key"
      item-value="key"
      clearable
      :loading="isJiraProjectsLoading"
      :disabled="isJiraProjectsLoading"
      :readonly="readonly"
    >
      <template v-slot:selection="data">
        <v-row
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); flex-wrap: nowrap"
          class="py-0"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-img
              v-if="
                data.item.avatarUrls['32x32'] != null &&
                  data.item.avatarUrls['32x32'] != ''
              "
              :src="wrapAvatarUrl(data.item.avatarUrls['32x32'])"
              height="32"
              width="32"
              position="center center"
              contain
            >
            </v-img>
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column">
            <div class="jira-project-key">
              {{ data.item.key }}
              <v-chip class="ml-2" x-small color="">
                <div class="jira-project-category-name">
                  {{ data.item.projectCategory.name }}
                </div>
              </v-chip>
            </div>
            <div class="jira-project-name" style="max-width: 100%">
              {{ data.item.name }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item="data">
        <v-row
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none"
          class="py-2"
        >
          <v-col sm="auto" class="d-flex align-center mr-2">
            <v-img
              v-if="
                data.item.avatarUrls['48x48'] != null &&
                  data.item.avatarUrls['48x48'] != ''
              "
              :src="wrapAvatarUrl(data.item.avatarUrls['48x48'])"
              height="48"
              width="48"
              position="center center"
              contain
            >
            </v-img>
          </v-col>
          <v-col class="d-flex justify-center col-auto flex-column">
            <div class="jira-project-key">
              {{ data.item.key }}
              <v-chip class="ml-2" x-small color="">
                <div class="jira-project-category-name">
                  {{ data.item.projectCategory.name }}
                </div>
              </v-chip>
            </div>
            <div class="jira-project-name" style="max-width: 225px">
              {{ data.item.name }}
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:label-append>
        <slot name="label-append"></slot>
      </template>
    </v-autocomplete-alt>
    <v-tooltip top nudge-top="-4" :disabled="value == null">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          min-width="28px !important"
          width="28px !important"
          height="28px !important"
          color="secondary"
          outlined
          class="pa-0"
          small
          target="_blank"
          :href="`https://dangeloconsultants.atlassian.net/browse/${value}`"
          :disabled="value == null"
        >
          <i class="fad fa-arrow-up-right-from-square fs-14px"></i>
        </v-btn>
      </template>
      <span>Open Jira Project</span>
    </v-tooltip>
  </div>
</template>

<script>
import jiraAPI from "../../Projects/services/jira-service";

export default {
  props: {
    value: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    allowView: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return { jiraProjects: [], isJiraProjectsLoading: false };
  },
  methods: {
    handleSelectedJiraProject(val) {
      this.$emit("input", val);
    },
    getJiraProjects() {
      this.isJiraProjectsLoading = true;
      jiraAPI
        .projects()
        .then(resp => {
          this.jiraProjects = resp.data;
          this.isJiraProjectsLoading = false;
        })
        .catch(err => {
          this.isJiraProjectsLoading = false;
          this.$handleError(err);
        });
    },
    jiraProjectsFilter(item, queryText, itemText) {
      const text1 = item.key.toLowerCase();
      const text2 = item.name.toLowerCase();
      const text3 =
        item.projectCategory == null
          ? ""
          : item.projectCategory.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        text1.indexOf(searchText) > -1 ||
        text2.indexOf(searchText) > -1 ||
        text3.indexOf(searchText) > -1
      );
    },
    wrapAvatarUrl(url) {
      return jiraAPI.getAvatarWrapperUrl(url);
    },
    refresh() {
      this.getJiraProjects();
    }
  },
  created() {
    this.getJiraProjects();
  }
};
</script>

<style scoped lang="scss">
.jira-project-key {
  color: $shades-black;
  font-size: 14px;
  font-weight: 600;
}
.jira-project-name {
  color: rgba($shades-black, 0.54);
  font-size: 14px;
  font-weight: 400;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
