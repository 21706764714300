<template>
  <v-container class="pa-3" fluid>
    <div class="d-flex justify-space-between align-center mb-2">
      <h3 class="text--primary font-weight-black mr-3 d-inline-block">Project Contacts</h3>
      <transition name="fade" mode="out-in" appear v-if="canEdit">
        <div key="project-info-vars-btns" class="d-flex" style="gap: 0.5rem">
          <v-btn @click="discard()" :disabled="!hasChanges || loading || saveLoading">
            <i class="fal fa-redo mr-2"></i>Discard
          </v-btn>
          <v-btn
            color="info"
            @click="saveContacts()"
            :loading="saveLoading"
            :disabled="loading || saveLoading || !hasChanges || !canEdit"
          >
            <i class="fal fa-check mr-2"></i>Save Contacts
          </v-btn>
        </div>
      </transition>
    </div>
    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 mt-3 inset-shadow">
      <v-btn color="info" ref="addContact" :disabled="loading" @click="open()" v-if="canEdit">
        <i class="far fa-link mr-2"></i>Link a Contact
      </v-btn>
      <v-data-table
        dense
        :headers="headers"
        :items="selected"
        :items-per-page="-1"
        class="contacts-table elevation-2 mt-2"
        :loading="loading"
        hide-default-footer
        @click:row="rowClicked"
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
        </template>
        <template v-slot:[`item.contactState`]="{ item }">
          {{ $getTextByValue(item.contactState, states) }}
        </template>
        <template v-slot:[`item.firstName`]="{ item }">
          <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
        </template>
        <template v-slot:[`item.actions`]="{ index }">
          <v-btn icon elevation="0" color="error" @click.stop="del(index)" :disabled="!canEdit">
            <i class="fal fa-trash-alt"></i>
          </v-btn>
        </template>
        <template v-slot:[`item.phone`]="{ item }">
          <phone-number v-model="item.phone"></phone-number>
        </template>
        <template v-slot:[`item.addressLine1`]="{ item }">
          <address-summary :value="item"></address-summary>
        </template>
        <template v-slot:[`item.company`]="{ item }">
          <v-tooltip top nudge-top="-16px">
            <template v-slot:activator="{ on, attrs }">
              <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.company.id)">
                <v-img
                  v-if="item.company.logoUrl != null && item.company.logoUrl != ''"
                  :src="item.company.logoUrl"
                  max-height="28"
                  max-width="54"
                  position="left center"
                  contain
                >
                </v-img>
                <v-img
                  v-else
                  src="/img/DNA_Symbol.png"
                  max-height="28"
                  max-width="54"
                  position="left center"
                  contain
                >
                </v-img>
              </div>
            </template>
            <span>{{ item.company.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.contactState`]="{ item }">
          {{ $getTextByValue(item.contactState, states) }}
        </template>
        <template v-slot:no-data>
          <img width="300" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>
      </v-data-table>
      <add-contacts-to
        ref="addContactToProject"
        @confirm="slideoutsConfirmed"
        :selected="project"
        name="Project"
      ></add-contacts-to>
      <edit-contact ref="editContact" disableEdit></edit-contact>
      <edit-client ref="editClient"></edit-client>
    </div>
  </v-container>
</template>
<script>
import AddContactsTo from "../../Leads/components/EditLead/AddContactsTo.vue";
import Enums from "../../../plugins/enums";
import projectsAPI from "../services/projects-service";
import perms from "../../../plugins/permissions";
import clientContactHeader from "../../Companies/Clients/config/tables/clientContact.header";
import EditContact from "../../Companies/Shared/components/EditContact.vue";
import EditClient from "../../Companies/Clients/components/EditClient.vue";

export default {
  components: { AddContactsTo, EditContact, EditClient },
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headers: clientContactHeader,
      selected: [],
      selectedCemented: [],
      hasChanges: false,
      loading: false,
      saveLoading: false,
      states: this.$options.filters.EnumToList(Enums.clientStates),
    };
  },
  methods: {
    rowClicked(row) {
      this.$refs.editContact.open(row.id);
    },
    open() {
      this.$refs.addContactToProject.open();
    },
    slideoutsConfirmed(newContacts) {
      newContacts.forEach((elm) => {
        const index = this.selected.findIndex((contact) => contact.id == elm.id);
        if (index == -1) {
          this.selected.push(elm);
        } else {
          this.selected.splice(index, 1, elm);
        }
      });
    },
    viewClient(id) {
      this.$refs.editClient.open(id);
    },
    del(index) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this contact?`,
          title: `Delete Contact?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                this.selected.splice(index, 1);
                this.$dialog.notify.info(
                  "The deletion will take effect after the contact has been saved",
                  {
                    position: "top-right",
                    timeout: 3000,
                  }
                );
              },
            },
          },
        })
        .then((res) => {});
    },
    getProjectContacts() {
      this.loading = true;
      projectsAPI
        .getProjectItem(this.project.id, "Contacts")
        .then((resp) => {
          this.setSelected(resp.data);
          this.cementSelected();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveContacts() {
      this.saveLoading = true;
      projectsAPI
        .saveProjectItem(this.project.id, "Contacts", {
          companyContactIds: this.cloneDeep(this.selected).map((elm) => elm.id),
        })
        .then((resp) => {
          this.setSelected(resp.data);
          this.cementSelected();
          this.$dialog.notify.success("Contacts updated!", {
            position: "top-right",
            timeout: 3000,
          });
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    setSelected(data) {
      this.$log(data.map((elm) => elm.companyContact));
      this.selected = data.map((elm) => elm.companyContact);
    },
    checkChanges() {
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementSelected() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkChanges();
      },
      deep: true,
    },
    hasChanges() {
      this.$emit("has-changes", this.hasChanges);
    },
  },
  created() {
    this.getProjectContacts();
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectContacts.Update);
    },
  },
};
</script>
