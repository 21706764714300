<template>
  <span class="d-inline-flex" style="gap: 0.25rem">
    <span v-if="value == null || value == ''">
      <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
    </span>
    <span v-else>
      <span class="font-weight-medium">{{ parsedValue.DisplayName }}</span>
      <entity-id class="ml-1" :path="entityUrl" :title="title" hideLabel tooltipOnTop></entity-id>
    </span>
  </span>
</template>

<script>
export default {
  components: {},
  name: "object-logged-prop",
  inheritAttrs: false,
  data() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    urlPrefix: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
    entityUrl() {
      if (this.parsedValue.Id == null) return null;
      return `${this.urlPrefix}/${this.parsedValue.Id}`;
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
