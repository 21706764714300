<template>
  <div v-if="installersList != null && installersList.length > 0">
    <v-tooltip
      top
      nudge-top="-12"
      z-index="9999"
      :disabled="emptyTooltip == null || emptyTooltip == '' || installerId != null"
      v-if="!readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="pa-0" v-bind="attrs" v-on="on">
          <v-select-alt
            class="installer-select"
            :class="showMenuIcon ? 'show-menu-icon' : ''"
            :placeholder="emptyText != null && emptyText != '' ? emptyText : 'N/A'"
            v-model="installerId"
            :items="installersList"
            hide-details
            transparent
            :clearable="clearable"
            item-text="name"
            item-value="id"
            @click.stop
            :menu-props="{
              contentClass: 'provider-installer-select-menu',
              zIndex: '999 !important',
            }"
          >
            <template v-slot:selection="{ item }">
              <equipment-installer v-if="item.id" :installer-name="item.name"></equipment-installer>
            </template>
            <template v-slot:item="{ item }">
              <equipment-installer v-if="item.id" :installer-name="item.name"></equipment-installer>
            </template>
          </v-select-alt>
        </div>
      </template>
      <span>{{ emptyTooltip }}</span>
    </v-tooltip>
    <equipment-installer
      v-else-if="installerObj"
      :installer-name="installerObj.name"
    ></equipment-installer>
    <span v-else class="text--disabled fs-14px" style="padding-top: 1px; padding-left: 7px">
      N/A
    </span>
  </div>
</template>

<script>
import EquipmentInstaller from "./EquipmentInstaller.vue";

export default {
  name: "equipment-installer-selector-static",
  data() {
    return {
      installerId: null,
    };
  },
  props: {
    installersList: {
      type: Array,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: null,
    },
    showMenuIcon: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: null,
    },
    emptyTooltip: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.installerId = this.value;
    // this.$log("this.installerId", this.installerId);
    // this.$log("this.installersList", this.installersList);
  },
  computed: {
    installerObj() {
      return this.installerId != null
        ? this.installersList.find((p) => p.id == this.installerId)
        : null;
    },
  },
  methods: {},
  watch: {
    installerId: {
      handler() {
        // this.$log("this.installerId", this.installerId);
        this.$emit("input", this.installerId);
        this.$emit("change", this.installerObj);
      },
    },
    value: {
      handler() {
        this.$log("this.installerId", this.installerId);
        if (!this.isEqual(this.installerId, this.value)) {
          this.installerId = this.value;
        }
      },
      deep: true,
    },
    installersList: {
      handler() {},
      deep: true,
    },
  },
  components: { EquipmentInstaller },
};
</script>

<style lang="scss">
.installer-select {
  display: inline-block;
  width: min-content;
  min-width: 52px;
  margin-left: 0.25rem;

  .v-text-field__details {
    display: none;
  }

  .theme--light.v-text-field--filled:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:not(:hover) {
    background-color: transparent !important;
  }
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: unset !important;
  }

  &:not(.show-menu-icon) .v-input__append-inner {
    display: none !important;
  }

  &:not(.show-menu-icon) .v-input__append-inner {
    display: none !important;
  }

  &.show-menu-icon {
    min-width: 72px;

    &.v-select-alt .v-input .v-input__control > .v-input__slot {
      min-height: 28px !important;
      padding-left: 4px !important;
      padding-right: 4px !important;
    }

    .v-input__append-inner {
      padding: 0;

      .v-input__icon {
        min-width: 18px;
        width: 18px;
      }
    }
  }

  .v-select__selections {
    pointer-events: none;
  }
}
</style>
