<template>
  <div>
    <slideout
      dock="right"
      :size="600"
      :min-size="600"
      :allow-resize="false"
      :append-to="nested ? '' : '#app'"
      class="rack-visualizer-slideout"
      :visible.sync="slideouts.visualizer.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <div class="d-flex align-center">
          <h3 class="font-weight-bold pa-1 mr-2" small>
            <i class="fad fa-container-storage fa-rotate-90 mr-2"></i>
            Rack Visualizer for
          </h3>
          <v-chip color="blue-grey lighten-4" label class="elevation-1" v-if="areaGroup != null">
            <i class="fad fa-shapes mr-2"></i>
            {{ areaGroup.name }}
          </v-chip>
        </div>
        <div>
          <v-btn @click="closeUpdateSlideout()" icon>
            <i class="far fa-times"></i>
          </v-btn>
        </div>
      </template>
      <v-container
        v-if="slideouts.visualizer.active"
        fluid
        :id="rzId"
        class="inset-shadow"
        style="background: #eceff1"
      >
        <div
          class="d-flex align-center"
          style="gap: 0.5rem"
          v-if="rackData != null && rackData.rackEquipment"
        >
          <h4 class="ma-0" style="width: 125px">Rack Equipment:</h4>
          <v-sheet elevation="1" rounded outlined class="d-flex">
            <equipment-card
              :equipment="rackData.rackEquipment"
              noBorder
              showInstallerAndProvider
              small
            />
          </v-sheet>
        </div>
        <div v-if="errors.noRackEquipment" class="error-container">No Rack Equipment</div>
        <div v-else-if="rackData != null" class="rack-container">
          <div class="rack-stack-wrapper">
            <div class="rack-stack">
              <div
                class="rack-row"
                v-for="(rackRow, i) in rackData.stack"
                :key="i"
                :style="{ zIndex: rackRow.row }"
              >
                <div class="num-section">
                  <v-card
                    flat
                    :color="
                      equipmentMenu.rackRow && equipmentMenu.rackRow.row == rackRow.row
                        ? 'teal'
                        : 'transparent'
                    "
                    @click="
                      () => {
                        rackNumMode = !rackNumMode;
                      }
                    "
                  >
                    <span v-if="rackNumMode">{{ ("0" + rackRow.row).slice(-2) }}</span>
                    <span v-else>{{ rackRow.row * 1.75 }}</span>
                  </v-card>
                </div>
                <div class="equip-section">
                  <v-card
                    @focus="cardOnFocus"
                    :id="getRackCardId(rackRow)"
                    v-if="rackRow.equipment == null && !rackRow.locked"
                    :height="40"
                    @click="openEquipmentMenu($event, rackRow)"
                    rounded="0"
                    elevation="0"
                    class="empty-slot-card d-flex align-center blue-grey--text text--lighten-3"
                  >
                    <div class="fs-14px mx-3">
                      <i class="fad fa-empty-set"></i>
                    </div>
                    <div
                      style="text-transform: uppercase; margin-left: 12px"
                      class="fs-14px font-weight-medium"
                    >
                      Empty Slot
                    </div>
                  </v-card>
                  <v-card
                    @focus="cardOnFocus"
                    style="transitions: all 0.15s ease-out"
                    :color="
                      rackRow.hovering ||
                      (equipmentMenu.rackRow && equipmentMenu.rackRow.row == rackRow.row)
                        ? 'teal'
                        : 'white'
                    "
                    :dark="
                      rackRow.hovering ||
                      (equipmentMenu.rackRow && equipmentMenu.rackRow.row == rackRow.row)
                    "
                    @mouseenter="rackRow.hovering = true"
                    @mouseleave="rackRow.hovering = false"
                    :id="getRackCardId(rackRow)"
                    v-else-if="rackRow.equipment != null"
                    :height="40 * (rackRow.equipment.rackUnits || 1)"
                    @click="openEquipmentMenu($event, rackRow)"
                    rounded="0"
                    class="d-flex align-center"
                  >
                    <equipment-card
                      :equipment="rackRow.equipment"
                      noBorder
                      showInstallerAndProvider
                      small
                      fullWidth
                      showRackUnits
                    />
                  </v-card>
                </div>
                <div class="actions-section">
                  <v-card flat color="transparent" @click="switchCols(rackRow)">
                    <i
                      :class="
                        rackRow.equipment != null || (rackRow.equipment == null && rackRow.locked)
                          ? 'orange--text text--accent-4'
                          : 'teal--text text--accent-3'
                      "
                      class="fas fa-circle-small"
                    ></i>
                    <i
                      :class="
                        rackRow.equipmentB != null
                          ? 'orange--text text--accent-4'
                          : 'teal--text text--accent-3'
                      "
                      class="fas fa-circle-small"
                      v-if="rackRow.cols == 2"
                    ></i>
                  </v-card>
                </div>
              </div>
            </div>
          </div>
          <div class="rack-stand">
            <span class="wheel-left"></span>
            <span class="wheel-right"></span>
            <span class="pin-left"></span>
            <span class="pin-right"></span>
          </div>
        </div>
      </v-container>
      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!slideouts.visualizer.hasChanges"
            @click="discardChanges()"
            color="secondary"
          >
            <i class="fal fa-clock-rotate-left mr-2"></i> Discard
          </v-btn>
          <v-btn
            color="info"
            :disabled="!slideouts.visualizer.valid || !slideouts.visualizer.hasChanges"
            @click="updateConfirmed()"
            :loading="slideouts.visualizer.isLoading"
          >
            <i class="fal fa-save mr-2"></i>Save Changes
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>

    <!-- Rename Folder Modal -->
    <v-dialog v-model="equipmentMenu.show" max-width="550px" persistent scrollable>
      <v-card key="equips" v-if="equipmentMenu.rackRow != null && equipmentMenu.show">
        <v-card-title class="font-weight-bold px-2" small>
          <i class="fad fa-container-storage fa-rotate-90 mr-2"></i>
          Slot
          <v-chip
            class="mx-1 mono-font font-weight-medium"
            color="teal"
            dark
            style="flex: none; height: 20px; padding: 4px"
            label
            >{{ equipmentMenu.rackRow.row }}</v-chip
          >
          Equipment
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4 px-4">
          <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
            <v-radio-group
              v-model="equipmentMenu.equipmentId"
              mandatory
              hide-details
              class="mt-0 pt-0 mr-0 d-flex flex-column"
              column
              dense
              style="gap: 0.5rem"
            >
              <v-card
                class="d-flex align-center justify-center mb-3"
                height="48px"
                :color="equipmentMenu.equipmentId == null ? 'teal' : 'white'"
                @click="
                  ($event) => {
                    equipmentMenu.equipmentId = null;
                  }
                "
                :dark="equipmentMenu.equipmentId == null"
              >
                <v-row dense class="ma-0" style="height: 100%">
                  <v-col
                    cols="12"
                    md="1"
                    style="
                      overflow: hidden;
                      border-radius: 0.25rem 0 0 0.25rem;
                      gap: 0.25rem;
                      line-height: 1;
                    "
                    class="d-flex flex-column align-center justify-center font-weight-bold pa-0"
                    :class="
                      equipmentMenu.equipmentId == null
                        ? 'white--text teal darken-2'
                        : 'teal--text text--lighten-2 teal lighten-5'
                    "
                  >
                    <v-radio class="equipment-radio-btn" :value="null" color="white"></v-radio>
                  </v-col>
                  <v-col cols="12" md="11">
                    <div
                      class="d-flex align-center"
                      style="height: 100%"
                      :class="equipmentMenu.equipmentId == null ? 'white--text' : 'blue-grey--text'"
                    >
                      <div class="fs-14px mx-3">
                        <i class="fad fa-empty-set"></i>
                      </div>
                      <div
                        style="text-transform: uppercase; margin-left: 12px"
                        class="fs-14px font-weight-medium"
                      >
                        Empty Slot
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="available-equipment-card"
                :class="
                  isAvailableEquipmentDisabled(ae) && equipmentMenu.equipmentId != ae.equipmentId
                    ? 'opacity-40'
                    : ''
                "
                v-for="(ae, i) in availableEquipment"
                :key="i"
                :color="equipmentMenu.equipmentId == ae.equipmentId ? 'teal' : 'white'"
                v-on="
                  isAvailableEquipmentDisabled(ae) && equipmentMenu.equipmentId != ae.equipmentId
                    ? {}
                    : {
                        click: ($event) => {
                          equipmentMenu.equipmentId = ae.equipmentId;
                        },
                      }
                "
                :dark="equipmentMenu.equipmentId == ae.equipmentId"
              >
                <v-row dense class="ma-0">
                  <v-col
                    cols="12"
                    md="1"
                    style="
                      overflow: hidden;
                      border-radius: 0.25rem 0 0 0.25rem;
                      gap: 0.25rem;
                      line-height: 1;
                    "
                    class="d-flex flex-column align-center justify-center font-weight-bold pa-0"
                    :class="
                      equipmentMenu.equipmentId == ae.equipmentId
                        ? 'white--text teal darken-2'
                        : 'teal--text text--lighten-2 teal lighten-5'
                    "
                  >
                    <v-radio
                      class="equipment-radio-btn"
                      :value="ae.equipmentId"
                      :disabled="isAvailableEquipmentDisabled(ae)"
                      color="white"
                    ></v-radio>
                  </v-col>
                  <v-col cols="12" md="10">
                    <equipment-card
                      :equipment="ae.equipment"
                      noBorder
                      showInstallerAndProvider
                      small
                      showRackUnits
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    style="
                      overflow: hidden;
                      border-radius: 0 0.25rem 0.25rem 0;
                      gap: 0.25rem;
                      line-height: 1;
                    "
                    class="d-flex flex-column align-center justify-center font-weight-bold pa-0"
                    :class="
                      equipmentMenu.equipmentId == ae.equipmentId
                        ? 'white--text teal darken-2'
                        : 'teal--text text--lighten-2 teal lighten-5'
                    "
                  >
                    <i class="fad fa-box-check fs-14px"></i>
                    <span class="fs-16px">
                      {{ ae.availableCount }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-radio-group>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeEquipmentMenu" text> <i class="fal fa-times mr-2"></i>Cancel </v-btn>
          <v-btn @click="confirmEquipmentMenu" color="teal" dark>
            <i class="fad fa-check mr-2"></i>Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import perms from "../../../plugins/permissions";
import EquipmentCard from "../../Equipments/components/EquipmentCard.vue";

export default {
  name: "rack-visualizer",
  data() {
    return {
      rzId: null,
      errors: {
        noRackEquipment: false,
        quantityExceedsSource: false,
      },
      equipmentMenu: {
        show: false,
        rackRow: null,
        originalEquipmentId: null,
        equipmentId: null,
      },
      perms: perms,
      areaGroup: null,
      areaGroupEquipmentList: [],
      draggable: {
        onDrag: false,
        posX: false,
        posY: false,
        clientWidth: false,
        clientHeight: false,
        cardEl: null,
        cardId: null,
        sourceStackItem: null,
        targetStackItem: null,
      },
      rackData: null,
      rackDataCemented: null,
      rackNumMode: true,
      slideouts: {
        visualizer: {
          active: false,
          valid: false,
          hasChanges: null,
          isLoading: false,
        },
      },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
      };
    },
    availableEquipment() {
      return this.areaGroupEquipmentList
        .filter((ae) => !ae.equipment.isRack)
        .map((ae) => {
          ae.availableCount = this.countAvailableEquipment(ae);
          return ae;
        });
    },
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.rzId = this.generateUniqueId();
  },
  mounted() {},
  methods: {
    countAvailableEquipment(ae) {
      if (this.rackData == null) return 0;
      var list = this.rackData.stack.filter((rackRow) => rackRow.equipmentId == ae.equipmentId);
      // && rackRow.equipmentId != this.equipmentMenu.originalEquipmentId
      var equipmentMenuCount =
        this.equipmentMenu.equipmentId == ae.equipmentId &&
        this.equipmentMenu.originalEquipmentId != ae.equipmentId
          ? 1
          : 0;

      var finalCount = ae.totalCount - list.length - equipmentMenuCount;

      if (
        finalCount == 0 &&
        ae.equipmentId == this.equipmentMenu.originalEquipmentId &&
        this.equipmentMenu.equipmentId != this.equipmentMenu.originalEquipmentId
      ) {
        finalCount = 1;
      }

      return finalCount;
    },
    isAvailableEquipmentDisabled(ae) {
      return (
        ae.disabled ||
        ae.equipment.rackUnits == 0 ||
        ae.availableCount == 0 ||
        !this.hasFreeMountableSlots(ae)
      );
      // && this.equipmentMenu.originalEquipmentId != ae.equipmentId
    },
    hasFreeMountableSlots(ae) {
      if (
        this.equipmentMenu.rackRow == null ||
        this.equipmentMenu.originalEquipmentId == ae.equipmentId
      )
        return true;

      //make a fake stack withouth the current slot
      const fakeStack = this.cloneDeep(this.rackData.stack);
      if (this.equipmentMenu.rackRow.equipment != null) {
        for (
          let i = this.equipmentMenu.rackRow.idx;
          i >= this.equipmentMenu.rackRow.row - this.equipmentMenu.rackRow.equipment.rackUnits;
          i--
        ) {
          fakeStack[i].locked = false;
          fakeStack[i].equipment = null;
          fakeStack[i].equipmentId = null;
        }
      }

      const rackUnits = ae.equipment.rackUnits;
      var freeCount = 0;
      for (let i = this.equipmentMenu.rackRow.idx; i >= 0; i--) {
        const rackRow = fakeStack[i];
        if (!rackRow.locked) {
          freeCount++;
          if (freeCount == rackUnits) return true;
        } else return false;
      }
      return false;
    },
    cardOnFocus(event) {
      this.$log("cardOnFocus", event);
      event.target.blur();
    },
    closeEquipmentMenu() {
      this.equipmentMenu.show = false;
      this.equipmentMenu.rackRow = null;
      this.equipmentMenu.equipmentId = null;
      this.equipmentMenu.originalEquipmentId = null;
    },
    confirmEquipmentMenu() {
      this.$log(">>>>>>> confirmEquipmentMenu", this.equipmentMenu.equipmentId);
      // empty existing slots
      if (this.equipmentMenu.rackRow.equipment != null) {
        //unlock other rows
        if (this.equipmentMenu.rackRow.equipment.rackUnits > 1) {
          for (
            let i = this.equipmentMenu.rackRow.row - 2;
            i >= this.equipmentMenu.rackRow.row - this.equipmentMenu.rackRow.equipment.rackUnits;
            i--
          ) {
            this.rackData.stack[i].locked = false;
            this.rackData.stack[i].equipment = null;
            this.rackData.stack[i].equipmentId = null;
          }
        }
        this.equipmentMenu.rackRow.locked = false;
        this.equipmentMenu.rackRow.equipment = null;
        this.equipmentMenu.rackRow.equipmentId = null;
      }

      if (this.equipmentMenu.equipmentId != null) {
        var targetEquip = this.cloneDeep(
          this.availableEquipment.find((te) => te.equipmentId == this.equipmentMenu.equipmentId)
        );
        this.equipmentMenu.rackRow.locked = true;
        this.equipmentMenu.rackRow.equipment = targetEquip.equipment;
        this.equipmentMenu.rackRow.equipmentId = targetEquip.equipmentId;
        //lock other rows
        if (this.equipmentMenu.rackRow.equipment.rackUnits > 1) {
          for (
            let i = this.equipmentMenu.rackRow.row - 2;
            i >= this.equipmentMenu.rackRow.row - this.equipmentMenu.rackRow.equipment.rackUnits;
            i--
          ) {
            this.rackData.stack[i].locked = true;
            this.rackData.stack[i].equipment = null;
            this.rackData.stack[i].equipmentId = null;
          }
        }
      }
      this.closeEquipmentMenu();
    },
    openEquipmentMenu(e, rackRow) {
      this.$log(">>>>>>>>>>>>> open EquipmentMenu", this.cloneDeep(rackRow));
      e.preventDefault();
      this.equipmentMenu.show = false;
      var cardEl = document.querySelector(`#${this.getRackCardId(rackRow)}`);
      var rect = cardEl.getBoundingClientRect();
      var posX = rect.x + cardEl.clientWidth / 2 - 300 / 2;
      var posY = rect.y + 18;
      this.equipmentMenu.x = posX;
      this.equipmentMenu.y = posY;
      this.equipmentMenu.rackRow = rackRow;
      this.equipmentMenu.equipmentId = rackRow.equipmentId;
      this.equipmentMenu.originalEquipmentId = rackRow.equipmentId;
      this.$log(">>>>>>>>>>>>> equipmentMenu.equipmentId", this.equipmentMenu.equipmentId);
      setTimeout(() => {
        this.equipmentMenu.show = true;
      }, 50);
    },
    switchCols(rackRow) {
      if (
        rackRow.equipment != null ||
        rackRow.equipmentB != null ||
        (rackRow.equipment == null && rackRow.locked)
      ) {
        this.$dialog.notify.error("Rack row is occupied!", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      if (rackRow.cols == 2) rackRow.cols = 1;
      else if (rackRow.cols == 1) rackRow.cols = 2;
    },
    getRackCardId(rackRow) {
      return "rz_" + this.rzId + "card_" + rackRow.row + "_" + rackRow.equipment?.id;
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    discardChanges() {
      this.areaGroup = this.cloneDeep(this.areaGroupCemented);
    },
    open(areaGroup) {
      this.areaGroup = this.cloneDeep(areaGroup);
      this.areaGroupEquipmentList = this.areaGroup.areaEquipments.map((ae) => {
        return {
          equipmentId: ae.equipment.id,
          equipment: this.getEquipVm(ae),
          totalCount: ae.count,
          availableCount: ae.count,
          disabled: false,
        };
      });
      if (this.areaGroup.rackData == null) {
        //default new data
        var racksFound = areaGroup.areaEquipments.filter((ae) => ae.equipment.isRack);

        if (racksFound.length == 1) {
          this.rackData = {
            rackEquipment: this.getEquipVm(racksFound[0]),
            stack: [],
          };
          for (let i = 0; i < this.rackData.rackEquipment.rackUnits; i++) {
            this.rackData.stack.push({
              idx: i,
              row: i + 1,
              cols: 1,
              equipmentId: null,
              equipment: null,
              locked: false,
              hovering: false,
            });
          }
        } else if (racksFound.length == 0) {
          this.$dialog.notify.error(`No rack equipment added to this area group!`, {
            position: "top-right",
            timeout: 3000,
          });
          return;
        } else if (racksFound.length > 1) {
          this.$dialog.notify.error(`More than 1 rack equipment exists in this area group!`, {
            position: "top-right",
            timeout: 3000,
          });
          return;
        }
      } else {
        //there was data already saved, parse it
        this.rackData = JSON.parse(this.areaGroup.rackData);

        //check if there was no rack equipment selected, then display an error
        if (this.rackData.rackEquipment == null || this.rackData.rackEquipment.id == null) {
          this.hasErrors = true;
          this.errors.noRackEquipment = true;
        }
      }
      this.cementRackData();
      this.slideouts.visualizer.active = true;
    },
    getEquipVm(ae) {
      return {
        id: ae.equipment.id,
        isRack: ae.equipment.isRack,
        rackUnits: ae.equipment.rackUnits,
        imageUrl: ae.equipment.imageUrl,
        category: ae.equipment.category,
        tag: ae.equipment.tag,
        model: ae.equipment.model,
        installerId: ae.installerId,
        installer: {
          name: ae.installer?.name,
        },
        providerId: ae.providerId,
        provider: {
          name: ae.provider?.name,
        },
        manufacture: {
          id: ae.equipment.manufacture?.id,
          name: ae.equipment.manufacture?.name,
          logoUrl: ae.equipment.manufacture?.logoUrl,
        },
        productFamily: {
          id: ae.equipment.productFamily?.id,
          name: ae.equipment.productFamily?.name,
        },
      };
    },
    cementRackData() {
      this.rackDataCemented = this.cloneDeep(this.rackData);
      this.checkForChanges();
    },
    updateConfirmed() {
      this.announceChange();
      this.closeUpdateSlideout();
    },
    closeUpdateSlideout() {
      this.slideouts.visualizer.isLoading = false;
      this.slideouts.visualizer.active = false;
    },
    checkForChanges() {
      if (!this.slideouts.visualizer.active) return;
      this.slideouts.visualizer.hasChanges = !this.isEqual(this.rackData, this.rackDataCemented);
    },
  },
  watch: {
    areaGroup: {
      handler() {
        if (this.slideouts.visualizer.active) {
          this.checkForChanges();
        }
      },
      deep: true,
    },
  },
  components: { EquipmentCard },
};
</script>

<style lang="scss">
.menu-equipment-selector.teal.lighten-5 {
  border: 1.5px solid #00bfa5 !important;
}

.update-naming-template-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}

.no-move {
  transition: transform 0s;
}

.card-on-drag {
  position: fixed !important;
  pointer-events: none !important;
  user-select: none !important;
  opacity: 0.54;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.segments-container {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='rgba(42, 54, 59, 0.4)' stroke-width='3' stroke-dasharray='6%2c 6' stroke-dashoffset='4' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 8px;
}

.rack-container {
  position: relative;
  margin: 2rem 3rem 6rem 3rem;
  background: #eceff1;
  border-radius: 0.5rem;
  filter: drop-shadow(2px 4px 6px rgba($shades-black, 0.32));

  .rack-stack-wrapper {
    background: $shades-black;
    background: linear-gradient(90deg, $shades-black 0%, #465a62 50%, $shades-black 100%);
    background: radial-gradient(circle, #656565 0%, #323232 100%);
    background: radial-gradient(circle, #656565 0%, #4e4e4e 100%);
    background: linear-gradient(90deg, #444 0%, #444 20%, #656565 50%, #444 80%, #444 100%);
    border: thin solid #212121;
    // border-radius: 0.25rem;
    padding: 1rem;

    &::before {
      content: "";
      position: absolute;
      left: 0rem;
      top: calc(-1rem + 1px);
      width: calc(100%);
      height: 1rem;
      background: #444;
      background: linear-gradient(90deg, #444 0%, #444 20%, #656565 50%, #444 80%, #444 100%);
      border: thin solid #212121;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0rem;
      bottom: calc(-1rem + 1px);
      width: calc(100%);
      height: 1rem;
      background: #444;
      background: linear-gradient(90deg, #444 0%, #444 20%, #656565 50%, #444 80%, #444 100%);
      border: thin solid #212121;
    }

    .rack-stack {
      position: relative;
      background: $shades-black;
      background: #212121;
      background: #eceff1;
      display: flex;
      flex-direction: column-reverse;
      border: thin solid #212121;

      &::before {
        content: "";
        background: #444;
        background: linear-gradient(0deg, #444 0%, #555 50%, #444 100%);
        position: absolute;
        width: 1rem;
        height: calc(100% + 2rem + 2rem + 2px);
        left: calc(-2rem - 1px);
        top: calc(-1rem - 1px - 1rem);
        border: thin solid #212121;
        border-radius: 3px 0 0 3px;
      }

      &::after {
        content: "";
        background: #444;
        background: linear-gradient(0deg, #444 0%, #555 50%, #444 100%);
        width: 1rem;
        height: calc(100% + 2rem + 2rem + 2px);
        position: absolute;
        right: calc(-2rem - 1px);
        top: calc(-1rem - 1px - 1rem);
        border: thin solid #212121;
        border-radius: 0 3px 3px 0;
      }
    }
  }

  .rack-row {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 40px;

    .num-section {
      width: 48px;
      background: $shades-black;
      background: #323232;
      background: #4e4e4e;
      border-bottom: thin solid #212121;

      .v-card {
        font-family: "Cascadia Code", monospace;
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
        width: 100%;
        height: 100%;
        padding: 0 0.25rem;

        &:hover {
          background: rgba($shades-black, 0.08);
        }
      }
    }

    .equip-section {
      width: 100%;
      display: flex;
      position: relative;
      z-index: 2;
      border-left: thin solid #212121;
      border-right: thin solid #212121;

      .v-card {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        padding: 0.25rem;

        &:hover {
          background: rgba(#fff, 0.72);
        }
      }
    }

    .actions-section {
      width: 48px;
      height: 100%;
      background: $shades-black;
      background: #323232;
      background: #4e4e4e;
      border-bottom: thin solid #212121;

      .v-card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.25rem;
        gap: 0.25rem;
        font-size: 10px;

        &:hover {
          background: rgba($shades-black, 0.08);
        }
      }
    }

    &:first-child {
      .num-section,
      .actions-section {
        border-bottom: none;
      }
    }
  }

  .rack-stand {
    position: relative;

    .fake-shadow {
      position: absolute;
      top: 380px;
      height: 20px;
      border-radius: 30px;
      left: -200px;
      background: transparent;
      box-shadow: 200px 0 10px 2px rgba(0, 0, 0, 0.4);
      margin-left: 0;
      opacity: 1;
      box-shadow: 180px 0 30px rgba(0, 0, 0, 0.3);
    }

    .wheel-right,
    .wheel-left {
      position: absolute;
      bottom: calc(-16px - 1rem + 1px);
      display: inline-block;
      // background: linear-gradient(0deg, #444 0%, #656565 100%);
      background: linear-gradient(0deg, #444 0%, #444 30%, #656565 60%, #444 100%);
      width: 20px;
      height: 16px;
      border-radius: 0 0 4px 4px;

      &::before {
        content: "";
        background: #767676;
        position: absolute;
        height: 5px;
        width: 5px;
        left: -5px;
        top: calc(0);
        border-radius: 2px 0 0 2px;
        z-index: -1;
      }

      &::after {
        content: "";
        background: #767676;
        position: absolute;
        height: 5px;
        width: 5px;
        right: -5px;
        top: calc(0);
        border-radius: 0 2px 2px 0;
        z-index: -1;
      }
    }

    .wheel-left {
      left: 4rem;
    }

    .wheel-right {
      right: 4rem;
    }

    .pin-right,
    .pin-left {
      position: absolute;
      bottom: calc(-10px - 1rem + 1px);
      display: inline-block;
      background: linear-gradient(0deg, #aaa 0%, #bbb 30%, #aaa 60%, #aaa 100%);
      background: linear-gradient(90deg, #aaa 0%, #323232 100%);
      width: 5px;
      height: 10px;
      border: thin solid #656556;
      border-top: none;
      z-index: -1;

      &::before {
        content: "";
        background: radial-gradient(circle, #aaa 0%, #ddd 50%, #aaa 100%);
        border: thin solid #323232;
        border-top: none;
        position: absolute;
        height: 4px;
        width: 10px;
        left: -3.5px;
        top: 0;
        border-radius: 0 0 2px 2px;
      }

      &::after {
        content: "";
        background: radial-gradient(circle, #aaa 0%, #ddd 50%, #aaa 100%);
        border: thin solid #323232;
        border-bottom: 2px solid #212121;
        position: absolute;
        height: 5px;
        width: 15px;
        left: -6px;
        bottom: -4px;
        border-radius: 50%;
        z-index: -1;
      }
    }

    .pin-left {
      left: 2rem;
    }

    .pin-right {
      right: 2rem;
    }
  }
}

.empty-slot-card {
  background: transparent !important;

  &:hover {
    background: #b0bec5 !important;
    background: #cfd8dc !important;

    & > div {
      color: #607d8b !important;
    }
  }
}

.equipment-radio-btn {
  margin: 0;

  .v-input--selection-controls__input {
    margin: 0;
  }
}

.available-equipment-card {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
