import Api from "../../Shared/services/api";

const baseUrl = "UserSettings";

export default {
  getLaborRates(id) {
    return Api().get(baseUrl + `/${id}/LaborRates`);
  },
  UpdateLaborRates(id, data) {
    return Api().put(baseUrl + `/${id}/LaborRates`, data);
  },
};
