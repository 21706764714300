<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '1200px'"
    :min-size="nested ? 400 : 1200"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
    :class="readonly ? 'slideout-readonly-content' : ''"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Master Quote ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i :class="'mr-2 fad ' + selected.category.icon"></i>MasterQuote:
          {{ selected.tag }}
        </span>
        <span v-else> <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.tag }}' </span>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Add Master Quote
      </h3>
      <div>
        <v-btn
          @click="switchToEditMode()"
          v-if="readonly && !isFetching"
          color="info"
          small
          class="ml-2"
          style="height: 24px; font-size: 12px"
        >
          <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
        </v-btn>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <div color="white" style="position: sticky; top: 0; z-index: 1">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!basicInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Basic Information
        </v-tab>
        <v-tab :key="1">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!techDetailsTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Technical Details
        </v-tab>
        <v-tab :key="2">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!pricingTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Pricing
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching MasterQuote ...</p>
    </v-container>
    <v-container fluid class="pa-0" v-else>
      <v-tabs-items v-model="slideouts.update.tab">
        <v-tab-item :key="0" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab0" ref="updateForm0">
              <v-row class="my-0">
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <equipment-category-selector
                    required
                    v-model="selected.categoryId"
                    :obj.sync="selected.category"
                    :readonly="readonly"
                  ></equipment-category-selector>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.lengthMax(12), allRules.noWhiteSpaces]"
                    label="Tag"
                    id="masterQuoteTag"
                    ref="masterQuoteTag"
                    class="masterQuote-tag-text-field"
                    placeholder="XYZ-123"
                    v-model="selected.tag"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <single-image-uploader
                    id="add-client-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="selected.imageUrl"
                    label="Master Quote Image"
                    :disabled="readonly"
                  ></single-image-uploader>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-textarea-alt
                    :rules="[allRules.required]"
                    label="Description"
                    id="masterQuoteDescription"
                    placeholder="Master Quote Description"
                    v-model="selected.description"
                    no-resize
                    height="132px"
                    :readonly="readonly"
                  >
                  </v-textarea-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0 mt-3">
                  <v-text-field-alt
                    label="Make <i class='fs-12px opacity-54 ml-2 mb-0' style='line-height: 0;'>Obsolete [To Be Removed]</i>"
                    id="equipmentMake"
                    placeholder="XYZ-123"
                    v-model="selected.make"
                    readonly
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
              <manufacturer-product-family-editor
                ref="manufacturerProductFamilyEditor"
                :readonly="readonly"
                :manufacturerId.sync="selected.manufactureId"
                :productFamilyId.sync="selected.productFamilyId"
              ></manufacturer-product-family-editor>
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0 mt-3">
                  <v-menu
                    v-model="dateReceivedMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :nudge-top="20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field-alt
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        label="Date Received"
                        id="masterQuoteDateReceived"
                        placeholder="Date Received"
                        v-model="selected.dateReceived"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field-alt>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="selected.dateReceived"
                      @input="dateReceivedMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0 mt-3">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Quote Number"
                    id="masterQuoteQuoteNumber"
                    placeholder="XYZ-123"
                    v-model="selected.quoteNumber"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 comp-wrpr">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Provider"
                    id="EquipmentProvider"
                    placeholder="Provider"
                    v-model="selected.provider"
                    :items="providers"
                    :filter="providersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideouts.update.providerLoading"
                    :disabled="slideouts.update.providerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                    </template>
                    <template v-slot:item="data">
                      <equipment-provider :provider-name="data.item.name"></equipment-provider>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0 comp-wrpr">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    label="Installer"
                    id="EquipmentInstaller"
                    placeholder="Installer"
                    v-model="selected.installer"
                    :items="installers"
                    :filter="installersFilter"
                    dense
                    filled
                    return-object
                    item-text="name"
                    item-value="id"
                    :loading="slideouts.update.installerLoading"
                    :disabled="slideouts.update.installerLoading"
                    :readonly="readonly"
                  >
                    <template v-slot:selection="data">
                      <equipment-installer :installer-name="data.item.name"></equipment-installer>
                    </template>
                    <template v-slot:item="data">
                      <equipment-installer :installer-name="data.item.name"></equipment-installer>
                      <p
                        class="ma-0 ml-2 text--secondary"
                        v-if="data.item.description != null && data.item.description != ''"
                      >
                        {{ data.item.description }}
                      </p>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <div class="v-combobox-wrap">
                    <label for="masterQuoteHashtags">Hashtags</label>
                    <v-combobox
                      id="masterQuoteHashtags"
                      :persistent-placeholder="true"
                      placeholder="MasterQuote Hashtags"
                      append-icon="fas fa-caret-down"
                      deletable-chips
                      :search-input.sync="slideouts.update.searchHashtags"
                      v-model="selected.masterQuoteHashtags"
                      :items="masterQuoteHashtags"
                      return-object
                      no-filter
                      item-text="name"
                      item-value="id"
                      multiple
                      chips
                      dense
                      filled
                      solo
                      flat
                      hide-selected
                      class="mt-1"
                      :loading="slideouts.update.isHashtagsLoading"
                      :readonly="readonly"
                    >
                      <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip
                          v-bind="attrs"
                          color="secondary"
                          :input-value="selected"
                          label
                          small
                        >
                          <span :class="!readonly ? 'pr-2' : ''">
                            {{ item.name || item }}
                          </span>
                          <v-icon v-if="!readonly" small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-combobox>
                  </div>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    label="Sharepoint Link To PDF Quote"
                    id="masterQuoteSharepointURL"
                    placeholder="Sharepoint Link To PDF Quote"
                    v-model="selected.sharepointLink"
                    :readonly="readonly"
                    :append-outer-icon="
                      selected.sharepointLink != null &&
                      allRules.urlForced(selected.sharepointLink) == true
                        ? 'fal fa-external-link fs-18px'
                        : ''
                    "
                    class="shrink"
                    @click:append-outer="openURL()"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="1" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab1" ref="updateForm1">
              <v-row class="my-0">
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Weight (Lbs.)"
                    id="masterQuoteWeight"
                    ref="masterQuoteWeight"
                    placeholder="Weight (Lbs.)"
                    v-model="selected.weight"
                    :readonly="readonly"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <label class="v-switch-label">Is Rack Mountable?</label>
                  <v-switch
                    color="info"
                    v-model="selected.isRackMountable"
                    :readonly="readonly"
                    :disabled="readonly"
                  ></v-switch>
                </v-col>
                <v-col cols="auto" class="py-0">
                  <v-text-field-alt
                    label="Rack Units (RU)"
                    id="masterQuoterackUnits"
                    placeholder="Rack Units (RU)"
                    v-model="selected.rackUnits"
                    :readonly="readonly"
                    v-if="selected.isRackMountable"
                    :rules="[allRules.number]"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-divider class="mb-5"></v-divider>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    label="Power Consumption (Watts)"
                    id="masterQuotePowerConsumption"
                    placeholder="PowerConsumption (Watts)"
                    v-model="selected.powerConsumption"
                    :readonly="readonly"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <label class="d-block mb-1" style="font-weight: 600 !important; font-size: 15px">
                    HeatLoad & Hvac
                    <span class="text--secondary">Calculation</span>
                  </label>
                  <v-btn-toggle v-model="selected.powerAutoCalculate" mandatory color="info">
                    <v-btn :value="false" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-open-alt </v-icon> -->
                      <i class="fad fa-lock-open-alt mr-2 secondary--text"></i>
                      <span>Manual</span>
                    </v-btn>
                    <v-btn :value="true" :disabled="readonly">
                      <!-- <v-icon left> fal fa-lock-alt </v-icon> -->
                      <i class="fad fa-lock-alt mr-2 secondary--text"></i>
                      <span>Auto Derived</span>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0"> </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="masterQuoteHeatLoad"
                    placeholder="HeatLoad (BTU/Hr)"
                    :label="
                      selected.powerAutoCalculate
                        ? `HeatLoad (BTU/Hr) <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HeatLoad (BTU/Hr)`
                    "
                    v-model="selected.heatload"
                    :readonly="readonly"
                    :class="selected.powerAutoCalculate ? '-font-italic' : ''"
                    type="number"
                    hide-spin-buttons
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    id="masterQuoteHvacTonnage"
                    placeholder="HVAC Tonnage"
                    :label="
                      selected.powerAutoCalculate
                        ? `HVAC Tonnage <i class='fad fa-lock-alt text--disabled mx-1'></i>`
                        : `HVAC Tonnage`
                    "
                    v-model="selected.hvacTonnage"
                    :readonly="readonly"
                    type="number"
                    hide-spin-buttons
                    :class="selected.powerAutoCalculate ? 'font-italic' : ''"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item :key="2" eager>
          <v-container fluid class="pa-6">
            <v-form v-model="forms.tab2" ref="updateForm2">
              <v-row class="my-0">
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber]"
                    label="MSRP"
                    id="masterQuoteMSRP"
                    ref="masterQuoteMSRP"
                    placeholder="MSRP"
                    v-model="selected.msrp"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Availability"
                    id="masterQuoteAvailability"
                    placeholder="availability"
                    v-model="selected.availability"
                    :items="MasterQuoteAvailability | EnumToList"
                    :readonly="readonly"
                  >
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    type="number"
                    hide-spin-buttons
                    label="Dealer Price"
                    id="masterQuoteDealerPrice"
                    placeholder="Dealer Price"
                    v-model="selected.dealerPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.requiredNumber, allRules.number]"
                    type="number"
                    hide-spin-buttons
                    label="Street Price"
                    id="masterQuoteStreetPrice"
                    placeholder="Street Price"
                    v-model="selected.streetPrice"
                    prefix="$"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="readonly" @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
        <v-btn
          v-if="!readonly"
          color="info"
          :disabled="!valid || slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="updateConfirmed()"
          :loading="slideouts.update.isLoading"
        >
          <i class="fal mr-2" :class="selected.id ? 'fa-check' : 'fa-plus'"></i>
          {{ selected.id ? "Save Changes" : "Create" }}
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import categoriesAPI from "../../Equipments/services/categories-service";
import installersAPI from "../../Equipments/services/installers-service";
import providersAPI from "../../Equipments/services/providers-service";
import hashtagsAPI from "../../Equipments/services/hashtags-service";
import ApiService from "../services/masterQuotes-service.js";
import EquipmentInstaller from "../../Equipments/components/EquipmentInstaller.vue";
import EquipmentProvider from "../../Equipments/components/EquipmentProvider.vue";
import ManufacturerProductFamilyEditor from "../../Equipments/components/ManufacturerProductFamilyEditor.vue";
import EquipmentCategorySelector from "../../Equipments/components/EquipmentCategorySelector.vue";

export default {
  components: {
    EquipmentInstaller,
    EquipmentProvider,
    ManufacturerProductFamilyEditor,
    EquipmentCategorySelector,
  },
  name: "edit-master-quote",
  data() {
    return {
      masterQuoteId: null,
      dateReceivedMenu: false,
      isFetching: false,
      readonly: false,
      imageApiUrl: `masterQuotes/Image`,
      MasterQuoteAvailability: Object.freeze({
        Available: 1,
        Presale: 2,
        Discontinued: 3,
      }),
      forms: {
        tab0: false,
        tab1: false,
        tab2: false,
      },
      categories: [],
      providers: [],
      installers: [],
      masterQuoteHashtags: [],
      isLoading: false,
      selected: {},
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          hasChanges: false,
          tab: null,
          isLoading: false,
          providerLoading: false,
          installerLoading: false,
          searchHashtags: "",
          isHashtagsLoading: false,
          hashtagsTimerId: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    spaceId: {
      type: Number,
    },
    projectId: {
      type: Number,
    },
  },
  mounted() {
    this.getProviders();
    this.getInstallers();
  },

  computed: {
    valid() {
      return this.forms.tab0 && this.forms.tab1 && this.forms.tab2;
    },
    isMainRoute() {
      if (this.$route.name == "single-masterQuote") return true;
      else return false;
    },
    basicInfoTabIsValid() {
      return this.forms.tab0;
    },
    techDetailsTabIsValid() {
      return this.forms.tab1;
    },
    pricingTabIsValid() {
      return this.forms.tab2;
    },
  },
  methods: {
    onSlideoutClosing(e) {
      this.$console().log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementMasterQuote();
      this.$releaseChanges();
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    fetchMasterQuote() {
      this.isFetching = true;
      ApiService.getMasterQuoteById(this.masterQuoteId)
        .then((resp) => {
          this.$console().log("getMasterQuoteById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          if (this.selected.dateReceived)
            this.selected.dateReceived = this.formatDate(new Date(this.selected.dateReceived));

          this.cementMasterQuote();
          if (this.isMainRoute) document.title = this.selected.tag + " | Concordia";
        })
        .catch((err) => {
          this.$console().log("getMasterQuoteById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    announceChange(isNewMasterQuote) {
      this.$emit("save", this.selected, isNewMasterQuote);
    },
    getHashtagsDebounced() {
      // cancel pending call
      clearTimeout(this.slideouts.update.hashtagsTimerId);

      // delay new call 500ms
      this.slideouts.update.hashtagsTimerId = setTimeout(() => {
        this.getHashtags();
      }, 500);
    },
    getHashtags() {
      this.slideouts.update.isHashtagsLoading = true;
      hashtagsAPI
        .typeHead(
          this.slideouts.update.searchHashtags ? this.slideouts.update.searchHashtags : "",
          false
        )
        .then((resp) => {
          this.masterQuoteHashtags = resp.data.slice();
          this.slideouts.update.isHashtagsLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.isHashtagsLoading = false;
          this.$handleError(err);
        });
    },
    categoriesFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    addNewProvider() {
      this.$refs.addProvider.open();
    },
    onProviderAdded(providerData, isNewProvider) {
      this.$console().log("onProviderAdded", providerData, isNewProvider);
      if (isNewProvider) {
        this.addToArr(this.providers, providerData);
        this.selected.providerId = providerData.id;
        this.refreshProvidersAutocomplete();
      }
    },
    getProviders() {
      this.slideouts.update.providerLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providers = resp.data;
          this.refreshProvidersAutocomplete();
          this.checkProvidersHasSelected();
          this.slideouts.update.providerLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.providerLoading = false;
          this.$handleError(err);
        });
    },
    providersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshProvidersAutocomplete() {
      if (this.selected.providerId != null && this.providers.length > 0)
        this.selected.provider =
          this.providers.find((c) => c.id == this.selected.providerId) || null;
    },
    checkProvidersHasSelected() {
      if (
        this.selected.provider != null &&
        !this.providers.some((t) => t.id == this.selected.provider.id)
      ) {
        this.providers.unshift(this.cloneDeep(this.selected.provider));
      }
    },
    addNewInstaller() {
      this.$refs.addInstaller.open();
    },
    onInstallerAdded(installerData, isNewInstaller) {
      if (isNewInstaller) {
        this.addToArr(this.installers, installerData);
        this.selected.installerId = installerData.id;
        this.refreshInstallersAutocomplete();
      }
    },
    getInstallers() {
      this.slideouts.update.installerLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installers = resp.data;
          this.refreshInstallersAutocomplete();
          this.checkInstallersHasSelected();
          this.slideouts.update.installerLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.installerLoading = false;
          this.$handleError(err);
        });
    },
    installersFilter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = (item.description || "").toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    refreshInstallersAutocomplete() {
      if (this.selected.installerId != null && this.installers.length > 0)
        this.selected.installer =
          this.installers.find((c) => c.id == this.selected.installerId) || null;
    },
    checkInstallersHasSelected() {
      if (
        this.selected.installer != null &&
        !this.installers.some((t) => t.id == this.selected.installer.id)
      ) {
        this.installers.unshift(this.cloneDeep(this.selected.installer));
      }
    },
    updateConfirmed() {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      toSend.categoryId = toSend.category.id;
      toSend.providerId = toSend.provider.id;
      toSend.installerId = toSend.installer.id;
      toSend.spaceId = this.spaceId;
      toSend.projectId = this.projectId;
      toSend.rackUnits = toSend.rackUnits || 0;
      if (toSend.masterQuoteHashtags != null && toSend.masterQuoteHashtags.length > 0) {
        toSend.newHashtags = toSend.masterQuoteHashtags.filter((hashtag) => hashtag.id == null);
        toSend.masterQuoteHashtags = toSend.masterQuoteHashtags.filter(
          (hashtag) => hashtag.id != null
        );
        toSend.masterQuoteHashtags = toSend.masterQuoteHashtags.map((hashtag) => hashtag.id);
      }
      ApiService.update(toSend)
        .then((resp) => {
          var message = "Item updated successfully";
          this.selected = this.cloneDeep(resp.data);
          this.cementMasterQuote();
          var isNewMasterQuote = false;
          if (!toSend.id) {
            isNewMasterQuote = true;
            message = "Item added successfully";
          }
          this.announceChange(isNewMasterQuote);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.closeUpdateSlideout();
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    isNum(value) {
      // return /^[0-9]+.[0-9]+$/.test(value);
      return /^[0-9]+.[0-9]+|[0-9]+$/.test(value);
    },
    refreshHeatAndHvac() {
      if (
        this.selected == null ||
        this.selected.powerAutoCalculate != true ||
        !this.isNum(this.selected.powerConsumption)
      )
        return;

      //Heat Load = POWER CONSUMPTION * 3.412
      //HVAC Tonnage = (POWER CONSUMPTION * 3.412) / 12000
      let pc = parseFloat(this.selected.powerConsumption);
      this.$console().log("POWER CONSUMPTION:", this.selected.powerConsumption, pc);
      this.selected.heatload = (pc * 3.412).toFixed(2);
      this.selected.hvacTonnage = ((pc * 3.412) / 12000).toFixed(5);
    },

    open(id, editMode = false) {
      this.slideouts.update.tab = 0;
      if (id == null) {
        this.selected = {
          powerConsumption: null,
          powerAutoCalculate: true,
        };
        this.readonly = false;
        this.isFetching = false;
        this.masterQuoteId = null;

        this.cementMasterQuote();
      } else {
        this.readonly = !editMode;
        this.masterQuoteId = id;
        this.fetchMasterQuote();
      }
      this.slideouts.update.active = true;
    },
    handleFocus() {
      if (this.slideouts.update.tab == 0) {
        setTimeout(() => {
          this.$refs.masterQuoteTag.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 1) {
        setTimeout(() => {
          this.$refs.masterQuoteWeight.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 2) {
        setTimeout(() => {
          this.$refs.masterQuoteMSRP.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 3) {
      }
    },

    cementMasterQuote() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    openURL() {
      window.open(this.selected.sharepointLink);
    },
  },
  watch: {
    masterQuote: {
      handler() {
        this.selected = this.cloneDeep(this.masterQuote || {});
        this.cementMasterQuote();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkProvidersHasSelected();
        this.checkInstallersHasSelected();
        this.checkForChanges();
      },
      deep: true,
    },
    "slideouts.update.searchHashtags": {
      handler() {
        this.getHashtagsDebounced();
      },
    },
    "selected.powerAutoCalculate": {
      handler() {
        if (this.selected.powerAutoCalculate) {
          this.refreshHeatAndHvac();
        }
      },
    },
    "selected.powerConsumption": {
      handler() {
        if (this.isNum(this.selected.powerConsumption)) {
          this.refreshHeatAndHvac();
        }
      },
    },
    "selected.tag": {
      handler() {
        if (this.selected != null && this.selected.tag != null)
          this.selected.tag = this.selected.tag.toUpperCase();
      },
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
  },
};
</script>
