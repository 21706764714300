export default [
  {
    text: "Actions",
    value: "actions",
    width: "60px",
    align: "left",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
    hiddenInPrint: true,
  },
  {
    text: "AVAL",
    value: "availability",
    width: "40px",
    cellClass: "px-1",
    class: "px-1",
    order: 1,
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Image",
    value: "name",
    width: "80px",
    order: 2,
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Category",
    value: "category",
    sortable: false,
    width: "120px",
    order: 3,
    hidable: false,
    hidden: false,
  },
  { text: "Tag", value: "tag", width: "120px", order: 4, hidable: true, hidden: false },
  {
    text: "Provider",
    value: "provider",
    width: "100px",
    order: 5,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "Installer",
    value: "installer",
    width: "100px",
    order: 6,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "MSRP",
    value: "msrp",
    width: "140px",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "Accessories",
    value: "accessoriesCount",
    width: "120px",
    order: 8,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "Stats",
    value: "stats",
    width: "100px",
    order: 9,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "Description",
    value: "description",
    width: "250px",
    order: 10,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "Manufacturer",
    width: "150px",
    value: "manufacture.name",
    order: 11,
    hidable: true,
    hidden: false,
  },
  {
    text: "Family",
    width: "150px",
    value: "productFamily.name",
    order: 12,
    hidable: true,
    hidden: false,
  },
  { text: "Model", width: "110px", value: "model", order: 13, hidable: true, hidden: false },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 14,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 15,
    hidable: true,
    hidden: false,
  },
  {
    text: "Creator",
    value: "createdBy",
    width: "130px",
    order: 16,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updater",
    value: "updatedBy",
    width: "130px",
    order: 17,
    hidable: true,
    hidden: false,
  },
];
