import { render, staticRenderFns } from "./ProjectOverview.vue?vue&type=template&id=a236e65a&"
import script from "./ProjectOverview.vue?vue&type=script&lang=js&"
export * from "./ProjectOverview.vue?vue&type=script&lang=js&"
import style0 from "./ProjectOverview.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBtn,VBtnToggle,VCard,VDivider,VIcon,VProgressLinear,VTooltip,VTreeview})
