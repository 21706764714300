export const AccessListMixin = {
  computed: {
    errors() {
      let errs = [];
      if (!this.selected)
        return errs;
      if (this.selected.accessListRecords) {
        // Records with no user selected yet
        if (this.selected.accessListRecords.some(e => !e.userId))
          errs.push("Some Individuals records has no user selected!");

        var accesslistRecords = this.selected.accessListRecords.filter(e => e.userId).map((e) => e.userId);
        if (new Set([...accesslistRecords]).size != accesslistRecords.length)
          errs.push("Individuals Access can't be duplicated!")
      }
      if (this.selected.accessGroupRecords) {
        if (this.selected.accessGroupRecords.some(e => !e.accessGroup || !e.accessGroup.id))
          errs.push("Some Groups Access has no group selected!")
        var groupsIds = this.selected.accessGroupRecords.filter(e => e.accessGroup && e.accessGroup.id).map((e) => e.accessGroup.id);
        if (new Set([...groupsIds]).size != groupsIds.length)
          errs.push("Groups Access can't be duplicated!")
      }
      return errs;
    }
  }
}
