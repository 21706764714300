<template>
  <v-card class="project-overview-card position-relative" width="300px">
    <h4 class="project-overview-header pl-3">
      <div class="d-flex align-center">
        <!-- <v-btn icon color="info" small @click="$emit('collapse')">
          <i class="fas fa-caret-right fs-16px"></i>
        </v-btn> -->
        <i class="fad fa-box-full mx-2"></i>Project Overview
      </div>
      <!-- <v-switch dense v-model="treeView" label="Tree View"></v-switch> -->
      <v-btn-toggle v-model="treeView" mandatory color="info" dense group style="height: 40px">
        <v-btn dense :value="false" style="border-radius: 4px">
          <v-icon>fal fa-list</v-icon>
        </v-btn>
        <v-btn dense :value="true" style="border-radius: 4px">
          <v-icon>fal fa-folder-tree</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-progress-linear
        indeterminate
        color="info"
        absolute
        top
        v-show="isLoading"
      ></v-progress-linear>
    </h4>
    <div class="project-overview-content" v-if="spaces && spaces.length > 0">
      <div v-if="treeView">
        <v-treeview dense open-on-click transition :items="spacesTree">
          <template v-slot:prepend="{ item }">
            <i class="fad fa-building tree-building" v-if="item.type == 'Building'"></i>
            <i class="fad fa-layer-group tree-level" v-else-if="item.type == 'Level'"></i>
            <i class="fad fa-draw-polygon tree-space" v-else-if="item.type == 'Space'"></i>
          </template>
          <template v-slot:label="{ item }">
            <span class="tree-building" v-if="item.type == 'Building'">{{ item.name }}</span>
            <span class="tree-level" v-else-if="item.type == 'Level'">{{ item.name }}</span>
            <span
              class="tree-space"
              v-else-if="item.type == 'Space'"
              @click.stop="onTreeItemClicked(item)"
            >
              {{ item.name }}
            </span>
          </template>
          <template v-slot:append="{ item }">
            <span class="tree-total-msrp">
              <v-tooltip left z-index="999" nudge-left="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="d-inline-block"
                    style="font-size: 14px; font-weight: 600 !important"
                  >
                    {{ item.totals.totalMSRPWithInstallCost | usdFormat }}
                  </div>
                </template>
                <span>
                  <i class="fad fa-building tree-building" v-if="item.type == 'Building'"></i>
                  <i class="fad fa-layer-group tree-level" v-else-if="item.type == 'Level'"></i>
                  <i class="fad fa-draw-polygon tree-space" v-else-if="item.type == 'Space'"></i>
                  <span class="ml-2">{{ item.name }}</span>
                </span>
                <v-divider class="my-1 d-block" style="width: 100%" dark></v-divider>
                <span class="d-flex flex-column align-end" style="gap: 0.25rem">
                  <span style="font-size: 12px; font-weight: 500 !important">
                    <b class="blue--text mr-1" style="font-size: 12px; font-weight: 700 !important">
                      {{ installationFactorVal }}x
                    </b>
                    {{ item.totals.installationCost | usdFormat }}
                    <i class="fad fa-wrench ml-1"></i>
                  </span>
                  <span>
                    {{ item.totals.equipmentsTotalMsrpPrice | usdFormat }}
                    <i class="fad fa-usd-square ml-1"></i>
                  </span>
                  <v-divider
                    class="my-1 d-block"
                    style="width: 100%; border-style: dashed !important"
                    dark
                  ></v-divider>
                  <span style="font-size: 16px; font-weight: 600 !important">
                    {{ item.totals.totalMSRPWithInstallCost | usdFormat }}
                  </span>
                </span>
              </v-tooltip>
            </span>
          </template>
        </v-treeview>
      </div>
      <div v-else>
        <project-overview-item
          v-for="space in spaces"
          :space="space"
          :installation-factor="installationFactorVal"
          :key="space.id"
          @click="onItemClicked"
        ></project-overview-item>
      </div>
    </div>
    <div v-else class="project-overview-content empty">
      <img src="/img/art/undraw_building_re_xfcm.svg" />
      <p v-if="projectId == null">Project is not yet created!</p>
      <p v-else>No spaces added yet!</p>
    </div>
    <div class="project-overview-footer">
      <div class="project-overview-price-type">
        <v-btn-toggle
          v-model="pricePerSqft"
          mandatory
          color="orange darken-2"
          dense
          group
          style="height: 36px"
        >
          <v-btn dense :value="false" small> Total </v-btn>
          <v-btn dense :value="true" small> Per SQFT </v-btn>
        </v-btn-toggle>
      </div>
      <div class="text-left">
        <div style="opacity: 0.72; font-size: 13px; font-weight: 600 !important">
          <i class="fad fa-wrench mr-2"></i>Install Cost
          <b class="purple--text" style="font-size: 12px; font-weight: 700 !important">
            {{ installationFactorVal }}x
          </b>
        </div>
        <h3 v-if="pricePerSqft" style="font-size: 14px">
          <span v-if="totalSQFT > 0">{{ (installationCost / totalSQFT) | usdFormat }}</span>
          <span v-else>{{ 0 | usdFormat }}</span>
          <span class="orange--text text--darken-2 font-weight-bold fs-12px"> /SQFT </span>
        </h3>
        <h3 v-else style="font-size: 14px">
          {{ installationCost | usdFormat }}
        </h3>
        <v-divider class="my-1"></v-divider>
        <div style="opacity: 0.72; font-size: 13px; font-weight: 600">
          <i class="fad fa-usd-square mr-2"></i>Total MSRP
        </div>
        <h3 v-if="pricePerSqft">
          <span v-if="totalSQFT > 0">{{ (totalMSRP / totalSQFT) | usdFormat }}</span>
          <span v-else>{{ 0 | usdFormat }}</span>
          <span class="orange--text text--darken-2 font-weight-bold fs-12px"> /SQFT </span>
        </h3>
        <h3 v-else>{{ totalMSRP | usdFormat }}</h3>
      </div>
      <v-divider vertical></v-divider>
      <div class="text-right">
        <div v-if="pricePerSqft" class="orange lighten-4 px-2 mb-2" style="border-radius: 0.3rem">
          <h4 style="font-size: 16px">
            <span style="opacity: 0.75; font-size: 12px; font-weight: 600" class="mr-1">
              Total SQFT:
            </span>
            <span v-if="totalSQFT > 0">{{ totalSQFT | numberWithCommas }}</span>
            <span v-else>{{ totalSQFT }}</span>
          </h4>
        </div>
        <div style="opacity: 0.72; font-size: 13px; font-weight: 600">Total Cost</div>
        <h3 v-if="pricePerSqft" style="font-size: 20px">
          <span v-if="totalSQFT > 0">{{ (totalMSRPWithInstallCost / totalSQFT) | usdFormat }}</span>
          <span v-else>{{ 0 | usdFormat }}</span>
          <span class="orange--text text--darken-2 font-weight-bold fs-12px"> /SQFT </span>
        </h3>
        <h3 v-else style="font-size: 20px">
          {{ totalMSRPWithInstallCost | usdFormat }}
        </h3>
      </div>
    </div>
  </v-card>
</template>

<script>
import projectService from "../../Projects/services/projects-service";
import ProjectOverviewItem from "./ProjectOverviewItem.vue";

export default {
  name: "project-overview",
  data() {
    return {
      spaces: [],
      buildings: [],
      isLoading: false,
      treeView: false,
      pricePerSqft: false,
    };
  },
  computed: {
    spacesTotal() {
      if (this.spaces != null) return this.spaces.length;
      else return 0;
    },
    totalSQFT() {
      if (this.buildings.length > 0)
        return this.buildings.reduce((total, cur) => {
          return total + cur.area || 0;
        }, 0);
      else return 0;
    },
    totalMSRP() {
      if (this.spaces.length > 0)
        return this.spaces.reduce((total, cur) => {
          return total + cur.equipmentsTotalMsrpPrice * (cur.isBatch ? cur.batchCount : 1);
        }, 0);
      else return 0;
    },
    installationFactorVal() {
      if (
        this.installationFactor != null &&
        this.allRules.number(this.installationFactor) &&
        this.installationFactor > 0
      )
        return this.installationFactor;
      else return 0.25;
    },
    installationCost() {
      return this.totalMSRP * this.installationFactorVal;
    },
    totalMSRPWithInstallCost() {
      return this.totalMSRP + this.totalMSRP * this.installationFactorVal;
    },
    spacesTree() {
      var tree = [];
      if (this.spaces.length >= 0) {
        this.spaces.forEach((space) => {
          // this.$log(">>>>>>>>>> space", space);

          //Find the Building
          var building_index = tree.findIndex((b) => b.id == space.level.building.id);
          if (building_index == -1) {
            //Building Not Found
            tree.push({
              id: space.level.building.id,
              name: space.level.building.name,
              type: "Building",
              totals: this.getBuildingTotals(space.level.building.id),
              children: [
                {
                  id: space.level.id,
                  name: space.level.name,
                  type: "Level",
                  totals: this.getLevelTotals(space.level.id),
                  children: [
                    {
                      id: space.id,
                      name: space.name,
                      space: space,
                      type: "Space",
                      totals: this.getSpaceTotals(space.id),
                    },
                  ],
                },
              ],
            });
          } else {
            //Building Not Found
            //find the Level
            var level_index = tree[building_index].children.findIndex(
              (l) => l.id == space.level.id
            );
            if (level_index == -1) {
              //Level Not Found
              tree[building_index].children.push({
                id: space.level.id,
                name: space.level.name,
                type: "Level",
                totals: this.getLevelTotals(space.level.id),
                children: [
                  {
                    id: space.id,
                    name: space.name,
                    space: space,
                    type: "Space",
                    totals: this.getSpaceTotals(space.id),
                  },
                ],
              });
            } else {
              //Level Found
              // this.$log("Level Found", building_index, level_index, space, tree);
              tree[building_index].children[level_index].children.push({
                id: space.id,
                name: space.name,
                space: space,
                type: "Space",
                totals: this.getSpaceTotals(space.id),
              });
            }
          }
        });
      }
      return tree;
    },
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    installationFactor: {
      type: Number,
      default: null,
    },
  },
  mounted() {
    //get project spaces
    // this.$log("Project Overview Project Id", this.projectId);
    if (this.projectId) this.getProjectOverview();
  },
  methods: {
    onTreeItemClicked(item) {
      if (item.type == "Space") this.onItemClicked(item.id);
    },
    onItemClicked(spaceId) {
      this.$emit("open-item", spaceId);
    },
    getProjectOverview() {
      if (this.isLoading) return;
      // this.$log("getProjectOverview()", this.projectId);
      this.isLoading = true;
      projectService
        .getOverview(this.projectId)
        .then((resp) => {
          this.spaces = resp.data.spaces;
          this.buildings = resp.data.buildings;
          this.$log("spaces", resp.data.spaces);
          this.$log("buildings", resp.data.buildings);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    refresh() {
      this.getProjectOverview();
    },
    getBuildingTotals(buildingId) {
      var totals = {
        equipmentsTotalMsrpPrice: 0,
        installationCost: 0,
        totalMSRPWithInstallCost: 0,
      };

      totals.equipmentsTotalMsrpPrice = this.spaces.reduce((total, cur) => {
        if (cur.level.building.id == buildingId) {
          return total + cur.equipmentsTotalMsrpPrice;
        } else return total;
      }, 0);

      totals.installationCost = totals.equipmentsTotalMsrpPrice * this.installationFactorVal;
      totals.totalMSRPWithInstallCost =
        totals.equipmentsTotalMsrpPrice +
        totals.equipmentsTotalMsrpPrice * this.installationFactorVal;

      return totals;
    },
    getLevelTotals(LevelId) {
      var totals = {
        equipmentsTotalMsrpPrice: 0,
        installationCost: 0,
        totalMSRPWithInstallCost: 0,
      };

      totals.equipmentsTotalMsrpPrice = this.spaces.reduce((total, cur) => {
        if (cur.level.id == LevelId) {
          return total + cur.equipmentsTotalMsrpPrice;
        } else return total;
      }, 0);

      totals.installationCost = totals.equipmentsTotalMsrpPrice * this.installationFactorVal;
      totals.totalMSRPWithInstallCost =
        totals.equipmentsTotalMsrpPrice +
        totals.equipmentsTotalMsrpPrice * this.installationFactorVal;

      return totals;
    },
    getSpaceTotals(spaceId) {
      var totals = {
        equipmentsTotalMsrpPrice: 0,
        installationCost: 0,
        totalMSRPWithInstallCost: 0,
      };

      totals.equipmentsTotalMsrpPrice = this.spaces.find(
        (s) => s.id == spaceId
      ).equipmentsTotalMsrpPrice;

      totals.installationCost = totals.equipmentsTotalMsrpPrice * this.installationFactorVal;
      totals.totalMSRPWithInstallCost =
        totals.equipmentsTotalMsrpPrice +
        totals.equipmentsTotalMsrpPrice * this.installationFactorVal;

      return totals;
    },
  },
  watch: {
    projectId: {
      handler() {
        this.getProjectOverview();
      },
    },
  },
  components: { ProjectOverviewItem },
};
</script>

<style lang="scss">
.project-overview-content > div {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .v-treeview-node__prepend {
    min-width: 20px !important;
    margin-right: 4px !important;
  }

  .v-treeview-node__level:nth-child(3) {
    display: none !important;
  }

  .tree-building {
    color: $info-base;
  }
  .tree-level {
    color: $success-base;
  }

  .tree-space {
    font-weight: 600;
    color: rgba($shades-black, 0.87);
    font-size: 14px;

    &:hover {
      color: $orange-base;
    }
  }

  // .v-treeview-node--leaf * {
  //   cursor: default !important;
  // }
}
</style>
