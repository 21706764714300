import Api from "../../Shared/services/api";

const baseUrl = "DNADefaults";
export default {
  getLaborRates() {
    return Api().get(`${baseUrl}/LaborRates`);
  },
  saveLaborRates(data) {
    return Api().put(`${baseUrl}/LaborRates`, data);
  },
  getDefaultAccessGroups() {
    return Api().get(`${baseUrl}/GetDefaultAccessGroups`);
  },
  saveDefaultAccessGroups(data) {
    return Api().post(`${baseUrl}/SaveDefaultAccessGroups`, data);
  },
};
