<template>
  <v-data-table
    v-if="entryData != null"
    style="
      overflow: auto;
      overflow: overlay;
      height: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    "
    class="elevation-2 mt-4 table-sticky-header-exclude-footer"
    dense
    :headers="headers"
    :items="recordsList"
    :items-per-page="30"
    :sort-by="sortBy"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }"
  >
    <template v-slot:[`header.sellCost`]>
      <span v-if="isDirectExpense">Revenue</span>
      <span v-else>Sell Rate</span>
    </template>
    <template v-slot:[`header.laborCost`]>
      <span v-if="isDirectExpense">Cost</span>
      <span v-else>Cost Rate</span>
    </template>
    <template v-slot:[`item.project.fullName`]="{ item }">
      <span class="main-title font-weight-medium">
        {{ item.project.fullName }}
      </span>
      <entity-id
        class="ml-2"
        :path="`projects/${item.project.id}`"
        title="Project"
        hideLabel
        tooltipOnTop
      ></entity-id>
    </template>
    <template v-slot:[`item.client.name`]="{ item }">
      <v-tooltip v-if="item.client" top nudge-top="-16px" z-index="999999">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="pa-2"
            style="cursor: pointer"
            v-bind="attrs"
            v-on="on"
            @click.stop="viewClient(item.client.id)"
          >
            <v-img
              v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
              :src="item.client.logoUrl"
              max-height="28"
              max-width="54"
              position="left center"
              contain
            >
            </v-img>
            <v-img
              v-else
              src="/img/DNA_Symbol.png"
              max-height="28"
              max-width="54"
              position="left center"
              contain
            >
            </v-img>
          </div>
        </template>
        <span>{{ item.client.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.contract.id`]="{ item }">
      <code
        class="mono-font ml-2 secondary--text fs-12px"
        :class="{
          'brown lighten-4 brown--text': item.contract.isArchived,
          'blue-grey lighten-5':
            !item.contract.isArchived && item.contract.status == enums.CONTRACT_STATUS.Draft.value,
          'orange lighten-4':
            !item.contract.isArchived &&
            item.contract.status == enums.CONTRACT_STATUS.Pending.value,
          'green lighten-4':
            !item.contract.isArchived &&
            item.contract.status == enums.CONTRACT_STATUS.Approved.value,
        }"
        style="flex: none"
      >
        #{{ item.contract.id }}
      </code>
    </template>
    <template v-slot:[`item.contract.name`]="{ item }">
      {{ item.contract.name }}
    </template>
    <template v-slot:[`item.contract.status`]="{ item }">
      <div class="d-inline-flex align-center">
        <v-chip
          v-if="item.contract.isArchived"
          class="px-2"
          small
          label
          :color="'brown'"
          :text-color="'white'"
        >
          <i class="fad fa-archive mr-2"></i>
          <span class="font-weight-medium" style="line-height: 0"> Archived </span>
        </v-chip>
        <contract-status v-else small :status="item.contract.status"></contract-status>
        <!-- <v-tooltip
          top
          z-index="9999999"
          max-width="400px"
          v-if="item.contract.status == enums.CONTRACT_STATUS.Approved.value"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              class="ml-1 px-1"
              min-width="20px"
              height="24px"
              small
              color="green darken-4"
              @click.stop="displayApprovedMessage(item.contract)"
            >
              <i class="fad fa-circle-info fs-16px"></i>
            </v-btn>
          </template>
          <div style="width: 100%" class="py-1" :id="'contract-approved-msg-' + item.contract.id">
            <div class="font-weight-bold green--text accent-3">
              <i class="fas fa-circle-check mr-1" style="width: 20px"></i>Contract Approved
            </div>
            <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
            <div class="d-flex align-center">
              <i class="fad fa-user mr-1" style="width: 20px"></i>By:
              <span class="font-weight-bold ml-1">
                {{ item.contract.approvedBy.firstName }}
                {{ item.contract.approvedBy.lastName }}
              </span>
            </div>
            <div class="d-flex align-center">
              <i class="fad fa-calendar-day mr-1" style="width: 20px"></i>On:
              <span class="font-weight-bold ml-1">
                <dater :date="item.contract.approvedOn"></dater
              ></span>
            </div>
            <div
              class="d-flex align-start flex-column"
              v-if="
                item.contract.approveNote != null &&
                item.contract.approveNote.trim() != '<p></p>' &&
                item.contract.approveNote.trim() != ''
              "
            >
              <v-divider dark class="my-2 mx-0" style="width: 100%"></v-divider>
              <b class="d-inline-flex align-center">
                <i class="fad fa-note mr-1" style="width: 20px"></i>Notes:
              </b>
              <div
                class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 mt-2"
                style="line-break: anywhere"
                v-html="item.contract.approveNote"
              ></div>
            </div>
          </div>
        </v-tooltip> -->
      </div>
    </template>
    <template v-slot:[`item.constructionPhase.startDate`]="{ item }">
      <h4>{{ item.constructionPhase.startDate.substr(0, 7) }}</h4>
    </template>
    <template v-slot:[`item.constructionPhase.name`]="{ item }">
      <construction-phase-type
        small
        :type="item.constructionPhase.type"
        class="mr-1"
      ></construction-phase-type>
      {{ item.constructionPhase.name }}
    </template>
    <template v-slot:[`item.constructionPhaseRecord`]="{ item }">
      <h4 class="d-flex align-start flex-column">
        <span
          class="d-flex flex-column"
          v-if="item.record.laborType != null && item.record.laborType != 0"
        >
          <label
            class="d-inline-flex blue-grey--text align-center"
            style="font-weight: 700 !important; font-size: 12px; flex: none; line-height: 1"
          >
            <i class="fas fa-caret-right fs-8px mr-1" style="line-height: 1"></i>
            {{ getLaborTypeObj(item.record.laborType).desc }}
          </label>
          <user-all-selector
            v-if="item.record.recordType == 0"
            :users="users"
            required
            hideLabel
            hideDetails
            hideBorder
            readonly
            small
            responsive
            :isUsersLoading="isUsersLoading"
            v-model="item.record.userId"
          ></user-all-selector>
          <user-avatar
            v-else
            mini
            showNoUser
            :noUserText="item.record.placeholderName"
            noUserIcon="fas fa-question"
          ></user-avatar>
        </span>
        <div class="d-flex align-start flex-column" v-else>
          <label
            class="d-inline-flex blue-grey--text align-center"
            style="font-weight: 700 !important; font-size: 12px; flex: none; line-height: 1"
          >
            <i class="fad mr-1" :class="getExpenseCategoryObj(item.record.category).icon"></i>
            {{ getExpenseCategoryObj(item.record.category).text }}
          </label>
          <span class="fs-12px">{{ item.record.description }}</span>
        </div>
      </h4>
    </template>
    <template v-slot:[`item.category`]="{ item }">
      <label
        class="d-flex mb-0 align-center"
        style="font-weight: 600 !important; font-size: 14px; flex: none; gap: 0.5rem"
      >
        <i class="fad ml-1" :class="getExpenseCategoryObj(item.category).icon"></i>
        {{ getExpenseCategoryObj(item.category).text }}
      </label>
    </template>
    <template v-slot:[`item.description`]="{ item }">{{ item.description }}</template>
    <template v-slot:[`item.hours`]="{ item }">{{ item.hours }}</template>
    <template v-slot:[`item.sellCost`]="{ item }">
      <span v-if="isDirectExpense">{{ item.sellCost | usdFormat }}</span>
      <span v-else>{{ item.sellCost | usdFormat }} /hr</span>
    </template>
    <template v-slot:[`item.laborCost`]="{ item }">
      <span v-if="isDirectExpense">{{ item.laborCost | usdFormat }}</span>
      <span v-else>{{ item.laborCost | usdFormat }} /hr</span>
    </template>
    <template v-slot:[`item.total`]="{ item }">
      <!-- {{ item.total | usdFormat }} -->
      <span v-if="showLaborRates" class="font-weight-bold">{{
        (item.hours * item.laborCost) | usdFormat
      }}</span>
      <span v-else class="font-weight-bold">{{ (item.hours * item.sellCost) | usdFormat }}</span>
    </template>
    <template v-slot:no-data>
      <img width="300" src="/img/art/fogg-no-connection-2.png" />
      <p class="font-weight-bold">No Data Available!</p>
    </template>
  </v-data-table>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import ContractStatus from "../../Contracts/components/ContractStatus.vue";
import ConstructionPhaseType from "../../Contracts/components/ConstructionPhaseType.vue";
import UserAllSelector from "../../Shared/components/UserAllSelector.vue";

export default {
  name: "big-board-entry-details-table",
  components: {
    ContractStatus,
    ConstructionPhaseType,
    UserAllSelector,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    recordsList: {
      type: Array,
      default: () => [],
    },
    entryData: {
      type: Object,
      default: null,
    },
    type: {
      type: Number,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    isUsersLoading: {
      type: Boolean,
      default: true,
    },
    rowTotalMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      enums,
      perms,
      sortBy: "project.fullName",
      laborTypesList: enums.LABOR_TYPE,
    };
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        this.$log("customSort", a[index]);
        if (index[0] == "constructionPhaseRecord") {
          if (!isDesc[0]) {
            return this.getLaborTypeObj(a.record.laborType)
              .desc.toLowerCase()
              .localeCompare(this.getLaborTypeObj(b.record.laborType).desc.toLowerCase());
          } else {
            return this.getLaborTypeObj(b.record.laborType)
              .desc.toLowerCase()
              .localeCompare(this.getLaborTypeObj(a.record.laborType).desc.toLowerCase());
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
            } else {
              return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    viewClient(id) {
      this.$emit("viewClient", id);
    },
    displayApprovedMessage(contract) {
      var approvedMsg = document
        .querySelector("#contract-approved-msg-" + contract.id)
        .cloneNode(true);
      var devElms = approvedMsg.querySelectorAll(".v-divider");
      devElms.forEach((dev) => {
        dev.className = "v-divider theme--light my-2 mx-0";
      });
      this.$dialog
        .info({
          title: `'${contract.name}' is approved!`,
          text: approvedMsg.innerHTML,
          color: "success",
        })
        .then((res) => {});
    },
    getLaborTypeObj(val) {
      return this.getEnumMember(this.laborTypesList, val);
    },
    getExpenseCategoryObj(val) {
      return this.getEnumMember(enums.DIRECT_EXPENSE_CATEGORY, val);
    },
  },
  mounted() {
    if (this.rowTotalMode) this.sortBy = "constructionPhase.startDate";
    else this.sortBy = "project.fullName";
  },
  watch: {},
  computed: {
    isDirectExpense() {
      return (
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.laborType == enums.LABOR_TYPE.DirectExpensesRevenue.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesCost.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.DirectExpensesRevenue.value
      );
    },
    isDirectExpenseCost() {
      return this.entryData.isCost;
    },
    showLaborRates() {
      return (
        (this.isMyBoard && this.entryData.rowType == "UserEntry") ||
        this.entryData.laborType == enums.LABOR_TYPE.Labor.value ||
        this.entryData.originalLaborType == enums.LABOR_TYPE.Labor.value
      );
    },
    activeBoard() {
      return this.getEnumMember(enums.BIG_BOARD_TYPE, this.type);
    },
    isBigBoard() {
      return this.type == enums.BIG_BOARD_TYPE.BigBoard.value;
    },
    isSmallBoard() {
      return this.type == enums.BIG_BOARD_TYPE.SmallBoard.value;
    },
    isMyBoard() {
      return this.type == enums.BIG_BOARD_TYPE.MyBoard.value;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "68%";
    },
  },
};
</script>
<style lang="scss">
.entry-totals {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  & > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > span {
      font-weight: 500;
      padding: 0rem 0.35rem;
      border-radius: 0.25rem;
      gap: 0.5rem;
      margin-left: 0.5rem;
    }

    i {
      font-size: 10px;
      margin-right: 8px;
    }
  }

  .val-positive > span {
    color: $info-base;
    background: rgba($info-base, 0.08);
  }

  .val-negative > span {
    color: $accent-base;
    background: rgba($accent-base, 0.08);
  }

  .val-zero > span {
    color: rgba($shades-black, 0.4);

    i {
      color: rgba($shades-black, 0.4);
    }
  }
}
</style>
