<template>
  <div v-if="phase != null && stageObj != null">
    <v-chip
      class="project-stage px-2"
      :small="!small"
      :x-small="small"
      :color="stageObj.color"
      :text-color="stageObj.textColor"
    >
      <i class="fas mr-2" :class="stageObj.icon" style="line-height: 0"></i>
      <span class="font-weight-medium" style="line-height: 0">{{ phase.name }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "phase-item",
  data() {
    return {
      stageObj: null,
    };
  },
  props: {
    phase: {
      type: Object,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateStageObj();
  },

  methods: {
    updateStageObj() {
      if (this.phase != null) {
        this.stageObj = this.getEnumMember(Enums.PROJECT_STAGE, this.phase.status);
      }
    },
  },
  watch: {
    phase: {
      handler() {
        this.updateStageObj();
      },
    },
  },
};
</script>
