<template>
  <v-container fluid style="text-align: center;">
    <img
      width="500"
      style="max-width: 100%"
      src="/img/art/undraw_page_not_found_re_e9o6 (1).svg"
    />
    <h1 class="mt-3" style="font-size: min(6rem, 7ch)">
      {{ label }} Not Found!
    </h1>
    <h3
      class="font-weight-bold text--disabled"
      style="font-size: min(2rem, 3ch)"
    >
      Uh-Oh! Nothing here...
    </h3>
  </v-container>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss"></style>
