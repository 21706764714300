<template>
  <v-card elevation="2" class="pa-2 mb-2" style="width: 100%">
    <v-row dense align="center" v-if="directExpense != null">
      <v-col cols="12" md="5" class="d-flex flex-row">
        <v-text-field-alt
          :rules="[allRules.required]"
          label="Expense Description"
          placeholder="Expense Description"
          style="width: 100%"
          v-model.number="directExpense.description"
          :readonly="readonly || contract.isLocked"
          hide-details
        >
        </v-text-field-alt>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex justify-start pr-2"
        v-if="$has(perms.ProjectContracts.ViewAndUpdateDirectExpensesCost)"
      >
        <v-text-field-alt
          :rules="[allRules.number, allRules.requiredNumber]"
          v-model.number="directExpense.laborCost"
          :readonly="readonly || contract.isLocked"
          number-sign-color
          label="Cost"
          placeholder="Cost"
          prefix="$"
          hide-details
        >
        </v-text-field-alt>
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-start pr-2">
        <v-text-field-alt
          :rules="[allRules.number, allRules.requiredNumber]"
          v-model.number="directExpense.sellCost"
          :readonly="readonly || contract.isLocked"
          number-sign-color
          label="Revenue"
          placeholder="Revenue"
          prefix="$"
          hide-details
          @input="onSellCostInput"
        >
        </v-text-field-alt>
      </v-col>
      <v-col
        cols="12"
        :md="$has(perms.ProjectContracts.ViewAndUpdateDirectExpensesCost) ? 1 : 4"
        class="d-flex justify-end align-end pr-2"
        style="height: 72px"
      >
        <v-btn
          color="red"
          icon
          elevation="0"
          :disabled="readonly"
          v-if="!contract.isLocked"
          @click="deleteDirectExpense"
        >
          <i class="fal fa-trash-alt"></i>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";

export default {
  name: "construction-phase-direct-cost",
  data() {
    return {
      perms,
      enums,
      directExpense: null,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    contract: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isUsersLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.directExpense = this.cloneDeep(this.value);
  },
  methods: {
    deleteDirectExpense() {
      this.$emit("delete");
    },
    onSellCostInput() {
      this.directExpense.laborCost = this.directExpense.sellCost;
    },
  },
  computed: {},
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.directExpense, this.value)) {
          this.directExpense = this.cloneDeep(this.value);
          this.$log("directExpense", this.directExpense);
        }
      },
      deep: true,
    },
    directExpense: {
      handler() {
        if (!this.isEqual(this.directExpense, this.value)) {
          this.$emit("input", this.directExpense);
          this.$log("directExpense", this.directExpense);
        }
      },
      deep: true,
    },
  },
  created() {},
  components: {},
};
</script>

<style lang="scss">
.update-construction-phase-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}
</style>
