import Api from "../../Shared/services/api";
const baseUrl = "ConstructionPhases";
export default {
  query(projectId, options) {
    options.projectId = projectId;
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  cloneConstructionPhase(id, entity) {
    return Api().post(baseUrl + `/${id}/Clone`,entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  unlink(id) {
    return Api().put(baseUrl + `/${id}/Unlink`);
  },
};
