<template>
  <v-container fluid style="text-align: center">
    <img width="500" style="max-width: 100%" src="/img/art/undraw_moonlight_-5-ksn.svg" />
    <h1 class="mt-3" style="font-size: min(5rem, 7ch)">Error Loading {{ label }}!</h1>
    <h3 class="font-weight-bold text--disabled" style="font-size: min(1.5rem, 3ch)">
      Uh-Oh! Something went wrong!
    </h3>
  </v-container>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
