<template>
  <div class="comp-wrpr space-templates-selector" :key="'spaceTemplateSelector'">
    <v-autocomplete-alt
      auto-select-first
      :label="'Space Template'"
      id="spaceTemplate"
      placeholder="No Template Selected!"
      :value="value"
      @input="emitTemplate"
      :items="templates"
      :search-input.sync="options.search"
      dense
      filled
      no-filter
      clearable
      item-value="id"
      item-text="name"
      :loading="isTemplatesLoading"
      :readonly="readonly"
      :menu-props="{ contentClass: 'space-templates-selector-menu' }"
    >
      <template v-for="item in ['selection', 'item']" #[`${item}`]="data">
        <v-row
          :key="data.item.id + '_spaceTemplateSelector_' + item + templates.length"
          align-content="center"
          justify="start"
          no-gutters
          style="flex: none; max-width: calc(100% - 40px); width: 100%; flex-wrap: nowrap"
          class="py-0"
        >
          <v-col
            v-if="false && item == 'item'"
            sm="auto"
            style="width: 20px; margin-left: -0.5rem"
            class="d-flex align-center mr-2 fs-10px opacity-54 mono-font justify-end"
          >
            {{ data.item.i }}
          </v-col>
          <v-col
            sm="auto"
            class="d-flex align-center mr-2"
            :class="item == 'selection' ? 'pl-1' : ''"
          >
            <v-badge color="secondary" bordered left offset-x="15px" offset-y="28px">
              <template v-slot:badge>
                <i class="fal fa-code mb-0" style="font-size: 10px"></i>
              </template>
              <div class="typeahead-space-img">
                <v-img
                  v-if="(data.item.imageUrl != null) & (data.item.imageUrl != '')"
                  class="img"
                  :src="data.item.imageUrl"
                  height="100%"
                  width="100%"
                  contain
                ></v-img>
                <i v-else :class="'fad fa-vector-square'"></i>
              </div>
            </v-badge>
          </v-col>
          <v-col class="d-flex justify-center col-md-12 flex-column">
            <strong class="typeahead-space-name">{{ data.item.name }}</strong>
          </v-col>
        </v-row>
      </template>
      <template #no-data>
        <div class="d-flex flex-column align-center justify-center" v-if="!isTemplatesLoading">
          <i class="fad fa-empty-set my-3" style="font-size: 36px"></i>
          <div>No templates available!</div>
        </div>
        <div class="d-flex flex-column align-center justify-center" v-else></div>
      </template>
      <template #append-item>
        <div v-intersect="onIntersect" v-if="!isEndReached">
          <div
            :class="isTemplatesLoading ? '' : 'opacity-0'"
            class="px-4 py-2 align-center d-flex teal--text font-weight-medium"
          >
            <v-progress-circular
              indeterminate
              color="teal"
              class="mr-3 ml-1"
              :width="1.5"
              :size="16"
            ></v-progress-circular>
            Loading {{ templates.length == 0 ? "" : "more" }} templates...
          </div>
        </div>
      </template>
    </v-autocomplete-alt>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :loading="isTemplatesLoading"
      :disabled="isTemplatesLoading || readonly"
      @click="getData"
    >
      <i class="fas fa-sync"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      @click="viewSpaceTemplate(value)"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fas fa-eye"></i>
    </v-btn>
    <v-btn
      min-width="28px !important"
      width="28px !important"
      height="28px !important"
      color="secondary"
      outlined
      class="pa-0"
      small
      :href="`/space-templates/${value}`"
      target="_blank"
      :disabled="value == null"
      v-if="allowView"
    >
      <i class="fas fa-external-link"></i>
    </v-btn>

    <edit-space-template ref="addSpaceTemplate" nested></edit-space-template>
  </div>
</template>

<script>
import EditSpaceTemplate from "../../Spaces/components/EditSpaceTemplate.vue";
import spaceTemplatesService from "../../Spaces/services/spaceTemplates-service";
export default {
  components: { EditSpaceTemplate },
  props: {
    value: {
      type: [Number, String],
    },
    obj: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    allowView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      templates: [],
      isTemplatesLoading: false,
      templatesTimerId: null,
      total: 0,
      isEndReached: false,
      isIntersecting: false,
      searching: false,
      storedSearch: null,
      options: {
        search: null,
        page: 0,
        sortBy: ["name"],
        sortDesc: [false],
        itemsPerPage: 20,
      },
    };
  },
  mounted() {
    this.storedSearch = null;
    this.isEndReached = false;
    this.templates = [];
  },
  methods: {
    emitTemplate(id) {
      this.$emit("input", id);
      this.$emit("update:obj", id ? this.templates.find((elm) => elm.id == id) : null);
    },
    getData() {
      if (this.storedSearch != this.options.search) {
        this.options.page = 0;
        this.isEndReached = false;
        this.templates = [];
      }
      var optionsToSend = this.cloneDeep(this.options);
      this.storedSearch = optionsToSend.search;

      if (this.isEndReached || this.isTemplatesLoading) return;
      this.isTemplatesLoading = true;

      this.options.page++;
      optionsToSend.page = this.options.page;

      spaceTemplatesService
        .queryLite(this.$clean(optionsToSend, true))
        .then((resp) => {
          if (resp.data.items.length < this.options.itemsPerPage) this.isEndReached = true;
          this.templates.push(...resp.data.items);
          this.templates = this.templates.map((t, key) => {
            return {
              ...t,
              i: key + 1,
            };
          });

          this.total = resp.data.total;
          this.isTemplatesLoading = false;

          this.refreshAutocomplete();
        })
        .catch((error) => {
          this.isTemplatesLoading = false;
          this.options.page--;
        });
    },
    getDataDebounced() {
      if (this.templatesTimerId == null) {
        this.templatesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.templatesTimerId);
      // delay new call 400ms
      this.templatesTimerId = setTimeout(() => {
        this.getData();
      }, 500);
    },
    refreshAutocomplete() {
      if (this.value && (!this.obj || this.value != this.obj.id)) {
        const index = this.templates.findIndex((elm) => elm.id == this.value);
        if (index == -1) {
          this.fetchObj();
        }
      } else {
        if (
          this.obj &&
          (this.templates.findIndex((elm) => elm.id == this.obj.id) == -1 ||
            this.value != this.obj.id)
        ) {
          this.addAndEmit(this.obj);
        }
      }
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getDataDebounced();
      }
    },
    // loadMore(undefine, _, isIntersecting) {

    //   if (isIntersecting && this.total > this.templates.length && !this.isTemplatesLoading) {
    //     // this line to loadMore if there is no search
    //     if (!this.options.search) this.options.page++;
    //     // this line to clear search only if there is item selected
    //     if (this.value) this.options.search = "";
    //   }
    // },
    unshift(item) {
      this.addUniqueArr(this.templates, [item], "id", true);
    },
    viewSpaceTemplate(id) {
      this.$refs.addSpaceTemplate.open(id);
    },
    addAndEmit(item) {
      this.addUniqueArr(this.templates, [item], "id", true);
      this.emitTemplate(item.id);
    },
    fetchObj() {
      spaceTemplatesService.getTemplateById(this.value).then((res) => {
        this.addAndEmit(res.data);
      });
    },
  },
  created() {
    this.getDataDebounced();
  },
  watch: {
    "options.search": {
      handler() {
        this.$log("WATCH options.search");
        this.getDataDebounced();
      },
    },
    obj() {
      this.refreshAutocomplete();
    },
    value() {
      this.refreshAutocomplete();
    },
  },
};
</script>
<style lang="scss">
.comp-wrpr.space-templates-selector {
  .v-autocomplete-alt {
    width: calc(100% - 40px);
    flex: 1 1 auto;
    overflow: hidden;
  }
}

.space-templates-selector-menu {
  max-width: 500px;
}

.typeahead-space-name {
  max-width: calc(100% - 40px) !important;
}

.typeahead-space-description {
  max-width: calc(100% - 40px) !important;
}
</style>
