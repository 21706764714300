<template>
  <div v-if="visibility != null && visibilityObj != null">
    <v-chip
      class="team-visibility px-2"
      :small="!small"
      :x-small="small"
      :color="visibilityObj.color || 'secondary'"
      :text-color="visibilityObj.textColor || 'white'"
    >
      <i
        class="fas mr-2"
        v-if="visibilityObj.icon != null"
        :class="visibilityObj.icon"
        style="line-height: 0"
      ></i>
      <span class="font-weight-medium" style="line-height: 0">{{
        visibilityObj.text.toUpperCase()
      }}</span>
    </v-chip>
  </div>
</template>

<script>
import Enums from "../../../plugins/enums";
export default {
  name: "ms-team-visibility",
  data() {
    return {
      visibilityObj: null,
    };
  },
  props: {
    visibility: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: null,
    },
  },
  mounted() {
    this.updateVisibilityObj();
  },
  methods: {
    updateVisibilityObj() {
      if (this.visibility != null) {
        this.visibilityObj = this.getEnumMember(Enums.MS_TEAM_VISIBILITY, this.visibility);
      }
    },
  },
  watch: {
    visibility: {
      handler() {
        this.updateVisibilityObj();
      },
    },
  },
};
</script>

<style lang="scss"></style>
