<template>
  <!-- <div :id="`projectSpace${projectId}`"> -->
  <!-- </div> -->
  <v-container fluid class="pa-3">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        overflow: auto;
        overflow: overlay;
      "
      class="pa-3 pt-0 d-flex flex-column inset-shadow"
      v-if="projectId != null && projectId != '' && projectId != NaN"
    >
      <v-card
        class="pa-2 align-self-center"
        elevation="5"
        color="#ECEFF1"
        :width="idle ? 'max(40%, 500px)' : '1350px'"
        :height="idle ? '48px' : '230px'"
        :class="idle ? '' : 'ml-4'"
        style="
          position: sticky;
          top: 0;
          z-index: 10;
          border-radius: 0 0 0.5rem 0.5rem;
          transition: all 0.2s ease-out;
          border: 1px solid #b0bec5;
          --background: linear-gradient(#ffffff 0%, #eceff1);
          border-top: none;
        "
      >
        <v-scroll-y-transition mode="out-in">
          <div class="d-flex align-center justify-space-between" v-if="idle" key="idle">
            <h3 class="font-weight-black ml-2 mb-0 d-inline-block">Project Notes</h3>
            <div class="d-flex align-center" style="gap: 0.5rem">
              <v-text-field
                v-model="options.search"
                label="Search"
                ref="mainSearch"
                class="table-search-field"
                dense
                solo
                hide-details
                clearable
                prepend-inner-icon="fas fa-search"
                :loading="searching"
                style="max-width: 200px"
              >
              </v-text-field>
              <v-btn
                color="info"
                @click="addNote"
                :disabled="loading || saving"
                height="32px !important"
                min-height="32px !important"
              >
                <i class="fas fa-plus mr-2" style="line-height: 0"></i> New Note
              </v-btn>
            </div>
          </div>
          <rich-text-editor
            ref="richTextEditor"
            key="not-idle"
            v-else
            v-model="selected.body"
            :title="selected.id == null ? 'New Note' : 'Update Note'"
            showLabel
            maxHeight="120px"
            class="ma-2"
          >
            <template v-slot:label-append>
              <div class="d-flex align-center" style="gap: 0.5rem">
                <v-btn
                  color="info"
                  x-small
                  @click="saveNote"
                  :loading="saving"
                  :disabled="
                    loading ||
                    saving ||
                    selected.body == null ||
                    selected.body.trim() == '<p></p>' ||
                    selected.body.trim() == ''
                  "
                >
                  <i class="fas fa-check mr-2" style="font-size: 12px; line-height: 0"></i>
                  {{ selected.id == null ? "Create" : "Update" }}
                </v-btn>
                <v-btn color="secondary" x-small @click="discard">
                  <i class="fas fa-times mr-2" style="font-size: 12px; line-height: 0"></i>
                  Discard
                </v-btn>
              </div>
            </template>
          </rich-text-editor>
        </v-scroll-y-transition>
      </v-card>
      <div
        style="height: 100%; display: flex; flex-direction: column; margin-top: -48px"
        class="pa-3 pt-0"
      >
        <v-timeline class="notes-timeline" align-top dense>
          <v-timeline-item v-for="(item, i) in notes" :key="i" color="white" right fill-dot>
            <template v-slot:icon>
              <user-avatar
                style="z-index: 100"
                :user="item.createdBy"
                icon
                :mini="false"
              ></user-avatar>
            </template>
            <v-card>
              <v-card-title class="py-2 pr-2 d-flex justify-space-between" style="100%">
                <span class="fs-12px opacity-54"
                  ><dater :date="item.createDate" no-tooltip></dater
                ></span>
                <v-menu dense offset-x left v-if="!readonly && isNoteOwner(item)">
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn icon elevation="0" v-bind="attrs" v-on="on" :disabled="readonly">
                      <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                    </v-btn>
                  </template>

                  <v-list class="more-options-menu">
                    <v-list-item @click="updateNote(item)" v-if="$has(perms.ProjectNotes.Update)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small class="secondary--text">far fa-pen</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Update</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="delNote(item)" v-if="$has(perms.ProjectNotes.Delete)">
                      <v-list-item-icon class="mr-2 justify-center">
                        <v-icon small class="red--text">far fa-trash-alt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="red--text">Delete</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <div
                  class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 secondary--text"
                  v-html="
                    item.body != null && item.body != ''
                      ? item.body
                      : `<i class='fad fa-empty-set opacity-64'></i>`
                  "
                />
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="!isEndReached"
            :key="'end-not-reached'"
            color="white"
            right
            fill-dot
          >
            <template v-slot:icon>
              <i class="fad fa-spinner-third fa-spin"></i>
            </template>
            <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-0">
              Fetching more notes...
            </v-card-title>
          </v-timeline-item>
          <v-timeline-item
            v-else
            :key="'end-reached'"
            class="align-center"
            color="white"
            right
            fill-dot
          >
            <template v-slot:icon>
              <i
                v-if="notes.length == 0 && options.search != null && options.search.trim != ''"
                class="fas fa-xmark"
              ></i>
              <i v-else-if="notes.length == 0" class="fad fa-empty-set"></i>
              <i v-else class="fad fa-flag-pennant"></i>
            </template>
            <v-card-title
              v-if="notes.length == 0 && options.search != null && options.search.trim != ''"
              class="fs-14px pa-0 text-secondary"
            >
              No matching notes for your search: <b class="ml-2">{{ options.search }}</b>
            </v-card-title>
            <v-card-title v-else-if="notes.length == 0" class="fs-14px pa-0 text-secondary">
              No notes added yet!
            </v-card-title>
            <v-card-title v-else class="fs-14px pa-0 text-secondary"> End of Notes </v-card-title>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
  </v-container>
</template>

<script>
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import projectNotesService from "../services/projectNotes-service";
import perms from "../../../plugins/permissions";
import enums from "../../../plugins/enums";

export default {
  name: "project-notes",
  data() {
    return {
      perms,
      enums,
      idle: true,
      isLogsInitiallyLoaded: false,
      isEndReached: false,
      selected: {
        projectId: null,
        body: null,
      },
      notes: [],
      total: 0,
      notesTimerId: null,
      loading: false,
      saving: false,
      storedSearch: null,
      searching: false,
      options: {
        search: null,
        projectId: null,
        page: 0,
        itemsPerPage: 10,
        sortBy: ["createDate"],
        sortDesc: [true],
        createdByIds: [],
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getProjectNotes();
  },
  methods: {
    isNoteOwner(note) {
      if (note.createdBy.id == this.loggedInUser.id) return true;
      return false;
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getProjectNotes();
      }
    },
    addNote() {
      this.selected = {
        projectId: this.projectId,
        id: null,
        body: null,
      };
      this.idle = false;
      setTimeout(() => {
        this.$refs.richTextEditor.focus();
      }, 500);
    },
    discard() {
      this.idle = true;
      this.selected = {
        projectId: this.projectId,
        id: null,
        body: null,
      };
    },
    saveNote() {
      this.saving = true;
      projectNotesService
        .update(this.cloneDeep(this.selected))
        .then((resp) => {
          this.saving = false;
          if (this.selected.id == null) {
            this.addToArr(this.notes, resp.data, true);
          } else {
            this.updateArr(this.notes, resp.data);
          }
          this.discard();
        })
        .catch((err) => {
          this.saving = false;
          this.$handleError(err);
        });
    },
    getProjectNotes() {
      this.$log(">>>> getProjectNotes - this.isEndReached", this.isEndReached);
      this.options.page++;
      var optionsToSend = this.cloneDeep(this.options);
      optionsToSend.projectId = this.projectId;
      if (this.storedSearch != optionsToSend.search) {
        optionsToSend.page = 1;
        this.isEndReached = false;
        this.notes = [];
      }
      this.storedSearch = optionsToSend.search;

      if (this.isEndReached || this.saving || this.loading || this.searching) return;

      this.loading = true;
      if (optionsToSend.search != null && optionsToSend.search.trim() != "") this.searching = true;

      projectNotesService
        .query(this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isLogsInitiallyLoaded) this.isLogsInitiallyLoaded = true;
          if (resp.data.items.length < this.options.itemsPerPage) this.isEndReached = true;
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            this.notes.push(element);
          });
          this.total = resp.data.total;
          this.loading = false;
          this.searching = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getProjectNotes();
            }
          }, 500);
        })
        .catch((err) => {
          this.loading = false;
          this.searching = false;
          this.$handleError(err);
        });
    },
    updateNote(note) {
      this.selected = this.cloneDeep(note);
      this.idle = false;
      setTimeout(() => {
        this.$refs.richTextEditor.focus();
      }, 350);
    },
    delNote(noteObj) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this note?`,
          title: `Delete Note?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return projectNotesService
                  .delete(noteObj.id)
                  .then((resp) => {
                    this.onDeleteNoteSuccess(noteObj.id);
                    this.$dialog.notify.success("Note deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the note!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteNoteSuccess(id) {
      const index = this.notes.indexOf(this.notes.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.notes.splice(index, 1);
      }
    },
    getProjectNotesDebounced(id) {
      // if (this.notesTimerId == null) {
      //   this.notesTimerId = -1;
      //   this.getProjectNotes();
      //   return;
      // }
      // cancel pending call
      clearTimeout(this.notesTimerId);

      // delay new call 400ms
      this.notesTimerId = setTimeout(() => {
        this.getProjectNotes();
      }, 400);
    },
  },
  watch: {
    "options.search": {
      handler() {
        this.$log("WATCH options.search");
        this.getProjectNotesDebounced();
      },
    },
  },
  components: { RichTextEditor },
};
</script>

<style lang="scss">
.project-spaces-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.template-link-alert .v-alert__icon {
  align-self: center;
}

.fake-text-input {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba($shades-black, 0.06);
  padding: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  color: rgba($shades-black, 0.54);
}

.v-timeline.notes-timeline {
  padding-top: 64px;

  &:before {
    height: calc(100% + 100px);
    height: calc(100% - 34px); //minus the top: 10px + margin-bottom: 24px; shift
    top: 10px !important;
  }

  .v-timeline-item:first-child {
    &::before {
      content: "";
      background: #90a4ae;
      position: absolute;
      left: 43px;
      top: 10px;
      width: 10px;
      height: 10px;
      border-radius: 10px 10px 10px 10px;
    }
  }
  .v-timeline-item:last-child {
    padding-bottom: 0 !important;
    margin-bottom: 24px;
  }
}
</style>
