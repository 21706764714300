import Api from "../../Shared/services/api";

const baseUrl = "projectNotes";
export default {
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  get(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
};
