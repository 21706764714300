var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":("projectPhase" + _vm.projectId)}},[(_vm.projectId != null && _vm.projectId != '' && _vm.projectId != NaN)?_c('v-container',{staticClass:"pa-5 pt-2",attrs:{"fluid":""}},[_c('h3',{staticClass:"text--primary font-weight-black"},[_vm._v(" Total Phases: "),_c('v-chip',{staticClass:"ml-2 font-weight-bold",attrs:{"small":"","label":"","loading":_vm.loadingStates.table}},[_vm._v(_vm._s(_vm.total))])],1),_c('div',{staticClass:"px-5 pt-2 pb-5 mt-3 inset-shadow",staticStyle:{"background":"#eceff1","border-radius":"0.5rem"}},[_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"d-flex align-start",staticStyle:{"gap":"0.5rem"},attrs:{"lg":"3","sm":"12","cols":"12"}},[(!_vm.readonly)?_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.add}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("New Phase")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"lg":"9","md":"12","sm":"12","cols":"12"}},[_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getProjectPhasesDebounced()}}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
              'max-width':
                _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                  ? '200px'
                  : '110px',
            }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 project-phases-table",attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loadingStates.table,"items-per-page":_vm.options.itemsPerPage,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30, 50, 100],
        },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"ma-0"},[_vm._v(_vm._s(item.name))])]}},{key:"item.number",fn:function(ref){
        var item = ref.item;
return [_c('h4',{staticClass:"ma-0"},[_vm._v(_vm._s(item.number))])]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.createdBy,"icon":""}})]}},{key:"item.updatedBy",fn:function(ref){
        var item = ref.item;
return [_c('user-avatar',{attrs:{"user":item.updatedBy,"icon":""}})]}},{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [_c('dater',{attrs:{"date":item.startDate,"dateonly":""}})]}},{key:"item.endDate",fn:function(ref){
        var item = ref.item;
return [_c('dater',{attrs:{"date":item.endDate,"dateonly":""}})]}},{key:"item.poReceiveDate",fn:function(ref){
        var item = ref.item;
return [_c('dater',{attrs:{"date":item.poReceiveDate,"dateonly":""}})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('project-stage',{attrs:{"small":true,"stage":item.status}})]}},{key:"item.totalAuthorizedFees",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("usdFormat")(item.totalAuthorizedFees)))])]}},{key:"item.tempoPlannedTimeLink",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":"","target":"_blank","href":item.tempoPlannedTimeLink},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("far fa-external-link")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.tempoPlannedTimeLink))])])]}},{key:"item.tempoLoggedTimeLink",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","target":"_blank","href":item.tempoLoggedTimeLink},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("far fa-external-link")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(item.tempoLoggedTimeLink))])])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-left"},[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","elevation":"0","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();return _vm.update(item.id)}}},[_c('i',{staticClass:"fal fa-pen"})]),_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[_c('v-list-item',{attrs:{"disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();return _vm.del(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{class:_vm.$has(_vm.perms.Projects.Update) ? '' : 'opacity-54',attrs:{"small":""}},[_vm._v("fal fa-trash-alt red--text")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text",class:_vm.$has(_vm.perms.Projects.Update) ? '' : 'opacity-54'},[_vm._v("Delete")])],1)],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"300","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1),_c('edit-project-phase',{ref:"editProjectPhase",attrs:{"project-id":_vm.projectId},on:{"save":_vm.onPhaseSaved,"close":_vm.onPhasePanelClosed}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }