<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold">
        <i class="fad fa-link mr-2"></i>Link Contacts to This {{ name }}
      </h3>
      <v-btn @click="slideoutsDiscard()" icon><i class="far fa-times"></i></v-btn>
    </template>
    <v-container fluid class="pa-3" style="height: 100%; display: flex; flex-direction: column">
      <div
        style="
          background: #eceff1;
          border-radius: 0.5rem;
          height: 100%;
          display: flex;
          flex-direction: column;
        "
        class="pa-5 inset-shadow"
      >
        <div style="display: flex; justify-content: space-between">
          <v-btn
            style="height: 36px !important"
            :disabled="readonly"
            color="info"
            @click="openAddContact()"
          >
            <i class="fal fa-plus mr-2"></i>Create New Contact
          </v-btn>
          <div class="justify-end d-flex mb-4" style="gap: 0.5rem">
            <filter-menu
              :unshiftList="selected.client ? [selected.client] : []"
              title="Client"
              height="38"
              v-model="options.Clients"
              id="client"
              baseUrl="clients"
              :multiple="true"
            >
              <template #[`item`]="{ item }">
                <v-list-item-avatar class="mr-3 my-0" rounded>
                  <v-img
                    height="25"
                    width="25"
                    v-if="item.logoUrl != null && item.logoUrl != ''"
                    :src="item.logoUrl"
                    class="img"
                    position="center center"
                    contain
                  ></v-img>
                  <v-img
                    v-else
                    src="/img/DNA_Symbol.png"
                    height="25"
                    width="25"
                    position="center center"
                    contain
                  >
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </filter-menu>
            <v-text-field
              v-model="options.search"
              label="Search Contacts"
              dense
              solo
              hide-details
              clearable
              prepend-inner-icon="far fa-search"
              :style="{
                'max-width': $vuetify.breakpoint.lgAndUp ? '250px' : 'auto',
              }"
            >
            </v-text-field>
          </div>
        </div>
        <v-data-table
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
          dense
          :headers="headers"
          v-model="slideouts.data"
          :items="entities"
          show-select
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="15"
          :loading="loadingStates.table"
          class="elevation-2 table-sticky-header-exclude-footer"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'far fa-arrow-to-left',
            lastIcon: 'far fa-arrow-to-right',
            prevIcon: 'far fa-angle-left',
            nextIcon: 'far fa-angle-right',
            itemsPerPageOptions: [15, 30, 50, 100],
          }"
          item-key="id"
        >
          <template v-slot:no-data>
            <img width="300" src="/img/art/fogg-no-connection-2.png" />
            <p class="font-weight-bold">No Data Available!</p>
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            <v-row style="margin: 5px 0px">
              <user-avatar :user="item" hide-roles disable-open-link></user-avatar>
            </v-row>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <phone-number v-model="item.phone"></phone-number>
          </template>
          <template v-slot:[`item.addressLine1`]="{ item }">
            <address-summary :value="item"></address-summary>
          </template>
          <template v-slot:[`item.contactState`]="{ item }">
            {{ $getTextByValue(item.contactState, states) }}
          </template>
          <template v-slot:[`item.company`]="{ item }">
            <v-tooltip top nudge-top="-16px">
              <template v-slot:activator="{ on, attrs }">
                <div class="pa-2" v-bind="attrs" v-on="on">
                  <v-img
                    v-if="item.company.logoUrl != null && item.company.logoUrl != ''"
                    :src="item.company.logoUrl"
                    max-height="28"
                    max-width="54"
                    position="left center"
                    contain
                  >
                  </v-img>
                  <v-img
                    v-else
                    src="/img/DNA_Symbol.png"
                    max-height="28"
                    max-width="54"
                    position="left center"
                    contain
                  >
                  </v-img>
                </div>
              </template>
              <span>{{ item.company.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:loading>
            <video width="250" muted loop autoplay style="padding: 0.5rem">
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Searching the Cosmos...</p>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="slideoutsDiscard()"> <i class="fal fa-chevron-left mr-2"></i>Discard </v-btn>
        <v-btn color="info" :disabled="!slideouts.data.length" @click="slideoutsConfirmed()">
          <i class="fal fa-check mr-2"></i> Add
        </v-btn>
      </v-card-actions>
    </template>
    <edit-contact
      @save="onSaveContact"
      @onClose="onCloseEditContact"
      ref="editContactComponent"
      nested
    >
    </edit-contact>
  </slideout>
</template>
<script>
import ContactServices from "../../../Companies/Clients/services/contacts-service";
import Enums from "../../../../plugins/enums";
import clientContactHeader from "../../../Companies/Clients/config/tables/clientContact.header";
import editContact from "./EditContact.vue";

export default {
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
    },
    name: {
      type: String,
      default: "Lead",
    },
  },
  data() {
    return {
      slideouts: {
        active: false,
        data: [],
        hasChanges: false,
      },
      headers: this.cloneDeep(clientContactHeader).splice(1, clientContactHeader.length - 1),
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["id"],
        sortDesc: [true],
        Clients: [],
      },
      loadingStates: {
        table: false,
      },
      entities: [],
      total: 0,
      contactTimer: null,
      states: this.$options.filters.EnumToList(Enums.clientStates),
    };
  },

  methods: {
    open() {
      this.slideouts.active = true;
      this.options.Clients = [];
    },
    openAddContact() {
      this.$refs.editContactComponent.open();
    },
    slideoutsDiscard() {
      this.slideouts.active = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }
      //reset the changes
      this.slideouts.data = [];
      this.$releaseChanges();
      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    slideoutsConfirmed() {
      this.$emit("confirm", this.slideouts.data);
      this.slideouts.data = [];
      this.slideouts.hasChanges = false;
      this.slideoutsDiscard();
    },
    checkForChanges() {
      if (!this.slideouts.active) return;
      this.slideouts.hasChanges = this.slideouts.data.length > 0;
      if (this.slideouts.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    getDataDebounced() {
      if (this.contactTimer == null) {
        this.contactTimer = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.contactTimer);

      // delay new call 400ms
      this.contactTimer = setTimeout(() => {
        this.getData();
      }, 400);
    },
    getData() {
      this.loadingStates.table = true;
      this.$backToTop();
      var toSend = this.$clean(this.options, true);
      this.$log(">>>>>>>>> toSend", toSend);
      ContactServices.queryAll(toSend)
        .then((resp) => {
          this.entities = resp.data.items;
          this.total = resp.data.total;
          this.loadingStates.table = false;
          if (this.selected.contact) {
            var itemSelected = this.entities.filter((e) => e.id == this.selected.contact.id);
            if (itemSelected && itemSelected.length > 0) this.slideouts.data.push(itemSelected[0]);
          }
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    onCloseEditContact() {},
    onSaveContact(selected) {
      this.getData();
      this.$log(selected);
      this.selected.contact = selected;
    },
  },
  watch: {
    "slideouts.data": {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },

    options: {
      handler() {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { editContact },
};
</script>
