<template>
  <!-- <div :id="`projectSpace${projectId}`"> -->
  <!-- </div> -->
  <v-container fluid class="pa-3" v-if="projectId != null && projectId != '' && projectId != NaN">
    <div
      style="
        background: #eceff1;
        border-radius: 0.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
      "
      class="pa-3 pt-0 inset-shadow"
    >
      <v-row class="mt-0 mb-1" justify="space-between" align="center">
        <v-col lg="4" sm="12" cols="12" class="d-flex align-center" style="gap: 0.5rem">
          <h3 class="text--primary font-weight-black ma-0">
            Spaces:
            <v-chip class="ml-2 font-weight-bold" small label :loading="loadingStates.table">{{
              total
            }}</v-chip>
          </h3>

          <v-menu dense offset-y bottom v-if="!readonly">
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="info" class="ml-2" v-bind="attrs" v-on="on">
                <span class="fs-12px"
                  ><i class="far fa-plus mr-2"></i>New<i class="fas fa-caret-down ml-4"></i
                ></span>
              </v-btn>
            </template>
            <v-list class="more-options-menu">
              <v-list-item @click="add(false)">
                <v-list-item-icon class="mr-2 justify-center">
                  <i class="fad fa-table-pivot secondary--text"></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text font-weight-medium"
                    >Space</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="add(true)">
                <v-list-item-icon class="mr-2 justify-center">
                  <i class="fad fa-grid-2-plus secondary--text"></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text font-weight-medium"
                    >Batch</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="openCloneAnotherProjectSpaceModal()">
                <v-list-item-icon class="mr-2 justify-center">
                  <i class="fad fa-clone secondary--text"></i>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="secondary--text font-weight-medium"
                    >Clone from Another Project</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          lg="8"
          md="12"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <filter-manager
            ref="filterManager"
            v-model="selectedFilters"
            :options.sync="options"
          ></filter-manager>
          <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />

          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
          <refresh :loading="loadingStates.table" @refresh="getProjectSpacesDebounced()"></refresh>
        </v-col>
      </v-row>
      <v-data-table
        style="
          overflow: auto;
          overflow: overlay;
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        "
        dense
        :headers="tableHeader"
        :items="entities"
        :options.sync="options"
        :server-items-length="total"
        :loading="loadingStates.table"
        :items-per-page="options.itemsPerPage"
        :item-class="spaceClass"
        class="elevation-2 project-spaces-table table-sticky-header-exclude-footer"
        @click:row="rowClicked"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageText: 'Spaces per page:',
          firstIcon: 'far fa-arrow-to-left fs-14px',
          lastIcon: 'far fa-arrow-to-right fs-14px',
          prevIcon: 'far fa-angle-left fs-14px',
          nextIcon: 'far fa-angle-right fs-14px',
          itemsPerPageOptions: [15, 30, 50, 100],
        }"
        item-key="id"
      >
        <template v-slot:progress>
          <v-progress-linear color="blue" absolute top indeterminate></v-progress-linear>
        </template>

        <template v-slot:[`header.viewed`]>
          <span class="d-flex align-center">
            <span>Viewed</span>
            <v-btn icon color="orange darken-2" small @click="clearAllProjectsSpaces">
              <i class="fas fa-undo"></i
            ></v-btn>
          </span>
        </template>

        <template v-slot:[`header.actions`]>
          <i class="fad fa-cog pl-2"></i>
        </template>

        <template v-slot:[`item.imageUrl`]="{ item }">
          <v-badge
            color="info"
            left
            :value="false && selected.id == item.id"
            offset-x="15px"
            offset-y="38px"
          >
            <template v-slot:badge>
              <i class="fas fa-wrench mb-0"></i>
            </template>
            <v-tooltip right z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div class="space-img">
                    <div
                      style="height: 100%; width: 100%"
                      v-viewer
                      @click.stop
                      v-if="item.imageUrl != null && item.imageUrl != ''"
                    >
                      <img
                        :key="item.id + '_img'"
                        :src="item.imageUrl"
                        height="100%"
                        width="100%"
                      />
                    </div>
                    <i v-else :class="'fad fa-vector-square'"></i>
                  </div>
                </div>
              </template>
              <span>
                <v-img
                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                  class="img"
                  :src="item.imageUrl"
                  height="250px"
                  width="250px"
                  contain
                ></v-img>
                <i
                  v-else
                  :class="'fad fa-vector-square'"
                  style="font-size: 32px; padding: 2rem"
                ></i>
              </span>
            </v-tooltip>
          </v-badge>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <strong>
            <i class="fad fa-grid-2-plus fs-15px deep-purple--text mr-2" v-if="item.isBatch"></i>
            {{ item.name }}</strong
          >
          <!-- <p class="ma-0" v-if="item.description != null && item.description.trim() != ''">
              {{ item.description }}
            </p> -->
        </template>

        <template v-slot:[`item.spaceNumber`]="{ item }">
          <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <status-item small :statusList="statusList" :status="item.state"></status-item>
        </template>

        <template v-slot:[`item.template.name`]="{ item }">
          <v-tooltip top z-index="999" nudge-top="-8px" v-if="item.template != null">
            <template v-slot:activator="{ on, attrs }">
              <v-card
                rounded="lg"
                v-bind="attrs"
                v-on="on"
                @click.stop="openSpaceTemplate(item.template.id)"
                class="d-flex align-center justify-start px-1"
              >
                <v-badge color="secondary" bordered left offset-x="10px" offset-y="32px">
                  <template v-slot:badge>
                    <i class="fad fa-code mb-0" style="font-size: 10px"></i>
                  </template>
                  <div class="typeahead-space-img">
                    <v-img
                      v-if="item.template.imageUrl != null && item.template.imageUrl != ''"
                      class="img"
                      :src="item.template.imageUrl"
                      height="100%"
                      width="100%"
                      contain
                    ></v-img>
                    <i v-else :class="'fad fa-vector-square'"></i>
                  </div>
                </v-badge>
                <strong class="space-name pl-1" style="font-size: 12px">{{
                  item.template.name
                }}</strong>
              </v-card>
            </template>
            <span> View Space Template </span>
          </v-tooltip>
          <span v-else class="text--disabled">
            <i class="fad fa-unlink"></i>
          </span>
        </template>

        <template v-slot:[`item.iavSheetCount`]="{ item }">
          <span
            class="mono-font font-weight-bold"
            :class="{
              'text--disabled': item.iavSheetCount == 0,
              'info--text': item.iavSheetCount != 0,
            }"
            >{{ item.iavSheetCount }}
          </span>
        </template>

        <template v-slot:[`item.scdSheetCount`]="{ item }">
          <span
            class="mono-font font-weight-bold"
            :class="{
              'text--disabled': item.scdSheetCount == 0,
              'info--text': item.scdSheetCount != 0,
            }"
            >{{ item.scdSheetCount }}
          </span>
        </template>

        <template v-slot:[`item.planSheetCount`]="{ item }">
          <span
            class="mono-font font-weight-bold"
            :class="{
              'text--disabled': item.planSheetCount == 0,
              'info--text': item.planSheetCount != 0,
            }"
            >{{ item.planSheetCount }}
          </span>
        </template>

        <template v-slot:[`item.overviewSheetCount`]="{ item }">
          <span
            class="mono-font font-weight-bold"
            :class="{
              'text--disabled': item.overviewSheetCount == 0,
              'info--text': item.overviewSheetCount != 0,
            }"
            >{{ item.overviewSheetCount }}
          </span>
        </template>

        <template v-slot:[`item.detailSheetCount`]="{ item }">
          <span
            class="mono-font font-weight-bold"
            :class="{
              'text--disabled': item.detailSheetCount == 0,
              'info--text': item.detailSheetCount != 0,
            }"
            >{{ item.detailSheetCount }}
          </span>
        </template>

        <template v-slot:[`item.createdBy`]="{ item }">
          <user-avatar :user="item.createdBy" icon></user-avatar>
        </template>

        <template v-slot:[`item.updatedBy`]="{ item }">
          <user-avatar :user="item.updatedBy" icon></user-avatar>
        </template>

        <template v-slot:[`item.createDate`]="{ item }">
          <dater :date="item.createDate" dateonly></dater>
        </template>

        <template v-slot:[`item.updateDate`]="{ item }">
          <dater :date="item.updateDate" dateonly></dater>
        </template>

        <template v-slot:[`item.equipmentCount`]="{ item }">
          <equipment-count :count="item.equipmentCount" />
        </template>

        <template v-slot:[`item.viewed`]="{ item }">
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-flex">
                <v-btn color="teal darken-1" icon small @click.stop="onSpaceOpenClicked(item)">
                  <i class="fas fa-eye fs-15px" v-if="item.viewed"></i>
                  <i class="fal fa-eye secondary--text fs-15px" v-else></i>
                </v-btn>
              </div>
            </template>
            <span v-if="!item.viewed">Click to mark as <u>Viewed</u></span>
            <span v-else>Click to mark as <u>Not Viewed</u></span>
          </v-tooltip>
          <v-tooltip right z-index="999" nudge-right="-4px" v-if="lastViewedSpaceId == item.id">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="d-inline-flex ml-2">
                <i class="fas fa-flag-pennant orange--text fs-15px"></i>
              </div>
            </template>
            <span>Last Viewed Space</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex align-center">
            <v-menu dense offset-x right>
              <template v-slot:activator="{ attrs, on }">
                <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
                  <i class="far fa-ellipsis-v" style="font-size: 15px"></i>
                </v-btn>
              </template>
              <v-list class="more-options-menu">
                <v-list-item @click="update(item.id)" :disabled="readonly">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon
                      class="secondary--text"
                      small
                      :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                    >
                      far fa-pen
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="secondary--text"
                      :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                    >
                      Update
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="openCloneModal(item)" :disabled="readonly">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon
                      class="secondary--text"
                      small
                      :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                    >
                      far fa-clone
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="secondary--text"
                      :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                    >
                      Clone
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-2"></v-divider>
                <v-list-item @click="displayActivityLogs(item)">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small class="secondary--text">far fa-history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="secondary--text"> Activity Logs </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="displayChangesSince(item)" v-if="false">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small class="secondary--text">far fa-calendar-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="secondary--text">
                      View Changes Since
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item @click="displaySpaceAggregatedChange(item)">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small class="secondary--text">far fa-calendar-clock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="secondary--text">
                      Aggregated Changes
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="my-2"></v-divider>
                <v-list-item @click="del(item)" :disabled="readonly">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small :class="$has(perms.Projects.Delete) ? '' : 'opacity-54'">
                      far fa-trash-alt red--text
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="red--text"
                      :class="$has(perms.Projects.Delete) ? '' : 'opacity-54'"
                      >Delete</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template v-slot:no-data>
          <img width="300" src="/img/art/fogg-no-connection-2.png" />
          <p class="font-weight-bold">No Data Available!</p>
        </template>

        <template v-slot:loading>
          <video
            width="250"
            muted
            loop
            autoplay
            class="inset-shadow-video mt-4"
            style="padding: 0.5rem; border-radius: 10rem; background: #fff"
          >
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold mt-1">Searching the Cosmos...</p>
        </template>
      </v-data-table>
    </div>
    <edit-project-space
      ref="editProjectSpace"
      :project-id="projectId"
      @save="onSpaceSaved"
      @fetch="onSpaceFetch"
      @close="onSpacePanelClosed"
      @clone="addToSpaces"
      @delete="onDeleteSpaceSuccess"
    ></edit-project-space>
    <edit-space-template ref="editTemplate"></edit-space-template>
    <clone-space @done="addToSpaces" ref="cloneSpaceModal"></clone-space>
    <clone-another-project-space
      @done="addToSpaces"
      ref="cloneAnotherProjectSpaceModal"
    ></clone-another-project-space>
    <space-activity-log ref="spaceActivityLog"></space-activity-log>
    <space-aggregated-change ref="spaceAggregatedChange"></space-aggregated-change>
    <space-changes-since ref="showChangesSince"></space-changes-since>
  </v-container>
</template>

<script>
import projectService from "../../Projects/services/projects-service";
import spaceService from "../services/spaces-service";
import EditProjectSpace from "../components/EditProjectSpace.vue";
import EditSpaceTemplate from "../../Spaces/components/EditSpaceTemplate.vue";
import CloneSpace from "./CloneSpace.vue";
import CloneAnotherProjectSpace from "./CloneAnotherProjectSpace.vue";
import perms from "../../../plugins/permissions";
import projectSpaceHeader from "../../Spaces/config/tables/projectSpace.header";
import Enums from "../../../plugins/enums";
import SpaceActivityLog from "../components/activityLogs/SpaceActivityLog.vue";
import SpaceChangesSince from "../components/activityLogs/SpaceChangesSince.vue";
import SpaceAggregatedChange from "../components/activityLogs/SpaceAggregatedChange.vue";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  SpaceTemplateFilterSettings,
  LevelFilterSettings,
  BuildingFilterSettings,
  ProjectSpaceCreatedByFilterSettings,
  ProjectSpaceUpdatedByFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";

export default {
  name: "project-spaces",
  components: {
    EditProjectSpace,
    EditSpaceTemplate,
    CloneSpace,
    SpaceActivityLog,
    SpaceChangesSince,
    CloneAnotherProjectSpace,
    SpaceAggregatedChange,
    FilterManager,
  },
  data() {
    return {
      perms: perms,
      statusList: Enums.SPACE_STATUS,
      selectedFilters: [],
      storageKey: "ProjectSpaces",
      openSpaceInEditMode: false,
      entities: [],
      levels: [],
      selected: {},
      buildings: [],
      total: 0,
      search: "",
      lastViewedSpaceId: null,
      projectSpacesTimerId: null,
      mainSearchInFocus: false,
      options: {
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        createdByIds: [],
        updatedByIds: [],
        buildingIds: [],
        levelIds: [],
        spaceTemplateIds: [],
      },
      loadingStates: {
        table: false,
      },
      openSpaceAsReadOnly: false,
      slideouts: {},
      modals: {},
      headers: projectSpaceHeader,
      defaultHeaders: [],
    };
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    this.checkForSingleSpaceRoute(this.$route);
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  methods: {
    initFilterManagerSettings() {
      var spaceTemplateFilterSettings = new SpaceTemplateFilterSettings();
      spaceTemplateFilterSettings.projectId = this.projectId;

      var levelFilterSettings = new LevelFilterSettings();
      levelFilterSettings.projectId = this.projectId;

      var buildingFilterSettings = new BuildingFilterSettings();
      buildingFilterSettings.projectId = this.projectId;

      var projectSpaceCreatedByFilterSettings = new ProjectSpaceCreatedByFilterSettings();
      projectSpaceCreatedByFilterSettings.projectId = this.projectId;

      var projectSpaceUpdatedByFilterSettings = new ProjectSpaceUpdatedByFilterSettings();
      projectSpaceUpdatedByFilterSettings.projectId = this.projectId;

      this.selectedFilters = [
        spaceTemplateFilterSettings,
        levelFilterSettings,
        buildingFilterSettings,
        projectSpaceCreatedByFilterSettings,
        projectSpaceUpdatedByFilterSettings,
      ];
    },
    onStorageUpdate(event) {
      this.$log(">>>> onStorageUpdate");
      if (event.key === "projectSpacesViewedList") {
        this.$log(">>>> onStorageUpdate projectSpacesViewedList");
        this.updateViewedSpacesList();
      }
    },
    onSpaceOpenClicked(item) {
      if (!item.viewed) {
        item.viewed = true;
        this.addSpaceToLocalStorage(item.id);
      } else {
        item.viewed = false;
        this.removeSpaceFromLocalStorage(item.id);
      }
    },
    addSpaceToLocalStorage(spaceId) {
      spaceId = spaceId.toString();
      var projectSpacesViewedList = this.$getFromLocal(`projectSpacesViewedList`, true, []);
      if (!projectSpacesViewedList.includes(spaceId)) {
        projectSpacesViewedList.push(spaceId);
        this.$setToLocal(`projectSpacesViewedList`, projectSpacesViewedList, true);
      }
    },
    removeSpaceFromLocalStorage(spaceId) {
      spaceId = spaceId.toString();
      var projectSpacesViewedList = this.$getFromLocal(`projectSpacesViewedList`, true, []);
      if (projectSpacesViewedList.includes(spaceId)) {
        var index = projectSpacesViewedList.findIndex((id) => id == spaceId);
        if (index != -1) {
          projectSpacesViewedList.splice(index, 1);
          this.$setToLocal(`projectSpacesViewedList`, projectSpacesViewedList, true);
        }
      }
    },
    checkSpaceFromLocalStorage(spaceIds) {
      var projectSpacesViewedList = this.$getFromLocal(`projectSpacesViewedList`, true, []);
      var viewedList = new Set();
      for (let i = 0; i < spaceIds.length; i++) {
        const spaceId = spaceIds[i];
        if (projectSpacesViewedList.includes(spaceId.toString())) {
          viewedList.add(spaceId.toString());
        }
      }
      return viewedList;
    },
    clearAllProjectsSpaces() {
      this.$dialog.info({
        text: `Are you sure you want to mark all spaces in all projects as <u>Not Viewed</u>?`,
        title: `Mark all spaces in all projects as Not Viewed`,
        color: "orange darken-2",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "orange darken-2",
            handle: () => {
              this.$setToLocal(`projectSpacesViewedList`, [], true);
              setTimeout(() => {
                this.updateViewedSpacesList();
              }, 100);
              this.$dialog.notify.success(
                `All projects spaces are now marked as <u>Not Viewed</u>!`,
                {
                  position: "top-right",
                  timeout: 3000,
                }
              );
            },
          },
        },
      });
    },
    updateViewedSpacesList() {
      var viewedSpaces = this.checkSpaceFromLocalStorage(this.entities.map((s) => s.id));
      // this.$log("updateViewedSpacesList: ", viewedSpaces);
      for (let i = 0; i < this.entities.length; i++) {
        let item = this.entities[i];
        if (viewedSpaces.has(item.id.toString())) item.viewed = true;
        else item.viewed = false;
      }
    },
    spaceClass(item) {
      return [
        item.isBatch ? "batch-space" : "",
        item.viewed ? "viewed-space" : "",
        item.id == this.lastViewedSpaceId ? "last-viewed-space" : "",
      ].join(" ");
    },
    onSpaceOpenChanged(checked, item) {
      if (checked) {
        item.viewed = true;
      } else {
        item.viewed = false;
      }
    },
    displayActivityLogs(item) {
      setTimeout(() => {
        this.$refs.spaceActivityLog.open(item);
      });
    },
    displayChangesSince(item) {
      setTimeout(() => {
        this.$refs.showChangesSince.open(item);
      });
    },
    displaySpaceAggregatedChange(space) {
      setTimeout(() => {
        this.$refs.spaceAggregatedChange.open(space);
      });
    },
    openCloneModal(space) {
      this.$refs.cloneSpaceModal.open(space);
    },
    openCloneAnotherProjectSpaceModal() {
      this.$refs.cloneAnotherProjectSpaceModal.open(this.projectId);
    },
    openSpaceTemplate(id) {
      this.$refs.editTemplate.open(id);
    },
    onSpaceSaved(spaceData, isNewSpace) {
      spaceData.viewed = true;
      this.$log("onSpaceSaved", spaceData, isNewSpace);
      if (isNewSpace) {
        // this.entities.push(this.cloneDeep(spaceData));
        this.addToArr(this.entities, spaceData, true);
        this.total = this.entities.length;
        // this.options.search = spaceData.id;
      } else {
        this.updateArr(this.entities, spaceData);
      }
      this.$emit("refresh-overview");
    },
    onSpacePanelClosed() {
      // this.$log("onSpacePanelClosed", this.selected);
      const path = `/projects/${this.projectId}/spaces`;
      if (this.$route.path !== path) this.$router.push(path);
      setTimeout(() => {
        this.selected = {};
      }, 50);
    },
    focus() {
      this.$refs.mainFilter.focus();
    },
    getAreaGroupEquipmentsCount(areaGroup) {
      if (areaGroup != null)
        return areaGroup.areaEquipments.reduce((total, cur) => {
          return total + cur.count;
        }, 0);
      else return 0;
    },
    rowClicked(item) {
      this.view(item.id);
    },
    getProjectSpacesDebounced() {
      if (this.projectSpacesTimerId == null) {
        this.projectSpacesTimerId = -1;
        this.getProjectSpaces();
        return;
      }
      // cancel pending call
      clearTimeout(this.projectSpacesTimerId);

      // delay new call 400ms
      this.projectSpacesTimerId = setTimeout(() => {
        this.getProjectSpaces();
      }, 400);
    },
    onSpaceFetch(spaceId) {
      this.updateViewedSpacesList();
    },
    getProjectSpaces() {
      this.$backToTop(0, document.querySelector(".project-spaces-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      projectService
        .getSpaces(this.projectId, this.$clean(this.options, true))
        .then((resp) => {
          for (let i = 0; i < resp.data.items.length; i++) {
            let item = resp.data.items[i];
            item.viewed = false;
          }
          this.entities = resp.data.items || [];
          this.total = resp.data.total;
          this.loadingStates.table = false;
          this.updateViewedSpacesList();
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    closeSpace() {
      this.$refs.editProjectSpace.closeSpacePanel();
    },
    add(isBatch) {
      this.selected = {};
      this.open(null, isBatch);
    },
    view(id) {
      this.selected = { id: id };
      this.$log("view selected", this.selected);
      this.openSpaceInEditMode = false;
      setTimeout(() => {
        const path = `/projects/${this.projectId}/spaces/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    update(id) {
      this.selected = { id: id };
      this.$log("update selected", this.selected);
      this.openSpaceInEditMode = true;
      setTimeout(() => {
        const path = `/projects/${this.projectId}/spaces/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    open(id, isBatch = false) {
      setTimeout(() => {
        this.lastViewedSpaceId = id;
        this.$refs.editProjectSpace.open(id, isBatch, this.openSpaceInEditMode);
        this.openSpaceInEditMode = false;
      }, 400);
    },
    del(spaceObj) {
      var building =
        spaceObj.level && spaceObj.level.building ? `${spaceObj.level.building.name}.` : ``;
      var level = spaceObj.level ? `${spaceObj.level.name}.` : ``;
      var space =
        spaceObj.spaceNumber != null && spaceObj.spaceNumber != ""
          ? `${spaceObj.spaceNumber}.${spaceObj.name}`
          : `${spaceObj.name}`;
      var spaceHtml = `<h4>${building}${level}${space}</h4>`;
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this space?<br/>${spaceHtml}`,
          title: `Delete Space?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return spaceService
                  .delete(spaceObj.id)
                  .then((resp) => {
                    this.onDeleteSpaceSuccess(spaceObj.id);
                    this.$dialog.notify.success("Space deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                    this.$emit("refresh-overview");
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the space!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onDeleteSpaceSuccess(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    checkForSingleSpaceRoute(route) {
      if (route.name == "view-project-space") {
        this.open(route.params.spaceId);
        return;
      }
    },
    addToSpaces(space) {
      this.addToArr(this.entities, this.cloneDeep(space), true);
      this.$emit("refresh-overview");
    },
  },
  watch: {
    options: {
      handler(val) {
        // this.$log("options", this.cloneDeep(this.options));
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getProjectSpacesDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.$log("PROJECT SPACES - oldRoute", oldRoute, "newRoute", newRoute);

        //from space view TO => space view,
        //so do nothing from here, and the active project spaces tab will handle it
        if (
          (oldRoute.name == "view-project" ||
            oldRoute.name == "view-project-spaces" ||
            oldRoute.name == "view-project-space") &&
          newRoute.name == "view-project-space"
        ) {
          this.open(newRoute.params.spaceId);
          return;
        }
      },
      deep: true,
    },
    projectId: {
      handler(newRoute, oldRoute) {
        this.getProjectSpaces();
      },
    },
  },
};
</script>

<style lang="scss">
.project-spaces-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.template-link-alert .v-alert__icon {
  align-self: center;
}
.batch-space {
  background: rgba($deep-purple-base, 0.06);
  color: $deep-purple-base !important;

  .space-img {
    border-color: $deep-purple-base !important;
  }

  &:hover {
    background: rgba($deep-purple-base, 0.12) !important;
  }
}

.viewed-space {
  background: rgba(#009688, 0.08);

  &:hover {
    background: rgba(#009688, 0.16) !important;
  }
}
// .last-viewed-space {
//   background: rgba($orange-base, 0.08);
// }
</style>
