<template>
  <v-sheet
    :elevation="inset ? '' : 2"
    height="32"
    class="big-board-contracts-filter d-flex flex-row align-center pl-2"
    :style="inset ? `background: #eceff1;` : ``"
    :class="inset ? `inset-shadow` : ``"
    rounded
  >
    <label class="fs-14px ma-0 mx-1" style="font-weight: 600">{{ label }}:</label>
    <v-radio-group
      v-model="value"
      mandatory
      hide-details
      class="mt-0 pt-0 ml-1 big-board-radio"
      row
    >
      <v-radio :value="null" color="orange darken-1">
        <template v-slot:label>
          <div
            class="font-weight-bold fs-13px d-flex align-center"
            :class="value == null ? 'orange--text text--darken-1' : 'secondary--text'"
          >
            ALL
          </div>
        </template>
      </v-radio>
      <v-radio :value="enums.CONTRACT_STATUS.Pending.value" color="orange darken-1">
        <template v-slot:label>
          <div
            class="font-weight-bold fs-13px d-flex align-center"
            :class="
              value == enums.CONTRACT_STATUS.Pending.value
                ? 'orange--text text--darken-1'
                : 'secondary--text'
            "
          >
            <i class="fas fa-pause mr-2" style="line-height: 0"></i> Pending
          </div>
        </template>
      </v-radio>
      <v-radio :value="enums.CONTRACT_STATUS.Approved.value" color="orange darken-1">
        <template v-slot:label>
          <div
            class="font-weight-bold fs-13px d-flex align-center"
            :class="
              value == enums.CONTRACT_STATUS.Approved.value
                ? 'orange--text text--darken-1'
                : 'secondary--text'
            "
          >
            <i class="fas fa-play mr-2" style="line-height: 0"></i> Approved
          </div>
        </template>
      </v-radio>
    </v-radio-group>
  </v-sheet>
</template>

<script>
import enums from "../../../plugins/enums";

export default {
  name: "big-board-contracts-filter",
  data() {
    return {
      enums,
      filterContractStatus: null,
    };
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: "Contracts",
    },
    inset: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.filterContractStatus = this.value;
  },
  computed: {},
  watch: {
    value: {
      handler(val) {
        this.filterContractStatus = this.value;
      },
    },
    filterContractStatus: {
      handler(val) {
        this.$emit("input", this.filterContractStatus);
      },
    },
  },
  components: {},
};
</script>

<style lang="scss"></style>
