import Api from "../../Shared/services/api";
import { buildParams } from "../../../plugins/helpers";

const baseUrl = "Contracts";
export default {
  query(projectId, options) {
    options.projectId = projectId;
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  getById(id) {
    return Api().get(baseUrl + `/${id}`);
  },
  getContractDetails(id) {
    return Api().get(baseUrl + `/${id}/Details`);
  },
  getContractPhases(id) {
    return Api().get(baseUrl + `/${id}/ConstructionPhases`);
  },
  getByListIds_old(ids) {
    return Api().get(baseUrl + `/List/${ids}`);
  },
  getByListIds(ids) {
    return Api().post(baseUrl + `/List`, ids);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  approve(id, data) {
    return Api().patch(baseUrl + `/${id}/approve`, data);
  },
  unapprove(id) {
    return Api().patch(baseUrl + `/${id}/unapprove`);
  },
  sendForApproval(id) {
    return Api().patch(baseUrl + `/${id}/SendForApproval`);
  },
  cloneContract(id, data) {
    return Api().post(baseUrl + `/${id}/clone`, data);
  },
  archiveUnArchive(id, data, isArchive) {
    if (isArchive) return Api().patch(baseUrl + `/${id}/Archive`, data);
    else return Api().patch(baseUrl + `/${id}/UnArchive`, data);
  },
  sendForUpdate(id) {
    return Api().patch(baseUrl + `/${id}/SendForUpdate`);
  },
  shiftContractPhases(id, data) {
    return Api().put(baseUrl + `/${id}/shiftContractPhases`, data);
  },
  addPauseDurationPhases(id, data) {
    return Api().put(baseUrl + `/${id}/AddPausedDurationPhases`, data);
  },
  lock(id) {
    return Api().patch(baseUrl + `/${id}/lock`);
  },
  unlock(id) {
    return Api().patch(baseUrl + `/${id}/unlock`);
  },
  getContractDefaultRates(contractId) {
    return Api().get(`${baseUrl}/${contractId}/ContractLaborRates`);
  },
  updateContractDefaultRates(contractId, data) {
    return Api().put(`${baseUrl}/${contractId}/ContractLaborRates`, data);
  },
  getContractUsersRates(contractId) {
    return Api().get(`${baseUrl}/${contractId}/UsersRates`);
  },
  updateContractUsersRates(contractId, data) {
    return Api().put(`${baseUrl}/${contractId}/UsersRates`, data);
  },
  exportEntryDetailsInExcel(entity) {
    return Api().post(baseUrl + "/ExportEntryDetailsInExcel", entity);
  },
  getActivityLog(id, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + `/${id}/ActivityLogs?` + qParams.toString());
  },
  addActivityLog(id, note) {
    return Api().post(baseUrl + `/${id}/ActivityLogs`, {
      notes: note,
      type: 2,
    });
  },
  queryManagement(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/GetManagement?" + qParams.toString());
  },
  download(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/download?" + qParams.toString());
  },
  exportExcel(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "/Export?" + qParams.toString());
  },
  getContractDefaultRates(contractId) {
    return Api().get(`${baseUrl}/${contractId}/ContractLaborRates`);
  },
  updateContractDefaultRates(contractId, data) {
    return Api().put(`${baseUrl}/${contractId}/ContractLaborRates`, data);
  },
  getContractUsersRates(contractId) {
    return Api().get(`${baseUrl}/${contractId}/UsersRates`);
  },
  updateContractUsersRates(contractId, data) {
    return Api().put(`${baseUrl}/${contractId}/UsersRates`, data);
  },
  checkIfContractNameExist({ name, projectId }) {
    return Api().get(`${baseUrl}/${projectId}/Name/Exist?contractName=${name}`);
  },
};
