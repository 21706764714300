<template>
  <apexcharts
    ref="chart"
    type="line"
    height="350"
    :options="chartOptions"
    :series="series"
  ></apexcharts>
</template>
<script>
export default {
  name: "project-timeline-chart",
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        },

        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Days left until (start date, construction start date, construction completion date)",
          align: "left",
          style: {
            fontSize: "16px",
            fontFamily: '"Karla", serif;',
          },
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          padding: {
            top: 10,
            right: 0,
            bottom: 0,
            left: 30,
          },
        },
        markers: {
          size: 1,
          showNullDataPoints: false,
        },
        xaxis: {
          categories: [
            "",
            "Start Date",
            "Construction Start Date",
            "Construction Complete Date",
            "",
          ],
          min: "Start Date",
          max: undefined,
          labels: {
            rotate: -45,
            rotateAlways: false,
            offsetX: 0,
          },
        },
        yaxis: {
          title: {
            text: "Remaining Days",
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: false,
          offsetY: 0,
          offsetX: -5,
        },
        tooltip: {
          enabled: true,
          followCursor: true,
          fillSeriesColor: false,
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
          },
          y: {
            show: false,
            formatter: (value) => {
              return `Remaining Days (${parseInt(value) || 0})`.replace("NaN", "");
            },
          },
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          name: "Lead Timeline",
          type: "line",
          color: this.$vuetify.theme.themes.light.info,
          data: [
            null,
            this.getDayDiff(new Date(), this.selected.leadTimeline.potentialStartDate),
            this.getDayDiff(new Date(), this.selected.leadTimeline.potentialConstructionStartDate),
            this.getDayDiff(
              new Date(),
              this.selected.leadTimeline.potentialConstructionCompleteDate
            ),
            null,
          ],
        },

        {
          name: "Opportunity Timeline",
          type: "line",
          hide: true,
          color: this.$vuetify.theme.themes.light.chart.orange,
          data: [
            null,
            this.getDayDiff(new Date(), this.selected.opportunityTimeline.potentialStartDate),
            this.getDayDiff(
              new Date(),
              this.selected.opportunityTimeline.potentialConstructionStartDate
            ),
            this.getDayDiff(
              new Date(),
              this.selected.opportunityTimeline.potentialConstructionCompleteDate
            ),
            null,
          ],
        },
        {
          name: "Active Timeline",
          type: "line",
          color: this.$vuetify.theme.themes.light.success,
          data: [
            null,
            this.getDayDiff(new Date(), this.selected.activeTimeline.potentialStartDate),
            this.getDayDiff(
              new Date(),
              this.selected.activeTimeline.potentialConstructionStartDate
            ),
            this.getDayDiff(
              new Date(),
              this.selected.activeTimeline.potentialConstructionCompleteDate
            ),
            null,
          ],
        },
      ];
    },
  },
  methods: {
    getDayDiff(start, end) {
      if (end) {
        const diffTime = Math.abs(new Date(end).getTime() - start.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
      }
      return null;
    },
  },
};
</script>
