export default [
  { text: "Image", value: "imageUrl", width: "80px", cellClass: "px-0", class: "px-0" },
  {
    text: "Building",
    value: "level.building.name",
    width: "100px",
  },
  {
    text: "Level",
    value: "level.name",
    width: "82px",
  },
  { text: "Number", value: "spaceNumber", width: "100px" },
  { text: "Name", value: "name", width: "140px" },
  { text: "Equipment", value: "equipmentCount", width: "120px" },
  { text: "Creator", value: "createdBy", width: "96px" },
  { text: "Last Update", value: "updateDate", width: "130px" },
];
