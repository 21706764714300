var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"3","sm":"12","cols":"12"}},[_c('v-btn',{attrs:{"disabled":_vm.readonly || _vm.hasChanges,"color":"info"},on:{"click":_vm.add}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("Add Master Quote")])],1),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"md":"9","sm":"12","cols":"12"}},[_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}}),_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-card',{ref:"masterQuoteTableCard",attrs:{"elevation":"2"}},[_c('v-data-table',{staticClass:"masterQuotes-table",attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"loading":_vm.loadingStates.table,"id":"masterQuoteTable","hide-default-footer":"","disable-pagination":"","footer-props":{
          showFirstLastPage: true,
          firstIcon: 'far fa-arrow-to-left',
          lastIcon: 'far fa-arrow-to-right',
          prevIcon: 'far fa-angle-left',
          nextIcon: 'far fa-angle-right',
          itemsPerPageOptions: [15, 30, 50, 100],
        }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[(item.imageUrl != null && item.imageUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',attrs:{"src":item.imageUrl,"height":"100%","width":"100%"}})]):(item.category != null)?_c('i',{class:'fad ' + item.category.icon}):_c('i',{class:'fad fa-plug'})])])]}}],null,true)},[_c('span',[((item.imageUrl != null) & (item.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.imageUrl,"width":"250px","height":"250px","contain":""}}):(item.category != null)?_c('i',{class:'fad ' + item.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}}):_c('i',{class:'fad fa-plug',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.category",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"sm":"auto"}},[(item.category != null)?_c('i',{class:'masterQuote-icon fad ' + item.category.icon}):_c('i',{class:'masterQuote-icon fad fa-plug'})]),_c('v-col',{staticClass:"d-flex align-center"},[(item.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.category.name))]):_vm._e()])],1)]}},{key:"item.manufacture.name",fn:function(ref){
        var item = ref.item;
return [(item.manufacture != null)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(item.manufacture.logoUrl != null && item.manufacture.logoUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.manufacture.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(item.manufacture.logoUrl != null && item.manufacture.logoUrl != '')?_c('v-img',{attrs:{"src":item.manufacture.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0"},[_vm._v(_vm._s(item.manufacture.name))])],1):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.productFamily.name",fn:function(ref){
        var item = ref.item;
return [(item.productFamily != null)?_c('h5',{staticClass:"ma-0"},[_vm._v(_vm._s(item.productFamily.name))]):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.tag",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"masterQuote-tag font-weight-bold"},[_vm._v(_vm._s(item.tag))])]}},{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [(item.provider)?_c('equipment-provider',{attrs:{"provider-name":item.provider.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.installer",fn:function(ref){
        var item = ref.item;
return [(item.installer)?_c('equipment-installer',{attrs:{"installer-name":item.installer.name}}):_c('i',{class:'far fa-times pl-2 text--disabled'})]}},{key:"item.quoteNumber",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"masterQuote-quoteNumber"},[_vm._v(_vm._s(item.quoteNumber))])]}},_vm._l((['dateReceived', 'createDate', 'updateDate']),function(date){return {key:("item." + date),fn:function(ref){
        var item = ref.item;
return [_c('dater',{key:date,attrs:{"date":item[date],"dateonly":""}})]}}}),_vm._l((['updatedBy', 'createdBy']),function(field){return {key:("item." + field),fn:function(ref){
        var item = ref.item;
return [_c('user-avatar',{key:field,attrs:{"user":item[field],"icon":""}})]}}}),{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v text--secondary",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[_c('v-list-item',{attrs:{"disabled":_vm.readonly || _vm.hasChanges},on:{"click":function($event){return _vm.update(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Update")])],1)],1),_c('v-list-item',{attrs:{"disabled":_vm.readonly || _vm.hasChanges},on:{"click":function($event){return _vm.del(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{class:{ 'red--text': !_vm.readonly },attrs:{"small":""}},[_vm._v("fal fa-trash-alt")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{ 'red--text': !_vm.readonly }},[_vm._v("Delete")])],1)],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)],1),_c('edit-master-quotes',{ref:"editMasterQuote",attrs:{"nested":"","projectId":_vm.projectId,"spaceId":_vm.spaceId},on:{"save":_vm.onMasterQuoteSaved}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }