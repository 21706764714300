<template>
  <div>
    <h3 v-if="areaGroupName != null" class="mt-2 d-flex justify-space-between align-center">
      <span class="d-inline-flex align-center">
        <i class="fad fa-info-circle mr-2"></i>Add to:
        <v-chip color="white" label class="ml-2 elevation-1">
          <i class="fad fa-shapes mr-2"></i> {{ areaGroupName }}
        </v-chip>
      </span>
      <v-btn color="secondary" @click="createNewEquipment">
        <i class="fal fa-plus mr-2"></i> Create New Equipment
      </v-btn>

      <!-- @save="onEquipmentSaved" -->
      <edit-equipment
        :equipment="toBeCreateEquipment"
        @save="onEquipmentSaved"
        ref="createNewEquipmentComponent"
        nested
      ></edit-equipment>
    </h3>
    <v-row class="mt-0" justify="space-between">
      <v-col class="d-flex justify-start align-center">
        <v-switch
          v-model="selectedOnly"
          class="ma-0"
          style="font-size: 14px !important"
          hide-details
        >
          <template v-slot:label>
            <label class="v-switch-label ma-0">Only Show Selected</label>
          </template>
        </v-switch>
        <v-switch
          v-model="showHidden"
          class="ma-0 ms-4"
          style="font-size: 14px !important"
          hide-details
        >
          <template v-slot:label>
            <label class="v-switch-label ma-0">Show Deprecated & Discontinued Equipment</label>
          </template>
        </v-switch>
      </v-col>
      <v-col lg="3" md="3" sm="12" class="d-flex justify-end align-center">
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          hide-details
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          clearable
          prepend-inner-icon="far fa-search"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-show="selectedOnly"
      dense
      :headers="headers"
      :items="selectedEquipments"
      :item-class="rowClass"
      :items-per-page="-1"
      class="elevation-2 mt-1"
      hide-default-footer
    >
      <template v-slot:[`item.equipment.name`]="{ item }">
        <v-tooltip left z-index="999" nudge-left="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <v-img
                  v-if="(item.equipment.imageUrl != null) & (item.equipment.imageUrl != '')"
                  class="img"
                  :src="item.equipment.imageUrl"
                  height="100%"
                  width="100%"
                  contain
                ></v-img>
                <i
                  v-else-if="item.equipment.category != null"
                  :class="'fad ' + item.equipment.category.icon"
                ></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.equipment.imageUrl != null) & (item.equipment.imageUrl != '')"
              class="img"
              :src="item.equipment.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.equipment.category != null"
              :class="'fad ' + item.equipment.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.equipment.tag`]="{ item }">
        <div class="equipment-tag">{{ item.equipment.tag }}</div>
      </template>

      <template v-slot:[`item.equipment.category`]="{ item }">
        <v-row no-gutters>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i
              :class="'equipment-icon fad ' + item.equipment.category.icon"
              v-if="item.equipment.category"
            ></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.equipment.category">{{
              item.equipment.category.name
            }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.equipment.manufacture.name`]="{ item }">
        <div
          v-if="item.equipment.manufacture != null"
          class="d-flex align-center"
          style="gap: 0.5rem"
        >
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="
                      item.equipment.manufacture.logoUrl != null &&
                      item.equipment.manufacture.logoUrl != ''
                    "
                  >
                    <img
                      :key="item.equipment.manufacture.id + '_img'"
                      :src="item.equipment.manufacture.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.equipment.manufacture.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="
                  item.equipment.manufacture.logoUrl != null &&
                  item.equipment.manufacture.logoUrl != ''
                "
                :src="item.equipment.manufacture.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0">{{ item.equipment.manufacture.name }}</h5>
        </div>
        <div v-else class="text--disabled">N/A</div>
      </template>

      <template v-slot:[`item.equipment.model`]="{ item }">
        <div class="equipment-model">{{ item.equipment.model }}</div>
      </template>

      <template v-slot:[`item.equipment.availability`]="{ item }">
        <equipment-availability
          :availability="item.equipment.availability"
          mini
        ></equipment-availability>
      </template>

      <template v-slot:[`item.equipment.installerId`]="{ item }">
        <equipment-installer-selector-static
          v-model="item.equipment.installerId"
          :installers-list="installersList"
          readonly
        ></equipment-installer-selector-static>
      </template>

      <template v-slot:[`item.equipment.providerId`]="{ item }">
        <equipment-provider-selector-static
          v-model="item.equipment.providerId"
          :providers-list="providersList"
          readonly
        ></equipment-provider-selector-static>
      </template>

      <template v-slot:[`item.count`]="{ item }">
        <counter
          :key="item.equipment.id"
          v-model="item.count"
          :min="0"
          :max="1000"
          @input="onCountChangeFromSelectedOnly($event, item.equipment)"
        />
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="secondary" icon elevation="0" @click="viewEquipment(item.equipment)">
          <i class="fad fa-info"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
    </v-data-table>
    <v-data-table
      v-show="!selectedOnly"
      dense
      :headers="headers"
      :items="selectedEntities"
      :item-class="rowClass"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="15"
      :loading="loadingStates.table"
      class="elevation-2 mt-1"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:[`item.equipment.name`]="{ item }">
        <v-tooltip left z-index="999" nudge-left="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <div class="equipment-img">
                <v-img
                  v-if="(item.equipment.imageUrl != null) & (item.equipment.imageUrl != '')"
                  class="img"
                  :src="item.equipment.imageUrl"
                  height="100%"
                  width="100%"
                  contain
                ></v-img>
                <i
                  v-else-if="item.equipment.category != null"
                  :class="'fad ' + item.equipment.category.icon"
                ></i>
                <i v-else :class="'fad fa-plug'"></i>
              </div>
            </div>
          </template>
          <span>
            <v-img
              v-if="(item.equipment.imageUrl != null) & (item.equipment.imageUrl != '')"
              class="img"
              :src="item.equipment.imageUrl"
              width="250px"
              height="250px"
              contain
            ></v-img>
            <i
              v-else-if="item.equipment.category != null"
              :class="'fad ' + item.equipment.category.icon"
              style="font-size: 32px; padding: 2rem"
            ></i>
            <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.equipment.tag`]="{ item }">
        <div class="equipment-tag">{{ item.equipment.tag }}</div>
      </template>
      <template v-slot:[`item.equipment.category`]="{ item }">
        <v-row no-gutters>
          <v-col sm="auto" class="d-flex justify-content-start align-items-center">
            <i
              :class="'equipment-icon fad ' + item.equipment.category.icon"
              v-if="item.equipment.category"
            ></i>
          </v-col>
          <v-col class="d-flex align-center">
            <span class="category-name" v-if="item.equipment.category">{{
              item.equipment.category.name
            }}</span>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.equipment.availability`]="{ item }">
        <equipment-availability
          :availability="item.equipment.availability"
          mini
        ></equipment-availability>
      </template>

      <template v-slot:[`item.equipment.manufacture.name`]="{ item }">
        <div
          v-if="item.equipment.manufacture != null"
          class="d-flex align-center"
          style="gap: 0.5rem"
        >
          <v-tooltip right z-index="999" nudge-right="-4px">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div class="company-logo-mini">
                  <div
                    style="height: 100%; width: 100%"
                    v-viewer
                    @click.stop
                    v-if="
                      item.equipment.manufacture.logoUrl != null &&
                      item.equipment.manufacture.logoUrl != ''
                    "
                  >
                    <img
                      :key="item.equipment.manufacture.id + '_img'"
                      :src="item.equipment.manufacture.logoUrl"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                  <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                    <img
                      :key="item.equipment.manufacture.id + '_img'"
                      src="/img/DNA_Symbol.png"
                      height="100%"
                      width="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
              </div>
            </template>
            <span>
              <v-img
                v-if="
                  item.equipment.manufacture.logoUrl != null &&
                  item.equipment.manufacture.logoUrl != ''
                "
                :src="item.equipment.manufacture.logoUrl"
                height="250px"
                width="250px"
                contain
              ></v-img>
              <v-img v-else src="/img/DNA_Symbol.png" height="250px" width="250px" contain></v-img>
            </span>
          </v-tooltip>
          <h5 class="ma-0">{{ item.equipment.manufacture.name }}</h5>
        </div>
        <div v-else class="text--disabled">N/A</div>
      </template>

      <template v-slot:[`item.equipment.model`]="{ item }">
        <div class="equipment-model">{{ item.equipment.model }}</div>
      </template>

      <template v-slot:[`item.count`]="{ item }">
        <counter
          v-model="item.count"
          :min="0"
          :max="1000"
          @input="onCountChange($event, item.equipment)"
        />
      </template>

      <template v-slot:[`item.equipment.installerId`]="{ item }">
        <equipment-installer-selector-static
          v-model="item.equipment.installerId"
          :installers-list="installersList"
          readonly
        ></equipment-installer-selector-static>
      </template>

      <template v-slot:[`item.equipment.providerId`]="{ item }">
        <equipment-provider-selector-static
          v-model="item.equipment.providerId"
          :providers-list="providersList"
          readonly
        ></equipment-provider-selector-static>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="secondary" icon elevation="0" @click="viewEquipment(item.equipment)">
          <i class="fad fa-info"></i>
        </v-btn>
      </template>

      <template v-slot:no-data>
        <img width="300" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
    </v-data-table>

    <edit-equipment ref="viewEquipment" nested></edit-equipment>
  </div>
</template>

<script>
import ApiService from "../services/equipments-service.js";
import addEquipmentHeader from "../config/tables/addEquipment.header";
import EquipmentProviderSelectorStatic from "../../Equipments/components/EquipmentProviderSelectorStatic.vue";
import EquipmentInstallerSelectorStatic from "../../Equipments/components/EquipmentInstallerSelectorStatic.vue";
import Enums from "../../../plugins/enums";
import EditEquipment from "./EditEquipment.vue";
import EquipmentAvailability from "./EquipmentAvailability.vue";

export default {
  name: "add-equipments",
  data() {
    return {
      selectedOnly: false,
      showHidden: false,
      equipmentTimerId: null,
      entities: [],
      selectedEquipments: [],
      total: 0,
      search: "",
      valid: false,
      toBeCreateEquipment: {},
      categories: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
      },
      slideouts: {
        add: {
          active: false,
          isLoading: false,
        },
        update: {
          active: false,
          tab: null,
          isLoading: false,
          categoryLoading: false,
        },
      },
      loadingStates: {
        table: false,
      },
      headers: addEquipmentHeader,
    };
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    areaGroupName: {
      type: String,
      default: null,
    },
    installersList: {
      type: Array,
      default: () => [],
    },
    providersList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedEntities() {
      return this.showHidden
        ? this.entities
        : this.entities.filter((elm) => {
            return ![
              Enums.EquipmentAvailability.Deprecated.value,
              Enums.EquipmentAvailability.Discontinued.value,
            ].includes(elm.equipment.availability);
          });
    },
  },
  mounted() {
    this.entities = [];
    if (this.value) {
      this.selectedEquipments = this.value.slice();
    } else {
      this.selectedEquipments = [];
    }
    this.$log("this.selectedEquipments", this.selectedEquipments);
  },
  methods: {
    viewEquipment(equipmentObj) {
      this.$refs.viewEquipment.open(equipmentObj.id, false);
    },
    createNewEquipment() {
      this.toBeCreateEquipment = {
        powerConsumption: null,
        powerAutoCalculate: true,
        accessories: [],
      };
      this.$refs.createNewEquipmentComponent.open();
    },
    onEquipmentSaved(equipment, isNewEquipment) {
      this.selectedEquipments.push({
        count: 1,
        equipment: equipment,
        installerId: equipment.installerId,
        installer: equipment.installer,
        providerId: equipment.providerId,
        provider: equipment.provider,
      });
      this.options.search = equipment.id;
      this.focus();
      this.annouceChange();
    },
    focus() {
      this.$refs.mainSearch.focus();
    },
    onCountChange(newVal, equipment) {
      this.$log("onCountChange", newVal, equipment);
      let foundIndex = this.selectedEquipments.findIndex((e) => e.equipment.id === equipment.id);
      let found = foundIndex != -1 ? this.selectedEquipments[foundIndex] : null;
      if (newVal > 0) {
        if (foundIndex != -1) found.count = newVal;
        else
          this.selectedEquipments.push({
            count: newVal,
            equipment: equipment,
            installerId: equipment.installerId,
            installer: equipment.installer,
            providerId: equipment.providerId,
            provider: equipment.provider,
          });
      } else {
        if (foundIndex != -1) found.count = newVal;
        // if (foundIndex != -1) this.selectedEquipments.splice(foundIndex, 1);
      }

      this.annouceChange();
    },
    onCountChangeFromSelectedOnly(newVal, equipment) {
      this.$log("onCountChangeFromSelectedOnly", newVal, equipment);
      let foundIndex = this.selectedEquipments.findIndex((e) => e.equipment.id === equipment.id);
      if (newVal <= 0 && foundIndex != -1) {
        //this.selectedEquipments.splice(foundIndex, 1);
      }

      //update existing entities
      let foundEntityItem = this.entities.find((e) => e.equipment.id === equipment.id);
      this.$log("foundEntityItem", foundEntityItem);
      if (foundEntityItem) foundEntityItem.count = newVal;

      this.annouceChange();
    },
    annouceChange() {
      this.$emit("input", this.selectedEquipments);
    },
    getData() {
      this.loadingStates.table = true;
      this.$log("this.options", this.options);
      this.optionsToSend = this.cloneDeep(this.options);
      this.optionsToSend.sortBy = this.optionsToSend.sortBy.map((s) => s.replace("equipment.", ""));
      ApiService.query(this.$clean(this.optionsToSend))
        .then((resp) => {
          resp.data.items = resp.data.items.map((equip) => {
            return {
              equipment: equip,
              count: this.getAddedQty(equip),
            };
          });
          this.$log("resp.data.items", resp.data.items);
          this.entities = resp.data.items.slice();
          this.$log("entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getAddedQty(equip) {
      let found = this.selectedEquipments.find((e) => e.equipment.id == equip.id);
      if (found) return found.count;
      return 0;
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    getDataDebounced() {
      if (this.equipmentTimerId == null) {
        this.equipmentTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.equipmentTimerId);

      // delay new call 400ms
      this.equipmentTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
  },
  watch: {
    options: {
      handler(newData, oldData) {
        this.$log(">>>>>>>>>>>>>>>>>>>> search:", oldData.page, " => ", newData.page);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: {
    EquipmentProviderSelectorStatic,
    EquipmentInstallerSelectorStatic,
    EditEquipment,
    EquipmentAvailability,
  },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}
</style>
