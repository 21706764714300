<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row class="mt-2 mb-1" justify="space-between">
        <v-col md="3" sm="12" cols="12">
          <v-btn :disabled="readonly || hasChanges" color="info" @click="add"
            ><i class="fal fa-plus mr-2"></i>Add Master Quote</v-btn
          >
        </v-col>
        <v-col
          md="9"
          sm="12"
          cols="12"
          class="d-flex flex-row align-center justify-end flex-wrap"
          style="gap: 0.5rem"
        >
          <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
          <columns-visibility-control
            :defaults="defaultHeaders"
            :withColumnsOrder="true"
            v-model="headers"
            :storageKey="storageKey"
          />
          <v-text-field
            v-model="options.search"
            label="Search"
            ref="mainSearch"
            class="table-search-field"
            @focus="mainSearchInFocus = true"
            @blur="mainSearchInFocus = false"
            :style="{
              'max-width':
                mainSearchInFocus || (options.search != '' && options.search != null)
                  ? '200px'
                  : '110px',
            }"
            dense
            solo
            clearable
            hide-details
            prepend-inner-icon="far fa-search"
            :loading="loadingStates.table"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-card elevation="2" ref="masterQuoteTableCard">
        <v-data-table
          dense
          :headers="tableHeader"
          :items="entities"
          :options.sync="options"
          :loading="loadingStates.table"
          @click:row="rowClicked"
          id="masterQuoteTable"
          class="masterQuotes-table"
          hide-default-footer
          disable-pagination
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'far fa-arrow-to-left',
            lastIcon: 'far fa-arrow-to-right',
            prevIcon: 'far fa-angle-left',
            nextIcon: 'far fa-angle-right',
            itemsPerPageOptions: [15, 30, 50, 100],
          }"
        >
          <template v-slot:progress>
            <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <v-tooltip right z-index="999" nudge-right="-4px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <div class="equipment-img">
                    <div
                      style="height: 100%; width: 100%"
                      v-viewer
                      @click.stop
                      v-if="item.imageUrl != null && item.imageUrl != ''"
                    >
                      <img
                        :key="item.id + '_img'"
                        :src="item.imageUrl"
                        height="100%"
                        width="100%"
                      />
                    </div>
                    <i v-else-if="item.category != null" :class="'fad ' + item.category.icon"></i>
                    <i v-else :class="'fad fa-plug'"></i>
                  </div>
                </div>
              </template>
              <span>
                <v-img
                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                  class="img"
                  :src="item.imageUrl"
                  width="250px"
                  height="250px"
                  contain
                ></v-img>
                <i
                  v-else-if="item.category != null"
                  :class="'fad ' + item.category.icon"
                  style="font-size: 32px; padding: 2rem"
                ></i>
                <i v-else :class="'fad fa-plug'" style="font-size: 32px; padding: 2rem"></i>
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.category`]="{ item }">
            <v-row no-gutters>
              <v-col sm="auto" class="d-flex justify-content-start align-items-center">
                <i
                  v-if="item.category != null"
                  :class="'masterQuote-icon fad ' + item.category.icon"
                ></i>
                <i v-else :class="'masterQuote-icon fad fa-plug'"></i>
              </v-col>
              <v-col class="d-flex align-center">
                <span class="category-name" v-if="item.category">{{ item.category.name }}</span>
              </v-col>
            </v-row>
          </template>

          <template v-slot:[`item.manufacture.name`]="{ item }">
            <div v-if="item.manufacture != null" class="d-flex align-center" style="gap: 0.5rem">
              <v-tooltip right z-index="999" nudge-right="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="company-logo-mini">
                      <div
                        style="height: 100%; width: 100%"
                        v-viewer
                        @click.stop
                        v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                      >
                        <img
                          :key="item.manufacture.id + '_img'"
                          :src="item.manufacture.logoUrl"
                          height="100%"
                          width="100%"
                          style="object-fit: contain"
                        />
                      </div>
                      <div style="height: 100%; width: 100%" v-viewer @click.stop v-else>
                        <img
                          :key="item.manufacture.id + '_img'"
                          src="/img/DNA_Symbol.png"
                          height="100%"
                          width="100%"
                          style="object-fit: contain"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <span>
                  <v-img
                    v-if="item.manufacture.logoUrl != null && item.manufacture.logoUrl != ''"
                    :src="item.manufacture.logoUrl"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                  <v-img
                    v-else
                    src="/img/DNA_Symbol.png"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                </span>
              </v-tooltip>
              <h5 class="ma-0">{{ item.manufacture.name }}</h5>
            </div>
            <div v-else class="text--disabled">N/A</div>
          </template>

          <template v-slot:[`item.productFamily.name`]="{ item }">
            <h5 class="ma-0" v-if="item.productFamily != null">{{ item.productFamily.name }}</h5>
            <div v-else class="text--disabled">N/A</div>
          </template>

          <template v-slot:[`item.tag`]="{ item }">
            <div class="masterQuote-tag font-weight-bold">{{ item.tag }}</div>
          </template>

          <template v-slot:[`item.provider`]="{ item }">
            <equipment-provider
              v-if="item.provider"
              :provider-name="item.provider.name"
            ></equipment-provider>
            <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
          </template>

          <template v-slot:[`item.installer`]="{ item }">
            <equipment-installer
              v-if="item.installer"
              :installer-name="item.installer.name"
            ></equipment-installer>
            <i v-else :class="'far fa-times pl-2 text--disabled'"></i>
          </template>

          <template v-slot:[`item.quoteNumber`]="{ item }">
            <div class="masterQuote-quoteNumber">{{ item.quoteNumber }}</div>
          </template>

          <template
            v-for="date in ['dateReceived', 'createDate', 'updateDate']"
            #[`item.${date}`]="{ item }"
          >
            <dater :key="date" :date="item[date]" dateonly></dater>
          </template>
          <template v-for="field in ['updatedBy', 'createdBy']" #[`item.${field}`]="{ item }">
            <user-avatar :key="field" :user="item[field]" icon></user-avatar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu dense offset-x right>
              <template v-slot:activator="{ attrs, on }">
                <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
                  <i class="far fa-ellipsis-v text--secondary" style="font-size: 16px"></i>
                </v-btn>
              </template>
              <v-list class="more-options-menu">
                <v-list-item :disabled="readonly || hasChanges" @click="update(item.id)">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small>fal fa-pen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Update</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item :disabled="readonly || hasChanges" @click="del(item.id)">
                  <v-list-item-icon class="mr-2 justify-center">
                    <v-icon small :class="{ 'red--text': !readonly }">fal fa-trash-alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :class="{ 'red--text': !readonly }"
                      >Delete</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:no-data>
            <img width="500" src="/img/art/fogg-no-connection-2.png" />
            <p class="font-weight-bold">No Data Available!</p>
          </template>
          <template v-slot:loading>
            <video width="250" muted loop autoplay style="padding: 0.5rem">
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Searching the Cosmos...</p>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <edit-master-quotes
      nested
      @save="onMasterQuoteSaved"
      ref="editMasterQuote"
      :projectId="projectId"
      :spaceId="spaceId"
    ></edit-master-quotes>
  </div>
</template>

<script>
import ApiService from "../services/masterQuotes-service.js";
import spaceApiService from "../../Spaces/services/spaces-service";
import EquipmentProvider from "../../Equipments/components/EquipmentProvider.vue";
import EquipmentInstaller from "../../Equipments/components/EquipmentInstaller.vue";
import EditMasterQuotes from "../components/EditMasterQuotes.vue";
import masterQuoteHeader from "../config/tables/masterQuote.header";

export default {
  props: {
    hasChanges: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    spaceId: {
      type: Number,
    },
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      storageKey: "MasterQuotes",
      paramId: null,
      entities: [],
      selected: {},
      inventoryTimerId: null,
      defaultHeaders: [],
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
      },
      storedSearch: null,
      loadingStates: {
        table: false,
      },
      modals: {
        replaceMasterQuote: {
          active: false,
          sourceMasterQuote: {},
        },
      },
      headers: masterQuoteHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    //this.$refs.mainSearch.focus();
    this.$moveable(document.querySelector("#masterQuoteTable .v-data-table__wrapper"));
  },
  methods: {
    getData() {
      this.$backToTop();
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      this.loadingStates.table = true;
      spaceApiService
        .getMasterQuoteBySpaceId(this.$clean(optionsToSend, true), this.spaceId, this.projectId)
        .then((resp) => {
          this.$console().log("getMasterQuoteBySpaceId >> success", resp.data);
          this.entities = resp.data;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    del(id) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this masterQuote?`,
          title: `Delete MasterQuote?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(id)
                  .then((resp) => {
                    const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
                    if (index > -1) {
                      this.entities.splice(index, 1);
                    }
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the item", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },

    onMasterQuoteSaved(masterQuote, isNewMasterQuote) {
      if (isNewMasterQuote) {
        this.addToArr(this.entities, masterQuote);
      } else {
        this.updateArr(this.entities, masterQuote);
      }
    },
    getDataDebounced() {
      if (this.inventoryTimerId == null) {
        this.inventoryTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.inventoryTimerId);

      // delay new call 400ms
      this.inventoryTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },

    add() {
      this.selected = {};
      this.$refs.editMasterQuote.open(null);
    },
    update(id) {
      this.$refs.editMasterQuote.open(id, true);
    },
    view(id) {
      this.$refs.editMasterQuote.open(id, false);
    },
    rowClicked(row) {
      this.view(row.id);
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: {
    EquipmentProvider,
    EquipmentInstaller,
    EditMasterQuotes,
  },
};
</script>

<style lang="scss">
.masterQuotes-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
.v-list-item__title {
  font-size: 14px !important;
}
</style>
