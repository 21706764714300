import Api from "../../Shared/services/api";

const baseUrl = "reports";
export default {
  BOM(entity) {
    return Api().post(baseUrl + `/BOM`, entity);
  },
  ExtendedBOM(entity) {
    return Api().post(baseUrl + `/BOM/Extended`, entity);
  },
  AreaGroupsBOM(entity) {
    return Api().post(baseUrl + `/BOM/AreaGroups`, entity);
  },
  PIF(entity) {
    return Api().post(baseUrl + `/PIF`, entity);
  },
  OPC(entity) {
    return Api().post(baseUrl + `/OPC`, entity);
  },
  BIN(entity) {
    return Api().post(baseUrl + `/BidIN`, entity);
    /**, {
      responseType: "blob",
    } */
  },
  ContractProposal(entity) {
    return Api().post(baseUrl + `/ContractProposal`, entity);
  },
  SpaceList(entity) {
    return Api().post(baseUrl + `/SpaceList`, entity);
  },
  DrawingSheetList(entity) {
    return Api().post(baseUrl + `/DrawingSheetList`, entity);
  },
  RevitFamilies(entity) {
    return Api().post(baseUrl + `/RevitFamilies`, entity);
  },
};
