export default [
  { text: "Count", value: "count", align: "left", sortable: false, width: "100px" },
  { text: "Info", value: "actions", sortable: false, width: "54px" },
  { text: "Image", value: "equipment.name", width: "90px", sortable: false },
  { text: "Category", value: "equipment.category" },
  { text: "Tag", value: "equipment.tag" },
  {
    text: "Manufacturer",
    width: "150px",
    value: "equipment.manufacture.name",
  },
  { text: "Model", value: "equipment.model" },
  {
    text: "AVAL",
    value: "equipment.availability",
    width: "48px",
    cellClass: "px-1",
    class: "px-1",
  },
  { text: "Provider", value: "equipment.providerId", width: "100px" },
  { text: "Installer", value: "equipment.installerId", width: "100px" },
];
