<template>
  <v-dialog v-model="modals.active" max-width="350px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Contract
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneProjectForm">
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field-alt
                :rules="[allRules.required, allRules.noWhiteSpaces]"
                label="Contract Name"
                id="contractName"
                ref="contractName"
                placeholder="Contract Name"
                v-model="modals.data.name"
                @blur="checkIfNameExist"
                :error-messages="modals.errorMessage"
                :loading="modals.checkNameLoading"
              >
              </v-text-field-alt>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneContractDiscard()">
            <i class="fal fa-chevron-left mr-2"></i>Discard
          </v-btn>
          <v-btn
            color="info"
            @click="cloneContractConfirmed()"
            :disabled="!modals.valid"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i>
            Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import contractService from "../services/contracts-service";
import enums from "../../../plugins/enums";

export default {
  components: {},
  props: {},
  data() {
    return {
      enums: enums,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        data: {
          name: "",
          contractId: "",
          projectId: "",
        },
      },
    };
  },
  computed: {},
  methods: {
    open(contract) {
      this.$log(contract);
      this.modals.active = true;
      this.modals.data.name = this.contractNameConvention(contract.name);
      this.modals.data.contractId = contract.id;
      this.modals.data.projectId = contract.projectId;
    },
    checkIfNameExist() {
      if (this.modals.data.name) {
        this.modals.checkNameLoading = true;
        contractService
          .checkIfContractNameExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `(${this.modals.data.name}) Contract name is already exist`;
            else this.modals.errorMessage = "";
          })
          .finally(() => (this.modals.checkNameLoading = false));
      }
    },
    contractNameConvention(name) {
      return name + " [Clone]";
    },
    cloneContractDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        data: {
          name: "",
          contractId: "",
        },
      };
    },
    cloneContractConfirmed() {
      this.modals.loading = true;
      contractService
        .cloneContract(this.modals.data.contractId, this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Contract cloned successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.cloneContractDiscard();
          this.$emit("save", this.modals.data.contractId);
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
