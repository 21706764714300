<template>
  <!-- Create Area Group Modal -->
  <v-dialog v-model="modal" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fad fa-edit mr-2"></i>Change Project Number
      </v-card-title>
      <v-divider></v-divider>
      <v-form ref="editProjectNumberForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12">
                <v-text-field-alt
                  :label="`Project Number ${
                    isCheckingProjectNumber
                      ? '<i class=`far fa-spinner-third fa-spin ml-2`></i>'
                      : ''
                  }`"
                  id="editProjectNumber"
                  ref="editProjectNumberBox"
                  placeholder="Ex: 23107"
                  v-model="number"
                  @blur="checkProjectNumber"
                  :error-messages="numberErrors"
                  :disabled="isCheckingProjectNumber"
                  :loading="isCheckingProjectNumber"
                >
                </v-text-field-alt>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discard()" text> <i class="fal fa-xmark mr-2"></i>Cancel</v-btn>
          <v-btn
            color="info"
            :disabled="!isProjectNumberValid || isCheckingProjectNumber"
            @click="updateProjectNumberConfirmed()"
            :loading="isUpdatingProjectNumber"
          >
            <i class="fal fa-check mr-2"></i>
            {{ isCheckingProjectNumber ? "Validating Project Number..." : "Update Number" }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import projectsAPI from "../services/projects-service";

export default {
  components: {},
  data() {
    return {
      modal: false,
      numberErrors: [],
      isProjectNumberValid: false,
      isCheckingProjectNumber: false,
      isUpdatingProjectNumber: false,
      projectId: null,
      number: null,
      numberOriginal: null,
    };
  },
  methods: {
    open(project) {
      this.modal = true;
      this.isProjectNumberValid = false;
      this.isCheckingProjectNumber = false;
      this.isUpdatingProjectNumber = false;
      this.projectId = project.id;
      this.number = project.number;
      this.numberOriginal = project.number;
    },
    discard() {
      this.modal = false;
      this.isProjectNumberValid = false;
      this.projectId = null;
      this.number = null;
      this.numberOriginal = null;
    },
    checkProjectNumber(evt) {
      if (this.number == null || this.number == "") {
        this.numberErrors = ["Empty Project Number!"];
        this.isProjectNumberValid = false;
        return;
      }
      if (this.number == this.numberOriginal) {
        this.numberErrors = ["Same Project Number!"];
        this.isProjectNumberValid = false;
        return;
      }
      this.isCheckingProjectNumber = true;
      projectsAPI
        .validateProjectNumber(this.number)
        .then((resp) => {
          this.$log("validateProjectNumber:", resp);
          this.isCheckingProjectNumber = false;
          if (resp == null || !resp.data) {
            this.numberErrors = ["Invalid Number!"];
            this.isProjectNumberValid = false;
          } else {
            this.numberErrors = [];
            this.isProjectNumberValid = true;
          }
        })
        .catch((err) => {
          this.isCheckingProjectNumber = false;
          this.numberErrors = ["Invalid Number!"];
          this.isProjectNumberValid = false;
        });
    },
    updateProjectNumberConfirmed() {
      if (!this.isProjectNumberValid) {
        return;
      }
      this.isUpdatingProjectNumber = true;
      projectsAPI
        .updateProjectNumber(this.projectId, this.number)
        .then((resp) => {
          this.isUpdatingProjectNumber = false;
          this.$log("updateProjectNumber:", this.number, resp);
          this.$emit("save", this.number);
          this.discard();
        })
        .catch((err) => {
          this.isUpdatingProjectNumber = false;
        });
    },
  },
};
</script>
