<template>
  <slideout
    dock="right"
    :size="nested ? '87%' : '800px'"
    :min-size="nested ? 400 : 800"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.fileManager.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="contract != null" class="font-weight-bold pa-1" small>
        <i class="fad fa-folder-open mr-2"></i> Contract Documents: <b>{{ contract.name }}</b>
      </h3>
      <v-btn @click="discard()" icon><i class="far fa-times"></i></v-btn>
    </template>

    <v-container
      fluid
      key="results"
      class="pa-0"
      v-if="slideouts.fileManager.active && contract != null"
    >
      <div style="background: #eceff1; height: 100%" class="px-3 py-2 inset-shadow">
        <file-manager
          id="contract-file-manager"
          ref="contractFileManager"
          label="Contract Documents"
          multiple
          :base-url="baseUrl"
          :disabled="contract.isLocked"
          :can-upload="canUploadDocs"
          :can-update="canUpdateDocs"
          :can-download="canDownloadDocs"
          :can-delete="canDeleteDocs"
        ></file-manager>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="discard" color="secondary"><i class="far fa-times mr-2"></i>Close</v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import FileManager from "../../Shared/components/FileManager/FileManager.vue";
import perms from "../../../plugins/permissions";

export default {
  name: "contract-file-manager",
  data() {
    return {
      perms,
      baseUrl: null,
      contractId: null,
      contract: null,
      slideouts: {
        fileManager: {
          isLoading: false,
          active: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    discard() {
      this.slideouts.fileManager.active = false;
    },
    open(contractId) {
      this.contractId = contractId;
      this.contract = null;
      this.baseUrl = `Contracts/${contractId}/Files`;
      this.slideouts.fileManager.active = true;
    },
    openWithContract(contract) {
      this.contractId = contract.id;
      this.contract = contract;
      this.baseUrl = `Contracts/${contract.id}/Files`;
      this.slideouts.fileManager.active = true;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.fileManager.isLoading && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
      this.contractId = null;
      this.contract = null;
      this.baseUrl = null;
    },
  },
  computed: {
    canUploadDocs() {
      return this.$has(perms.ProjectContracts.DocumentsUpload);
    },
    canDownloadDocs() {
      return this.$has(perms.ProjectContracts.DocumentsDownload);
    },
    canUpdateDocs() {
      return this.$has(perms.ProjectContracts.DocumentsUpdateDetails);
    },
    canDeleteDocs() {
      return this.$has(perms.ProjectContracts.DocumentsDelete);
    },
  },
  mounted() {},
  components: { FileManager },
};
</script>

<style lang="scss">
.v-list-item__title {
  font-size: 14px !important;
}

// .old-contract-container {
//   padding: 0.5rem;
//   border: 1px dashed rgba($shades-black, 0.4);
//   border-radius: 0.5rem;
// }
</style>
