import Api from "../../Shared/services/api";
import ApiUploader from "../../Shared/services/apiUploader";

const baseUrl = "levels";
export default {
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  get(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
};
