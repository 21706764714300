<template>
  <span v-if="space != null">
    <span class="fs-14px info--text mr-2" v-if="space.level.building">
      <i class="far fa-building mr-1"></i> {{ space.level.building.name }}
    </span>
    <span class="fs-14px success--text mr-2">
      <i class="far fa-layer-group mr-1"></i>
      {{ space.level.name }}
    </span>
    <span
      class="fs-12px mr-2 opacity-54"
      v-if="space.spaceNumber != null && space.spaceNumber != ''"
    >
      <i class="fas fa-hashtag mr-1"></i>{{ space.spaceNumber }}
    </span>
    <span class="fs-12px mr-2 opacity-54" v-else>
      <i class="fas fa-hashtag mr-1"></i><i class="fal fa-horizontal-rule"></i>
    </span>
    <span class="fs-14px mr-2">
      {{ space.name }}
    </span>
  </span>
</template>

<script>
export default {
  name: "space-info-line",
  data() {
    return {};
  },
  props: {
    space: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
