<template>
  <div>
    <slideout
      dock="right"
      :size="nested ? '87%' : '1100px'"
      :min-size="nested ? 400 : 1100"
      :allow-resize="nested ? false : true"
      :append-to="nested ? '' : '#app'"
      :visible.sync="slideouts.activityLog.active"
      class="slideout-dark-header"
    >
      <template v-slot:header>
        <h4 v-if="!isLogsInitiallyLoaded && isLoading" class="font-weight-bold pa-1" small>
          <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Space Activity Logs ...
        </h4>
        <h4 v-else-if="spaceId != null" class="font-weight-bold pa-1" small>
          <span>
            <i class="mr-2 fad fa-history"></i>Activity Logs: "<b
              >{{ space.spaceNumber }} {{ space.name }}</b
            >"
          </span>
        </h4>
        <div class="d-flex flex-row align-center">
          <div
            v-if="spaceId != null"
            class="d-flex flex-row align-center mr-2 blue-grey darken-2 px-2"
            style="gap: 0.5rem; border-radius: 0.35rem"
          >
            <div>
              <span class="fs-12px font-weight-bold mr-1 white--text">Created by</span>
              <user-avatar :user="space.createdBy" icon showNoUser tooltipBottom></user-avatar>
            </div>
            <div>
              <span class="fs-12px font-weight-bold mr-1 white--text">Updated by</span>
              <user-avatar :user="space.updatedBy" icon showNoUser tooltipBottom></user-avatar>
            </div>
          </div>
          <v-btn @click="slideouts.activityLog.active = false" icon dark>
            <i class="far fa-times"></i>
          </v-btn>
        </div>
      </template>
      <div style="background: #eceff1; height: 100%; overflow: auto" class="pr-5 inset-shadow">
        <v-timeline align-top dense style="min-height: 100%">
          <v-timeline-item v-for="(item, i) in entities" :key="i" color="white" right fill-dot>
            <template v-slot:icon>
              <user-avatar
                style="z-index: 100"
                :user="item.createdBy"
                icon
                :mini="false"
              ></user-avatar>
            </template>
            <v-card
              :color="
                item.type == ActivityLogType.Automatic.value ? 'blue-grey lighten-4' : 'white'
              "
            >
              <v-card-title class="fs-14px py-2">
                <span class="mr-2">
                  <span v-if="item.type == ActivityLogType.Automatic.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-bolt-auto"></i>
                        </div>
                      </template>
                      <span>Auto logged by Concordia</span>
                    </v-tooltip>
                  </span>
                  <span v-else-if="item.type == ActivityLogType.Manual.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-square-quote"></i>
                        </div>
                      </template>
                      <span>This note is manually added by user</span>
                    </v-tooltip>
                  </span>
                  <span v-else-if="item.type == ActivityLogType.RelatedActions.value">
                    <v-tooltip top z-index="999" nudge-top="-4px">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="text-center"
                          style="font-size: 16px; width: 24px"
                        >
                          <i class="fad fa-cog"></i>
                        </div>
                      </template>
                      <span>This is a log for an action performed by user</span>
                    </v-tooltip>
                  </span>
                </span>
                <dater :date="item.createDate" no-tooltip></dater>
                <u
                  v-if="item.variance.sourceOfLog == SourceOfLog.SpaceTemplate"
                  class="font-weight-bold fs-12px deep-purple--text ml-2"
                >
                  Updated by Space Template
                </u>
              </v-card-title>
              <v-card-text v-if="item.type == ActivityLogType.Automatic.value">
                <v-expansion-panels multiple>
                  <v-expansion-panel key="props">
                    <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                      <v-row no-gutters justify="space-between">
                        <v-col cols="auto" class="d-flex align-center">
                          <span class="font-weight-bold">
                            <v-chip
                              style="
                                height: 22px;
                                min-width: 22px;
                                justify-content: center;
                                font-size: 12px;
                              "
                              color="blue-grey"
                              text-color="white"
                              class="mr-2"
                              >{{ item.variance.props.length }}</v-chip
                            >Change{{ item.variance.props.length > 1 ? "s" : "" }}
                          </span>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="logged-changes-panel-content">
                      <div v-for="(changedProp, j) in item.variance.props" :key="j">
                        <v-divider class="mb-1"></v-divider>
                        <div class="py-2 px-4 mb-1">
                          <span class="d-flex align-center">
                            <v-chip small color="blue-grey" text-color="white" style="height: 22px">{{
                              changedProp.prop
                                .split(/(?=[A-Z])/)
                                .join(" ")
                                .replace(". ", " > ")
                            }}</v-chip>
                            <span class="font-weight-medium ml-2">was updated</span>
                          </span>
                          <v-row class="mt-1">
                            <v-col cols="12" md="6">
                              <div class="d-flex">
                                <span class="font-weight-bold mr-2">From:</span>
                                <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.oldValue"
                                ></manufacturer-logged-prop> -->
                                <default-logged-prop
                                  v-model="changedProp.oldValue"
                                ></default-logged-prop>
                              </div>
                            </v-col>
                            <v-col cols="12" md="6">
                              <div class="d-flex">
                                <span class="font-weight-bold mr-2">To:</span>
                                <!-- <manufacturer-logged-prop
                                  v-if="changedProp.prop == 'Manufacture'"
                                  v-model="changedProp.newValue"
                                ></manufacturer-logged-prop> -->
                                <default-logged-prop
                                  v-model="changedProp.newValue"
                                ></default-logged-prop>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel
                    key="equip"
                    v-if="
                      item.variance.areaGroups.length > 0 ||
                      item.variance.oldAreaGroups.length > 0 ||
                      item.variance.areaGroupsDiffs.length > 0
                    "
                  >
                    <v-expansion-panel-header class="px-4 py-0" :ripple="true">
                      <v-row no-gutters justify="space-between">
                        <v-col cols="auto" class="d-flex align-center">
                          <span class="font-weight-bold"> Equipment Changes </span>
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center"> </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="logged-changes-panel-content pa-2 pt-0">
                      <v-container
                        fluid
                        class="blue-grey lighten-5"
                        style="
                          border-radius: 4px;
                          border: 1.5px solid;
                          border-color: #b39ddb !important;
                        "
                      >
                        <!-- <v-divider class="mt-4"></v-divider> -->
                        <h3 class="secondary--text">
                          <v-sheet elevation="1" rounded="xl" class="d-inline-flex align-center">
                            <label class="fake-label ma-0 mx-2">Show as: </label>
                            <v-btn-toggle
                              v-model="item.variance.diffsView"
                              mandatory
                              color="deep-purple"
                              dense
                              group
                              style="height: 28px"
                            >
                              <v-btn
                                :value="false"
                                color="white"
                                class="mx-1"
                                style="border-radius: 12px; padding: 8px 10px !important"
                              >
                                <i class="fad fa-camera mr-2"></i>
                                <span style="text-transform: none" class="font-weight-medium">
                                  Snapshots
                                </span>
                              </v-btn>
                              <v-btn
                                :value="true"
                                color="white"
                                class="mx-0 mr-1"
                                style="border-radius: 12px; padding: 8px 10px !important"
                              >
                                <i class="far fa-plus-minus mr-2"></i>
                                <span style="text-transform: none" class="font-weight-medium">
                                  Differences
                                </span>
                              </v-btn>
                            </v-btn-toggle>
                          </v-sheet>
                        </h3>
                        <v-container fluid class="pa-0 mt-2" v-if="item.variance.diffsView">
                          <area-groups-entry-log
                            :areaGroups="item.variance.areaGroupsDiffs"
                          ></area-groups-entry-log>
                        </v-container>
                        <v-container fluid class="pa-0" v-else>
                          <v-row>
                            <v-col cols="12" md="6">
                              <h3
                                class="mb-1 mt-3 blue-grey--text fake-label"
                                style="padding-left: 2px"
                              >
                                From
                              </h3>
                              <area-groups-entry-log
                                :areaGroups="item.variance.oldAreaGroups"
                              ></area-groups-entry-log>
                            </v-col>
                            <v-col cols="12" md="6">
                              <h3
                                class="mb-1 mt-3 blue-grey--text fake-label"
                                style="padding-left: 2px"
                              >
                                To
                              </h3>
                              <area-groups-entry-log
                                :areaGroups="item.variance.areaGroups"
                              ></area-groups-entry-log>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
              <v-card-text v-else-if="item.type == ActivityLogType.Manual.value">
                <div
                  class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0 secondary--text"
                  v-html="
                    item.body != null && item.body != ''
                      ? item.body
                      : `<i class='fad fa-empty-set opacity-64'></i>`
                  "
                />
              </v-card-text>
              <v-card-text v-else-if="item.type == ActivityLogType.RelatedActions.value">
                <related-actions-logs v-model="item.body"></related-actions-logs>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="!isEndReached"
            :key="'end-not-reached'"
            color="white"
            right
            fill-dot
          >
            <template v-slot:icon>
              <i class="fad fa-spinner-third fa-spin"></i>
            </template>
            <v-card-title v-intersect="onIntersect" class="fs-14px py-1 px-0">
              Fetching more activity logs...
            </v-card-title>
          </v-timeline-item>
          <v-timeline-item v-else :key="'end-reached'" color="white" right fill-dot>
            <template v-slot:icon>
              <i class="fad fa-flag-pennant"></i>
            </template>
            <v-card-title v-if="entities.length == 0" class="fs-14px py-1 px-0 text-secondary">
              No logs recorded yet!
            </v-card-title>
            <v-card-title v-else class="fs-14px py-1 px-0 text-secondary">End of Logs</v-card-title>
          </v-timeline-item>
        </v-timeline>
      </div>
      <template v-slot:footer>
        <v-card-actions>
          <v-btn text @click="slideouts.activityLog.active = false">
            <i class="fal fa-times mr-2"></i>
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="openNoteModal">
            <i class="far fa-plus mr-2"></i>
            Add Note/Review
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>

    <v-dialog v-model="modals.note.active" max-width="850px" style="z-index: 99999999">
      <v-card dense>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Add Note / Review
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.note.valid" ref="addManualNote">
          <v-card-text>
            <v-container fluid class="pa-0">
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <rich-text-editor
                    v-model="modals.note.body"
                    title="Note"
                    subtitle="Add your notes or review for this space"
                    showLabel
                    maxHeight="132px"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modals.note.active = false" :disabled="modals.note.isLoading">
              <i class="fal fa-xmark mr-2"></i>Cancel
            </v-btn>
            <v-btn
              color="info"
              :loading="modals.note.isLoading"
              :disabled="!validNoteString || modals.note.isLoading"
              @click="noteConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Enums from "../../../../plugins/enums";
import spaceService from "../../services/spaces-service";
import RichTextEditor from "../../../Shared/components/RichTextEditor.vue";
import RelatedActionsLogs from "./RelatedActionsLogs.vue";
import DefaultLoggedProp from "./DefaultLoggedProp.vue";
import AreaGroupsEntryLog from "./AreaGroupsEntryLog.vue";

export default {
  name: "space-activity-log",
  components: {
    RichTextEditor,
    RelatedActionsLogs,
    DefaultLoggedProp,
    AreaGroupsEntryLog,
  },
  data() {
    return {
      ActivityLogType: Enums.ActivityLogType,
      SourceOfLog: {
        Space: 0,
        SpaceTemplate: 1,
      },
      isLogsInitiallyLoaded: false,
      isEndReached: false,
      isIntersecting: false,
      isLoading: false,
      spaceId: null,
      space: null,
      entities: [],
      modals: {
        note: {
          body: null,
          valid: false,
          active: false,
          isLoading: false,
        },
      },
      slideouts: {
        activityLog: {
          active: false,
        },
      },
      options: {
        search: null,
        includeSpace: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      },
      storedSearch: null,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.spaceId = this.cloneDeep(this.value);
  },
  computed: {
    // spaceUrl() {
    //   if (this.spaceId == null) return "";
    //   return `space/${this.spaceId}`;
    // },
    validNoteString() {
      if (!this.modals.note.active) return false;
      if (this.stripHtmlTags(this.modals.note.body) == "") return false;
      return true;
    },
  },
  methods: {
    openNoteModal() {
      this.modals.note = {
        body: null,
        valid: false,
        active: true,
        isLoading: false,
      };
    },
    noteConfirmed() {
      this.modals.note.isLoading = true;
      spaceService
        .addActivityLog(this.spaceId, this.modals.note.body)
        .then((resp) => {
          this.modals.note = {
            body: null,
            valid: false,
            active: false,
            isLoading: false,
          };
          resp.data.variance.props.forEach((variance) => {
            if (variance.prop == "ImageUrl") variance.prop = "Image";
          });
          this.entities.unshift(resp.data);
        })
        .catch((err) => {
          this.this.modals.note.isLoading = false;
          this.$handleError(err);
        });
    },
    stripHtmlTags(str) {
      if (str === null || str === "") return "";
      else str = str.toString();

      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/gi, "");
    },
    open(space) {
      this.space = space;
      this.spaceId = space.id;
      this.isEndReached = false;
      this.entities = [];
      this.options = {
        search: null,
        includeSpace: false,
        page: 0,
        itemsPerPage: 5,
        sortBy: "createDate",
        sortDesc: true,
      };
      this.getData();
      this.slideouts.activityLog.active = true;
    },
    getData() {
      this.$log(">>>> getData - this.isEndReached", this.isEndReached);
      if (this.isEndReached || !this.slideouts.activityLog.active || this.isLoading) return;
      this.options.page++;
      this.$log(">>>> getData > PROCEEDED > PAGE", this.options.page);
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      // if (!this.isLogsInitiallyLoaded) {
      //   optionsToSend.includeSpace = true;
      // }
      this.isLoading = true;
      spaceService
        .getActivityLog(this.spaceId, this.$clean(optionsToSend, true))
        .then((resp) => {
          if (!this.isLogsInitiallyLoaded) {
            this.isLogsInitiallyLoaded = true;
            // this.space = resp.data.space;
          }
          if (resp.data.items.length < this.options.itemsPerPage) {
            this.isEndReached = true;
          }
          this.$log("resp.data", resp.data, "isEndReached ", this.isEndReached);
          resp.data.items.forEach((element) => {
            if (element.variance.props != null)
              element.variance.props.forEach((variance) => {
                if (variance.prop == "ImageUrl") variance.prop = "Image";
              });
            element.variance.diffsView = true;
            element.variance.areaGroupsDiffs = this.compare(
              this.cloneDeep(element.variance.oldAreaGroups || []),
              this.cloneDeep(element.variance.areaGroups || [])
            );
            this.entities.push(element);
          });
          this.total = resp.data.total;
          this.isLoading = false;

          setTimeout(() => {
            this.$log(">>>> setTimeout - this.isIntersecting:", this.isIntersecting);
            if (this.isIntersecting && !this.isEndReached) {
              this.getData();
            }
          }, 500);
        })
        .catch((err) => {
          this.isLoading = false;
          this.$handleError(err);
        });
    },
    onIntersect(entries, observer) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      this.isIntersecting = entries[0].isIntersecting;
      this.$log(">>>> onIntersect - this.isIntersecting:", this.isIntersecting);
      if (this.isIntersecting) {
        this.getData();
      }
    },
    compare(oldGroups, newGroups) {
      //check old groups
      for (let i = 0; i < oldGroups.length; i++) {
        const oldGroup = oldGroups[i];
        const matchingNewGroup = newGroups.find((ng) => ng.id == oldGroup.id);
        oldGroup.diffs = {};
        if (matchingNewGroup == null) oldGroup.diffs["deleted"] = true;
        else {
          //check for deleted equipment
          for (let j = 0; j < oldGroup.equipments.length; j++) {
            const oldEquip = oldGroup.equipments[j];
            const matchingNewEquip = matchingNewGroup.equipments.find((ne) => ne.id == oldEquip.id);
            oldEquip.diffs = {};
            if (matchingNewEquip == null) {
              // this is a new equipment
              oldEquip.diffs["deleted"] = true;
            }
          }
        }
      }

      //check new groups
      for (let i = 0; i < newGroups.length; i++) {
        const newGroup = newGroups[i];
        const matchingOldGroup = oldGroups.find((og) => og.id == newGroup.id);
        newGroup.diffs = {};
        if (matchingOldGroup == null) {
          // this is a new group
          newGroup.diffs["new"] = true;
        } else {
          //check if group name changed
          if (newGroup.name != matchingOldGroup.name) newGroup.diffs["nameChanged"] = true;

          //check for equipment changes
          var updateCount = 0;
          for (let j = 0; j < newGroup.equipments.length; j++) {
            const newEquip = newGroup.equipments[j];
            const matchingOldEquip = matchingOldGroup.equipments.find((oe) => oe.id == newEquip.id);
            newEquip.diffs = {};
            if (matchingOldEquip != null) {
              //check if the count changed
              if (newEquip.count != matchingOldEquip.count) {
                newEquip.diffs["countChanged"] = true;
                updateCount++;
              }
            } else {
              // this is a new equipment
              newEquip.diffs["new"] = true;
              updateCount++;
            }
          }
          if (updateCount > 0) newGroup.diffs["equipmentUpdated"] = true;

          // add deleted equipments
          var deletedOldEquipment = matchingOldGroup.equipments.filter((e) => e.diffs.deleted);
          if (deletedOldEquipment.length > 0) newGroup.equipments.push(...deletedOldEquipment);
        }
      }

      // add deleted equipments
      var deletedOldGroups = oldGroups.filter((e) => e.diffs.deleted);
      if (deletedOldGroups.length > 0) newGroups.push(...deletedOldGroups);

      return newGroups;
    },
  },
  watch: {
    value: {
      handler() {
        this.spaceId = this.cloneDeep(this.value);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.logged-changes-panel-content .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
