<template>
  <slideout
    dock="right"
    :size="nested ? '64%' : '700px'"
    :min-size="nested ? 400 : 700"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.selectSpaces.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 class="font-weight-bold pa-1" small>
        <i class="fad fa-check-square mr-2"></i>Select Spaces for
        <u class="">{{ forSheet }} Sheets</u>
      </h3>
      <v-btn @click="discardSelectSpaces()" icon><i class="far fa-times"></i></v-btn>
    </template>
    <v-container class="px-3 pt-3" style="height: 100%">
      <div
        style="
          background: #eceff1;
          border-radius: 0.5rem;
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;
        "
        class="pa-5 inset-shadow"
      >
        <v-row class="mb-2" justify="space-between">
          <v-col cols="12" md="4">
            <h4 class="ma-0">
              Selected Spaces Count:
              <b class="ml-1 info--text">
                {{ selected.length }}
              </b>
            </h4>
          </v-col>
          <v-col
            cols="12"
            md="8"
            class="align-center flex-wrap justify-end py-0 d-flex"
            style="gap: 0.5rem"
          >
            <filter-menu
              id="reportBuildings"
              title="Building"
              v-model="options.buildingIds"
              :items="buildings"
            >
              <template #[`item`]="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </filter-menu>
            <filter-menu
              id="levels"
              title="Level"
              :disabled="!levels.length"
              v-model="options.levelIds"
              :items="levels"
            >
              <template #[`item`]="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </filter-menu>
            <filter-menu
              id="SpaceTemplates"
              title="Space Template"
              v-model="options.spaceTemplateIds"
              baseUrl="SpaceTemplates"
            >
              <template #[`item`]="{ item }">
                <v-list-item-avatar class="mr-3 my-0" rounded>
                  <v-img
                    height="25"
                    width="25"
                    v-if="item.imageUrl"
                    :src="item.imageUrl"
                    class="img"
                    position="center center"
                    contain
                  ></v-img>
                  <i v-else :class="'fad fa-vector-square'"></i>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </filter-menu>

            <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
            <v-text-field
              v-model="options.search"
              ref="mainSearch"
              class="table-search-field reports-spaces-search"
              @focus="mainSearchInFocus = true"
              @blur="mainSearchInFocus = false"
              :style="{
                'max-width':
                  mainSearchInFocus || (options.search != '' && options.search != null)
                    ? '200px'
                    : '110px',
              }"
              label="Search"
              dense
              solo
              clearable
              prepend-inner-icon="far fa-search"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          style="
            overflow: auto;
            overflow: overlay;
            height: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
          "
          dense
          :headers="headers"
          v-model="selected"
          :items="entities"
          show-select
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="15"
          :loading="loadingStates.table"
          class="elevation-2 table-sticky-header-exclude-footer"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'far fa-arrow-to-left',
            lastIcon: 'far fa-arrow-to-right',
            prevIcon: 'far fa-angle-left',
            nextIcon: 'far fa-angle-right',
            itemsPerPageOptions: [15, 30, 50, 100],
          }"
          item-key="id"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-tooltip top z-index="999" nudge-top="-4px">
              <template v-slot:activator="{ on: ont, attrs }">
                <div v-bind="attrs" v-on="ont">
                  <v-simple-checkbox
                    color="info"
                    :ripple="false"
                    v-bind="props"
                    v-on="on"
                    key="user-header-select"
                  ></v-simple-checkbox>
                </div>
              </template>
              <span>Select all spaces on THIS PAGE ONLY!</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.imageUrl`]="{ item }">
            <v-badge
              color="info"
              left
              :value="selected.id == item.id"
              offset-x="15px"
              offset-y="38px"
            >
              <template v-slot:badge>
                <i class="fas fa-wrench mb-0"></i>
              </template>
              <v-tooltip right z-index="999" nudge-right="-4px">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div class="space-img">
                      <v-img
                        v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                        class="img"
                        :src="item.imageUrl"
                        height="100%"
                        width="100%"
                        contain
                      ></v-img>
                      <i v-else :class="'fad fa-vector-square'"></i>
                    </div>
                  </div>
                </template>
                <span>
                  <v-img
                    v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                    class="img"
                    :src="item.imageUrl"
                    height="250px"
                    width="250px"
                    contain
                  ></v-img>
                  <i
                    v-else
                    :class="'fad fa-vector-square'"
                    style="font-size: 32px; padding: 2rem"
                  ></i>
                </span>
              </v-tooltip>
            </v-badge>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <strong>{{ item.name }}</strong>
          </template>

          <template v-slot:[`item.spaceNumber`]="{ item }">
            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
          </template>

          <template v-slot:[`item.iavSheetCount`]="{ item }">
            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
              item.iavSheetCount
            }}</span>
          </template>
          <template v-slot:[`item.scdSheetCount`]="{ item }">
            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
              item.scdSheetCount
            }}</span>
          </template>
          <template v-slot:[`item.planSheetCount`]="{ item }">
            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
              item.planSheetCount
            }}</span>
          </template>
          <template v-slot:[`item.overviewSheetCount`]="{ item }">
            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
              item.overviewSheetCount
            }}</span>
          </template>
          <template v-slot:[`item.detailSheetCount`]="{ item }">
            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
              item.detailSheetCount
            }}</span>
          </template>

          <template v-slot:[`item.createdBy`]="{ item }">
            <user-avatar :user="item.createdBy" icon></user-avatar>
          </template>

          <template v-slot:[`item.createDate`]="{ item }">
            <dater :date="item.createDate" dateonly></dater>
          </template>

          <template v-slot:[`item.updateDate`]="{ item }">
            <dater :date="item.updateDate" dateonly></dater>
          </template>

          <template v-slot:[`item.equipmentCount`]="{ item }">
            <equipment-count :count="item.equipmentCount" />
          </template>

          <template v-slot:[`item.updatedBy`]="{ item }">
            <span>{{ item.createdBy.firstName }} {{ item.createdBy.lastName }}</span>
          </template>

          <template v-slot:no-data>
            <img width="300" src="/img/art/fogg-no-connection-2.png" />
            <p class="font-weight-bold">No Data Available!</p>
          </template>

          <template v-slot:loading>
            <video width="250" muted loop autoplay style="padding: 0.5rem">
              <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
            </video>
            <p class="font-weight-bold">Searching the Cosmos...</p>
          </template>
        </v-data-table>
      </div>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="discardSelectSpaces()">
          <i class="fal fa-chevron-left mr-2"></i>Cancel
        </v-btn>
        <v-btn
          color="info"
          :disabled="slideouts.selectSpaces.isLoading || selected.length == 0"
          @click="selectConfirmed()"
        >
          <i class="fal fa-check mr-2"></i>Confirm
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import projectService from "../../Projects/services/projects-service";
import spacesService from "../services/spaces-service";
import reportSpaceHeader from "../config/tables/reportSpace.header";

export default {
  components: {},
  props: {
    projectId: {
      type: Number,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportSpaceHeader,
      forSheet: null,
      buildings: [],
      levels: [],
      entities: [],
      selected: [],
      total: 0,
      mainSearchInFocus: false,
      loadingStates: {
        table: false,
      },
      options: {
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        buildingIds: [],
        levelIds: [],
        spaceTemplateIds: [],
      },
      slideouts: {
        selectSpaces: {
          active: false,
          isLoading: false,
        },
      },
    };
  },
  computed: {
    headers() {
      let _headers = this.cloneDeep(this.reportSpaceHeader);
      let index = 3;
      if (this.forSheet == "IAV") {
        _headers.splice(index, 0, {
          text: "IAV Sheet Count",
          value: "iavSheetCount",
          width: "145px",
        });
      } else if (this.forSheet == "SCD") {
        _headers.splice(index, 0, {
          text: "SCD Sheet Count",
          value: "scdSheetCount",
          width: "150px",
        });
      } else if (this.forSheet == "Plan") {
        _headers.splice(index, 0, {
          text: "Plan Sheet Count",
          value: "planSheetCount",
          width: "150px",
        });
      } else if (this.forSheet == "Overview") {
        _headers.splice(index, 0, {
          text: "Overview Sheet Count",
          value: "overviewSheetCount",
          width: "175px",
        });
      } else if (this.forSheet == "Detail") {
        _headers.splice(index, 0, {
          text: "Detail Sheet Count",
          value: "detailSheetCount",
          width: "160px",
        });
      }
      return _headers;
    },
  },
  created() {
    this.getBuildingsAndLevels();
  },
  methods: {
    getBuildingsAndLevels() {
      spacesService.getBuildingsAndLevels(this.projectId).then((resp) => {
        this.buildings = resp.data;
      });
    },
    open(initial, forSheet) {
      this.forSheet = this.cloneDeep(forSheet);
      this.selected = this.cloneDeep(initial);
      this.slideouts.selectSpaces.active = true;
      this.$emit("opened");
    },
    discardSelectSpaces() {
      this.slideouts.selectSpaces.active = false;
      this.slideouts.selectSpaces.isLoading = false;
      this.selected = [];
    },
    onSlideoutClosing() {
      this.$emit("closed");
    },
    getProjectSpaces() {
      this.$log("getProjectSpaces()", this.projectId);
      this.loadingStates.table = true;
      projectService
        .getSpaces(this.projectId, this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("getProjectSpaces", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    selectConfirmed() {
      this.$emit("updated", this.selected);
      this.discardSelectSpaces();
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.cloneDeep(this.value);
        }
      },
      deep: true,
    },
    options: {
      handler() {
        this.getProjectSpaces();
      },
      deep: true,
    },
    "options.buildingIds"(ids) {
      this.options.levelIds = [];
      this.levels = this.buildings
        .filter((elm) => ids.includes(elm.id))
        .map((elm) => elm.levels)
        .flat();
    },
  },
};
</script>
