<template>
  <v-container fluid class="pa-3" v-if="project != null">
    <v-form v-model="valid" ref="projectInformationForm" :disabled="!isProjectInitiallyLoaded">
      <div class="d-flex justify-space-between align-center" style="width: 100%">
        <h3 class="text--primary font-weight-black">Basic Details</h3>
        <transition name="fade" mode="out-in" appear>
          <div
            key="project-info-vars-btns"
            class="d-flex"
            style="gap: 0.5rem"
            v-if="project.id && !readonly"
          >
            <v-btn
              color="secondary"
              @click="$emit('discard')"
              :disabled="!hasChange || isProjectSaving"
            >
              <i class="fal fa-clock-rotate-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              @click="saveProjectInfo()"
              :loading="isProjectInfoSaving"
              :disabled="!valid || isProjectSaving || !hasChange"
            >
              <i class="fal fa-check mr-2"></i>Save Information
            </v-btn>
          </div>
        </transition>
      </div>
      <v-divider class="mb-3 mt-2"></v-divider>
      <v-row class="mt-3 mb-0">
        <v-col cols="12" md="4" sm="12" class="py-0">
          <v-text-field-alt
            :rules="[allRules.required, allRules.noWhiteSpaces]"
            label="Name"
            id="projectName"
            ref="projectNameBox"
            placeholder="Project Name"
            v-model="project.name"
            :readonly="readonly"
          >
          </v-text-field-alt>
        </v-col>
        <v-col cols="12" :md="createMode ? 3 : 4" sm="12" class="py-0">
          <v-select-alt
            label="Project Type"
            id="ProjectType"
            placeholder="Project Type"
            :rules="[allRules.required]"
            v-model="project.type"
            :items="enums.PROJECT_TYPE_LIST"
            dense
            filled
            :readonly="readonly || project.stage != enums.PROJECT_STAGE.Opportunity.value"
          >
            <template v-slot:selection="{ item }">
              <span>
                <b class="secondary white--text rounded px-2 mr-1">{{ item.letter }}</b>
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <span>
                <b class="secondary white--text rounded px-2 mr-1">{{ item.letter }}</b>
                {{ item.text }}
              </span>
            </template>
          </v-select-alt>
        </v-col>
        <v-col v-if="createMode" cols="12" md="3" sm="12" class="py-0" key="create-num-col">
          <v-text-field-alt
            :label="
              project.autoGenerateProjectNumber
                ? `Project Number ${toBeAutoGenerated}`
                : `Project Number ${
                    isCheckingProjectNumber
                      ? '<i class=`far fa-spinner-third fa-spin ml-2`></i>'
                      : ''
                  }`
            "
            id="projectNumber"
            ref="projectNumberBox"
            placeholder="Ex: 23107"
            v-model="project.number"
            @blur="checkProjectNumber"
            :error-messages="numberErrors"
            :disabled="isCheckingProjectNumber"
            :loading="isCheckingProjectNumber"
            :readonly="readonly || project.autoGenerateProjectNumber"
          >
          </v-text-field-alt>
        </v-col>
        <v-col v-else cols="12" md="4" sm="12" class="py-0" key="edit-num-col">
          <div class="comp-wrpr">
            <v-text-field-alt
              label="Project Number"
              id="projectNumberReadonly"
              ref="projectNumberBoxReadonly"
              placeholder="Ex: 23107"
              v-model="project.number"
              readonly
            >
            </v-text-field-alt>
            <v-btn
              v-has="perms.Projects.UpdateProjectNumber"
              min-width="28px !important"
              width="28px !important"
              height="28px !important"
              color="secondary"
              class="pa-0"
              small
              @click="openEditProjectDialog"
            >
              <i class="fas fa-pen"></i>
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="createMode" cols="12" md="2" sm="12" class="py-0" key="auto-gen-num-col">
          <div
            class="d-flex flex-row-reverse align-center justify-end"
            style="gap: 0.25rem; height: 100%"
          >
            <label class="v-switch-label" for="autoGenerateProjectNumber">Auto Generate?</label>
            <v-switch
              id="autoGenerateProjectNumber"
              color="orange"
              class="ma-0 pa-0"
              v-model="project.autoGenerateProjectNumber"
              hide-details
            ></v-switch>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="12" class="py-0" key="stage-col">
          <v-select-alt
            id="ProjectStage"
            label="Project Stage"
            placeholder="Project Stage"
            v-model="project.stage"
            :readonly="readonly"
            :items="projectStagesList"
            hide-details
            transparent
            @change="stageChange"
            item-text="textSingle"
            item-value="value"
          >
            <template v-slot:selection="{ item }">
              <project-stage class="project-stage-pointer" :stage="item.value"></project-stage>
            </template>
            <template v-slot:item="{ item }">
              <project-stage class="project-stage-pointer" :stage="item.value"></project-stage>
            </template>
          </v-select-alt>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="py-0" key="status-col">
          <v-select-alt
            id="ProjectStatus"
            :rules="[allRules.required]"
            label="Project Status"
            placeholder="Project Status"
            v-model="project.status"
            :items="projectStatusList"
            :readonly="readonly"
          >
            <template v-slot:selection="{ item }">
              <project-status :status="item.value"></project-status>
            </template>
            <template v-slot:item="{ item }">
              <project-status :status="item.value"></project-status>
            </template>
          </v-select-alt>
        </v-col>
        <v-col cols="12" md="5" sm="12" class="py-0">
          <!-- :obj.sync="project.client" -->
          <client-selector
            v-model="project.clientId"
            :readonly="readonly"
            required
          ></client-selector>
        </v-col>
        <!-- <v-col cols="12" :md="createMode ? 4 : 6" sm="12" class="py-0"> -->
        <v-col
          cols="12"
          :md="createMode ? 6 : 6"
          sm="12"
          class="py-0 d-flex align-center"
          style="gap: 0.75rem"
        >
          <jira-project-selector
            style="width: 100%"
            :label="
              project.createJiraProject
                ? `<i class='fab fa-jira mr-1'></i> Jira Project ${toBeAutoGenerated}`
                : `<i class='fab fa-jira mr-1'></i> Jira Project`
            "
            v-model="project.jiraCode"
            ref="jiraProjectSelector"
            :readonly="readonly"
          >
            <template
              v-slot:label-append
              v-if="
                false &&
                !createMode &&
                project.jiraCreationStatus != enums.LinkedProjectStatus.Idle.value
              "
            >
              <v-tooltip left max-width="320px" z-index="99999" class="ml-2" color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="text--secondary"
                    style="font-size: 18px; line-height: 0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fad fa-info-circle"></i>
                  </div>
                </template>
                <span style="font-size: 14px"> Auto Creation: {{ jiraProjectStatus }} </span>
              </v-tooltip>
            </template>
          </jira-project-selector>
          <div
            v-if="false && createMode && $has(perms.Projects.CreateJiraProject)"
            class="d-flex flex-row-reverse align-center justify-end"
            style="gap: 0.25rem; height: 100%"
          >
            <label class="v-switch-label" for="createJiraProject" style="white-space: nowrap">
              Auto Create?
            </label>
            <v-switch
              id="createJiraProject"
              color="orange"
              class="ma-0 pa-0"
              v-model="project.createJiraProject"
              hide-details
            ></v-switch>
          </div>
        </v-col>
        <!-- <v-col cols="12" :md="createMode ? 4 : 6" sm="12" class="py-0" key="teams-col"> -->
        <v-col
          cols="12"
          :md="createMode ? 6 : 6"
          sm="12"
          class="py-0 d-flex align-center"
          key="ms-teams-col"
          style="gap: 0.75rem"
        >
          <microsoft-teams-selector
            style="width: 100%"
            ref="microsoftTeamsSelector"
            :label="
              project.createMSTeam
                ? `<img src='${defaultTeamsLogo}' style='height: 14px;' class='mr-2'> MS Team ${toBeAutoGenerated}`
                : `<img src='${defaultTeamsLogo}' style='height: 14px;' class='mr-2'> MS Team`
            "
            :team-id.sync="project.microsoftTeamId"
            :site-name.sync="project.microsoftGeneralSiteName"
            :general-channel-id.sync="project.microsoftGeneralChannelId"
            :auto-generate="project.createMSTeam"
            :readonly="
              readonly ||
              project.createMSTeam ||
              project.msTeamCreationStatus == enums.LinkedProjectStatus.Pending.value ||
              project.msTeamCreationStatus == enums.LinkedProjectStatus.Inprogress.value
            "
          >
            <template
              v-slot:label-append
              v-if="
                !createMode && project.msTeamCreationStatus != enums.LinkedProjectStatus.Idle.value
              "
            >
              <v-tooltip left max-width="320px" z-index="99999" class="ml-2" color="secondary">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="text--secondary"
                    style="font-size: 18px; line-height: 0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fad fa-info-circle"></i>
                  </div>
                </template>
                <span style="font-size: 14px"> Auto Creation: {{ msTeamStatus }} </span>
              </v-tooltip>
            </template>
          </microsoft-teams-selector>
          <div
            v-if="createMode && $has(perms.Projects.CreateMsTeam)"
            class="d-flex flex-row-reverse align-center justify-end"
            style="gap: 0.25rem; height: 100%"
          >
            <label class="v-switch-label" for="createMSTeam" style="white-space: nowrap">
              Auto Create?
            </label>
            <v-switch
              id="createMSTeam"
              color="orange"
              class="ma-0 pa-0"
              v-model="project.createMSTeam"
              hide-details
            ></v-switch>
          </div>
        </v-col>
        <v-col cols="12" md="5" sm="12" class="py-0" key="img-upl-col">
          <single-image-uploader
            id="add-project-uploader"
            :apiUrl="imageApiUrl"
            v-model="project.imageUrl"
            label="Project Image"
            full-height
            :disabled="!isProjectInitiallyLoaded || readonly"
          ></single-image-uploader>
        </v-col>
        <v-col cols="12" md="7" sm="12" class="py-0" key="rich-desc-col">
          <rich-text-editor
            v-model="project.description"
            title="Description"
            showLabel
            :readonly="readonly"
            allowExpand
            maxHeight="140px"
          >
            <template v-slot:subtitle>
              <div class="d-flex align-center flex-wrap" style="gap: 0.5rem">
                <span
                  class="font-weight-medium orange--text text--darken-2"
                  style="position: relative"
                >
                  <i class="fas fa-asterisk fs-9px" style="top: 3px; position: absolute"></i>
                  <span style="margin-left: 9px">For Internal Use Only!</span>
                </span>
                <span
                  class="d-inline-flex align-center ml-2"
                  v-if="$has(perms.ProjectVariables.View)"
                >
                  <i class="fad fa-circle-info mr-1"></i>To access reports description:
                </span>
                <v-btn
                  color="secondary"
                  small
                  dark
                  min-height="24px"
                  height="24px"
                  class="px-2"
                  @click="switchToVariablesTab"
                  :disabled="isProjectSaving"
                >
                  <i class="fad fa-right mr-2"></i>Open Variables Tab
                </v-btn>
              </div>
            </template>
          </rich-text-editor>
        </v-col>
      </v-row>
      <h3 class="text--primary font-weight-black mt-4 mt-md-6">Project Location</h3>
      <v-divider class="mb-3 mt-2"></v-divider>
      <address-detail :readonly="readonly" v-model="project"></address-detail>
    </v-form>
    <slideout
      dock="right"
      :size="'80%'"
      :min-size="600"
      :append-to="'#app'"
      :visible.sync="slideouts.sharepoint.active"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold" small>
          <i class="fad fa-edit mr-2 mt-1"></i>Teams Channel SharePoint
        </h3>
        <div>
          <v-btn @click="discardSharepointSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>

      <v-container class="pa-0" style="height: 100%; width: 100%; max-width: 100%">
        <iframe
          style="height: 100%; width: 100%; max-width: 100%"
          v-if="slideouts.sharepoint.active"
          :src="slideouts.sharepoint.url"
          frameborder="0"
        ></iframe>
      </v-container>

      <template v-slot:footer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="discardSharepointSlideout()">
            <i class="fal fa-chevron-left mr-2"></i>Back
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
    <edit-project-number-modal
      ref="editProjectNumberModal"
      @save="projectNumberChanged"
    ></edit-project-number-modal>
  </v-container>
</template>

<script>
import ProjectStage from "../components/ProjectStage.vue";
import ProjectStatus from "../components/ProjectStatus.vue";
import JiraProjectSelector from "../../Shared/components/JiraProjectSelector.vue";
import ClientSelector from "../../Shared/components/ClientSelector.vue";
// import UserSelector from "../../Shared/components/UserSelector.vue";
import projectsAPI from "../services/projects-service";
import AddressDetail from "../../Shared/components/AddressDetail.vue";
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import EditProjectNumberModal from "./EditProjectNumberModal.vue";
import MicrosoftTeamsSelector from "./MicrosoftTeamsSelector.vue";

export default {
  components: {
    ProjectStatus,
    JiraProjectSelector,
    ClientSelector,
    AddressDetail,
    RichTextEditor,
    ProjectStage,
    EditProjectNumberModal,
    MicrosoftTeamsSelector,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    projectStatusList: {
      type: Array,
      default: () => [],
    },
    isProjectInitiallyLoaded: {
      type: Boolean,
    },
    isProjectSaving: {
      type: Boolean,
    },
    hasChange: {
      type: Boolean,
    },
    createMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      project: { client: null },
      perms,
      enums,
      numberErrors: [],
      isCheckingProjectNumber: false,
      isProjectNumberValid: false,
      valid: false,
      toBeAutoGenerated: ` <span class='orange--text fs-12px ml-2'><i class='fas fa-lock-a mr-1'></i>To Be Auto Generated</span>`,
      imageApiUrl: `Projects/Image`,
      isProjectInfoSaving: false,
      slideouts: {
        sharepoint: {
          active: false,
          url: null,
        },
      },
      defaultTeamsLogo: `https://upload.wikimedia.org/wikipedia/commons/c/c9/Microsoft_Office_Teams_%282018%E2%80%93present%29.svg`,
    };
  },
  methods: {
    projectNumberChanged(newNumber) {
      this.$log("1. projectNumberChanged => ", newNumber);
      this.$emit("project-number-changed", newNumber);
    },
    openEditProjectDialog() {
      this.$refs.editProjectNumberModal.open(this.project);
    },
    switchToVariablesTab() {
      this.$emit("switch-to-variables");
    },
    refreshJiraProjects() {
      this.$refs.jiraProjectSelector.refresh();
    },
    saveProjectInfo() {
      this.emitIsProjectSaving(true);
      this.isProjectInfoSaving = true;
      var toSend = this.cloneDeep(this.project);
      this.$log("saveProjectInfo > this.cloneDeep(this.project)", this.cloneDeep(this.project));
      if (toSend.state != null && typeof toSend.state != "string")
        toSend.state = toSend.state.value;
      this.$log("saveProjectInfo > toSend", toSend);
      projectsAPI
        .update(toSend)
        .then((resp) => {
          this.emitIsProjectSaving(false);
          this.isProjectInfoSaving = false;
          var message = "Project updated!";
          this.emitProject(resp.data);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
        })
        .catch((err) => {
          this.emitIsProjectSaving(false);
          this.isProjectInfoSaving = false;
          this.$handleError(err);
        });
    },
    checkProjectNumber(evt) {
      if (this.project.autoGenerateProjectNumber) return;
      if (this.project.number == null || this.project.number == "") {
        this.numberErrors = ["Invalid Number!"];
        this.isProjectNumberValid = false;
        return;
      }
      this.isCheckingProjectNumber = true;
      projectsAPI
        .validateProjectNumber(this.project.number)
        .then((resp) => {
          this.isCheckingProjectNumber = false;
          this.$log("validateProjectNumber:", resp);
          if (this.project.autoGenerateProjectNumber) {
            this.numberErrors = [];
            this.isProjectNumberValid = true;
            return;
          } else if (resp == null || !resp.data) {
            this.numberErrors = ["Invalid Number!"];
            this.isProjectNumberValid = false;
          } else {
            this.numberErrors = [];
            this.isProjectNumberValid = true;
          }
        })
        .catch((err) => {
          this.isCheckingProjectNumber = false;
          this.numberErrors = ["Invalid Number!"];
          this.isProjectNumberValid = false;
        });
    },
    emitProject(data) {
      this.$emit("update:project", data);
    },
    emitIsProjectSaving(data) {
      this.$emit("update:isProjectSaving", data);
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        this.ctrlKeyActive &&
        event.code == "KeyS" &&
        this.hasChange &&
        this.valid
      ) {
        this.saveProjectInfo();
      }
    },
    activateSaveKeyShortcut() {
      document.addEventListener("keydown", this.documentKeyListener);
    },
    deactivateSaveKeyShortcut() {
      document.removeEventListener("keydown", this.documentKeyListener);
    },
    stageChange() {
      if (this.isProjectInitiallyLoaded) {
        this.project.status = null;
      }
    },
  },
  computed: {
    projectStagesList() {
      if (!this.isProjectInitiallyLoaded) return [];
      return enums.PROJECT_STAGE_LIST;
    },
    msTeamStatus() {
      if (this.project == null || this.project.id == null || !this.isProjectInitiallyLoaded)
        return "";
      return this.getEnumMember(enums.LinkedProjectStatus, this.project.msTeamCreationStatus).text;
    },
    jiraProjectStatus() {
      if (this.project == null || this.project.id == null || !this.isProjectInitiallyLoaded)
        return "";
      return this.getEnumMember(enums.LinkedProjectStatus, this.project.jiraCreationStatus).text;
    },
  },
  created() {},
  mounted() {
    this.project = this.cloneDeep(this.value);
    this.activateSaveKeyShortcut();
    if (this.project.autoGenerateProjectNumber) {
      this.project.number = null;
      this.numberErrors = [];
      this.isProjectNumberValid = true;
    }
  },
  beforeDestroy() {
    this.deactivateSaveKeyShortcut();
  },
  watch: {
    hasChange() {
      this.$emit("update:has-change", this.hasChange);
    },
    valid() {
      if (this.createMode) this.$emit("valid-changes", this.valid && this.isProjectNumberValid);
      else this.$emit("valid-changes", this.valid);
    },
    isProjectNumberValid() {
      if (!this.createMode) return;
      this.$emit("valid-changes", this.valid && this.isProjectNumberValid);
    },
    "project.autoGenerateProjectNumber": {
      handler(newVal, oldVal) {
        if (!this.createMode) return;
        if (newVal == oldVal) return;
        if (this.project.autoGenerateProjectNumber) {
          this.project.number = null;
          this.numberErrors = [];
          this.isProjectNumberValid = true;
        } else {
          this.project.number = null;
          this.numberErrors = [];
          this.isProjectNumberValid = false;
          this.$refs.projectNumberBox.focus();
        }
      },
      deep: true,
    },
    "project.createJiraProject": {
      handler() {
        if (!this.createMode) return;
        if (this.project.createJiraProject) {
          this.project.jiraCode = null;
        }
      },
      deep: true,
    },
    "project.createMSTeam": {
      handler() {
        if (!this.createMode) return;
        if (this.project.createMSTeam) {
          this.project.microsoftTeamId = null;
          this.project.microsoftGeneralSiteName = null;
          this.project.microsoftGeneralChannelId = null;
        }
      },
      deep: true,
    },
    value: {
      handler() {
        if (this.isEqual(this.value, this.project)) return;
        this.project = this.cloneDeep(this.value);
      },
      deep: true,
    },
    project: {
      handler() {
        if (this.isEqual(this.value, this.project)) return;
        this.$emit("input", this.project);
      },
      deep: true,
    },
  },
};
</script>
