var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.installersList != null && _vm.installersList.length > 0)?_c('div',[(!_vm.readonly)?_c('v-tooltip',{attrs:{"top":"","nudge-top":"-12","z-index":"9999","disabled":_vm.emptyTooltip == null || _vm.emptyTooltip == '' || _vm.installerId != null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pa-0"},'div',attrs,false),on),[_c('v-select-alt',{staticClass:"installer-select",class:_vm.showMenuIcon ? 'show-menu-icon' : '',attrs:{"placeholder":_vm.emptyText != null && _vm.emptyText != '' ? _vm.emptyText : 'N/A',"items":_vm.installersList,"hide-details":"","transparent":"","clearable":_vm.clearable,"item-text":"name","item-value":"id","menu-props":{
            contentClass: 'provider-installer-select-menu',
            zIndex: '999 !important',
          }},on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
return [(item.id)?_c('equipment-installer',{attrs:{"installer-name":item.name}}):_vm._e()]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [(item.id)?_c('equipment-installer',{attrs:{"installer-name":item.name}}):_vm._e()]}}],null,true),model:{value:(_vm.installerId),callback:function ($$v) {_vm.installerId=$$v},expression:"installerId"}})],1)]}}],null,false,13776398)},[_c('span',[_vm._v(_vm._s(_vm.emptyTooltip))])]):(_vm.installerObj)?_c('equipment-installer',{attrs:{"installer-name":_vm.installerObj.name}}):_c('span',{staticClass:"text--disabled fs-14px",staticStyle:{"padding-top":"1px","padding-left":"7px"}},[_vm._v(" N/A ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }