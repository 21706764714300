import Api from "../../Shared/services/api";

const baseUrl = "jira";
export default {
  projects() {
    return Api().get(`${baseUrl}/projects`);
  },
  issues(id) {
    return Api().get(`${baseUrl}/projects/${id}/phases-issues`);
  },
  createJiraPhase(data) {
    return Api().post(`${baseUrl}/projects/create-jira-phase`, data);
  },
  createJiraProject(projectId) {
    return Api().post(`${baseUrl}/projects/create-jira-project/${projectId}`);
  },
  getAvatarWrapperUrl(jiraAvatarUrl) {
    return `${process.env.VUE_APP_API_ENDPOINT}${baseUrl}/avatar?avatarUrl=${encodeURIComponent(
      jiraAvatarUrl
    )}`;
  },
};
