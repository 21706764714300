<template>
  <v-dialog
    v-model="modal.active"
    max-width="400px"
    persistent
    style="z-index: 99999999"
  >
    <v-card>
      <v-card-title class="font-weight-bold" small>
        <span><i class="fad fa-timer mr-2"></i>Shift Contract Phases</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modal.valid" ref="shiftContractForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12" class="d-flex flex-row align-center">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.intNumber]"
                  v-model="modal.data.shiftValue"
                  hideInput
                >
                </v-text-field-alt>

                <label
                  class="mr-2 d-block"
                  style="font-weight: 600 !important; font-size: 15px"
                >
                  Shift By
                </label>
                <counter
                  id="contractShiftTextField"
                  v-model="modal.data.shiftValue"
                  :min="-12"
                  :max="12"
                  :step="1"
                />
                <label
                  class="ml-2 d-block"
                  style="font-weight: 600 !important; font-size: 15px"
                >
                  Months
                </label>
                <input type="text" disabled readonly style="display: none" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="updateContractDiscard()">
            <i class="fal fa-xmark mr-2"></i> Cancel
          </v-btn>
          <v-btn
            color="info"
            :disabled="
              !modal.valid ||
                modal.loading ||
                modal.data.shiftValue == '' ||
                modal.data.shiftValue == null ||
                modal.data.shiftValue == 0
            "
            :loading="modal.loading"
            @click="shiftContractConfirmed()"
          >
            <i class="fal fa-check mr-2"></i> Confirm
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import contractsService from "../services/contracts-service";

export default {
  data() {
    return {
      modal: {
        active: false,
        valid: false,
        loading: false,
        data: {
          shiftValue: 0,
          contractId: null,
          contractName: "",
          contractStatus: null
        }
      }
    };
  },
  methods: {
    openShiftContractDialog(contract) {
      this.modal.data.shiftValue = 0;
      this.modal.data.contractId = contract.id;
      this.modal.data.contractName = contract.name;
      this.modal.data.contractStatus = contract.status;

      this.modal.active = true;
      this.modal.loading = false;
      this.modal.valid = false;
      setTimeout(() => {
        this.$refs.shiftContractForm.resetValidation();
      }, 150);
    },
    updateContractDiscard() {
      this.modal.active = false;
      this.modal.valid = false;
      this.modal.loading = false;
      this.modal.data = {
        shiftValue: 0,
        contractId: null,
        contractName: "",
        contractStatus: null
      };
    },
    shiftContractConfirmed() {
      this.modal.loading = true;
      let toSend = {
        ShiftValue: this.modal.data.shiftValue
      };
      var component = this;
      contractsService
        .shiftContractPhases(this.modal.data.contractId, toSend)
        .then(resp => {
          this.$log("updateContract", resp.data);
          this.modal.loading = false;
          this.updateContractDiscard();

          component.$emit("save");
        })
        .catch(err => {
          this.$log("updateContract", err);
          this.modal.loading = false;
          this.$handleError(err);
        });
    }
  }
};
</script>

<style>
</style>
