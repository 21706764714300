<template>
  <div>
    <slideout
      dock="right"
      :size="isSelectSpaceSlideoutActive ? '100%' : '900px'"
      :min-size="nested ? 400 : 900"
      :allow-resize="nested ? false : true"
      :append-to="nested ? '' : '#app'"
      :visible.sync="slideouts.bulkCreate.active"
      v-on:close="onSlideoutClosing"
    >
      <template v-slot:header>
        <h3 class="font-weight-bold pa-1" small>
          <i class="fad fa-circle-plus fa-swap-opacity mr-2"></i>Bulk Create Drawing Documents
        </h3>
        <div>
          <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
        </div>
      </template>

      <v-container v-if="loadingProjectNamingTemplates" fluid class="pa-5">
        <div class="pa-0 text-center">
          <video
            width="320"
            muted
            loop
            autoplay
            style="padding: 0.5rem; opacity: 0.64; margin: 0 auto"
          >
            <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
          </video>
          <p class="font-weight-bold">Preparing Bulk Create Form ...</p>
        </div>
      </v-container>
      <v-container v-else fluid style="background: #eceff1" class="pa-5 inset-shadow">
        <v-form v-model="slideouts.bulkCreate.valid" ref="updateForm">
          <h3 class="mb-3">
            Fill Required Data & Choose the Drawing Documents You Want to Create?
          </h3>
          <v-card class="pt-3 pb-3 px-1 shared-properties-card mb-4" elevation="0">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces, allRules.lengthMax(50)]"
                    label="Published Drawing Title"
                    id="publishedDrawingTitle"
                    ref="publishedDrawingTitle"
                    placeholder="Published Drawing Title"
                    v-model="options.sharedProperties.publishedDrawingTitle"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-menu
                    v-model="publishedDrawingDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :nudge-top="20"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field-alt
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        :rules="[allRules.required]"
                        label="Published Drawing Date"
                        id="publishedDrawingDate"
                        placeholder="Published Drawing Date"
                        v-model="options.sharedProperties.publishedDrawingDate"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field-alt>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      :rules="[allRules.required]"
                      v-model="options.sharedProperties.publishedDrawingDate"
                      @input="publishedDrawingDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="7" sm="12" class="pt-0">
                  <h4 style="font-weight: 600 !important; font-size: 15px">
                    <i class="fad fa-file-circle-question fs-16px mr-1"></i>
                    Document with the same name!
                  </h4>
                  <p class="mb-2 mt-1">
                    By default, Bulk Create Form will not create a new document if it already exists
                    with the same name, but you can change this behavior:
                  </p>
                  <v-switch
                    v-model="options.sharedProperties.OverrideIfExisted"
                    class="ma-0"
                    color="orange"
                    hide-details
                  >
                    <template v-slot:label>
                      <label
                        :class="{
                          'orange--text': options.sharedProperties.OverrideIfExisted,
                          'secondary--text': !options.sharedProperties.OverrideIfExisted,
                        }"
                        class="v-switch-label ma-0 mx-1 fs-14px"
                        >Override documents with the same name
                      </label>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-expansion-panels
            ref="ddmOptionsEP"
            class="ddm-eps"
            v-model="expandedPanels"
            multiple
            hover
            :focusable="false"
          >
            <v-expansion-panel
              :readonly="true || !options.coverSheet.create"
              class="sheet-option"
              :class="options.coverSheet.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.coverSheet.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.CoverSheet.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.coverSheet.create ? 'primary--text' : 'opacity-87'"
                    >
                      Cover Sheet
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.coverSheet.create"
                      hide-label
                      hide-details
                      v-model="options.coverSheet.namingTemplateId"
                      :document-type="DocumentType.CoverSheet.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.coverSheet.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="5" sm="12" class="py-0" style="height: 92.5px">
                    <h4 class="mb-0"><i class="fad fa-link mr-2"></i>Associate with</h4>
                    <v-radio-group
                      v-model="options.coverSheet.associateType"
                      row
                      color="info"
                      class="mt-2"
                      hide-details
                    >
                      <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.coverSheet.associateType ==
                                enums.ASSOCIATE_TYPE.NotAssociated.value,
                            }"
                            >Nothing</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.coverSheet.associateType ==
                                enums.ASSOCIATE_TYPE.Level.value,
                            }"
                            >Level</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.coverSheet.associateType ==
                                enums.ASSOCIATE_TYPE.Building.value,
                            }"
                            >Building
                          </label>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0 d-flex align-center"
                    v-if="
                      options.coverSheet.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value
                    "
                  >
                    <i class="fad fa-link-slash"></i>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0"
                    v-else-if="options.coverSheet.associateType == enums.ASSOCIATE_TYPE.Level.value"
                  >
                    <project-level-selector
                      :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                      :project-id="projectId"
                      v-model="options.coverSheet.associateTypeId"
                      ref="projectLevelSelector"
                    ></project-level-selector>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0"
                    v-else-if="
                      options.coverSheet.associateType == enums.ASSOCIATE_TYPE.Building.value
                    "
                  >
                    <project-building-selector
                      :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                      :project-id="projectId"
                      v-model="options.coverSheet.associateTypeId"
                      ref="projectBuildingSelector"
                    ></project-building-selector>
                  </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                      1 Cover Sheet
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.sitePlan.create"
              class="sheet-option"
              :class="options.sitePlan.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.sitePlan.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.SitePlan.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.sitePlan.create ? 'primary--text' : 'opacity-87'"
                    >
                      Site Plan Sheet
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.sitePlan.create"
                      hide-label
                      hide-details
                      v-model="options.sitePlan.namingTemplateId"
                      :document-type="DocumentType.SitePlan.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.sitePlan.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="5" sm="12" class="py-0" style="height: 92.5px">
                    <h4 class="mb-0"><i class="fad fa-link mr-2"></i>Associate with</h4>
                    <v-radio-group
                      v-model="options.sitePlan.associateType"
                      row
                      color="info"
                      class="mt-2"
                      hide-details
                    >
                      <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.sitePlan.associateType ==
                                enums.ASSOCIATE_TYPE.NotAssociated.value,
                            }"
                            >Nothing</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.sitePlan.associateType == enums.ASSOCIATE_TYPE.Level.value,
                            }"
                            >Level</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.sitePlan.associateType ==
                                enums.ASSOCIATE_TYPE.Building.value,
                            }"
                            >Building
                          </label>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0 d-flex align-center"
                    v-if="
                      options.sitePlan.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value
                    "
                  >
                    <i class="fad fa-link-slash"></i>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0"
                    v-else-if="options.sitePlan.associateType == enums.ASSOCIATE_TYPE.Level.value"
                  >
                    <project-level-selector
                      :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                      :project-id="projectId"
                      v-model="options.sitePlan.associateTypeId"
                      ref="projectLevelSelector"
                    ></project-level-selector>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                    sm="12"
                    class="py-0"
                    v-else-if="
                      options.sitePlan.associateType == enums.ASSOCIATE_TYPE.Building.value
                    "
                  >
                    <project-building-selector
                      :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                      :project-id="projectId"
                      v-model="options.sitePlan.associateTypeId"
                      ref="projectBuildingSelector"
                    ></project-building-selector>
                  </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                      1 Site Plan Sheet
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.plan.create"
              class="sheet-option"
              :class="options.plan.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.plan.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.Plan.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.plan.create ? 'primary--text' : 'opacity-87'"
                    >
                      Plan Sheet
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.plan.create"
                      hide-label
                      hide-details
                      v-model="options.plan.namingTemplateId"
                      :document-type="DocumentType.Plan.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.plan.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="6" sm="12" class="py-0" style="height: 92.5px">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-link fs-16px ml-1 mr-2"></i>Associate with
                    </h4>
                    <v-radio-group
                      v-model="options.plan.associateType"
                      row
                      color="info"
                      class="mt-2"
                      hide-details
                    >
                      <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.plan.associateType ==
                                enums.ASSOCIATE_TYPE.NotAssociated.value,
                            }"
                            >Nothing</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Space.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.plan.associateType == enums.ASSOCIATE_TYPE.Space.value,
                            }"
                            >Space</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.plan.associateType == enums.ASSOCIATE_TYPE.Level.value,
                            }"
                            >Level</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.plan.associateType == enums.ASSOCIATE_TYPE.Building.value,
                            }"
                            >Building
                          </label>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0 d-flex align-center"
                    v-if="options.plan.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value"
                  >
                    <i class="fad fa-link-slash"></i>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="options.plan.associateType == enums.ASSOCIATE_TYPE.Level.value"
                  >
                    <project-level-selector
                      :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                      :project-id="projectId"
                      v-model="options.plan.associateTypeId"
                      ref="projectLevelSelector"
                    ></project-level-selector>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="options.plan.associateType == enums.ASSOCIATE_TYPE.Building.value"
                  >
                    <project-building-selector
                      :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                      :project-id="projectId"
                      v-model="options.plan.associateTypeId"
                      ref="projectBuildingSelector"
                    ></project-building-selector>
                  </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row v-if="options.plan.associateType == enums.ASSOCIATE_TYPE.Space.value">
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="plan-sheets-loading"
                        v-if="options.plan.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="plan-sheets-table" v-else>
                        <v-row dense>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <label
                              class="d-inline-block mb-0 mr-2"
                              style="font-weight: 600 !important; font-size: 14px"
                            >
                              Include:
                            </label>
                            <v-radio-group
                              v-model="options.plan.selectAll"
                              mandatory
                              hide-details
                              class="mt-0 pt-0 mr-0"
                              row
                            >
                              <v-radio :value="true" color="info">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="options.plan.selectAll ? 'info--text' : ''"
                                  >
                                    All Spaces
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio :value="false" color="info" class="mr-0">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="!options.plan.selectAll ? 'info--text' : ''"
                                  >
                                    Selected Spaces
                                    <span class="mr-2" v-if="!options.plan.selectAll"
                                      >({{ options.plan.spaces.length }})</span
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <v-btn
                              v-if="!options.plan.selectAll"
                              class="font-weight-bold"
                              outlined
                              color="info"
                              small
                              @click="openSelectSpacesSlideout('Plan', options.plan.spaces)"
                            >
                              <i class="far fa-list-check fs-14px mr-2"></i>
                              Select
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                          v-if="!options.plan.selectAll"
                          dense
                          :headers="spacesHeader"
                          :items="options.plan.spaces"
                          class="elevation-2"
                          sort-by="name"
                          hide-default-footer
                          :items-per-page="-1"
                        >
                          <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-tooltip right z-index="999" nudge-right="-4px">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <div class="space-img">
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-vector-square'"></i>
                                  </div>
                                </div>
                              </template>
                              <span>
                                <v-img
                                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                  class="img"
                                  :src="item.imageUrl"
                                  height="250px"
                                  width="250px"
                                  contain
                                ></v-img>
                                <i
                                  v-else
                                  :class="'fad fa-vector-square'"
                                  style="font-size: 32px; padding: 2rem"
                                ></i>
                              </span>
                            </v-tooltip>
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <strong>{{ item.name }}</strong>
                          </template>
                          <template v-slot:[`item.spaceNumber`]="{ item }">
                            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                          </template>

                          <template v-slot:[`item.equipmentCount`]="{ item }">
                            <equipment-count :count="item.equipmentCount" />
                          </template>

                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span
                              v-if="item"
                              class="orange--text text--darken-2 font-weight-bold fs-14px"
                              >{{ item.planSheetCount }}</span
                            >
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              color="red"
                              icon
                              elevation="0"
                              @click="deletePlanSelectedSpace(item)"
                            >
                              <i class="fal fa-trash-alt"></i>
                            </v-btn>
                          </template>

                          <template v-slot:no-data>
                            <p class="font-weight-bold mt-3">No Spaces Selected!</p>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                      1 Plan Sheet
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.overview.create"
              class="sheet-option"
              :class="options.overview.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.overview.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="" style="width: 80px">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.Overview.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.overview.create ? 'primary--text' : 'opacity-87'"
                    >
                      Overview Sheet
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.overview.create"
                      hide-label
                      hide-details
                      v-model="options.overview.namingTemplateId"
                      :document-type="DocumentType.Overview.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.overview.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="6" sm="12" class="py-0" style="height: 92.5px">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-link fs-16px ml-1 mr-2"></i>Associate with
                    </h4>
                    <v-radio-group
                      v-model="options.overview.associateType"
                      row
                      color="info"
                      class="mt-2"
                      hide-details
                    >
                      <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.overview.associateType ==
                                enums.ASSOCIATE_TYPE.NotAssociated.value,
                            }"
                            >Nothing</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Space.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.overview.associateType == enums.ASSOCIATE_TYPE.Space.value,
                            }"
                            >Space</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.overview.associateType == enums.ASSOCIATE_TYPE.Level.value,
                            }"
                            >Level</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.overview.associateType ==
                                enums.ASSOCIATE_TYPE.Building.value,
                            }"
                            >Building
                          </label>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0 d-flex align-center"
                    v-if="
                      options.overview.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value
                    "
                  >
                    <i class="fad fa-link-slash"></i>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="options.overview.associateType == enums.ASSOCIATE_TYPE.Level.value"
                  >
                    <project-level-selector
                      :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                      :project-id="projectId"
                      v-model="options.overview.associateTypeId"
                      ref="projectLevelSelector"
                    ></project-level-selector>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="
                      options.overview.associateType == enums.ASSOCIATE_TYPE.Building.value
                    "
                  >
                    <project-building-selector
                      :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                      :project-id="projectId"
                      v-model="options.overview.associateTypeId"
                      ref="projectBuildingSelector"
                    ></project-building-selector>
                  </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row v-if="options.overview.associateType == enums.ASSOCIATE_TYPE.Space.value">
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="overview-sheets-loading"
                        v-if="options.overview.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="overview-sheets-table" v-else>
                        <v-row dense>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <label
                              class="d-inline-block mb-0 mr-2"
                              style="font-weight: 600 !important; font-size: 14px"
                            >
                              Include:
                            </label>
                            <v-radio-group
                              v-model="options.overview.selectAll"
                              mandatory
                              hide-details
                              class="mt-0 pt-0 mr-0"
                              row
                            >
                              <v-radio :value="true" color="info">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="options.overview.selectAll ? 'info--text' : ''"
                                  >
                                    All Spaces
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio :value="false" color="info" class="mr-0">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="!options.overview.selectAll ? 'info--text' : ''"
                                  >
                                    Selected Spaces
                                    <span class="mr-2" v-if="!options.overview.selectAll"
                                      >({{ options.overview.spaces.length }})</span
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <v-btn
                              v-if="!options.overview.selectAll"
                              class="font-weight-bold"
                              outlined
                              color="info"
                              small
                              @click="openSelectSpacesSlideout('Overview', options.overview.spaces)"
                            >
                              <i class="far fa-list-check fs-14px mr-2"></i>
                              Select
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                          v-if="!options.overview.selectAll"
                          dense
                          :headers="spacesHeader"
                          :items="options.overview.spaces"
                          class="elevation-2"
                          sort-by="name"
                          hide-default-footer
                          :items-per-page="-1"
                        >
                          <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-tooltip right z-index="999" nudge-right="-4px">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <div class="space-img">
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-vector-square'"></i>
                                  </div>
                                </div>
                              </template>
                              <span>
                                <v-img
                                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                  class="img"
                                  :src="item.imageUrl"
                                  height="250px"
                                  width="250px"
                                  contain
                                ></v-img>
                                <i
                                  v-else
                                  :class="'fad fa-vector-square'"
                                  style="font-size: 32px; padding: 2rem"
                                ></i>
                              </span>
                            </v-tooltip>
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <strong>{{ item.name }}</strong>
                          </template>
                          <template v-slot:[`item.spaceNumber`]="{ item }">
                            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                          </template>

                          <template v-slot:[`item.equipmentCount`]="{ item }">
                            <equipment-count :count="item.equipmentCount" />
                          </template>

                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span
                              v-if="item"
                              class="orange--text text--darken-2 font-weight-bold fs-14px"
                              >{{ item.overviewSheetCount }}</span
                            >
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              color="red"
                              icon
                              elevation="0"
                              @click="deleteOverviewSelectedSpace(item)"
                            >
                              <i class="fal fa-trash-alt"></i>
                            </v-btn>
                          </template>

                          <template v-slot:no-data>
                            <p class="font-weight-bold mt-3">No Spaces Selected!</p>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                      1 Overview Sheet
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.detail.create"
              class="sheet-option"
              :class="options.detail.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.detail.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="" style="width: 80px">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.Detail.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.detail.create ? 'primary--text' : 'opacity-87'"
                    >
                      Detail Sheet
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.detail.create"
                      hide-label
                      hide-details
                      v-model="options.detail.namingTemplateId"
                      :document-type="DocumentType.Detail.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.detail.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" md="6" sm="12" class="py-0" style="height: 92.5px">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-link fs-16px ml-1 mr-2"></i>Associate with
                    </h4>
                    <v-radio-group
                      v-model="options.detail.associateType"
                      row
                      color="info"
                      class="mt-2"
                      hide-details
                    >
                      <v-radio :value="enums.ASSOCIATE_TYPE.NotAssociated.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.detail.associateType ==
                                enums.ASSOCIATE_TYPE.NotAssociated.value,
                            }"
                            >Nothing</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Space.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.detail.associateType == enums.ASSOCIATE_TYPE.Space.value,
                            }"
                            >Space</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Level.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.detail.associateType == enums.ASSOCIATE_TYPE.Level.value,
                            }"
                            >Level</label
                          >
                        </template>
                      </v-radio>
                      <v-radio :value="enums.ASSOCIATE_TYPE.Building.value">
                        <template v-slot:label>
                          <label
                            class="v-switch-label"
                            :class="{
                              'secondary--text':
                                options.detail.associateType == enums.ASSOCIATE_TYPE.Building.value,
                            }"
                            >Building
                          </label>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0 d-flex align-center"
                    v-if="options.detail.associateType == enums.ASSOCIATE_TYPE.NotAssociated.value"
                  >
                    <i class="fad fa-link-slash"></i>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="options.detail.associateType == enums.ASSOCIATE_TYPE.Level.value"
                  >
                    <project-level-selector
                      :label="`<i class='fad fa-layer-group mr-1'></i> Associated Level`"
                      :project-id="projectId"
                      v-model="options.detail.associateTypeId"
                      ref="projectLevelSelector"
                    ></project-level-selector>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="12"
                    class="py-0"
                    v-else-if="options.detail.associateType == enums.ASSOCIATE_TYPE.Building.value"
                  >
                    <project-building-selector
                      :label="`<i class='fad fa-building mr-1'></i> Associated Building`"
                      :project-id="projectId"
                      v-model="options.detail.associateTypeId"
                      ref="projectBuildingSelector"
                    ></project-building-selector>
                  </v-col>
                </v-row>
                <v-divider class="mb-4"></v-divider>
                <v-row v-if="options.detail.associateType == enums.ASSOCIATE_TYPE.Space.value">
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="detail-sheets-loading"
                        v-if="options.detail.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="detail-sheets-table" v-else>
                        <v-row dense>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <label
                              class="d-inline-block mb-0 mr-2"
                              style="font-weight: 600 !important; font-size: 14px"
                            >
                              Include:
                            </label>
                            <v-radio-group
                              v-model="options.detail.selectAll"
                              mandatory
                              hide-details
                              class="mt-0 pt-0 mr-0"
                              row
                            >
                              <v-radio :value="true" color="info">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="options.detail.selectAll ? 'info--text' : ''"
                                  >
                                    All Spaces
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio :value="false" color="info" class="mr-0">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="!options.detail.selectAll ? 'info--text' : ''"
                                  >
                                    Selected Spaces
                                    <span class="mr-2" v-if="!options.detail.selectAll"
                                      >({{ options.detail.spaces.length }})</span
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <v-btn
                              v-if="!options.detail.selectAll"
                              class="font-weight-bold"
                              outlined
                              color="info"
                              small
                              @click="openSelectSpacesSlideout('Detail', options.detail.spaces)"
                            >
                              <i class="far fa-list-check fs-14px mr-2"></i>
                              Select
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                          v-if="!options.detail.selectAll"
                          dense
                          :headers="spacesHeader"
                          :items="options.detail.spaces"
                          class="elevation-2"
                          sort-by="name"
                          hide-default-footer
                          :items-per-page="-1"
                        >
                          <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-tooltip right z-index="999" nudge-right="-4px">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <div class="space-img">
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-vector-square'"></i>
                                  </div>
                                </div>
                              </template>
                              <span>
                                <v-img
                                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                  class="img"
                                  :src="item.imageUrl"
                                  height="250px"
                                  width="250px"
                                  contain
                                ></v-img>
                                <i
                                  v-else
                                  :class="'fad fa-vector-square'"
                                  style="font-size: 32px; padding: 2rem"
                                ></i>
                              </span>
                            </v-tooltip>
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <strong>{{ item.name }}</strong>
                          </template>
                          <template v-slot:[`item.spaceNumber`]="{ item }">
                            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                          </template>

                          <template v-slot:[`item.equipmentCount`]="{ item }">
                            <equipment-count :count="item.equipmentCount" />
                          </template>

                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span
                              v-if="item"
                              class="orange--text text--darken-2 font-weight-bold fs-14px"
                              >{{ item.detailSheetCount }}</span
                            >
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              color="red"
                              icon
                              elevation="0"
                              @click="deleteDetailSelectedSpace(item)"
                            >
                              <i class="fal fa-trash-alt"></i>
                            </v-btn>
                          </template>

                          <template v-slot:no-data>
                            <p class="font-weight-bold mt-3">No Spaces Selected!</p>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                      1 Detail Sheet
                    </p>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.scopeViewPlans.create"
              class="sheet-option"
              :class="options.scopeViewPlans.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.scopeViewPlans.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.ScopeView.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.scopeViewPlans.create ? 'primary--text' : 'opacity-87'"
                    >
                      Scope View Plan Sheets
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.scopeViewPlans.create"
                      hide-label
                      hide-details
                      v-model="options.scopeViewPlans.namingTemplateId"
                      :document-type="DocumentType.ScopeView.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.scopeViewPlans.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="auto">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="scope-view-sheets-loading"
                        v-if="options.scopeViewPlans.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="scope-view-sheets-tree" v-else>
                        <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px mb-1">
                          {{ options.scopeViewPlans.levelsIds.length }} Scope View Plan Sheets
                        </p>
                        <div style="border-radius: 0.5rem" class="pa-2 buildings-tree-wrapper">
                          <v-treeview
                            v-model="options.scopeViewPlans.levelsIds"
                            dense
                            open-on-click
                            transition
                            selectable
                            selected-color="orange darken-2"
                            :items="options.scopeViewPlans.tree"
                          >
                            <template v-slot:prepend="{ item }">
                              <i
                                class="fad fa-building tree-building"
                                v-if="item.type == 'Building'"
                              ></i>
                              <i class="fad fa-layer-group tree-level" v-else></i>
                            </template>
                            <template v-slot:label="{ item }">
                              <span class="tree-building" v-if="item.type == 'Building'"
                                >{{ item.name }}
                                <v-chip
                                  label
                                  v-if="item.area != null && item.area != 0"
                                  x-small
                                  color="secondary"
                                  class="ml-2 font-weight-bold px-1 justify-center d-inline-flex fs-11px"
                                  style="line-height: 0"
                                >
                                  {{ item.area | numberWithCommas }}
                                  <span class="ml-1">SQFT</span>
                                </v-chip>
                              </span>
                              <span class="tree-level" v-else>{{ item.name }}</span>
                            </template>
                          </v-treeview>
                        </div>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.iav.create"
              class="sheet-option"
              :class="options.iav.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.iav.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.IAV.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.iav.create ? 'primary--text' : 'opacity-87'"
                    >
                      IAV Sheets
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.iav.create"
                      hide-label
                      hide-details
                      v-model="options.iav.namingTemplateId"
                      :document-type="DocumentType.IAV.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.iav.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="auto" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="auto">
                    <v-scroll-y-transition mode="out-in" v-if="false">
                      <div
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="iav-sheets-loading-top"
                        v-if="!options.iav.loading"
                      >
                        <p
                          v-if="options.iav.selectAll"
                          class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px"
                        >
                          {{ spacesTotal * options.iav.sheetsPerSpace }} IAV Sheets
                        </p>
                        <p v-else class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                          {{ options.iav.spaces.length * options.iav.sheetsPerSpace }}
                          IAV Sheets
                        </p>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="iav-sheets-loading"
                        v-if="options.iav.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="iav-sheets-table" v-else>
                        <v-row dense>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <label
                              class="d-inline-block mb-0 mr-2"
                              style="font-weight: 600 !important; font-size: 14px"
                            >
                              Include:
                            </label>
                            <v-radio-group
                              v-model="options.iav.selectAll"
                              mandatory
                              hide-details
                              class="mt-0 pt-0 mr-0"
                              row
                            >
                              <v-radio :value="true" color="info">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="options.iav.selectAll ? 'info--text' : ''"
                                  >
                                    All Spaces
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio :value="false" color="info" class="mr-0">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="!options.iav.selectAll ? 'info--text' : ''"
                                  >
                                    Selected Spaces
                                    <span class="mr-2" v-if="!options.iav.selectAll"
                                      >({{ options.iav.spaces.length }})</span
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <v-btn
                              v-if="!options.iav.selectAll"
                              class="font-weight-bold"
                              outlined
                              color="info"
                              small
                              @click="openSelectSpacesSlideout('IAV', options.iav.spaces)"
                            >
                              <i class="far fa-list-check fs-14px mr-2"></i>
                              Select
                            </v-btn>
                            <v-btn
                              v-if="!options.iav.selectAll"
                              class="font-weight-bold ml-2"
                              outlined
                              color="purple"
                              small
                              @click="copySelectedIAVToSCD()"
                            >
                              <i class="far fa-copy mr-2"></i>
                              Copy to SCD
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                          v-if="!options.iav.selectAll"
                          dense
                          :headers="spacesHeader"
                          :items="options.iav.spaces"
                          class="elevation-2"
                          sort-by="name"
                          :items-per-page="-1"
                          hide-default-footer
                        >
                          <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-tooltip right z-index="999" nudge-right="-4px">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <div class="space-img">
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-vector-square'"></i>
                                  </div>
                                </div>
                              </template>
                              <span>
                                <v-img
                                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                  class="img"
                                  :src="item.imageUrl"
                                  height="250px"
                                  width="250px"
                                  contain
                                ></v-img>
                                <i
                                  v-else
                                  :class="'fad fa-vector-square'"
                                  style="font-size: 32px; padding: 2rem"
                                ></i>
                              </span>
                            </v-tooltip>
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <strong>{{ item.name }}</strong>
                          </template>
                          <template v-slot:[`item.spaceNumber`]="{ item }">
                            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                          </template>

                          <template v-slot:[`item.equipmentCount`]="{ item }">
                            <equipment-count :count="item.equipmentCount" />
                          </template>

                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span
                              v-if="item"
                              class="orange--text text--darken-2 font-weight-bold fs-14px"
                              >{{ item.iavSheetCount }}</span
                            >
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              color="red"
                              icon
                              elevation="0"
                              @click="deleteIavSelectedSpace(item)"
                            >
                              <i class="fal fa-trash-alt"></i>
                            </v-btn>
                          </template>

                          <template v-slot:no-data>
                            <p class="font-weight-bold mt-3">No Spaces Selected!</p>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.spec.create"
              class="sheet-option"
              :class="options.spec.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.spec.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.SpecSheet.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.spec.create ? 'primary--text' : 'opacity-87'"
                    >
                      SPEC Sheets
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.spec.create"
                      hide-label
                      hide-details
                      v-model="options.spec.namingTemplateId"
                      :document-type="DocumentType.SpecSheet.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.spec.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="3">
                    <h4>
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="spec-sheets-loading"
                        v-if="options.spec.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="spec-sheets-table" v-else>
                        <p class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                          {{ options.spec.total }} SPEC Plan Sheets
                        </p>
                        <v-data-table
                          dense
                          :headers="specResultHeaders"
                          :items="options.spec.result"
                          class="elevation-2 mt-2"
                          sort-by="category.name"
                          hide-default-footer
                        >
                          <template v-slot:[`item.category.name`]="{ item }">
                            <v-row no-gutters>
                              <v-col
                                sm="auto"
                                class="d-flex justify-content-start align-items-center"
                              >
                                <i
                                  v-if="item.category != null"
                                  :class="'equipment-icon fad ' + item.category.icon"
                                ></i>
                                <i v-else :class="'equipment-icon fad fa-plug'"></i>
                              </v-col>
                              <v-col class="d-flex align-center">
                                <span class="category-name" v-if="item.category">{{
                                  item.category.name
                                }}</span>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span class="orange--text text--darken-2 font-weight-bold fs-14px">{{
                              item.sheetsCount
                            }}</span>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :readonly="true || !options.scd.create"
              class="sheet-option"
              :class="options.scd.create ? 'sheet-option-selected' : ''"
            >
              <v-expansion-panel-header
                class="px-4 py-0"
                :ripple="false"
                style="min-height: 48px !important"
              >
                <v-row no-gutters justify="space-between">
                  <v-col cols="12" md="9" class="d-flex align-center">
                    <v-switch
                      v-model="options.scd.create"
                      color="info"
                      class="ma-0 pa-0"
                      hide-details
                      @click.stop
                    ></v-switch>
                    <div class="d-flex mr-1">
                      <document-type
                        disable-tooltip
                        :type="enums.DOCUMENT_TYPES.SCD.value"
                      ></document-type>
                    </div>
                    <h3
                      class="font-weight-bold ml-0 mb-0 pl-2"
                      :class="options.scd.create ? 'primary--text' : 'opacity-87'"
                    >
                      SCD Sheets
                    </h3>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-pen-field fs-16px ml-1 mr-2"></i>Naming Templates<i
                        class="fas fa-star-of-life pink--text label-icon"
                      ></i>
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9">
                    <naming-template-selector
                      :required="options.scd.create"
                      hide-label
                      hide-details
                      v-model="options.scd.namingTemplateId"
                      :document-type="DocumentType.SCD.value"
                    ></naming-template-selector>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0 d-flex align-center">
                      <i class="fad fa-drafting-compass fs-16px ml-1 mr-2"></i>Layout Templates
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" class="py-0 d-flex align-center">
                    <layout-template-selector
                      hide-label
                      hide-details
                      style="z-index: 1000; width: 100%"
                      v-model="options.scd.drawingViewLayoutId"
                    ></layout-template-selector>
                  </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <h4 class="mb-0">
                      <i class="fad fa-file-circle-plus fs-16px ml-1 mr-2"></i>Expected Result
                    </h4>
                  </v-col>
                  <v-col cols="12" md="9" v-if="false">
                    <v-scroll-y-transition mode="out-in">
                      <div
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="scd-sheets-loading-top"
                        v-if="!options.scd.loading"
                      >
                        <p
                          v-if="options.scd.selectAll"
                          class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px"
                        >
                          {{ spacesTotal * options.scd.sheetsPerSpace }} SCD Sheets
                        </p>
                        <p v-else class="mb-0 orange--text text--darken-2 font-weight-bold fs-14px">
                          {{ options.scd.spaces.length * options.scd.sheetsPerSpace }}
                          SCD Sheets
                        </p>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                  <v-col cols="12" md="12" class="pt-0">
                    <v-scroll-y-transition mode="out-in">
                      <p
                        class="mb-0 d-flex align-center orange--text text--darken-2"
                        key="scd-sheets-loading"
                        v-if="options.scd.loading"
                      >
                        <i class="fad fa-spinner-third fa-spin fs-20px mr-2"></i>
                        <span class="font-weight-bold fs-14px"> Processing... </span>
                      </p>
                      <div key="scd-sheets-table" v-else>
                        <v-row dense>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <label
                              class="d-inline-block mb-0 mr-2"
                              style="font-weight: 600 !important; font-size: 14px"
                            >
                              Include:
                            </label>
                            <v-radio-group
                              v-model="options.scd.selectAll"
                              mandatory
                              hide-details
                              class="mt-0 pt-0 mr-0"
                              row
                            >
                              <v-radio :value="true" color="info">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="options.scd.selectAll ? 'info--text' : ''"
                                  >
                                    All Spaces
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio :value="false" color="info" class="mr-0">
                                <template v-slot:label>
                                  <div
                                    class="font-weight-bold fs-14px"
                                    :class="!options.scd.selectAll ? 'info--text' : ''"
                                  >
                                    Selected Spaces
                                    <span class="mr-2" v-if="!options.scd.selectAll"
                                      >({{ options.scd.spaces.length }})</span
                                    >
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="12" md="auto" class="d-flex align-center mb-2">
                            <v-btn
                              v-if="!options.scd.selectAll"
                              class="font-weight-bold"
                              outlined
                              color="info"
                              small
                              @click="openSelectSpacesSlideout('SCD', options.scd.spaces)"
                            >
                              <i class="far fa-list-check fs-14px mr-2"></i>
                              Select
                            </v-btn>
                            <v-btn
                              v-if="!options.scd.selectAll"
                              class="font-weight-bold ml-2"
                              outlined
                              color="purple"
                              small
                              @click="copySelectedSCDToIAV()"
                            >
                              <i class="far fa-copy mr-2"></i>
                              Copy to IAV
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-data-table
                          v-if="!options.scd.selectAll"
                          dense
                          :headers="spacesHeader"
                          :items="options.scd.spaces"
                          class="elevation-2"
                          sort-by="name"
                          hide-default-footer
                          :items-per-page="-1"
                        >
                          <template v-slot:[`item.imageUrl`]="{ item }">
                            <v-tooltip right z-index="999" nudge-right="-4px">
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                  <div class="space-img">
                                    <v-img
                                      v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                      class="img"
                                      :src="item.imageUrl"
                                      height="100%"
                                      width="100%"
                                      contain
                                    ></v-img>
                                    <i v-else :class="'fad fa-vector-square'"></i>
                                  </div>
                                </div>
                              </template>
                              <span>
                                <v-img
                                  v-if="(item.imageUrl != null) & (item.imageUrl != '')"
                                  class="img"
                                  :src="item.imageUrl"
                                  height="250px"
                                  width="250px"
                                  contain
                                ></v-img>
                                <i
                                  v-else
                                  :class="'fad fa-vector-square'"
                                  style="font-size: 32px; padding: 2rem"
                                ></i>
                              </span>
                            </v-tooltip>
                          </template>

                          <template v-slot:[`item.name`]="{ item }">
                            <strong>{{ item.name }}</strong>
                          </template>
                          <template v-slot:[`item.spaceNumber`]="{ item }">
                            <span class="mono-font font-weight-bold">{{ item.spaceNumber }}</span>
                          </template>
                          <template v-slot:[`item.equipmentCount`]="{ item }">
                            <equipment-count :count="item.equipmentCount" />
                          </template>

                          <template v-slot:[`item.sheetsCount`]="{ item }">
                            <span
                              v-if="item"
                              class="orange--text text--darken-2 font-weight-bold fs-14px"
                              >{{ item.scdSheetCount }}</span
                            >
                          </template>

                          <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                              color="red"
                              icon
                              elevation="0"
                              @click="deleteScdSelectedSpace(item)"
                            >
                              <i class="fal fa-trash-alt"></i>
                            </v-btn>
                          </template>

                          <template v-slot:no-data>
                            <p class="font-weight-bold mt-3">No Spaces Selected!</p>
                          </template>
                        </v-data-table>
                      </div>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-container>
      <select-project-spaces
        ref="selectSpacesSlideout"
        nested
        :project-id="projectId"
        @opened="onSelectSpacesOpened"
        @updated="onSelectSpacesUpdated"
        @closed="onSelectSpacesClosed"
      ></select-project-spaces>
      <template v-slot:footer>
        <v-card-actions>
          <v-btn text @click="closeUpdateSlideout()">
            <i class="far fa-xmark mr-2"></i>Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.bulkCreate.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
          <v-btn
            color="info"
            :disabled="!atLeastOneOptionIsSelected || slideouts.bulkCreate.isLoading"
            @click="updateConfirmed()"
            :loading="slideouts.bulkCreate.isLoading"
          >
            <i class="fal fa-check mr-2"></i>
            Create
          </v-btn>
        </v-card-actions>
      </template>
    </slideout>
  </div>
</template>

<script>
import enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import ddmAPI from "../services/drawing-documents-service";
import projectsAPI from "../../Projects/services/projects-service";
import buildingsAPI from "../../Projects/services/buildings-service";
import specResultHeader from "../config/tables/specResult.header";
import SelectProjectSpaces from "../../Spaces/components/SelectProjectSpaces.vue";
import NamingTemplateSelector from "./NamingTemplateSelector.vue";
import ProjectBuildingSelector from "../../Shared/components/ProjectBuildingSelector.vue";
import ProjectLevelSelector from "../../Shared/components/ProjectLevelSelector.vue";
import LayoutTemplateSelector from "../../Shared/components/LayoutTemplateSelector.vue";
import DocumentType from "./DocumentType.vue";

export default {
  name: "bulk-create-drawing-documents",
  data() {
    return {
      perms,
      enums,
      namingTemplates: null,
      loadingProjectNamingTemplates: false,
      DocumentType: enums.DOCUMENT_TYPES,
      expandedPanels: [],
      publishedDrawingDateMenu: false,
      isSelectSpaceSlideoutActive: false,
      specResultHeaders: specResultHeader,
      spacesHeader: [
        { text: "Image", value: "imageUrl", width: "120px" },
        { text: "Number", value: "spaceNumber" },
        { text: "Name", value: "name" },
        { text: "Equipment", value: "equipmentCount", width: "120px" },
        { text: "Sheets Count", value: "sheetsCount" },
        { text: "Actions", value: "actions" },
      ],
      selectSpacesFor: null,
      options: {
        sharedProperties: {
          publishedDrawingTitle: null,
          publishedDrawingDate: null,
          OverrideIfExisted: false,
        },
        coverSheet: {
          epIdx: 0,
          create: false,
          namingTemplateId: null,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
        },
        sitePlan: {
          epIdx: 1,
          create: false,
          namingTemplateId: null,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
        },
        plan: {
          epIdx: 2,
          create: false,
          namingTemplateId: null,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        overview: {
          epIdx: 3,
          create: false,
          namingTemplateId: null,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        detail: {
          epIdx: 4,
          create: false,
          namingTemplateId: null,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        scopeViewPlans: {
          epIdx: 5,
          create: false,
          levelsIds: [],
          loading: false,
          loaded: false,
          tree: null,
          total: 0,
          namingTemplateId: null,
          drawingViewLayoutId: null,
        },
        iav: {
          epIdx: 6,
          create: false,
          selectAll: true,
          spaces: [],
          spacesIds: [],
          namingTemplateId: null,
          drawingViewLayoutId: null,
        },
        spec: {
          epIdx: 7,
          create: false,
          loading: false,
          loaded: false,
          data: null,
          result: [],
          total: 0,
          namingTemplateId: null,
          drawingViewLayoutId: null,
        },
        scd: {
          epIdx: 8,
          create: false,
          selectAll: true,
          spaces: [],
          spacesIds: [],
          namingTemplateId: null,
          drawingViewLayoutId: null,
        },
      },
      slideouts: {
        bulkCreate: {
          active: false,
          valid: false,
          tab: null,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
    spacesTotal: {
      type: Number,
      default: null,
    },
  },
  methods: {
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;
      if (!this.atLeastOneOptionIsSelected) {
        this.$dialog.notify.warning("At least one document type is required!", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }
      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    deleteIavSelectedSpace(space) {
      let foundIndex = this.options.iav.spaces.findIndex((e) => e.id === space.id);
      if (foundIndex != -1) {
        this.options.iav.spaces.splice(foundIndex, 1);
      }
    },
    deleteScdSelectedSpace(space) {
      let foundIndex = this.options.scd.spaces.findIndex((e) => e.id === space.id);
      if (foundIndex != -1) {
        this.options.scd.spaces.splice(foundIndex, 1);
      }
    },
    deletePlanSelectedSpace(space) {
      let foundIndex = this.options.plan.spaces.findIndex((e) => e.id === space.id);
      if (foundIndex != -1) {
        this.options.plan.spaces.splice(foundIndex, 1);
      }
    },
    deleteOverviewSelectedSpace(space) {
      let foundIndex = this.options.overview.spaces.findIndex((e) => e.id === space.id);
      if (foundIndex != -1) {
        this.options.overview.spaces.splice(foundIndex, 1);
      }
    },
    deleteDetailSelectedSpace(space) {
      let foundIndex = this.options.detail.spaces.findIndex((e) => e.id === space.id);
      if (foundIndex != -1) {
        this.options.detail.spaces.splice(foundIndex, 1);
      }
    },
    copySelectedIAVToSCD() {
      this.options.scd.create = true;
      this.options.scd.selectAll = false;
      this.options.scd.spaces = this.cloneDeep(this.options.iav.spaces);
    },
    copySelectedSCDToIAV() {
      this.options.iav.create = true;
      this.options.iav.selectAll = false;
      this.options.iav.spaces = this.cloneDeep(this.options.scd.spaces);
    },
    openSelectSpacesSlideout(forSheet, spaces) {
      this.selectSpacesFor = forSheet;
      this.$refs.selectSpacesSlideout.open(spaces, this.selectSpacesFor);
    },
    onSelectSpacesOpened() {
      this.isSelectSpaceSlideoutActive = true;
    },
    onSelectSpacesUpdated(result) {
      this.$log("onSelectSpacesUpdated", result);
      if (this.selectSpacesFor == "IAV") {
        this.options.iav.spaces = this.cloneDeep(result);
      } else if (this.selectSpacesFor == "SCD") {
        this.options.scd.spaces = this.cloneDeep(result);
      } else if (this.selectSpacesFor == "Plan") {
        this.options.plan.spaces = this.cloneDeep(result);
      } else if (this.selectSpacesFor == "Overview") {
        this.options.overview.spaces = this.cloneDeep(result);
      } else if (this.selectSpacesFor == "Detail") {
        this.options.detail.spaces = this.cloneDeep(result);
      }
    },
    onSelectSpacesClosed() {
      this.isSelectSpaceSlideoutActive = false;
      this.selectSpacesFor = null;
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    open() {
      this.getProjectVariables();
      this.slideouts.bulkCreate.active = true;
    },
    prepareForm() {
      this.options = {
        sharedProperties: {
          publishedDrawingTitle: null,
          publishedDrawingDate: null,
          OverrideIfExisted: false,
        },
        coverSheet: {
          epIdx: 0,
          create: false,
          namingTemplateId: this.namingTemplates.coverSheetNamingTemplateId,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
        },
        sitePlan: {
          epIdx: 1,
          create: false,
          namingTemplateId: this.namingTemplates.sitePlanNamingTemplateId,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
        },
        plan: {
          epIdx: 2,
          create: false,
          namingTemplateId: this.namingTemplates.planNamingTemplateId,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        overview: {
          epIdx: 3,
          create: false,
          namingTemplateId: this.namingTemplates.planNamingTemplateId,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        detail: {
          epIdx: 4,
          create: false,
          namingTemplateId: this.namingTemplates.planNamingTemplateId,
          drawingViewLayoutId: null,
          associateType: 0,
          associateTypeId: null,
          selectAll: true,
          spaces: [],
          spacesIds: [],
        },
        scopeViewPlans: {
          epIdx: 5,
          create: false,
          levelsIds: [],
          loading: false,
          loaded: false,
          tree: null,
          total: 0,
          namingTemplateId: this.namingTemplates.scopeViewNamingTemplateId,
          drawingViewLayoutId: null,
        },
        iav: {
          epIdx: 6,
          create: false,
          selectAll: true,
          spaces: [],
          spacesIds: [],
          namingTemplateId: this.namingTemplates.iavNamingTemplateId,
          drawingViewLayoutId: null,
        },
        spec: {
          epIdx: 7,
          create: false,
          loading: false,
          loaded: false,
          data: null,
          result: [],
          total: 0,
          namingTemplateId: this.namingTemplates.specNamingTemplateId,
          drawingViewLayoutId: null,
        },
        scd: {
          epIdx: 8,
          create: false,
          selectAll: true,
          spaces: [],
          spacesIds: [],
          namingTemplateId: this.namingTemplates.scdNamingTemplateId,
          drawingViewLayoutId: null,
        },
      };
      this.expandedPanels = [];
      this.slideouts.bulkCreate.active = true;
    },
    updateConfirmed() {
      this.slideouts.bulkCreate.isLoading = true;
      let toSend = this.cloneDeep(this.options);
      if (toSend.iav.create) {
        toSend.iav.spacesIds = toSend.iav.spaces.map((space) => {
          return space.id;
        });
        toSend.iav.spaces = [];
      }
      if (toSend.scd.create) {
        toSend.scd.spacesIds = toSend.scd.spaces.map((space) => {
          return space.id;
        });
        toSend.scd.spaces = [];
      }
      if (toSend.plan.create) {
        toSend.plan.spacesIds = toSend.plan.spaces.map((space) => {
          return space.id;
        });
        toSend.plan.spaces = [];
      }
      if (toSend.overview.create) {
        toSend.overview.spacesIds = toSend.overview.spaces.map((space) => {
          return space.id;
        });
        toSend.overview.spaces = [];
      }
      if (toSend.detail.create) {
        toSend.detail.spacesIds = toSend.detail.spaces.map((space) => {
          return space.id;
        });
        toSend.detail.spaces = [];
      }
      this.$log("updateConfirmed >>>>>>>> toSend", toSend);
      ddmAPI
        .bulkCreate(this.projectId, toSend)
        .then((resp) => {
          this.slideouts.bulkCreate.isLoading = false;
          var message = "Sheets created successfully!";
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("updated");
          this.closeUpdateSlideout();
        })
        .catch((err) => {
          this.$log("update >> error", err);
          this.slideouts.bulkCreate.isLoading = false;
          this.$handleError(err);
        });
    },
    rowClass(item) {
      return item.count > 0 ? "row-not-empty" : "";
    },
    closeUpdateSlideout() {
      this.slideouts.bulkCreate.isLoading = false;
      this.slideouts.bulkCreate.active = false;
    },
    processBulkCreateSheetsEP(item) {
      var idx = this.expandedPanels.findIndex((x) => x == item.epIdx);
      if (!item.create) {
        if (idx != -1) this.expandedPanels.splice(idx, 1);
      } else {
        if (idx == -1) this.expandedPanels.push(item.epIdx);
      }
    },
    getSheetsCount(count) {
      var sheetsPerPage = 4;
      var initial = Math.trunc(count / sheetsPerPage);
      var remainder = count % sheetsPerPage;
      var toAddition = remainder == 0 ? 0 : 1;
      return initial + toAddition;
    },
    getCategoriesEquipmentCount() {
      this.options.spec.loading = true;
      this.options.spec.loaded = false;
      projectsAPI
        .getCategoriesEquipmentCount(this.projectId)
        .then((resp) => {
          this.$log("getCategoriesEquipmentCount", resp.data);
          this.options.spec.loaded = true;
          this.options.spec.result = resp.data.map((g) => {
            return {
              category: g.category,
              equipmentCount: g.count,
              sheetsCount: this.getSheetsCount(g.count),
            };
          });
          this.options.spec.total = this.options.spec.result.reduce((total, cur) => {
            return total + cur.sheetsCount;
          }, 0);
          this.options.spec.loading = false;
        })
        .catch((err) => {
          this.options.spec.loading = false;
          this.$handleError(err);
        });
    },
    getBuildingsAndLevels() {
      this.options.scopeViewPlans.loading = true;
      buildingsAPI
        .getProjectBuildings(this.projectId)
        .then((resp) => {
          this.$log("getBuildingsAndLevels", resp.data.items);
          this.treePrepare(resp.data.items);
          this.options.scopeViewPlans.loaded = true;
          this.options.scopeViewPlans.loading = false;
        })
        .catch((err) => {
          this.options.scopeViewPlans.loading = false;
          this.$handleError(err);
        });
    },
    treePrepare(data) {
      this.options.scopeViewPlans.tree = data.map((elm) => this.getTreeObject(elm));
    },
    getTreeObject(elm) {
      return {
        id: elm.id,
        name: elm.name,
        shortName: elm.shortName,
        area: elm.area,
        description: elm.description,
        type: "Building",
        children: [...elm.levels],
      };
    },
    getProjectVariables() {
      this.loadingProjectNamingTemplates = true;
      projectsAPI
        .getProjectVariables(this.projectId)
        .then((resp) => {
          this.namingTemplates = resp.data;
          this.prepareForm();
        })
        .finally(() => {
          this.loadingProjectNamingTemplates = false;
        });
    },
  },
  computed: {
    atLeastOneOptionIsSelected() {
      return (
        Object.values(this.options).some((o) => o.create == true) && this.slideouts.bulkCreate.valid
      );
    },
  },
  watch: {
    "options.coverSheet.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.coverSheet);
      },
      deep: true,
    },
    "options.coverSheet.associateType": {
      handler(newVal, OldVal) {
        this.options.coverSheet.associateTypeId = null;
      },
      deep: true,
    },
    "options.sitePlan.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.sitePlan);
      },
      deep: true,
    },
    "options.sitePlan.associateType": {
      handler(newVal, OldVal) {
        this.options.sitePlan.associateTypeId = null;
      },
      deep: true,
    },
    "options.plan.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.plan);
      },
      deep: true,
    },
    "options.plan.associateType": {
      handler(newVal, OldVal) {
        this.options.plan.associateTypeId = null;
        this.options.plan.spaces = [];
        this.options.plan.spacesIds = [];
      },
      deep: true,
    },
    "options.plan.selectAll": {
      handler(newVal, OldVal) {
        if (this.options.plan.selectAll) {
          this.options.plan.spaces = [];
          this.options.plan.spacesIds = [];
        }
      },
      deep: true,
    },
    "options.overview.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.overview);
      },
      deep: true,
    },
    "options.overview.associateType": {
      handler(newVal, OldVal) {
        this.options.overview.associateTypeId = null;
        this.options.overview.spaces = [];
        this.options.overview.spacesIds = [];
      },
      deep: true,
    },
    "options.overview.selectAll": {
      handler(newVal, OldVal) {
        if (this.options.overview.selectAll) {
          this.options.overview.spaces = [];
          this.options.overview.spacesIds = [];
        }
      },
      deep: true,
    },
    "options.detail.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.detail);
      },
      deep: true,
    },
    "options.detail.associateType": {
      handler(newVal, OldVal) {
        this.options.detail.associateTypeId = null;
        this.options.detail.spaces = [];
        this.options.detail.spacesIds = [];
      },
      deep: true,
    },
    "options.detail.selectAll": {
      handler(newVal, OldVal) {
        if (this.options.detail.selectAll) {
          this.options.detail.spaces = [];
          this.options.detail.spacesIds = [];
        }
      },
      deep: true,
    },
    "options.spec.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.spec);
        if (this.options.spec.create && !this.options.spec.loading && !this.options.spec.loaded) {
          this.getCategoriesEquipmentCount();
        }
      },
      deep: true,
    },
    "options.scopeViewPlans.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.scopeViewPlans);
        if (
          this.options.scopeViewPlans.create &&
          !this.options.scopeViewPlans.loading &&
          !this.options.scopeViewPlans.loaded
        ) {
          this.getBuildingsAndLevels();
        }
      },
      deep: true,
    },
    "options.iav.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.iav);
      },
      deep: true,
    },
    "options.iav.selectAll": {
      handler(newVal, OldVal) {
        if (this.options.iav.selectAll) {
          this.options.iav.spaces = [];
          this.options.iav.spacesIds = [];
        }
      },
      deep: true,
    },
    "options.scd.create": {
      handler(newVal, OldVal) {
        this.processBulkCreateSheetsEP(this.options.scd);
      },
      deep: true,
    },
    "options.scd.selectAll": {
      handler(newVal, OldVal) {
        if (this.options.scd.selectAll) {
          this.options.scd.spaces = [];
          this.options.scd.spacesIds = [];
        }
      },
      deep: true,
    },
  },
  components: {
    SelectProjectSpaces,
    NamingTemplateSelector,
    ProjectBuildingSelector,
    ProjectLevelSelector,
    LayoutTemplateSelector,
    DocumentType,
  },
};
</script>

<style lang="scss">
.v-expansion-panels.ddm-eps > .v-expansion-panel > .v-expansion-panel-header:hover:before,
.v-expansion-panels.ddm-eps > .v-expansion-panel > .v-expansion-panel-header:focus:before {
  opacity: 0 !important;
}

.v-expansion-panels.ddm-eps
  > .v-expansion-panel
  > .v-expansion-panel-header
  .v-expansion-panel-header__icon {
  display: none;
}

.v-expansion-panels.ddm-eps > .v-expansion-panel .v-expansion-panel-content__wrap {
  padding: 8px 16px 16px 16px !important;
}

.update-layout-template-slideout {
  .v-window.v-tabs-items {
    overflow: visible !important;
  }
}
.ddm-eps {
  gap: 1rem;
}
.sheet-option {
  border: 2px solid rgba($shades-black, 0.4);
  margin: 0 !important;
  border-radius: 0.5rem !important;

  &.sheet-option-selected {
    border-color: $info-base;
  }
}

.shared-properties-card {
  border: 2px dashed rgba($shades-black, 0.4) !important;
  border-radius: 0.5rem !important;
}
</style>
