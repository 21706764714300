export default [
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    order: 0,
    width: "60px",
  },
  {
    text: "Company",
    value: "company",
    sortable: true,
    width: "110px",
  },
  {
    text: "Name",
    value: "firstName",
    sortable: true,
    width: "250px",
  },
  {
    text: "Email",
    value: "email",
    sortable: true,
  },
  {
    text: "Phone",
    value: "phone",
    sortable: false,
  },
  {
    text: "Address",
    value: "addressLine1",
    sortable: false,
    width: "200px",
  },
  {
    text: "Role",
    value: "contactRole",
    sortable: true,
  },
  {
    text: "Status",
    value: "contactState",
    sortable: true,
  },
];
