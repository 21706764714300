export default [
  {
    text: "Viewed",
    value: "viewed",
    sortable: false,
    width: "32px",
    cellClass: "pl-2 pr-0",
    class: "pl-2 pr-0",
    order: 0,
    hidable: false,
    hidden: false,
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    width: "40px",
    cellClass: "px-0",
    class: "px-0",
    order: 1,
    hidable: true,
    hidden: false,
  },
  {
    text: "Image",
    value: "imageUrl",
    width: "64px",
    cellClass: "pl-2 pr-0",
    class: "pl-2 pr-0",
    order: 2,
    hidable: false,
    hidden: false,
  },
  {
    text: "Building",
    value: "level.building.name",
    width: "100px",
    order: 3,
    hidable: false,
    hidden: false,
  },
  {
    text: "Level",
    value: "level.name",
    width: "82px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Number",
    value: "spaceNumber",
    width: "96px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "name", width: "175px", order: 6, hidable: false, hidden: false },
  {
    text: "Status",
    value: "state",
    width: "120px",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "Template",
    value: "template.name",
    width: "150px",
    cellClass: "px-0",
    class: "px-0",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Equipment",
    value: "equipmentCount",
    width: "120px",
    cellClass: "pr-0",
    class: "pr-0",
    order: 14,
    hidable: true,
    hidden: false,
  },
  {
    text: "IAV #",
    value: "iavSheetCount",
    width: "54px",
    cellClass: "px-0",
    class: "px-0",
    order: 9,
    hidable: true,
    hidden: false,
  },
  {
    text: "SCD #",
    value: "scdSheetCount",
    width: "57px",
    cellClass: "px-0",
    class: "px-0",
    order: 10,
    hidable: true,
    hidden: false,
  },
  {
    text: "Plan #",
    value: "planSheetCount",
    width: "57px",
    cellClass: "px-0",
    class: "px-0",
    order: 11,
    hidable: true,
    hidden: false,
  },
  {
    text: "Overview #",
    value: "overviewSheetCount",
    width: "85px",
    cellClass: "px-0",
    class: "px-0",
    order: 12,
    hidable: true,
    hidden: false,
  },
  {
    text: "Detail #",
    value: "detailSheetCount",
    width: "66px",
    cellClass: "px-0",
    class: "px-0",
    order: 13,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 15,
    hidable: true,
    hidden: false,
  },
  { text: "Creator", value: "createdBy", width: "96px", order: 16, hidable: true, hidden: false },
  { text: "Updater", value: "updatedBy", width: "96px", order: 17, hidable: true, hidden: false },
];
