<template>
  <v-container class="py-3 px-5" fluid>
    <div class="d-flex justify-space-between align-center mb-3">
      <h3 class="text--primary font-weight-black mr-3 d-inline-block">Project Assignees</h3>
    </div>

    <div style="background: #eceff1; border-radius: 0.5rem" class="pa-3 inset-shadow">
      <div class="d-flex align-center my-3">
        <v-tooltip top z-index="999" nudge-top="-4px">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn small color="white" height="38" @click.stop="drawer = !drawer">
                <v-icon>{{ drawer ? "far fa-arrow-from-right" : "far fa-bars" }}</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ drawer ? "Hide" : "Show" }} Assignees List!</span>
        </v-tooltip>
        <v-divider vertical class="mx-2"></v-divider>
        <v-menu
          v-model="dateRangeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto !important"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Choose Date Range"
              dense
              solo
              hide-details
              prepend-inner-icon="far fa-chevron-left"
              append-icon="far fa-chevron-right"
              readonly
              @click:append="next()"
              @click:prepend-inner="prev()"
              v-bind="attrs"
              v-on="on"
              max-width="600px"
            ></v-text-field>
          </template>
          <div class="d-flex">
            <v-date-picker
              :first-day-of-week="1"
              show-adjacent-months
              show-week
              landscape
              range
              v-model="dateRange"
              color="info"
              header-color="info"
            ></v-date-picker>
            <v-divider></v-divider>
            <v-sheet class="py-4 d-flex flex-column">
              <v-btn @click="setCurrentWeek(-1)" color="white" class="ma-1">prev week</v-btn>
              <v-btn @click="setCurrentWeek(0)" color="white" class="ma-1">current week</v-btn>
              <v-btn @click="setCurrentWeek(1)" color="white" class="ma-1">next week</v-btn>
              <v-divider class="my-2"></v-divider>
              <v-btn @click="setCurrentMonth(-1)" color="white" class="ma-1">prev month</v-btn>
              <v-btn @click="setCurrentMonth(0)" color="white" class="ma-1">current month</v-btn>
              <v-btn @click="setCurrentMonth(1)" color="white" class="ma-1">next month</v-btn>
              <v-divider class="my-2"></v-divider>
              <v-sheet class="d-flex" width="100%">
                <v-btn @click="prev()" icon><v-icon> far fa-chevron-left</v-icon></v-btn>
                <v-btn class="ml-auto" @click="next()" icon
                  ><v-icon> far fa-chevron-right</v-icon>
                </v-btn>
              </v-sheet>
            </v-sheet>
          </div>
        </v-menu>
        <v-btn
          color="white"
          class="ms-2"
          @click="getProjectAssignees"
          :disabled="loading"
          :loading="loading"
        >
          <i class="far fa-redo mr-2"></i> Refresh
        </v-btn>
        <v-sheet elevation="2" rounded class="ms-2">
          <v-btn-toggle v-model="mode" mandatory color="info">
            <v-btn value="plan">
              <i class="fad fa-calendar-alt mr-2 secondary--text"></i>
              <span>Plan Time</span>
            </v-btn>
            <v-btn value="log">
              <i class="fad fa-clock mr-2 secondary--text"></i>
              <span>Log Time</span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-sheet elevation="2" rounded class="ms-2">
          <v-btn-toggle v-model="showTempoPlans" color="info">
            <v-btn :value="true">
              <v-img width="14" class="mr-1" contain src="/img/tempo.svg"></v-img>
              <span>Tempo Plans</span>
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-btn color="white" class="ms-2" @click="syncWithTempo">
          <i class="far fa-sync mr-2"></i>Sync with Tempo
        </v-btn>
      </div>

      <div v-if="loading">
        <div
          :key="'assignees-loading'"
          class="assignees-loading d-flex flex-column align-center justify-center"
          style="height: 200px"
        >
          <v-progress-circular
            indeterminate
            color="info"
            :width="2"
            :size="28"
          ></v-progress-circular>
          <h4 class="mt-3 text--disabled">Loading Assignees ...</h4>
        </div>
      </div>

      <v-sheet v-else elevation="2" style="border-radius: 0.5rem">
        <!-- style="width: 200px; min-width: 200px" -->
        <v-simple-table class="resource-planner">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  rowspan="2"
                  ref="assigneesTh"
                  @resize="refreshAssigneesThWidth"
                  v-if="drawer"
                  class="text-left sticky left-0 pa-0"
                  style="width: 250px; min-width: 250px"
                >
                  <v-btn
                    tile
                    v-if="canEdit"
                    color="info"
                    ref="addAssignee"
                    @click="openAddAssigneeDialog"
                    :disabled="loading"
                    block
                    text
                    height="60px !important"
                  >
                    <i class="fal fa-plus mr-2"></i>Add New Assignee
                  </v-btn>
                </th>
                <th
                  rowspan="2"
                  style="width: 72px; min-width: 72px; text-transform: uppercase"
                  :style="{ left: assigneesThWidth + 'px' }"
                  v-if="drawer"
                  class="sticky total text-center"
                >
                  <h4 class="ma-0">Total<br />Plans</h4>
                </th>
                <th
                  rowspan="2"
                  v-if="isLog && drawer"
                  style="width: 72px; min-width: 72px; text-transform: uppercase"
                  :style="{ left: assigneesThWidth + 72 + 'px' }"
                  class="sticky total text-center"
                >
                  <h4 class="ma-0">Total<br />Logs</h4>
                </th>
                <th
                  v-for="month in dateRangeMonths"
                  class="text-center"
                  :key="'month_' + month.name"
                  :colspan="month.count"
                >
                  <v-tooltip top z-index="999" nudge-top="-4px">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="month-text text-uppercase"
                        :class="getMonthBgColor(month.name)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ getFullMonthName(month.name) }}
                      </div>
                    </template>
                    <span>{{ getFullMonthName(month.name) }}</span>
                  </v-tooltip>
                </th>
              </tr>
              <tr>
                <th
                  v-for="date in dateRangeNumber"
                  class="text-center"
                  :key="'date' + date"
                  style="width: 90px; min-width: 90px"
                >
                  <div
                    class="day-box"
                    :class="{
                      today: isToday(date),
                      'day-off-bg': getDay(date)[0] == 'Sat' || getDay(date)[0] == 'Sun',
                      ...getMonthBgColorToday(date),
                    }"
                  >
                    <div
                      class="day-text"
                      :class="{
                        today: isToday(date),
                        ...getMonthBgColorToday(date),
                      }"
                    >
                      {{ getDay(date)[0] }}
                    </div>
                    <!-- ...getMonthTextColor(date), -->
                    <h2
                      class="day-num"
                      :class="{
                        today: isToday(date),
                        ...getMonthBgColorToday(date),
                      }"
                    >
                      {{ getDay(date)[2] }}
                      <span v-if="isToday(date)" style="position: absolute; left: 8px; top: 8px">
                        <i class="fas fa-calendar-day fs-14px"></i>
                      </span>
                    </h2>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="selected.length">
                <tr v-for="(item, index) in selected" :key="'plan-row' + index">
                  <td v-if="drawer" class="sticky left-0 pa-0">
                    <v-list-item
                      @click="
                        item.toggle = !item.toggle;
                        refreshAssigneesThWidthNextTick();
                      "
                      class="assignees"
                      :key="(item.user ? item.user.id : 'noUser') + '-' + index"
                      dense
                    >
                      <span class="fs-14px mr-2 secondary--text">
                        <i v-if="item.toggle" class="fas fa-caret-down"></i>
                        <i v-else class="fas fa-caret-right secondary--text"></i>
                      </span>
                      <v-list-item-avatar size="30" class="mx-2 my-0" color="grey">
                        <v-img v-if="item.user" :src="item.user.avatarUrl"></v-img>
                        <i v-else class="fad fa-user-alt-slash white--text"></i>
                      </v-list-item-avatar>

                      <v-list-item-content class="pa-0">
                        <v-list-item-title v-if="item.user" style="font-size: 12px">
                          {{ item.user.firstName }} {{ item.user.lastName }}
                        </v-list-item-title>
                        <v-list-item-title v-else style="font-size: 12px"
                          >No user assigned!</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <span class="me-2">{{ $getTextByValue(item.type, types) }}</span>
                          <v-chip
                            v-if="item.isLead"
                            x-small
                            style="font-size: 10px; font-weight: 600"
                            text-color="gray darken-2"
                          >
                            <i class="far fa-check mr-2" style="line-height: 0"></i>
                            Lead
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="my-0">
                        <v-menu dense offset-x right>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
                              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                            </v-btn>
                          </template>
                          <v-list class="more-options-menu">
                            <v-list-item @click.stop="update(item, index)">
                              <v-list-item-icon class="mr-2 justify-center">
                                <v-icon small>fal fa-pen</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click.stop="del(index, item.id)">
                              <v-list-item-icon class="mr-2 justify-center">
                                <v-icon small>fal fa-trash-alt red--text</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="red--text">Delete</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <div v-if="item.toggle" :key="'plan-row-toggled' + index">
                      <v-sheet
                        class="d-flex flex-column"
                        v-for="(phase, phaseKey) in phases"
                        :key="phaseKey + 'phase'"
                      >
                        <div class="pl-10 d-flex align-center phase flex-row">
                          <!-- <phase-item :phase="phase"></phase-item> -->
                          <div class="d-flex align-center" style="height: 45px">
                            <v-chip
                              small
                              style="width: 28px; justify-content: center"
                              :color="getPhaseColor(phase)"
                              :text-color="getPhaseTextColor(phase)"
                            >
                              <i
                                class="fas fs-11px"
                                :class="getPhaseIcon(phase)"
                                style="line-height: 0"
                              ></i>
                            </v-chip>
                            <span class="ml-2 fs-12px secondary--text font-weight-bold">{{
                              phase.name
                            }}</span>
                          </div>
                          <v-spacer></v-spacer>
                          <!-- <v-tooltip top z-index="999" nudge-top="-4px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn color="info" v-bind="attrs" v-on="on" icon>
                                <v-icon>far fa-sync</v-icon>
                              </v-btn>
                            </template>
                            <span>Sync This Phase With Tempo</span>
                          </v-tooltip> -->
                        </div>
                        <v-divider v-if="phaseKey < phases.length - 1"></v-divider>
                      </v-sheet>
                    </div>
                  </td>
                  <td
                    v-if="drawer"
                    class="sticky total"
                    style="width: 72px; min-width: 72px"
                    :style="{ left: assigneesThWidth + 'px' }"
                  >
                    <v-sheet
                      color="transparent"
                      :height="item.toggle ? '45px' : '100%'"
                      class="cellUpper d-flex justify-center align-center"
                      >{{ getTotal(item) }}</v-sheet
                    >
                  </td>
                  <td
                    v-if="drawer && isLog"
                    class="sticky total"
                    style="width: 72px; min-width: 72px"
                    :style="{ left: assigneesThWidth + 72 + 'px' }"
                  >
                    <v-sheet
                      color="transparent"
                      :height="item.toggle ? '40px' : '100%'"
                      class="cellUpper d-flex justify-center align-center"
                      >{{ getTotalLog(index) }}</v-sheet
                    >
                  </td>
                  <td
                    v-for="date in dateRangeNumber"
                    :key="'date' + date"
                    class="pa-0"
                    :class="{
                      todayCell: isToday(date),
                      ...getMonthBgColorToday(date),
                    }"
                  >
                    <v-sheet
                      color="transparent"
                      height="45px"
                      class="cellUpper d-flex flex-column align-center"
                      :key="'plan-row-td-sheet-toggled' + index"
                    >
                      <div
                        style="flex: 1"
                        class="d-flex align-center"
                        :class="{ hideOnHover: !isLog }"
                      >
                        <span>{{ getValue(item, date) }}</span>
                      </div>
                      <v-btn
                        block
                        tile
                        text
                        height="100% !important"
                        min-width="auto"
                        color="primary"
                        @click="updateEvent({ date: getDate(date) }, index, false)"
                        v-if="!isLog"
                      >
                        <i class="fal fa-plus-circle fa-2x"></i
                      ></v-btn>
                      <v-progress-linear
                        :color="getColor(getValue(item, date, 'number'), getDay(date)[0], item)"
                        :value="getValue(item, date, 'percentage')"
                        style="width: 96%; border-radius: 0.25rem; margin-bottom: 1px"
                      ></v-progress-linear>
                    </v-sheet>
                    <div v-if="item.events && item.toggle" :key="'plan-row-td-toggled' + index">
                      <v-sheet
                        class="d-flex align-end flex-column"
                        v-for="(phase, phaseKey) in phases"
                        :key="phaseKey + 'phase'"
                        color="transparent"
                        :height="isLog ? '45px' : '45px'"
                        width="100%"
                      >
                        <div
                          class="d-flex align-center justify-center flex-column"
                          style="width: 100%; height: 100%"
                        >
                          <div
                            style="width: 90%"
                            :key="eventKey + 'eventKey'"
                            v-for="(event, eventKey) in getCurrentDateEvent(
                              item.events,
                              date,
                              phase.id
                            )"
                          >
                            <v-sheet
                              v-if="event.isTempoPlan ? showTempoPlans : true"
                              class="event py-1 px-2 rounded position-relative"
                              elevation="2"
                              v-ripple
                              @click="
                                event.isTempoPlan
                                  ? null
                                  : updateEvent({ date: getDate(date), ...event }, index, true)
                              "
                            >
                              <div class="d-flex align-center">
                                <v-tooltip
                                  top
                                  z-index="999"
                                  nudge-top="-4px"
                                  v-if="!event.isTempoPlan && event.isSynced"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      v-bind="attrs"
                                      v-on="on"
                                      class="green lighten-5 green--text darken-1--text d-inline-flex justify-center align-center mr-2"
                                      style="width: 20px; height: 20px; border-radius: 20px"
                                    >
                                      <i class="fas fa-arrows-rotate fs-12px"></i>
                                    </span>
                                  </template>
                                  <span>Synced with Tempo</span>
                                </v-tooltip>
                                <v-tooltip
                                  top
                                  z-index="999"
                                  nudge-top="-4px"
                                  v-else-if="!event.isTempoPlan && !event.isSynced"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <span
                                      v-bind="attrs"
                                      v-on="on"
                                      class="red lighten-5 red--text darken-1--text d-inline-flex justify-center align-center mr-2"
                                      style="width: 20px; height: 20px; border-radius: 20px"
                                    >
                                      <i class="far fa-rotate-exclamation fs-12px"></i>
                                    </span>
                                  </template>
                                  <span>Not Synced with Tempo</span>
                                </v-tooltip>
                                <span
                                  v-if="isLog && event.logTimes && !event.isTempoPlan"
                                  class="mr-1"
                                >
                                  {{ event.logTimes.duration || "0h" }} /
                                </span>
                                <v-img
                                  width="12"
                                  min-width="12"
                                  max-width="12"
                                  class="mr-1"
                                  v-if="event.isTempoPlan"
                                  contain
                                  src="/img/tempo.svg"
                                ></v-img>
                                {{ event.duration }}
                                <v-spacer></v-spacer>
                                <div class="event-actions ms-1" v-if="true && !event.isTempoPlan">
                                  <v-menu dense offset-x right>
                                    <template v-slot:activator="{ attrs, on }">
                                      <v-btn small icon elevation="0" v-bind="attrs" v-on="on">
                                        <i class="far fa-ellipsis-v" style="font-size: 14px"></i>
                                      </v-btn>
                                    </template>
                                    <v-list class="more-options-menu">
                                      <v-list-item
                                        @click="
                                          updateEvent(
                                            { date: getDate(date), ...event },
                                            index,
                                            true
                                          )
                                        "
                                      >
                                        <v-list-item-icon class="mr-2 justify-center">
                                          <v-icon small>far fa-pen</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Update</v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        v-if="!isLog"
                                        @click="
                                          updateEvent({ date: getDate(date), ...event }, index)
                                        "
                                      >
                                        <v-list-item-icon class="mr-2 justify-center">
                                          <v-icon small>far fa-clone</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title>Clone</v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>

                                      <v-list-item
                                        v-if="
                                          isLog ? event.logTimes && event.logTimes.duration : true
                                        "
                                        @click="delEvent(index, getDate(date), event)"
                                      >
                                        <v-list-item-icon class="mr-2 justify-center">
                                          <v-icon small>fal fa-trash-alt red--text</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                          <v-list-item-title class="red--text"
                                            >Delete</v-list-item-title
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </div>
                              </div>
                              <v-progress-linear
                                class="mt-1"
                                v-if="isLog && !event.isTempoPlan"
                                :color="
                                  getLogColor(getLogValue(event.logTimes.duration, event.duration))
                                "
                                :value="getLogValue(event.logTimes.duration, event.duration)"
                              ></v-progress-linear>
                            </v-sheet>
                          </div>
                        </div>
                        <v-divider
                          v-if="phaseKey < phases.length - 1"
                          style="width: 100%"
                        ></v-divider>
                      </v-sheet>
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td :colspan="2 + dateRangeNumber" class="text-center">
                  No Assignees Found
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    @click="openAddAssigneeDialog"
                    plain
                    small
                  >
                    <i class="fas fa-plus mr-1" plan> </i>Create
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-sheet>
    </div>

    <v-dialog v-model="modals.addAssignees.active" max-width="400px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small v-if="modals.addAssignees.update">
          <i class="fad fa-edit mr-2 mt-1"></i>Update Assignee
        </v-card-title>
        <v-card-title class="font-weight-bold" small v-else>
          <i class="fad fa-plus-square mr-2 mt-1"></i>Add New Assignee
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.addAssignees.valid" ref="AssigneeForm">
          <v-card-text>
            <v-container class="py-0">
              <v-row class="my-0" dense>
                <v-col cols="12">
                  <v-autocomplete-alt
                    auto-select-first
                    label="User"
                    id="AssigneeUser"
                    placeholder="User"
                    v-model="modals.addAssignees.data.user"
                    :items="usersList"
                    dense
                    filled
                    :filter="filterByFullName"
                    return-object
                    item-text="firstName"
                    item-value="id"
                    :loading="isUsersLoading"
                    :disabled="isUsersLoading"
                    clearable
                  >
                    <template v-slot:selection="{ item }">
                      <user-avatar disableOpenLink :user="item"></user-avatar>
                    </template>
                    <template v-slot:item="{ item }">
                      <user-avatar disableOpenLink :user="item"></user-avatar>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col cols="12">
                  <v-select-alt
                    label="Type"
                    placeholder="Type"
                    id="AssigneeType"
                    :items="types"
                    v-model="modals.addAssignees.data.type"
                    :rules="[allRules.required]"
                  ></v-select-alt>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-0"
                    hide-details
                    v-model="modals.addAssignees.data.isLead"
                    label="Is Lead"
                    :disabled="disabledIsLead"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="modalDiscard()"> <i class="fal fa-chevron-left mr-2"></i>Discard </v-btn>
            <v-btn
              color="info"
              :loading="saveLoading"
              :disabled="!modals.addAssignees.valid"
              @click="modalConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>
              {{ modals.addAssignees.update ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.updateEvent.active" max-width="550px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small v-if="modals.updateEvent.update">
          <i class="fad fa-pen-square mr-2"></i> Update Plan
        </v-card-title>
        <v-card-title v-else class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i> Add Plan
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.updateEvent.valid" ref="updateEventForm">
          <v-card-text>
            <v-container class="py-0" v-if="modals.updateEvent.active">
              <v-row class="my-0" dense>
                <v-col sm="12" class="mb-3">
                  <v-autocomplete-alt
                    auto-select-first
                    :rules="[allRules.required]"
                    :items="selected"
                    item-text="user.firstName"
                    item-value="id"
                    label="Assignee"
                    id="Assignee"
                    ref="Assignee"
                    placeholder="Assignee"
                    v-model="modals.updateEvent.data.item"
                    dense
                    filled
                    return-object
                    hide-details
                  >
                    <template #selection="{ item }">
                      <v-list-item dense>
                        <v-list-item-avatar size="30" class="me-2 my-0" color="grey">
                          <v-img v-if="item.user" :src="item.user.avatarUrl"></v-img>
                          <i v-else class="fad fa-user-alt-slash white--text"></i>
                        </v-list-item-avatar>

                        <v-list-item-content class="pa-0">
                          <v-list-item-title v-if="item.user" style="font-size: 12px">
                            {{ item.user.firstName }} {{ item.user.lastName }}
                          </v-list-item-title>
                          <v-list-item-title v-else style="font-size: 12px"
                            >No user assigned!</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            <span class="me-2">{{ $getTextByValue(item.type, types) }}</span>
                            <v-chip
                              v-if="item.isLead"
                              x-small
                              style="font-size: 10px; font-weight: 600"
                              color="secondary"
                              text-color="gray darken-2"
                            >
                              <i class="far fa-check mr-2" style="line-height: 0"></i>
                              Lead
                            </v-chip>
                            <span v-else
                              ><i
                                class="fal fa-times pl-2 text--disabled"
                                style="line-height: 0"
                              ></i
                            ></span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template #item="{ item, on, attrs }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-avatar size="30" class="me-2 my-0" color="grey">
                          <v-img v-if="item.user" :src="item.user.avatarUrl"></v-img>
                          <i v-else class="fad fa-user-alt-slash white--text"></i>
                        </v-list-item-avatar>

                        <v-list-item-content class="pa-0">
                          <v-list-item-title v-if="item.user" style="font-size: 12px">
                            {{ item.user.firstName }} {{ item.user.lastName }}
                          </v-list-item-title>
                          <v-list-item-title v-else style="font-size: 12px"
                            >No user assigned!</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            <span class="me-2">{{ $getTextByValue(item.type, types) }}</span>
                            <v-chip
                              v-if="item.isLead"
                              x-small
                              style="font-size: 10px; font-weight: 600"
                              color="secondary"
                              text-color="gray darken-2"
                            >
                              <i class="far fa-check mr-2" style="line-height: 0"></i>
                              Lead
                            </v-chip>
                            <span v-else
                              ><i
                                class="fal fa-times pl-2 text--disabled"
                                style="line-height: 0"
                              ></i
                            ></span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete-alt>
                </v-col>
                <v-col
                  sm="auto"
                  v-if="!modals.updateEvent.update"
                  class="d-flex align-center pr-4 pt-5"
                >
                  <v-switch
                    label="Period"
                    class="mt-0"
                    v-model="modals.updateEvent.data.period"
                    hide-details
                  ></v-switch>
                </v-col>
                <v-col sm="4" v-if="!modals.updateEvent.update">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field-alt
                        :rules="[allRules.required]"
                        prepend-inner-icon="far fa-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        label="Date"
                        id="Date"
                        ref="Date"
                        placeholder="Date"
                        v-model="modals.updateEvent.data.date"
                        hide-details
                      >
                      </v-text-field-alt>
                    </template>
                    <v-date-picker
                      :first-day-of-week="1"
                      v-model="modals.updateEvent.data.date"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-slide-x-transition v-if="!modals.updateEvent.update">
                  <v-col sm="4" v-if="modals.updateEvent.data.period" key="updateEvent-period">
                    <v-menu
                      v-model="dateMenuTo"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field-alt
                          :rules="[allRules.required]"
                          prepend-inner-icon="far fa-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          label="To"
                          id="DateTo"
                          ref="DateTo"
                          placeholder="To"
                          v-model="modals.updateEvent.data.to"
                          hide-details
                        >
                        </v-text-field-alt>
                      </template>
                      <v-date-picker
                        :first-day-of-week="1"
                        v-model="modals.updateEvent.data.to"
                        @input="dateMenuTo = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-slide-x-transition>
                <v-col :sm="modals.updateEvent.data.period ? 6 : 6" class="mt-4">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    label="Duration <span class='ml-1 text--secondary fs-12px float-right'><u>Format</u>: '0h 0m', '0' or '0%'</span>"
                    prepend-inner-icon="far fa-clock"
                    id="Duration"
                    ref="Duration"
                    placeholder="0h 0m"
                    v-model="modals.updateEvent.data.duration"
                    @blur="formateDuration()"
                    :suffix="modals.updateEvent.data.period ? 'Per Day' : ''"
                    hide-details
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="6" class="mt-4">
                  <v-select-alt
                    id="phase"
                    placeholder="Select a Phase"
                    label="Phase"
                    v-model="modals.updateEvent.data.projectPhaseId"
                    :items="phases"
                    item-value="id"
                    transparent
                    :rules="[allRules.required]"
                    hide-details
                  >
                    <template #selection="{ item }">
                      <phase-item :phase="item"></phase-item>
                    </template>
                    <template #item="{ item }">
                      <phase-item :phase="item"></phase-item>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col sm="12" class="mt-4">
                  <v-textarea-alt
                    label="Description"
                    id="Description"
                    ref="Description"
                    :rows="2"
                    placeholder="Description"
                    v-model="modals.updateEvent.data.description"
                    hide-details
                  >
                  </v-textarea-alt>
                </v-col>
                <v-col
                  v-if="modals.updateEvent.update && modals.updateEvent.data.rangeId != null"
                  sm="12"
                  class="mt-4"
                >
                  <v-switch
                    label="Update Whole Range"
                    class="mt-0"
                    v-model="modals.updateEvent.data.updateRange"
                    hide-details
                  ></v-switch>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateEventDiscard()">
              <i class="fal fa-chevron-left mr-2"></i> Cancel
            </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.updateEvent.valid || modals.updateEvent.loading"
              :loading="modals.updateEvent.loading"
              @click="updateEventConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>{{ modals.updateEvent.update ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.logTime.active" max-width="400px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small v-if="modals.logTime.update">
          <i class="fad fa-pen-square mr-2"></i> Edit Time Record
        </v-card-title>
        <v-card-title v-else class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i> Log Time
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.logTime.valid" ref="logTimeForm">
          <v-card-text>
            <v-container class="py-0" v-if="modals.logTime.active">
              <v-row class="my-0" dense>
                <v-col cols="6">
                  <user-avatar
                    :user="modals.logTime.data.item.user"
                    :disableOpenLink="true"
                  ></user-avatar>
                </v-col>
                <v-col cols="6">
                  <div class="py-2">
                    {{ modals.logTime.data.date }}
                  </div>
                </v-col>

                <v-col :sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    label="Duration"
                    prepend-inner-icon="far fa-clock"
                    id="Duration"
                    ref="Duration"
                    placeholder="0h 0m"
                    hint="'0h 0m' , '0' or '0%'"
                    v-model="modals.logTime.data.duration"
                    @blur="formateLogDuration(modals.logTime.data.planDuration)"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    label="Description"
                    id="Description"
                    ref="Description"
                    :rows="2"
                    placeholder="Description"
                    v-model="modals.logTime.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="logDiscard()"> <i class="fal fa-chevron-left mr-2"></i> Cancel </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.logTime.valid || modals.logTime.loading"
              :loading="modals.logTime.loading"
              @click="updateLogConfirmed()"
            >
              <i class="fal fa-check mr-2"></i>{{ modals.logTime.update ? "Update" : "Add" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modals.deletePlan.active" max-width="400px" persistent>
      <v-card>
        <v-card-title class="font-weight-bold" small v-if="modals.deletePlan.update">
          <i class="fad fa-pen-square mr-2"></i> Edit Time Record
        </v-card-title>
        <v-card-title v-else class="font-weight-bold" small>
          <i class="fad fa-pen-square mr-2"></i> Log Time
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.deletePlan.valid" ref="deletePlanForm">
          <v-card-text>
            <v-container class="py-0" v-if="modals.deletePlan.active">
              <v-row class="my-0" dense>
                <v-col cols="6">
                  <user-avatar
                    :user="modals.deletePlan.data.item.user"
                    :disableOpenLink="true"
                  ></user-avatar>
                </v-col>
                <v-col cols="6">
                  <div class="py-2">
                    {{ modals.deletePlan.data.date }}
                  </div>
                </v-col>

                <v-col :sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required]"
                    label="Duration"
                    prepend-inner-icon="far fa-clock"
                    id="Duration"
                    ref="Duration"
                    placeholder="0h 0m"
                    hint="'0h 0m' , '0' or '0%'"
                    v-model="modals.deletePlan.data.duration"
                    @blur="formateLogDuration(modals.deletePlan.data.planDuration)"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-textarea-alt
                    label="Description"
                    id="Description"
                    ref="Description"
                    :rows="2"
                    placeholder="Description"
                    v-model="modals.deletePlan.data.description"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="logDiscard()"> <i class="fal fa-chevron-left mr-2"></i> Cancel </v-btn>
            <v-btn
              color="info"
              :disabled="!modals.deletePlan.valid || modals.deletePlan.loading"
              :loading="modals.deletePlan.loading"
              @click="updateLogConfirmed()"
            >
              <i class="fal fa-trash-alt mr-2"></i>Delete
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import perms from "../../../plugins/permissions";
import assigneeHeader from "../../Admin/config/tables/assignee.header";
import ApiService from "../../Admin/services/StaffService";
import UserAvatar from "../../Shared/components/UserAvatar.vue";
import projectsAPI from "../services/projects-service";
import resourcePlannerAPI from "../services/resourcePlanner-service";
import logTimeAPI from "../services/logTime-service";
import tempoAPI from "../services/tempo-service";
import moment from "moment";
import phaseService from "../services/phases-service";
import PhaseItem from "./PhaseItem.vue";
import Enums from "../../../plugins/enums";

export default {
  components: { UserAvatar, PhaseItem },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      mode: "plan",
      showTempoPlans: false,
      phases: [],
      dateRange: [],
      dateRangeMenu: false,
      selected: [],
      selectedCemented: [],
      hasChanges: false,
      loading: false,
      disabledIsLead: false,
      saveLoading: false,
      dateMenu: false,
      dateMenuTo: false,
      drawer: true,
      modals: {
        addAssignees: {
          active: false,
          valid: false,
          data: {
            user: "",
            type: "",
            isLead: false,
          },
          index: -1,
          update: false,
        },
        updateEvent: {
          active: false,
          valid: false,
          loading: false,
          update: false,
          data: {
            duration: null,
            date: null,
            to: null,
            index: null,
            item: null,
            eventIndex: null,
            projectPhaseId: null,
            period: false,
          },
        },
        logTime: {
          active: false,
          valid: false,
          loading: false,
          update: false,
          data: {
            duration: null,
            index: null,
            item: null,
            eventIndex: null,
          },
        },
        deletePlan: {
          active: false,
          valid: false,
          loading: false,
          data: {
            duration: null,
            index: null,
            item: null,
            eventIndex: null,
          },
        },
      },
      headers: assigneeHeader,
      isUsersLoading: false,
      usersList: [],
      types: [
        {
          text: "AC",
          value: 1,
        },
        {
          text: "VI",
          value: 2,
        },
        {
          text: "BIM",
          value: 3,
        },
      ],
      phaseHeight: "25px",
      assigneesThWidth: 250,
    };
  },
  methods: {
    // - Helper section ------------------------------------------------
    getMonthBgSolidColor(month) {
      return {
        "blue white--text": month == "Jan",
        "purple white--text": month == "Feb",
        "cyan white--text": month == "Mar",
        "green white--text": month == "Apr",
        "teal white--text": month == "May",
        "deep-purple white--text": month == "Jun",
        "light-green white--text": month == "Jul",
        "indigo white--text": month == "Aug",
        "lime white--text": month == "Sep",
        "amber white--text": month == "Oct",
        "blue-grey white--text": month == "Nov",
        "brown white--text": month == "Dec",
      };
    },
    getMonthBgColor(month) {
      var classes = [
        { "blue-grey darken-3 white--text": month == "Jan" },
        { "blue-grey white--text": month == "Feb" },
        { "blue-grey darken-3 white--text": month == "Mar" },
        { "blue-grey white--text": month == "Apr" },
        { "blue-grey darken-3 white--text": month == "May" },
        { "blue-grey white--text": month == "Jun" },
        { "blue-grey darken-3 white--text": month == "Jul" },
        { "blue-grey white--text": month == "Aug" },
        { "blue-grey darken-3 white--text": month == "Sep" },
        { "blue-grey white--text": month == "Oct" },
        { "blue-grey darken-3 white--text": month == "Nov" },
        { "blue-grey white--text": month == "Dec" },
      ];
      return classes;
    },
    getMonthTextColor(date) {
      var classes = [
        { "blue-grey darken-3 white--text": month == "Jan" },
        { "blue-grey white--text": month == "Feb" },
        { "blue-grey darken-3 white--text": month == "Mar" },
        { "blue-grey white--text": month == "Apr" },
        { "blue-grey darken-3 white--text": month == "May" },
        { "blue-grey white--text": month == "Jun" },
        { "blue-grey darken-3 white--text": month == "Jul" },
        { "blue-grey white--text": month == "Aug" },
        { "blue-grey darken-3 white--text": month == "Sep" },
        { "blue-grey white--text": month == "Oct" },
        { "blue-grey darken-3 white--text": month == "Nov" },
        { "blue-grey white--text": month == "Dec" },
      ];
      return classes;
      // return {
      //   "blue--text": this.isToday(date) && this.getDay(date)[1] == "Jan",
      //   "purple--text": this.isToday(date) && this.getDay(date)[1] == "Feb",
      //   "cyan--text": this.isToday(date) && this.getDay(date)[1] == "Mar",
      //   "green--text": this.isToday(date) && this.getDay(date)[1] == "Apr",
      //   "teal--text": this.isToday(date) && this.getDay(date)[1] == "May",
      //   "deep-purple--text": this.isToday(date) && this.getDay(date)[1] == "Jun",
      //   "light-green--text": this.isToday(date) && this.getDay(date)[1] == "Jul",
      //   "indigo--text": this.isToday(date) && this.getDay(date)[1] == "Aug",
      //   "lime--text": this.isToday(date) && this.getDay(date)[1] == "Sep",
      //   "amber--text": this.isToday(date) && this.getDay(date)[1] == "Oct",
      //   "blue-grey--text": this.isToday(date) && this.getDay(date)[1] == "Nov",
      //   "brown--text": this.isToday(date) && this.getDay(date)[1] == "Dec",
      // };
    },
    getMonthBgColorToday(date) {
      var classes = { "light-blue lighten-5 info--text": this.isToday(date) };
      return classes;
      // return {
      //   "blue lighten-5 blue--text": this.isToday(date) && this.getDay(date)[1] == "Jan",
      //   "purple lighten-5 purple--text": this.isToday(date) && this.getDay(date)[1] == "Feb",
      //   "cyan lighten-5 cyan--text": this.isToday(date) && this.getDay(date)[1] == "Mar",
      //   "green lighten-5 green--text": this.isToday(date) && this.getDay(date)[1] == "Apr",
      //   "teal lighten-5 teal--text": this.isToday(date) && this.getDay(date)[1] == "May",
      //   "deep-purple lighten-5 deep-purple--text":
      //     this.isToday(date) && this.getDay(date)[1] == "Jun",
      //   "light-green lighten-5 light-green--text":
      //     this.isToday(date) && this.getDay(date)[1] == "Jul",
      //   "indigo lighten-5 indigo--text": this.isToday(date) && this.getDay(date)[1] == "Aug",
      //   "lime lighten-5 lime--text": this.isToday(date) && this.getDay(date)[1] == "Sep",
      //   "amber lighten-5 amber--text": this.isToday(date) && this.getDay(date)[1] == "Oct",
      //   "blue-grey lighten-5 blue-grey--text": this.isToday(date) && this.getDay(date)[1] == "Nov",
      //   "brown lighten-5 brown--text": this.isToday(date) && this.getDay(date)[1] == "Dec",
      // };
    },
    refreshAssigneesThWidth() {
      this.assigneesThWidth =
        this.$refs.assigneesTh != null ? this.$refs.assigneesTh.offsetWidth : 250;
      // this.$log("assigneesThWidth: ", this.assigneesThWidth);
    },
    refreshAssigneesThWidthNextTick() {
      setTimeout(() => {
        // this.$log("refreshAssigneesThWidthNextTick: ", this.assigneesThWidth);
        this.refreshAssigneesThWidth();
      }, 50);
    },
    filterByFullName(item, queryText, itemText) {
      const textOne = item.firstName.toLowerCase();
      const textTwo = item.lastName.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    getDay(days) {
      let date = new Date(this.dateRange[0]);
      return new Date(date.setDate(date.getDate() + days - 1)).toDateString().split(" ");
    },
    getDate(days) {
      let date = new Date(this.dateRange[0]);
      return this.formatDate(new Date(date.setDate(date.getDate() + days - 1)));
    },
    isToday(days) {
      return this.getDate(days) == this.formatDate(new Date());
    },
    prev() {
      let first = new Date(this.dateRange[0]);
      first = new Date(first.setDate(first.getDate() - this.dateRangeNumber));
      let last = new Date(this.dateRange[0]);
      last = new Date(last.setDate(last.getDate() - 1));
      this.dateRange = [this.formatDate(first), this.formatDate(last)];
    },
    next() {
      let last = new Date(this.dateRange[1]);
      last = new Date(last.setDate(last.getDate() + this.dateRangeNumber));
      let first = new Date(this.dateRange[1]);
      first = new Date(first.setDate(first.getDate() + 1));
      this.dateRange = [this.formatDate(first), this.formatDate(last)];
    },
    durationToObject(duration) {
      const [hour, minute] = this.getHoursAndMinutes(
        isNaN(duration) ? this.hoursMinuteToNumber(duration) : duration
      );
      return {
        hour,
        minute,
      };
    },
    objectToDuration(obj) {
      return this.validateTime(`${obj.hour || 0}h${obj.minute || 0}m`);
    },
    validateTime(duration, baseTime = null) {
      let hours = "";
      let minutes = "";
      if (isNaN(duration)) {
        if (duration.includes("%")) {
          let percentage = duration.split(/%/)[0];
          if (!isNaN(percentage)) {
            percentage = Number(percentage);

            [hours, minutes] = this.getHoursAndMinutes(
              ((baseTime || this.modals.updateEvent.data.item.workLoad) * percentage) / 100
            );
          }
        } else {
          hours = duration.split(/h/);
          if (hours.length) {
            let lastItem = hours[hours.length - 1];
            hours = hours.reduce((a, b) => {
              if (!isNaN(b)) return a + Number(b);
              return a;
            }, 0);
            if (lastItem.includes("m")) {
              minutes = lastItem.split(/m/);
              minutes = minutes.reduce((a, b) => {
                if (!isNaN(b)) return a + Number(b);
                return a;
              }, 0);
              if (minutes > 59) {
                let extraHour = 0;
                [extraHour, minutes] = this.getHoursAndMinutes(minutes / 60);
                hours += extraHour;
              }
            }
          }
        }
      } else {
        [hours, minutes] = this.getHoursAndMinutes(duration);
      }
      let time = "";
      if (hours) time = hours + "h";
      if (minutes) {
        if (time) time += " ";
        time += minutes + "m";
      }
      return time;
    },
    getHoursAndMinutes(duration) {
      const hours = Math.floor(duration);
      let minutes = duration % 1;
      if (minutes < 1) {
        minutes = Math.round(minutes * 60);
      }
      return [hours, minutes];
    },
    setCurrentWeek(week) {
      let first = moment().add(week, "weeks").startOf("week").format("YYYY-MM-DD");
      const last = moment().add(week, "weeks").endOf("week").format("YYYY-MM-DD");
      this.dateRange = [first, last];
    },
    setCurrentMonth(month) {
      const startOfMonth = moment().add(month, "M").startOf("month").format("YYYY-MM-DD");
      const endOfMonth = moment().add(month, "M").endOf("month").format("YYYY-MM-DD");
      this.dateRange = [startOfMonth, endOfMonth];
    },
    getCurrentDateEvent(events, days, projectPhaseId, searchDate = null) {
      let date = searchDate || this.getDate(days);
      return events.filter((event) => event.date == date && event.projectPhaseId == projectPhaseId);
    },
    getTotal(item) {
      let total = item.events.reduce((prev, cur) => {
        return Number(prev) + this.hoursMinuteToNumber(cur.duration);
      }, 0);
      return total ? this.validateTime(total) : 0;
    },
    getTotalLog(index) {
      let item = this.selected[index];
      let total = item.events.reduce((prev, cur) => {
        return (
          prev +
          this.hoursMinuteToNumber(
            cur.logTimes && !Array.isArray(cur.logTimes) ? cur.logTimes.duration : ""
          )
        );
      }, 0);
      return total ? this.validateTime(total) : 0;
    },
    // - Shared Between Log and Plan section ------------------------------------------------
    updateEvent(event, index, update) {
      if (this.isLog) {
        this.updateLog(event, index, update);
      } else {
        this.updatePlan(event, index, update);
      }
    },
    delEvent(index, date, event) {
      if (this.isLog) {
        this.delLog(index, event);
      } else {
        this.delPlan(index, date, event);
      }
    },
    setSelected(resp) {
      let selected = resp.data.items || resp.data;
      selected = selected.map((elm) => this.mapEvent(elm));
      this.selected = selected;
      this.cementSelected();
      this.refreshAssigneesThWidth();
    },
    mapEvent(elm, toggle = false) {
      elm.toggle = toggle;
      elm.events = elm.resourcePlans;
      elm.events.map((event) => {
        event.date = this.formatDate(event.date);
        //TODO: - remove this line after get real tempo
        // event.isTempoPlan = Math.random() < 0.5;
        event.duration = this.objectToDuration(event.duration);
        if (event.logTimes && Array.isArray(event.logTimes) && event.logTimes.length) {
          event.logTimes = event.logTimes[0];
          event.logTimes.duration = this.objectToDuration(event.logTimes.duration);
        }
        return event;
      });
      return elm;
    },
    // - Assignee section ------------------------------------------------
    getProjectAssignees() {
      this.loading = true;
      resourcePlannerAPI
        .query({
          ProjectId: this.projectId,
          FromDate: this.dateRange[0],
          ToDate: this.dateRange[1],
        })
        .then((resp) => {
          this.$log("resp", resp);
          this.setSelected(resp);
          this.$nextTick(() => {
            this.refreshAssigneesThWidth();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveAssignees(assignee) {
      return new Promise((resolve, reject) => {
        if (assignee) {
          this.saveLoading = true;
          projectsAPI
            .updateAssignee(this.projectId, assignee)
            .then((res) => {
              this.updateOrAdd(this.selected, this.mapEvent(res.data));
              this.cementSelected();
              this.$dialog.notify.success("Assignees updated!", {
                position: "top-right",
                timeout: 3000,
              });
              resolve();
            })
            .catch((err) => {
              this.$handleError(err);
              reject();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    del(index, id) {
      this.$dialog.warning({
        text: `Are you sure you want to delete this assignee?`,
        title: `Delete Assignee?`,
        color: "error",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "error",
            handle: () => {
              return projectsAPI.deleteAssignee(id).then(() => {
                this.selected.splice(index, 1);
                this.cementSelected();
                this.$dialog.notify.success("Deleted Successfully", {
                  position: "top-right",
                  timeout: 3000,
                });
              });
            },
          },
        },
      });
    },
    validateAssignee() {
      let valid = false;
      const user = this.modals.addAssignees.data.user;
      const index = this.modals.addAssignees.index;
      const item = {
        id: this.modals.addAssignees.data.id,
        user: user,
        type: this.modals.addAssignees.data.type,
        isLead: this.modals.addAssignees.data.isLead,
      };
      if (this.modals.addAssignees.data.isLead) {
        this.setLead(this.modals.addAssignees.data.type);
      } else {
        const count = this.getLeadCountByType(this.modals.addAssignees.data.type);
        item.isLead = count < 1 ? true : item.isLead;
      }
      const existIndex = this.selected.findIndex((assignee, key) => {
        let exist = assignee.type == this.modals.addAssignees.data.type;
        if (key == index || !exist || (user && !assignee.user) || (!user && assignee.user)) {
          return false;
        }
        if (user && assignee.user) {
          exist = assignee.user.id == user.id;
        }
        return exist;
      });
      if (existIndex > -1) {
        // item exist
        this.$dialog.notify.error("This assignee is exist with the same type", {
          position: "top-right",
          icon: "fas fa-user-edit fs-16px",
          timeout: 0,
        });
      } else {
        if (existIndex == -1 && index == -1) {
          // create
          valid = true;
        } else if ((existIndex > -1 && index == -1) || (existIndex == -1 && index > -1)) {
          // remove and replace
          valid = true;
        }
      }
      return [item, valid];
    },
    modalConfirmed() {
      let [assignee, valid] = this.validateAssignee();
      if (valid) {
        let cloned = this.cloneDeep(assignee);
        cloned.userId = cloned.user.id;
        delete cloned.user;

        this.saveAssignees(cloned)
          .then(() => {
            this.modalDiscard();
          })
          .catch(() => {
            this.discard();
          });
      }
    },
    modalDiscard() {
      this.modals.addAssignees = {
        active: false,
        valid: false,
        data: {
          user: "",
          type: "",
          isLead: false,
        },
        update: false,
        index: -1,
      };
    },
    getUsers() {
      this.isUsersLoading = true;
      ApiService.typeHead("''", true)
        .then((resp) => {
          this.usersList = resp.data;
          this.isUsersLoading = false;
        })
        .catch(() => {
          this.isUsersLoading = false;
        });
    },
    update(item, index) {
      this.modals.addAssignees.data = {
        id: item.id,
        user: item.user,
        type: item.type,
        isLead: item.isLead,
      };
      this.modals.addAssignees.update = true;
      this.modals.addAssignees.index = index;
      this.openAddAssigneeDialog();
    },
    setLead(type) {
      this.selected
        .filter((elm) => elm.type == type)
        .forEach((element) => {
          element.isLead = false;
        });
    },
    getLeadCountByType(type) {
      return this.selected.filter(
        (elm, key) => key != this.modals.addAssignees.index && elm.type == type && elm.isLead
      ).length;
    },
    checkChanges() {
      return;
      this.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      if (this.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    cementSelected() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkChanges();
    },
    discard() {
      this.selected = this.cloneDeep(this.selectedCemented);
      this.checkChanges();
    },
    openAddAssigneeDialog() {
      this.modals.addAssignees.active = true;
      setTimeout(() => {
        if (this.$refs.AssigneeForm) this.$refs.AssigneeForm.resetValidation();
      });
    },
    hoursMinuteToNumber(string) {
      let hours = string.split(/h/);
      let minutes = hours[hours.length - 1].split(/m/);
      hours = isNaN(hours[0]) ? 0 : Math.floor(Number(hours[0]));
      minutes = isNaN(minutes[0]) ? 0 : Number(minutes[0]) / 60;

      return hours + minutes;
    },
    // - Plan Time section ------------------------------------------------
    updateEventConfirmed() {
      let updateEvent = this.modals.updateEvent;
      updateEvent.loading = true;
      updateEvent.data.index = this.selected.findIndex((elm) => elm.id == updateEvent.data.item.id);
      this.selected[updateEvent.data.index].events =
        this.selected[updateEvent.data.index].events || [];

      if (!this.modals.updateEvent.data.period) {
        updateEvent.data.to = null;
      }
      let event = {
        id: updateEvent.data.id,
        fromDate: updateEvent.data.date,
        toDate: updateEvent.data.to,
        duration: this.durationToObject(updateEvent.data.duration),
        projectPhaseId: updateEvent.data.projectPhaseId,
        description: updateEvent.data.description,
        projectId: this.projectId,
        projectAssigneeId: updateEvent.data.item.id,
        updateRange: updateEvent.data.updateRange,
        RangeDate: {
          fromDate: this.dateRange[0],
          toDate: this.dateRange[1],
        },
      };
      if (!updateEvent.update) {
        const isSaveEvent = this.getCurrentDateEvent(
          this.selected[updateEvent.data.index].events,
          0,
          event.projectPhaseId,
          updateEvent.data.date
        );
        if (isSaveEvent.length) {
          event.duration = this.durationToObject(
            this.hoursMinuteToNumber(isSaveEvent[0].duration) +
              this.hoursMinuteToNumber(updateEvent.data.duration)
          );
          event.id = isSaveEvent[0].id;
        }
      }
      resourcePlannerAPI
        .update(event)
        .then((resp) => {
          var updatedAssigneeObj = this.mapEvent(resp.data, true);
          this.$log("updatedAssigneeObj: ", this.cloneDeep(updatedAssigneeObj));
          const curAssigneeObj = this.cloneDeep(this.selected[updateEvent.data.index]);
          this.$log("curAssigneeObj.resourcePlans: ", this.cloneDeep(curAssigneeObj.resourcePlans));
          if (this.modals.updateEvent.update) {
            for (let i = 0; i < curAssigneeObj.resourcePlans.length; i++) {
              var curRP = curAssigneeObj.resourcePlans[i];
              var idx = updatedAssigneeObj.resourcePlans.findIndex((r) => r.id == curRP.id);
              if (idx != -1) {
                curAssigneeObj.resourcePlans[i] = this.cloneDeep(
                  updatedAssigneeObj.resourcePlans[idx]
                );
                this.$log("idx: ", idx);
              }
            }
          } else {
            this.$log(
              "updatedAssigneeObj.resourcePlans: ",
              this.cloneDeep(updatedAssigneeObj.resourcePlans)
            );
            this.$log(
              "curAssigneeObj.resourcePlans: ",
              this.cloneDeep(curAssigneeObj.resourcePlans)
            );
            curAssigneeObj.resourcePlans.push(...updatedAssigneeObj.resourcePlans);
          }
          curAssigneeObj.events = this.cloneDeep(curAssigneeObj.resourcePlans);
          this.$log("curAssigneeObj: ", this.cloneDeep(curAssigneeObj));
          // let mappedAssignee = this.mapEvent(curAssigneeObj, true);
          this.$set(this.selected, updateEvent.data.index, curAssigneeObj);
          // this.combinePhases(updateEvent.data.index);
          this.updateEventDiscard();
        })
        .catch((rej) => {
          this.$handleError(rej);
        })
        .finally(() => {
          updateEvent.loading = false;
        });
    },
    updateEventDiscard() {
      this.modals.updateEvent = {
        active: false,
        valid: false,
        loading: false,
        update: false,
        data: {
          duration: null,
          date: null,
          index: null,
          item: null,
          eventIndex: null,
          projectPhaseId: null,
        },
      };
    },
    delPlan(index, date, event) {
      this.$log("delPlan", index, date, event);
      this.$dialog
        .error({
          text: `Are you sure you want to delete this plan?`,
          title: `Delete Plan?`,
          width: "550px",
          color: "error",
          persistent: true,
          actions: {
            cancel: {
              text: "Cancel",
            },
            wholeRange: {
              disabled: event.rangeId == null || event.rangeId == 0,
              icon: "fal fa-calendar-range mr-2",
              text: "Delete Whole Range",
              color: "pink",
              handle: () => {
                return resourcePlannerAPI.delete(event.id, true).then(() => {
                  this.selected[index].events = this.selected[index].events.filter(
                    (e) => e.rangeId != event.rangeId
                  );
                  this.selected[index].resourcePlans = this.selected[index].resourcePlans.filter(
                    (e) => e.rangeId != event.rangeId
                  );
                  this.$dialog.notify.success("Whole Range Deleted Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
              },
            },
            planOnly: {
              icon: "fal fa-calendar-day mr-2",
              text: "Delete This Plan Only",
              color: "error",
              handle: () => {
                return resourcePlannerAPI.delete(event.id, false).then(() => {
                  const eventIndex = this.selected[index].events.findIndex(
                    (e) => e.date == date && event.projectPhaseId == e.projectPhaseId
                  );
                  if (eventIndex !== -1) this.selected[index].events.splice(eventIndex, 1);

                  const rpIndex = this.selected[index].resourcePlans.findIndex(
                    (e) => e.date == date && event.projectPhaseId == e.projectPhaseId
                  );
                  if (rpIndex !== -1) this.selected[index].resourcePlans.splice(rpIndex, 1);

                  this.$dialog.notify.success("Plan Deleted Successfully!", {
                    position: "top-right",
                    timeout: 3000,
                  });
                });
              },
            },
          },
        })
        .then((res) => {});
    },
    getColor(duration, day, workLoad) {
      //TODO - update this to workLoad
      const workLoadTargetDayHours = this.getWorkLoadTargetDayHours(day, workLoad);
      // this.$log("getColor", duration, day, workLoad, workLoadTargetDayHours);
      if (duration < workLoadTargetDayHours - 0.08) {
        return "warning";
      } else if (
        duration <= workLoadTargetDayHours + 0.09 &&
        duration >= workLoadTargetDayHours - 0.09
      ) {
        return "success";
      } else {
        return "error";
      }
    },
    getWorkLoadTargetDayHours(day, workLoad) {
      switch (day) {
        case "Sat":
          return workLoad.saturdayWorkload;
          break;
        case "Sun":
          return workLoad.sundayWorkload;
          break;
        case "Mon":
          return workLoad.mondayWorkload;
          break;
        case "Tue":
          return workLoad.tuesdayWorkload;
          break;
        case "Wed":
          return workLoad.wednesdayWorkload;
          break;
        case "Thu":
          return workLoad.thursdayWorkload;
          break;
        case "Fri":
          return workLoad.fridayWorkload;
          break;
        default:
          return 8;
          break;
      }
    },
    getValue(item, days, type = "") {
      let date = this.getDate(days);
      const events = item.events.filter((event) => event.date == date);
      let count = events ? events.reduce((a, b) => a + this.hoursMinuteToNumber(b.duration), 0) : 0;
      switch (type) {
        case "percentage":
          const workLoadTargetDayHours = this.getWorkLoadTargetDayHours(this.getDay(days)[0], item);
          return (count / workLoadTargetDayHours) * 100;
          break;
        case "number":
          return count;
          break;
        default:
          return this.validateTime(String(count));
          break;
      }
    },
    getFullMonthName(shortName) {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].find((m) => m.startsWith(shortName));
    },
    getFullDayName(shortName) {
      return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"].find(
        (m) => m.startsWith(shortName)
      );
    },
    updatePlan({ date, duration, projectPhaseId, description, id, rangeId }, index, update) {
      if (!this.modals.updateEvent.active) {
        let updateEvent = this.modals.updateEvent;
        updateEvent.update = update;
        if (index || index == 0) {
          updateEvent.data.index = index;
          updateEvent.data.item = this.selected[index];
        }
        updateEvent.data.rangeId = rangeId;
        if (date) {
          updateEvent.data.date = date;
        }
        if (duration) {
          updateEvent.data.duration = duration;
        }
        if (projectPhaseId) {
          updateEvent.data.projectPhaseId = projectPhaseId;
        }
        if (description) {
          updateEvent.data.description = description;
        }
        if (updateEvent.update) {
          updateEvent.data.id = id;
        }
        updateEvent.active = true;
        updateEvent.data.eventIndex = updateEvent.update
          ? this.selected[updateEvent.data.index].events.findIndex(
              (elm) =>
                elm.date == updateEvent.data.date &&
                elm.projectPhaseId == updateEvent.data.projectPhaseId
            )
          : -1;
      }
    },
    formateDuration() {
      this.modals.updateEvent.data.duration = this.validateTime(
        this.modals.updateEvent.data.duration
      );
    },
    // - Log Time section ------------------------------------------------
    updateLog({ id, projectPhaseId, logTimes, duration, date }, index) {
      if (!this.modals.logTime.active) {
        let updateLog = this.modals.logTime;
        updateLog.update = false;
        if (index || index == 0) {
          updateLog.data.index = index;
          updateLog.data.item = this.selected[index];
        }
        if (id) {
          updateLog.data.resourcePlanId = id;
        }
        if (date) {
          updateLog.data.date = date;
        }
        if (duration) {
          updateLog.data.planDuration = duration;
        }
        if (logTimes) {
          updateLog.data.duration = logTimes.duration;
          updateLog.data.id = logTimes.id;
          updateLog.data.description = logTimes.description;
          updateLog.update = logTimes.id ? true : false;
        }
        if (projectPhaseId) {
          updateLog.data.projectPhaseId = projectPhaseId;
        }
        updateLog.active = true;
        updateLog.data.eventIndex = updateLog.update
          ? this.selected[updateLog.data.index].events.findIndex(
              (elm) =>
                elm.date == updateLog.data.date &&
                elm.projectPhaseId == updateLog.data.projectPhaseId
            )
          : -1;
      }
    },
    logDiscard() {
      this.modals.logTime = {
        active: false,
        valid: false,
        loading: false,
        update: false,
        data: {
          duration: null,
          index: null,
          item: null,
          eventIndex: null,
        },
      };
    },
    updateLogConfirmed() {
      let updateLog = this.modals.logTime;
      updateLog.loading = true;
      updateLog.data.index = this.selected.findIndex((elm) => elm.id == updateLog.data.item.id);
      this.selected[updateLog.data.index].events = this.selected[updateLog.data.index].events || [];
      let event = {
        resourcePlanId: updateLog.data.resourcePlanId,
        duration: this.durationToObject(updateLog.data.duration),
        description: updateLog.data.description,
        id: updateLog.data.id,
        RangeDate: {
          fromDate: this.dateRange[0],
          toDate: this.dateRange[1],
        },
      };
      logTimeAPI
        .update(event)
        .then((res) => {
          let elm = this.mapEvent(res.data, true);
          this.$set(this.selected, updateLog.data.index, elm);
          this.logDiscard();
        })
        .catch((rej) => {
          this.$handleError(rej);
        })
        .finally(() => {
          updateLog.loading = false;
        });
    },
    delLog(index, event) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this time?`,
          title: `Delete logged Time?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return logTimeAPI
                  .delete(event.logTimes.id, {
                    fromDate: this.dateRange[0],
                    toDate: this.dateRange[1],
                  })
                  .then((res) => {
                    let elm = this.mapEvent(res.data, true);
                    this.$set(this.selected, index, elm);
                    this.$log(res.data);
                    this.$dialog.notify.success("Deleted Successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    getLogValue(log, plan) {
      return log ? (this.hoursMinuteToNumber(log) / this.hoursMinuteToNumber(plan)) * 100 : 0;
    },
    getLogColor(duration) {
      if (duration < 100) {
        return "warning";
      } else if (duration == 100) {
        return "success";
      } else {
        return "error";
      }
    },
    formateLogDuration(planDuration) {
      this.modals.logTime.data.duration = this.validateTime(
        this.modals.logTime.data.duration,
        this.hoursMinuteToNumber(planDuration)
      );
    },
    // - phase section ------------------------------------------------
    getPhaseColor(phase) {
      return this.getEnumMember(Enums.PROJECT_STAGE, phase.status).color;
    },
    getPhaseTextColor(phase) {
      return this.getEnumMember(Enums.PROJECT_STAGE, phase.status).textColor;
    },
    getPhaseIcon(phase) {
      return this.getEnumMember(Enums.PROJECT_STAGE, phase.status).icon;
    },
    combinePhases(index) {
      var output = [];
      this.selected[index].events.forEach((item) => {
        let existing = output.filter(
          (v) => v.date == item.date && v.projectPhaseId == item.projectPhaseId
        );
        if (existing.length) {
          var existingIndex = output.indexOf(existing[0]);
          output[existingIndex].duration = this.validateTime(
            this.hoursMinuteToNumber(output[existingIndex].duration) +
              this.hoursMinuteToNumber(item.duration)
          );
        } else {
          output.push(item);
        }
      });
      this.selected[index].events = output;
    },
    getPhases() {
      phaseService
        .query(this.projectId, {})
        .then((resp) => {
          this.phases = resp.data.items || [];
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    getPhase(id) {
      return this.phases.find((elm) => elm.id == id);
    },
    syncWithTempo() {
      this.$dialog.info({
        text: `Are you sure you want to sync this project plans with Tempo?`,
        title: `Sync with Tempo?`,
        color: "info",
        width: "475px",
        icon: "fal fa-sync mr-3",
        persistent: true,
        actions: {
          false: {
            text: "Cancel",
          },
          true: {
            text: "Confirm",
            color: "info",
            handle: () => {
              return tempoAPI
                .syncPlans(this.projectId)
                .then((resp) => {
                  this.$dialog.notify.success("Project plans synced with Tempo successfully", {
                    position: "top-right",
                    timeout: 3000,
                  });
                })
                .catch((err) => {
                  // this.$dialog.notify.error("an error occurred during syncing plans!", {
                  //   position: "top-right",
                  //   timeout: 6000,
                  // });
                  this.$handleError(err);
                });
            },
          },
        },
      });
    },
  },
  watch: {
    mode: {
      handler() {
        this.refreshAssigneesThWidthNextTick();
      },
      deep: true,
    },
    selected: {
      handler() {
        this.checkChanges();
      },
      deep: true,
    },
    hasChanges() {
      // this.$emit("has-changes", this.hasChanges);
    },
    "modals.addAssignees.data.type"(val) {
      const assignees = this.getLeadCountByType(val);
      if (!!assignees) {
        this.modals.addAssignees.data.isLead = false;
        this.disabledIsLead = false;
      } else {
        this.disabledIsLead = true;
        this.modals.addAssignees.data.isLead = true;
      }
    },
    "modals.updateEvent.data.date"(val) {
      let validDate = this.modals.updateEvent.data.to
        ? moment(val).isAfter(this.modals.updateEvent.data.to)
        : true;
      if (this.modals.updateEvent.data.period && validDate) {
        this.modals.updateEvent.data.to = moment(val).add(1, "days").format("YYYY-MM-DD");
      }
    },
    "modals.updateEvent.data.period"(val) {
      if (val) {
        let validDate = this.modals.updateEvent.data.to
          ? moment(this.modals.updateEvent.data.date).isAfter(this.modals.updateEvent.data.to)
          : true;
        if (this.modals.updateEvent.data.period && validDate) {
          this.modals.updateEvent.data.to = moment(this.modals.updateEvent.data.date)
            .add(1, "days")
            .format("YYYY-MM-DD");
        }
      } else {
        this.modals.updateEvent.data.to = null;
      }
    },
    dateRange(val) {
      if (val.length == 2) {
        this.dateRangeMenu = false;
        this.getProjectAssignees();
      }
    },
    loading(val) {
      if (!val) {
        setTimeout(() => {
          this.$moveable(document.querySelector(".resource-planner .v-data-table__wrapper"));
          this.refreshAssigneesThWidth();
          document
            .querySelector(".resource-planner .v-data-table__wrapper")
            .addEventListener("scroll", (evt) => {
              this.refreshAssigneesThWidth();
            });
        }, 100);
      }
    },
  },
  created() {
    this.getPhases();
    this.getUsers();
    this.setCurrentWeek();
  },
  computed: {
    canEdit() {
      return this.$has(perms.ProjectAssignees.Update);
    },
    dateRangeText() {
      return this.dateRange.join(" ~ ");
    },
    dateRangeNumber() {
      return this.dateRange.length == 2
        ? Math.round(
            (new Date(this.dateRange[1]) - new Date(this.dateRange[0])) / (1000 * 60 * 60 * 24)
          ) + 1
        : 0;
    },
    dateRangeMonths() {
      var counts = {};
      for (let date = 1; date <= this.dateRangeNumber; date++) {
        const month = this.getDay(date)[1];
        counts[month] = {
          name: month,
          count: (counts[month] ? counts[month].count : 0 || 0) + 1,
        };
      }
      return counts;
    },
    isLog() {
      return this.mode == "log";
    },
  },
};
</script>

<style lang="scss">
.day-off-bg {
  position: relative;

  :after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
    background-color: #e5e5f7;
    opacity: 0.16;
    pointer-events: none;
    background: repeating-linear-gradient(45deg, #263238, #263238 4px, #cfd8dc 4px, #cfd8dc 20px);
  }
}

.day-box {
  position: relative;
  padding-bottom: 0.2rem;
  height: 100%;

  &.today {
    border-bottom: thin solid rgba(42, 54, 59, 0.12) !important;
  }

  .month-text {
    position: relative;
    text-transform: uppercase;
    background: rgba($shades-black, 0.64);
    color: #fff;

    &.today {
      color: #fff;
      background: $info-base;
    }
  }
  .day-num {
    font-weight: 600;
    color: rgba($shades-black, 0.72);
    line-height: 20px;

    &.today {
      color: $info-base;
    }
  }
  .day-text {
    opacity: 0.64;
    line-height: 18px;
    padding-top: 0.1rem;

    &.today {
      color: $info-base;
    }
  }
}
</style>

<style lang="scss">
.resource-planner {
  border-radius: 0.5rem;

  .v-data-table__wrapper > table > thead > tr > th {
    height: auto;
  }

  .v-list-item--dense,
  .v-list--dense .v-list-item {
    min-height: 48px !important;
  }

  table {
    border: thin solid rgba(42, 54, 59, 0.12);
    border-left: none;
    border-radius: 0.5rem;
  }

  td,
  th {
    border-left: thin solid rgba(42, 54, 59, 0.12);
    padding: 0 !important;

    &.todayCell {
      background: rgba($info-base, 0.08);
      border-bottom: 0 !important;
    }

    &.Assignee {
      border-left: none;
    }

    &.toggleAssigneeIcon {
      padding-right: 5px !important;
    }
  }

  td .cellUpper {
    .v-btn {
      display: none;
    }
    &:hover {
      & .v-btn {
        display: inline-flex;
        animation: fade-in 0.7s ease;
      }
      & .hideOnHover {
        display: none !important;
      }
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .event {
    cursor: pointer;
    white-space: nowrap;
    transition: width 0.5s ease;
  }
}
// .theme--light.v-data-table
//   > .v-data-table__wrapper
//   > table
//   > tbody
//   > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
//   background: none;
// }
.planner-resources {
  .planner-resources-drawer {
    flex: none;
    width: 100% !important;
  }

  .planner-resources-content {
    flex: 1 1 auto;
    overflow: auto;
    border: 1px solid rgba($shades-black, 0.1) !important;
    border-radius: 0.35rem !important;

    div.addAssignees,
    .assignees.v-list-item {
      border-top: 1px solid rgba($shades-black, 0.1) !important;
    }
  }
}

.project-assignees {
  border: 1px solid rgba($shades-black, 0.1);
  height: 100%;
}
.hide-header {
  .v-calendar-daily_head-weekday,
  .v-calendar-daily_head-day-label {
    display: none;
  }
}
.v-calendar-daily__head {
  margin-right: 0px !important;
}
.v-calendar-daily__intervals-head {
  display: none;
}
.sticky {
  position: sticky;
  background: white;
  z-index: 1;
}
.left-0 {
  left: 0;
}
.total {
  left: 270px;
  box-shadow: 5px 0px 5px -3px rgb(42 54 59 / 16%);
}
.position-relative {
  position: relative;
}
</style>
