<template>
  <v-dialog v-model="modals.active" max-width="900px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Another Project Space
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneSpaceForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col md="6">
                <project-selector
                  style="width: 100%"
                  class="mr-2"
                  :label="`<i class='fad fa-folder mr-2'></i>Source Project`"
                  required
                  v-model="modals.data.projectId"
                ></project-selector>
              </v-col>
              <v-col md="6">
                <project-space-selector
                  :label="`<i class='fad fa-table-pivot mr-2'></i>Source Space`"
                  :project-id="modals.data.projectId"
                  :readonly="modals.data.projectId == null"
                  v-model="modals.data.spaceId"
                  @change="spaceChanged"
                ></project-space-selector>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="py-0">
            <v-form v-model="modals.valid">
              <v-row class="my-0" dense>
                <v-col sm="12" class="mb-3">
                  <single-image-uploader
                    :disabled="modals.data.projectId == null || modals.data.spaceId == null"
                    id="clone-space-image-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="modals.data.imageUrl"
                    label="Space Image"
                  ></single-image-uploader>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :readonly="modals.data.projectId == null || modals.data.spaceId == null"
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Space Name"
                    placeholder="Space Name"
                    v-model="modals.data.name"
                    autofocus
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-text-field-alt
                    :readonly="modals.data.projectId == null || modals.data.spaceId == null"
                    label="Space Number"
                    id="spaceNumber"
                    ref="spaceNumber"
                    placeholder="Space Number"
                    v-model="modals.data.spaceNumber"
                    @blur="checkIfSpaceNumberExist"
                    :error-messages="modals.errorMessage"
                    :loading="modals.checkNumberLoading"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <space-building-level-editor
                    :projectId="targetProjectId"
                    v-model="modals.data.level"
                    :allowAdding="true"
                  ></space-building-level-editor>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneSpaceDiscard()" text><i class="far fa-xmark mr-2"></i>Cancel</v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.checkNameLoading"
            @click="cloneSpaceConfirmed()"
            :loading="modals.loading"
          >
            <i class="far fa-check mr-2"></i> Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import spacesService from "../services/spaces-service";
import SpaceBuildingLevelEditor from "./SpaceBuildingLevelEditor.vue";
import ProjectSelector from "../../Shared/components/ProjectSelector.vue";
import ProjectSpaceSelector from "../../Shared/components/ProjectSpaceSelector.vue";

export default {
  components: { SpaceBuildingLevelEditor, ProjectSelector, ProjectSpaceSelector },
  data() {
    return {
      imageApiUrl: `Spaces/Image`,
      targetProjectId: null,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        checkNumberLoading: false,
        data: {
          name: null,
          imageUrl: null,
          spaceId: null,
          projectId: null,
          spaceNumber: null,
          level: null,
        },
      },
    };
  },
  methods: {
    open(projectId) {
      this.targetProjectId = projectId;
      this.modals.active = true;
      this.modals.data.projectId = null;
      this.modals.data.spaceId = null;
      this.modals.data.imageUrl = null;
      this.modals.data.name = null;
      this.modals.data.spaceNumber = null;
      this.modals.data.level = null;
    },
    spaceChanged(spaceObj) {
      if (spaceObj != null) {
        this.modals.data.imageUrl = spaceObj.imageUrl;
        this.modals.data.name = spaceObj.name;
        this.modals.data.spaceNumber = spaceObj.spaceNumber;
      } else {
        this.modals.data.imageUrl = null;
        this.modals.data.name = null;
        this.modals.data.spaceNumber = null;
      }
    },
    cloneSpaceDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        checkNumberLoading: false,
        data: {
          name: null,
          imageUrl: null,
          spaceId: null,
          projectId: null,
          spaceNumber: null,
          level: null,
        },
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name) {
        this.modals.checkNameLoading = true;
        spacesService
          .checkIfSpaceNameExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `(${this.modals.data.name}) Space name is already exist`;
          })
          .finally(() => (this.modals.checkNameLoading = false));
      }
    },
    checkIfSpaceNumberExist() {
      if (this.modals.data.name && this.modals.data.level != null) {
        this.modals.checkNumberLoading = true;
        spacesService
          .checkIfSpaceNumberExist({
            spaceNumber: this.modals.data.spaceNumber,
            projectId: this.targetProjectId,
            level: this.modals.data.level,
          })
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `A space with the same number (${this.modals.data.spaceNumber}) already exists in the same level!`;
            else this.modals.errorMessage = "";
          })
          .finally(() => (this.modals.checkNumberLoading = false));
      }
    },
    cloneSpaceConfirmed() {
      this.modals.loading = true;
      spacesService
        .cloneSpaceFromAnotherProject(this.targetProjectId, this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Space cloned successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.cloneSpaceDiscard();
          this.$emit("done", res.data);
          this.$log(res.data);
          this.$router.push({
            name: `view-project-space`,
            params: { id: res.data.projectId, spaceId: res.data.id },
          });
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
  watch: {
    "modals.data.level": {
      handler() {
        this.checkIfSpaceNumberExist();
      },
      deep: true,
    },
  },
};
</script>
