<template>
  <v-dialog v-model="modals.active" max-width="500px" persistent>
    <v-card>
      <v-card-title class="font-weight-bold d-flex" small>
        <i class="fal fa-clone mr-2"></i>Clone Space Template
      </v-card-title>
      <v-divider></v-divider>
      <v-form v-model="modals.valid" ref="cloneSpaceTemplateForm">
        <v-card-text>
          <v-container class="py-0">
            <v-row class="my-0" dense>
              <v-col sm="12" class="mb-3">
                <single-image-uploader
                  id="clone-space-template-image-uploader"
                  :apiUrl="imageApiUrl"
                  v-model="modals.data.imageUrl"
                  label="Space Template Image"
                ></single-image-uploader>
              </v-col>
              <v-col sm="12">
                <v-text-field-alt
                  :rules="[allRules.required, allRules.noWhiteSpaces]"
                  label="Space Template Name"
                  placeholder="Space Template Name"
                  v-model="modals.data.name"
                  @blur="checkIfNameExist"
                  :error-messages="modals.errorMessage"
                  :loading="modals.checkNameLoading"
                >
                </v-text-field-alt>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cloneSpaceTemplateDiscard()" text>
            <i class="fal fa-xmark mr-2"></i>Cancel
          </v-btn>
          <v-btn
            color="info"
            :disabled="!modals.valid || modals.checkNameLoading"
            @click="cloneSpaceTemplateConfirmed()"
            :loading="modals.loading"
          >
            <i class="fal fa-check mr-2"></i> Clone
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import SingleImageUploader from "../../Shared/components/SingleImageUploader.vue";
import spaceTemplatesService from "../services/spaceTemplates-service";
export default {
  components: { SingleImageUploader },
  data() {
    return {
      imageApiUrl: `spacetemplates/Image`,
      modals: {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        data: {
          name: "",
          imageUrl: "",
          spaceTemplateId: "",
          projectId: "",
        },
      },
    };
  },
  methods: {
    open(spaceTemplate) {
      this.modals.active = true;
      this.modals.data.spaceTemplateId = spaceTemplate.id;
      this.modals.data.imageUrl = spaceTemplate.imageUrl;
      this.modals.data.projectId = spaceTemplate.projectId;
      this.modals.data.name = this.spaceTemplateNameConvention(spaceTemplate.name);
      this.checkIfNameExist();
    },
    spaceTemplateNameConvention(name) {
      return name + " [Clone]";
    },
    cloneSpaceTemplateDiscard() {
      this.modals = {
        active: false,
        valid: false,
        errorMessage: "",
        loading: false,
        checkNameLoading: false,
        data: {
          name: "",
          imageUrl: "",
          spaceTemplateId: "",
          projectId: "",
        },
      };
    },
    checkIfNameExist() {
      if (this.modals.data.name != null && this.modals.data.name.trim() != "") {
        this.modals.checkNameLoading = true;
        spaceTemplatesService
          .checkIfSpaceTemplateNameExist(this.modals.data)
          .then((res) => {
            this.$log(res.data);
            if (res.data)
              this.modals.errorMessage = `(${this.modals.data.name}) Space Template name is already exist`;
            else this.modals.errorMessage = null;
          })
          .finally(() => (this.modals.checkNameLoading = false));
      }
    },
    cloneSpaceTemplateConfirmed() {
      this.modals.loading = true;
      spaceTemplatesService
        .cloneSpaceTemplate(this.modals.data)
        .then((res) => {
          this.$dialog.notify.success("Space template cloned successfully!", {
            position: "top-right",
            timeout: 3000,
          });
          this.$log("cloneSpaceTemplate resp.data", res.data);
          this.$emit("done", res.data);
          // this.$router.push({
          //   name: `space-template`,
          //   params: { id: res.data.id },
          // });
          this.cloneSpaceTemplateDiscard();
        })
        .catch((err) => {
          this.$handleError(err);
        })
        .finally(() => (this.modals.loading = false));
    },
  },
};
</script>
