export default [
  {
    text: "Actions",
    value: "actions",
    order: 0,
    sortable: false,
    width: "150px",
  },
  {
    text: "Name",
    value: "user.firstName",
    sortable: true,
  },
  {
    text: "Type",
    value: "type",
    sortable: true,
  },
  {
    text: "Is Lead",
    value: "isLead",
    sortable: true,
  },
];
