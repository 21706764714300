<template>
  <span class="d-inline-flex" style="gap: 0.25rem">
    <span v-if="value == null || value == ''">
      <i class="fad fa-empty-set fs-16px ml-2" style="opacity: 0.24"></i>
    </span>
    <span v-else class="d-flex align-center" style="gap: 0.5rem">
      <span>
        <b class="secondary white--text rounded px-2 mr-1">{{
          getEnumMember(enums.PROJECT_TYPE, parsedValue.Value).letter
        }}</b>
        {{ getEnumMember(enums.PROJECT_TYPE, parsedValue.Value).text }}
      </span>
    </span>
  </span>
</template>

<script>
import enums from "../../../../plugins/enums";
export default {
  components: {},
  name: "type-logged-prop",
  inheritAttrs: false,
  data() {
    return { enums };
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedValue() {
      return JSON.parse(this.value);
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
