export default [
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    width: "40px",
    cellClass: "px-2",
    class: "px-2",
    order: 1,
    hidable: true,
    hidden: false,
  },
  {
    text: "Image",
    value: "space.imageUrl",
    width: "54px",
    cellClass: "pl-2 pr-0",
    class: "pl-2 pr-0",
    order: 2,
    hidable: false,
    hidden: false,
  },
  {
    text: "Building",
    value: "space.level.building.name",
    width: "100px",
    order: 3,
    hidable: false,
    hidden: false,
  },
  {
    text: "Level",
    value: "space.level.name",
    width: "82px",
    order: 4,
    hidable: true,
    hidden: false,
  },
  {
    text: "Number",
    value: "space.spaceNumber",
    width: "96px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "space.name", width: "175px", order: 6, hidable: false, hidden: false },
  {
    text: "Status",
    value: "space.state",
    width: "120px",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "Template",
    value: "space.template.name",
    width: "120px",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Equipment Count",
    value: "equipmentCount",
    width: "180px",
    order: 9,
    hidable: true,
    hidden: false,
  },
];
