<template>
  <v-card
    v-if="file != null"
    class="file"
    :class="{
      'uploading-failed': uploadError,
      'uploading-in-progress': uploadActive,
      'uploading-queue': uploadQueue,
    }"
    elevation="2"
    rounded="lg"
  >
    <div class="file-thumbnail" :data-ext="fileExt">
      <span class="file-ext elevation-1" :data-ext="fileExt">
        <span>{{ fileExt }}</span>
      </span>
    </div>
    <div class="file-info" ref="fileInfo">
      <v-tooltip top z-index="999" left nudge-top="-8px" :disabled="!fileNameOverflow">
        <template v-slot:activator="{ on, attrs }">
          <div class="file-name" ref="FileName" v-bind="attrs" v-on="on">
            {{ fileName }}
          </div>
        </template>
        <span> {{ fileName }}</span>
      </v-tooltip>
      <div class="file-details">
        <div class="file-size">{{ file.size | fileSize }}</div>
        <i class="fad fa-circle" v-if="!uploadQueue" style="font-size: 6px"></i>
        <div class="file-date" v-if="!uploadQueue"><dater :date="file.createDate"></dater></div>
        <i class="fad fa-circle" style="font-size: 6px" v-if="!uploadQueue"></i>
        <user-avatar v-if="!uploadQueue" :user="file.createdBy" icon mini></user-avatar>
        <i
          class="fad fa-circle"
          style="font-size: 6px"
          v-if="!uploadQueue && file.description != null && file.description.trim() != ''"
        ></i>
        <v-tooltip
          top
          z-index="999"
          nudge-top="-4px"
          v-if="!uploadQueue && file.description != null && file.description.trim() != ''"
        >
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="ml-1 d-flex">
              <i class="fad fa-square-quote fs-14px secondary--text"></i>
            </span>
          </template>
          <span class="d-flex align-center">
            <b class="mr-1"><u>Description</u>:</b>
            {{ file.description }}
          </span>
        </v-tooltip>
      </div>
    </div>
    <div class="file-actions">
      <v-btn
        v-if="!uploadQueue && file.blobUrl != null && file.blobUrl != ''"
        icon
        color="secondary"
        @click="view"
      >
        <i class="fad fa-eye"></i>
      </v-btn>
      <v-btn
        v-if="!uploadQueue && canDownload && file.blobUrl != null && file.blobUrl != ''"
        icon
        color="secondary"
        :href="file.blobUrl"
        :download="file.fileName"
        target="_blank"
      >
        <!-- <i class="fad fa-down-to-line"></i> -->
        <i class="fad fa-down-to-bracket"></i>
      </v-btn>
      <v-menu dense offset-x left v-if="!uploadQueue && (canUpdate || canDelete)">
        <template v-slot:activator="{ attrs, on }">
          <v-btn icon v-bind="attrs" v-on="on" color="secondary">
            <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
          </v-btn>
        </template>
        <v-list class="more-options-menu">
          <v-list-item @click="update" v-if="canUpdate">
            <v-list-item-icon class="mr-2 justify-center">
              <v-icon small class="secondary--text">fal fa-pen</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Update Description</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="del" v-if="canDelete">
            <v-list-item-icon class="mr-2 justify-center">
              <v-icon small>fal fa-trash-alt red--text</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="red--text">Delete</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip top z-index="999" nudge-top="-4px" v-if="uploadQueue">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="cancelUpload"
            dark
            rounded
            width="28px"
            min-width="28px"
            height="28px"
            min-height="28px"
            style="padding: 0 !important"
          >
            <i class="far fa-times"></i>
          </v-btn>
        </template>
        <span class="d-flex align-center">Cancel Uploading!</span>
      </v-tooltip>
      <v-tooltip top z-index="999" nudge-top="-4px" v-if="uploadQueue && uploadError">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="retryUpload"
            color="orange"
            dark
            rounded
            width="28px"
            min-width="28px"
            height="28px"
            min-height="28px"
            style="padding: 0 !important"
          >
            <i class="far fa-redo"></i>
          </v-btn>
        </template>
        <span class="d-flex align-center">Retry</span>
      </v-tooltip>
    </div>
    <div class="uploading-progress" v-if="uploadQueue">
      <div
        v-if="uploadActive || uploadError"
        class="bar"
        :style="{ width: uploadProgress + '%' }"
      ></div>
      <span class="percentage">
        <i v-if="!uploadActive && !uploadError" class="fad fa-spinner-third fa-spin"></i>
        <i
          v-else-if="!uploadActive && uploadError"
          class="fad fa-exclamation-triangle red--text opacity-32"
        ></i>
        <span v-else-if="uploadActive && !uploadError" class="opacity-72 green--text">
          {{ uploadProgress }}%
        </span>
      </span>
    </div>
  </v-card>
</template>
<script>
import perms from "../../../../plugins/permissions";

export default {
  name: "file",
  data() {
    return {
      perms,
      file: null,
      fileNameOverflow: false,
    };
  },
  props: {
    value: {
      type: [File, Object],
      default: null,
    },
    baseUrl: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    uploadQueue: {
      type: Boolean,
      default: false,
    },
    uploadActive: {
      type: Boolean,
      default: false,
    },
    uploadProgress: {
      type: Number,
      default: 0,
    },
    uploadError: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.value != null && this.value != "") {
      this.file = this.value;
    }
    setTimeout(() => {
      let linkEl = this.$refs["FileName"];
      if (linkEl == null) return false;
      this.fileNameOverflow = linkEl.offsetWidth < linkEl.scrollWidth;
    }, 250);
  },
  computed: {
    fileName() {
      if (this.file == null) return null;
      return this.file.fileName || this.file.name;
    },
    fileExt() {
      if (this.file == null) return null;
      return this.fileName.substring(this.fileName.lastIndexOf(".") + 1);
    },
  },
  watch: {
    value: {
      handler() {
        if (this.isEqual(this.value, this.file)) return;
        this.file = this.cloneDeep(this.value);
      },
      deep: true,
    },
  },
  methods: {
    view() {
      this.$log("View File", this.file);
      this.$cocoViewer.view(this.file.fileName, this.file.blobUrl, this.canDownload);
    },
    update() {
      this.$emit("update");
    },
    del() {
      this.$emit("delete");
    },
    cancelUpload() {
      this.$emit("cancel");
    },
    retryUpload() {
      this.$emit("retry");
    },
  },
};
</script>

<style lang="scss">
.file {
  position: relative;
  display: flex;
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-out;
  border: 1.5px solid #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .file-thumbnail {
    display: flex;
    flex: none;
    width: 64px;
    height: 64px;
    width: 54px;
    height: 54px;
    background: url("/img/file.svg") no-repeat center center transparent;
    background-size: contain;
    justify-content: flex-start;
    align-items: flex-end;
    filter: drop-shadow(0 0 3px rgba(96, 125, 139, 0.54));

    .file-ext {
      display: inline-flex;
      font-size: 12px;
      font-size: 10px;
      font-weight: 600;
      text-transform: uppercase;
      -ms-flex: none;
      -webkit-flex: none;
      flex: none;
      padding: 0.15rem 0.5rem;
      border-radius: 0.25rem;
      background: $shades-black;
      color: #fff;
      margin-bottom: 0.5rem;
      height: 17px;
      align-items: center;
      justify-content: center;

      span {
        line-height: 0;
      }
    }

    &[data-ext="pdf"] {
      .file-ext {
        background: #f44336;
      }
    }

    &[data-ext="svg"] {
      .file-ext {
        background: #20c997;
      }
    }

    &[data-ext="doc"],
    &[data-ext="docx"] {
      .file-ext {
        background: #2b579a;
      }
    }

    &[data-ext="ppt"],
    &[data-ext="pptx"] {
      .file-ext {
        background: #d44828;
      }
    }

    &[data-ext="xls"],
    &[data-ext="xlsx"] {
      .file-ext {
        background: #217346;
      }
    }

    &[data-ext="txt"] {
      .file-ext {
        background: #616161;
      }
    }

    &[data-ext="png"] {
      .file-ext {
        background: #3f51b5;
      }
    }

    &[data-ext="jpg"],
    &[data-ext="jpeg"] {
      .file-ext {
        color: rgba($shades-black, 0.87);
        background: #fdd835;
      }
    }

    &[data-ext="gif"] {
      .file-ext {
        background: #4caf50;
      }
    }

    &[data-ext="webp"] {
      .file-ext {
        background: #689f38;
      }
    }

    &[data-ext="dwg"] {
      .file-ext {
        background: #ff9800;
      }
    }

    &[data-ext="rvt"] {
      .file-ext {
        background: #255995;
        background: #29b6f6;
      }
    }

    &[data-ext="dxf"] {
      .file-ext {
        background: #009688;
      }
    }

    &[data-ext="wav"] {
      .file-ext {
        background: #fd7e14;
      }
    }

    &[data-ext="mp3"] {
      .file-ext {
        background: #c0ca33;
      }
    }

    &[data-ext="mp4"] {
      .file-ext {
        background: #2979ff;
      }
    }

    &[data-ext="xml"] {
      .file-ext {
        color: $shades-black;
        background: #ffab00;
      }
    }
    &[data-ext="json"] {
      .file-ext {
        background: #37474f;
      }
    }
  }

  .file-info {
    display: flex;
    flex: none;
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: calc(100% - 54px - 1rem - 40px - 40px - 2rem);

    .file-name {
      font-size: 16px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 1rem - 1rem);
      text-align: left;
    }

    .file-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: rgba($shades-black, 0.54);
      font-size: 12px;
      gap: 0.5rem;

      .file-date {
        font-weight: 500;
      }

      .file-size {
        display: inline-flex;
        -webkit-align-items: center;
        align-items: center;
        font-weight: 500;
      }
    }
  }

  .file-actions {
    flex: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;
    flex-grow: 1;
    gap: 0.5rem;
  }

  &:hover {
    border-color: rgba($shades-black, 0.87);
  }

  .uploading-progress {
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    user-select: none;
    border-radius: 0.5rem;
    overflow: hidden;

    .bar {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($success-base, 0.16);
    }

    .percentage {
      position: absolute;
      top: 50%;
      right: 6.5rem;
      font-size: 32px;
      font-weight: 700;
      color: rgba($shades-black, 0.16);
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.uploading-queue {
    border-color: rgba($info-base, 0.87) !important;
    border-style: dashed !important;
  }

  &.uploading-failed {
    border-color: rgba($error-base, 0.87) !important;
    border-style: solid !important;

    .uploading-progress {
      .bar {
        background: rgba($error-base, 0.08);
        width: 100% !important;
      }
    }
  }

  &.uploading-in-progress {
    border-color: rgba($success-base, 0.87) !important;
    border-style: solid !important;
  }
}
</style>
