<template>
  <apexcharts type="bar" height="350" :options="chartOptions" :series="series"></apexcharts>
</template>
<script>
export default {
  name: "project-budget-chart",
  props: {
    selected: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          offsetY: 10,
        },
        title: {
          text: "Project Budget (Labor, Materials, Total)",
          align: "left",
          style: {
            fontSize: "16px",
            fontFamily: '"Karla", serif;',
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "center",
            },
          },
        },
        fill: {
          opacity: 0.8,
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (price) {
              if (price == null || price == "" || Number.isNaN(price)) return "<u>N/A</u>";
              this.$log("price to be formatted", price);
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(price);
            },
          },
        },
        xaxis: {
          categories: ["Lead Budget", "Opportunity Budget", "Active Budget"],
        },
        yaxis: {
          labels: {
            formatter: function (price) {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(price);
            },
          },
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          name: "Labor Budget",
          color: this.$vuetify.theme.themes.light.info,
          data: [
            this.selected.leadBudget.laborBudget,
            this.selected.opportunityBudget.laborBudget,
            this.selected.activeBudget.laborBudget,
          ],
        },
        {
          name: "Materials Budget",
          color: this.$vuetify.theme.themes.light.chart.orange,
          data: [
            this.selected.leadBudget.materialsBudget,
            this.selected.opportunityBudget.materialsBudget,
            this.selected.activeBudget.materialsBudget,
          ],
        },
        {
          color: this.$vuetify.theme.themes.light.success,
          name: "Total Budget",
          data: [
            this.selected.leadBudget.totalBudget,
            this.selected.opportunityBudget.totalBudget,
            this.selected.activeBudget.totalBudget,
          ],
        },
      ];
    },
  },
};
</script>
