import Api from "../../Shared/services/api";

const baseUrl = "BigBoard";
export default {
  GetByProject(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/GetByProject` + "?" + qParams.toString());
  },
  GetByYear(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/GetByYear` + "?" + qParams.toString());
  },
  GetByRange(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/GetByRange` + "?" + qParams.toString());
  },
  GetForCurrentUser(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/GetForCurrentUser` + "?" + qParams.toString());
  },
};
