<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="1200"
    append-to="#app"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
    :class="{
      'slideout-readonly-content': readonly && selected && !selected.isBatch,
      'slideout-readonly-content-dark-header': readonly && selected && selected.isBatch,
      'slideout-deep-purple-header': selected && selected.isBatch,
    }"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Space ...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="!readonly && !selected.isBatch">
          <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.spaceNumber }}
          {{ selected.name }}'
        </span>
        <span v-if="!readonly && selected.isBatch">
          <i class="fad fa-grid-2-plus mr-2"></i> Update Batch: '{{ selected.spaceNumber }}
          {{ selected.name }}'
        </span>
        <span v-else-if="readonly && selected.isBatch"
          ><i class="fad fa-grid-2-plus mr-2"></i> Batch: '{{ selected.spaceNumber }}
          {{ selected.name }}'
        </span>
        <span v-else-if="readonly"
          ><i class="fad fa-draw-square mr-2"></i> Space: '{{ selected.spaceNumber }}
          {{ selected.name }}'
        </span>
        <!-- <code class="text-caption ml-2">#{{ selected.id }}</code> -->
        <entity-id
          class="ml-2"
          :label="selected.id"
          :path="spaceUrl"
          title="Project Space"
          :dark="selected.isBatch"
        ></entity-id>
      </h3>
      <h3 v-else-if="selected.isBatch" class="font-weight-bold pa-1" small>
        <i class="fad fa-grid-2-plus mr-2"></i> Add New Batch
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Add New Space
      </h3>
      <div>
        <code
          :dark="selected.isBatch"
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control
          :dark="selected.isBatch"
          v-model="slideouts.update.fullWidth"
        ></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon :dark="selected.isBatch"
          ><i class="far fa-times"></i
        ></v-btn>
      </div>
    </template>
    <div color="white" style="position: sticky; top: 0; z-index: 2">
      <v-tabs
        v-model="slideouts.update.tab"
        color="error"
        v-if="slideouts.update.active && !isFetching"
      >
        <v-tab :key="0">
          <v-fab-transition mode="out-in">
            <span class="tab-required-icon" v-if="!spaceInfoTabIsValid">
              <i class="fas fa-star-of-life pink--text"></i>
            </span>
          </v-fab-transition>
          Space Information
        </v-tab>
        <v-tab :key="1">
          Equipment
          <v-chip
            :color="slideouts.update.tab == 1 ? 'error' : 'grey lighten-4'"
            class="ml-2 font-weight-bold"
            small
            label
          >
            {{ spaceEquipmentCount }}
          </v-chip>
        </v-tab>
        <v-tab :key="2" :disabled="selected.id == null"> Master Quotes </v-tab>
        <v-tab :key="3"> Drawing Document Definitions </v-tab>
      </v-tabs>
      <v-divider></v-divider>
    </div>
    <v-container class="pa-0 text-center" v-if="isFetching">
      <video
        width="275"
        muted
        loop
        autoplay
        class="inset-shadow-video mt-8 mb-2"
        style="padding: 0.5rem; margin: 0 auto; border-radius: 10rem; background: #fff"
      >
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold mt-1">Fetching Space ...</p>
    </v-container>
    <v-container fluid class="pa-0" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-tabs-items v-model="slideouts.update.tab">
          <v-tab-item :key="0">
            <v-container fluid class="pa-6">
              <v-row class="my-0">
                <v-col v-if="selected.isBatch" cols="12" md="2" sm="12" class="py-0">
                  <label class="mb-2 d-block" style="font-weight: 600 !important; font-size: 15px">
                    Batch Space Count
                  </label>
                  <counter
                    v-model="selected.batchCount"
                    :min="2"
                    :max="100"
                    :readonly="readonly"
                    :step="1"
                  />
                </v-col>
                <v-col cols="12" :md="selected.isBatch ? 3 : 4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Name"
                    id="spaceName"
                    ref="spaceName"
                    placeholder="Space Name"
                    v-model="selected.name"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" :md="selected.isBatch ? 3 : 4" sm="12" class="py-0">
                  <v-text-field-alt
                    :rules="[allRules.noWhiteSpaces]"
                    label="Space Number"
                    id="spaceNumber"
                    ref="spaceNumber"
                    placeholder="Space Number"
                    class="mono-font-text-field"
                    v-model="selected.spaceNumber"
                    :readonly="readonly"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col cols="12" :md="selected.isBatch ? 3 : 4" sm="12" class="py-0">
                  <v-select-alt
                    :rules="[allRules.required]"
                    label="Space Status"
                    id="SpaceStatus"
                    placeholder="Status"
                    v-model="selected.state"
                    :items="spaceStatus"
                    dense
                    filled
                    item-text="text"
                    item-value="value"
                    :readonly="readonly"
                  >
                    <template #selection="{ item }">
                      <status-item
                        small
                        :statusList="statusList"
                        :status="item.value"
                      ></status-item>
                    </template>
                    <template #item="{ item }">
                      <status-item
                        small
                        :statusList="statusList"
                        :status="item.value"
                      ></status-item>
                    </template>
                  </v-select-alt>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <p
                    class="orange--text font-weight-medium mb-4"
                    style="margin-top: -0.75rem"
                    v-if="spaceNameNumberLimitReached"
                  >
                    <i class="fad fa-info-circle mr-2"></i>Space Name + Space Number length exceeds
                    30 characters!
                  </p>
                </v-col>
                <v-col cols="12" md="8" sm="12" class="py-0">
                  <space-template-selector
                    v-model="selected.templateId"
                    :readonly="readonly"
                    :obj.sync="selected.template"
                    ref="spaceTemplateSelector"
                  ></space-template-selector>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="py-0">
                  <!-- <v-text-field-alt
                      label="Complexity Factor"
                      type="number"
                      min="0"
                      placeholder="Space Complexity Factor"
                      v-model="selected.complexityFactor"
                      :disabled="selected.template != null"
                      :readonly="readonly"
                    >
                    </v-text-field-alt> -->
                  <label class="mb-2 d-block" style="font-weight: 600 !important; font-size: 15px">
                    Space Complexity Factor
                  </label>
                  <div class="d-flex align-center">
                    <counter
                      v-model="selected.complexityFactor"
                      :min="0.5"
                      :max="100"
                      :readonly="readonly || isSpaceTemplateAdded"
                      :step="0.5"
                    />
                    <v-tooltip
                      v-if="selected.templateId != null && !readonly"
                      top
                      nudge-bottom="20"
                      max-width="265px"
                      z-index="99999"
                      class="ml-2 d-inline"
                      color="secondary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="text--secondary"
                          style="font-size: 18px"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fad fa-info-circle"></i>
                        </span>
                      </template>
                      <span style="font-size: 14px">
                        Complexity Factor is locked because it copies from the linked template!
                      </span>
                    </v-tooltip>
                    <template v-else>
                      <v-tooltip
                        top
                        max-width="265px"
                        z-index="99999"
                        class="ml-3 d-inline"
                        color="secondary"
                        v-if="suggestComplexity && !readonly"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            class="text--secondary pointer d-flex align-center"
                            v-bind="attrs"
                            v-on="on"
                            @click="selected.complexityFactor = suggestComplexity"
                          >
                            Suggested:
                            <strong class="primary--text ml-1">{{ suggestComplexity }}</strong>
                            <v-fade-transition>
                              <v-icon
                                v-if="selected.complexityFactor == suggestComplexity"
                                color="success"
                                small
                                class="ml-2"
                                >far fa-check</v-icon
                              ></v-fade-transition
                            >
                          </span>
                        </template>
                        <span style="font-size: 14px"
                          >Click to use the suggested complexity factor!
                        </span>
                      </v-tooltip>
                    </template>
                  </div>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <v-divider class="mb-2"></v-divider>
                  <div v-if="selected.templateId != null">
                    <div>
                      <span class="d-flex flex-row align-center mb-1" style="gap: 0.35rem">
                        <label class="input-label">Space Description</label>
                        <span class="text--secondary">
                          To be used in BIN report under Systems Descriptions!
                        </span>
                        <v-tooltip
                          v-if="selected.templateId != null && !readonly"
                          top
                          nudge-bottom="20"
                          max-width="265px"
                          z-index="99999"
                          class="d-inline"
                          color="secondary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              class="text--secondary"
                              style="font-size: 18px; line-height: 0"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <i class="fad fa-info-circle"></i>
                            </span>
                          </template>
                          <span style="font-size: 14px">
                            Space Description is locked because it copies from the linked template!
                          </span>
                        </v-tooltip>
                      </span>
                    </div>
                    <div
                      class="tiptap-vuetify-editor__content tiptap-vuetify-editor__preview__readonly pa-0"
                      v-html="
                        selected.description != null && selected.description != ''
                          ? selected.description
                          : `<i class='fad fa-link opacity-40 mr-2'></i>${
                              selected.template != null ? selected.template.description : ''
                            }`
                      "
                    />
                  </div>
                  <rich-text-editor
                    v-else
                    v-model="selected.description"
                    title="Space Description"
                    subtitle="To be used in BIN report under Systems Descriptions!"
                    :readonly="readonly"
                    allowExpand
                    maxHeight="132px"
                    showLabel
                    class="mb-4 font-weight-medium"
                  />
                  <v-divider class="my-2"></v-divider>
                </v-col>
                <v-col cols="12" md="12" sm="12" class="py-0">
                  <space-building-level-editor
                    :projectId="projectId"
                    v-model="selected.level"
                    :allowAdding="!readonly"
                    :readonly="readonly"
                  ></space-building-level-editor>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <single-image-uploader
                    id="update-space-uploader"
                    :apiUrl="imageApiUrl"
                    v-model="selected.imageUrl"
                    label="Space Image"
                    :disabled="readonly"
                  ></single-image-uploader>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="py-0">
                  <v-textarea-alt
                    label="Notes"
                    id="spaceNotes"
                    placeholder="Write Your Notes"
                    v-model="selected.notes"
                    no-resize
                    height="132px"
                    :readonly="readonly"
                  >
                  </v-textarea-alt>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="1" eager>
            <space-area-groups-manager
              ref="spaceAreaGroupsManager"
              v-model="selected"
              :projectId="projectId"
              :readonly="readonly"
              :isLoading="slideouts.update.isLoading"
              :validSpace="slideouts.update.valid"
              :isSpaceTemplateAdded="isSpaceTemplateAdded"
              :isSpaceTemplateChanged="isSpaceTemplateChanged"
              :installersList="installersList"
              :providersList="providersList"
            ></space-area-groups-manager>
          </v-tab-item>
          <v-tab-item :key="2">
            <v-container fluid class="pa-6 pt-2" style="background: #eceff1">
              <master-quotes
                :readonly="readonly"
                :has-changes="slideouts.update.hasChanges"
                :spaceId="selected.id"
                :projectId="projectId"
              ></master-quotes>
            </v-container>
          </v-tab-item>
          <v-tab-item :key="3">
            <v-container fluid class="pa-6 pt-2">
              <v-alert
                prominent
                elevation="2"
                class="mt-2"
                type="info"
                dense
                v-if="!isSpaceTemplateChanged && !readonly && isSpaceTemplateAdded"
              >
                <v-row align="center">
                  <v-col class="grow">
                    Drawing Document Definitions editing is disabled because the space is linked
                    with template!
                  </v-col>
                  <v-col class="shrink">
                    <v-btn color="secondary" class="mr-2" @click="openTemplateInNewTab()">
                      <i class="fal fa-arrow-up-right-from-square mr-2"></i>
                      View Template
                    </v-btn>
                  </v-col>
                </v-row>
              </v-alert>
              <drawing-document-definitions
                v-model="selected"
                :readonly="isSpaceTemplateChanged || isSpaceTemplateAdded || readonly"
              ></drawing-document-definitions>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-btn
          v-if="!readonly && selected.id != null && !isSpaceTemplateAdded"
          color="secondary"
          :disabled="slideouts.update.hasChanges || slideouts.update.isLoading"
          @click="generateTemplateFromSpace()"
        >
          <i class="fal fa-code mr-2"></i> Generate Template
        </v-btn>
        <v-tooltip
          v-if="!readonly && selected.id != null && !isSpaceTemplateAdded"
          top
          nudge-bottom="20"
          max-width="320px"
          z-index="99999"
          class="ml-2"
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="text--secondary ml-2 pa-1" style="font-size: 18px" v-bind="attrs" v-on="on">
              <i class="fad fa-info-circle"></i>
            </div>
          </template>
          <span style="font-size: 14px">
            You need to save all changes to be able to generate a template from this space.
          </span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <div class="d-flex ml-2" v-if="!isFetching && readonly && $has(perms.Projects.Update)">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchSpace()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch Space</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="openCloneModal()">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small :class="$has(perms.Projects.Update) ? '' : 'opacity-54'">
                  fal fa-clone
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :class="$has(perms.Projects.Update) ? '' : 'opacity-54'">
                  Clone
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="del()">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small :class="$has(perms.Projects.Update) ? '' : 'opacity-54'">
                  fal fa-trash-alt red--text
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="red--text"
                  :class="$has(perms.Projects.Update) ? '' : 'opacity-54'"
                  >Delete</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
    </template>

    <!-- Generate Template Modal -->
    <v-dialog
      v-model="modals.generateTemplate.active"
      max-width="400px"
      persistent
      style="z-index: 99999999"
    >
      <v-card>
        <v-card-title class="font-weight-bold" small>
          <i class="fad fa-plus-square mr-2"></i>Generate Space Template
        </v-card-title>
        <v-divider></v-divider>
        <v-form v-model="modals.generateTemplate.valid" ref="generateTemplateForm">
          <v-card-text>
            <v-container>
              <v-row class="my-0" dense>
                <v-col sm="12">
                  <v-text-field-alt
                    :rules="[allRules.required, allRules.noWhiteSpaces]"
                    label="Template Name"
                    id="SpaceTemplateName"
                    placeholder="Descriptive & Unique Name"
                    v-model="modals.generateTemplate.data.name"
                  >
                  </v-text-field-alt>
                </v-col>
                <v-col sm="12">
                  <v-checkbox
                    color="info"
                    v-model="modals.generateTemplate.data.autoSelect"
                    class="font-weight-bold mt-0"
                    label="Link this new template to the space?"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="generateTemplateDiscard()" :disabled="modals.generateTemplate.isLoading">
              <i class="fal fa-chevron-left mr-2"></i>Discard
            </v-btn>
            <v-btn
              color="info"
              :loading="modals.generateTemplate.isLoading"
              :disabled="!modals.generateTemplate.valid || modals.generateTemplate.isLoading"
              @click="generateTemplateConfirmed()"
            >
              <i class="fal fa-check mr-2"></i> Generate
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Clone Space -->
    <clone-space @done="cloneSpaceDone" ref="cloneSpaceModal"></clone-space>
  </slideout>
</template>

<script>
import perms from "../../../plugins/permissions";
import SpaceBuildingLevelEditor from "./SpaceBuildingLevelEditor.vue";
import spaceService from "../services/spaces-service";
import spaceTemplatesService from "../services/spaceTemplates-service";
import installersAPI from "../../Equipments/services/installers-service";
import providersAPI from "../../Equipments/services/providers-service";
import SpaceTemplateSelector from "../../Shared/components/SpaceTemplateSelector.vue";
import enums from "../../../plugins/enums";
import RichTextEditor from "../../Shared/components/RichTextEditor.vue";
import MasterQuotes from "../../MasterQuotes/views/MasterQuotes.vue";
import PanelSizeControl from "@Shared/components/PanelSizeControl.vue";
import CloneSpace from "./CloneSpace.vue";
import DrawingDocumentDefinitions from "./DrawingDocumentDefinitions.vue";
import SpaceAreaGroupsManager from "./SpaceAreaGroupsManager.vue";

export default {
  name: "edit-project-space",
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      perms: perms,
      statusList: enums.SPACE_STATUS,
      spaceStatus: this.$options.filters.EnumToList(enums.SPACE_STATUS, true),
      spaceId: null,
      isFetching: false,
      bulk: {
        installerId: null,
        providerId: null,
        count: 0,
      },
      selected: {},
      selectedCemented: {},
      isTemplateLoading: false,
      templates: [],
      installersList: [],
      providersList: [],
      templatesTimerId: null,
      readonly: false,
      slideouts: {
        update: {
          active: false,
          tab: null,
          valid: false,
          hasChanges: null,
          isLoading: false,
          categoryLoading: false,
          installersLoading: false,
          providersLoading: false,
          fullWidth: false,
        },
        addEquipments: {
          active: false,
          isLoading: false,
        },
      },
      selectedEquipments: {},
      modals: {
        generateTemplate: {
          active: false,
          valid: false,
          isLoading: false,
          data: {
            name: null,
            autoSelect: true,
          },
        },
      },
      imageApiUrl: `Spaces/Image`,
      suggestComplexity: 0,
    };
  },
  mounted() {
    this.getInstallers();
    this.getProviders();
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.$has(this.perms.Projects.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    cloneSpaceDone(newSpace) {
      newSpace.viewed = true;
      this.$emit("clone", newSpace);
    },
    openCloneModal() {
      this.$refs.cloneSpaceModal.open(this.selected);
    },
    del() {
      var building =
        this.selected.level && this.selected.level.building
          ? `${this.selected.level.building.name}.`
          : ``;
      var level = this.selected.level ? `${this.selected.level.name}.` : ``;
      var space =
        this.selected.spaceNumber != null && this.selected.spaceNumber != ""
          ? `${this.selected.spaceNumber}.${this.selected.name}`
          : `${this.selected.name}`;
      var spaceHtml = `<h4>${building}${level}${space}</h4>`;
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this space?<br/>${spaceHtml}`,
          title: `Delete Space?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return spaceService
                  .delete(this.selected.id)
                  .then((resp) => {
                    this.$emit("delete", this.selected.id);
                    this.closeUpdateSlideout();
                    this.$dialog.notify.success("Space deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the space!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    getSuggestedComplexity() {
      spaceService.suggestedComplexity(this.spaceId).then((res) => {
        this.suggestComplexity = res.data.complexityFactor;
      });
    },
    getInstallers() {
      this.slideouts.update.installersLoading = true;
      installersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.installersList = resp.data;
          this.slideouts.update.installersLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.installersLoading = false;
          // this.$handleError(err);
        });
    },
    getProviders() {
      this.slideouts.update.providersLoading = true;
      providersAPI
        .typeHead(null, true)
        .then((resp) => {
          this.providersList = resp.data;
          this.slideouts.update.providersLoading = false;
        })
        .catch((err) => {
          this.slideouts.update.providersLoading = false;
          // this.$handleError(err);
        });
    },
    switchToEditMode() {
      this.readonly = false;
    },
    closeUpdateSlideout() {
      this.slideouts.update.isLoading = false;
      this.slideouts.update.active = false;
      this.slideouts.update.fullWidth = false;
    },
    onSlideoutClosing(e) {
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementSpace();
      this.$releaseChanges();
      this.isFetching = false;
      this.expandedAreaGroupPanels = [];
      // (this.selectedEquipments = {}), this.$emit("close");

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewSpace) {
      this.$log("announceChange()", isNewSpace);
      this.$emit("save", this.cloneDeep(this.selected), isNewSpace);
    },
    sortAreaGroups() {
      this.selected.areaGroups.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
    addSpaceToLocalStorage(spaceId) {
      this.$log(">>>> EDIT PROJECT SPACE => addSpaceToLocalStorage");
      spaceId = spaceId.toString();
      var projectSpacesViewedList = this.$getFromLocal(`projectSpacesViewedList`, true, []);
      if (!projectSpacesViewedList.includes(spaceId)) {
        projectSpacesViewedList.push(spaceId);
        this.$setToLocal(`projectSpacesViewedList`, projectSpacesViewedList, true);
      }
    },
    fetchSpace() {
      this.isFetching = true;
      this.slideouts.update.isLoading = true;
      spaceService
        .getSpaceById(this.spaceId)
        .then((resp) => {
          this.$log("getSpaceById >> success", resp.data);
          this.addSpaceToLocalStorage(this.spaceId);
          this.$emit("fetch", this.spaceId);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.sortAreaGroups();
          this.cementSpace();
          document.title = this.selected.name + " | Concordia";
        })
        .catch((err) => {
          this.$log("getTemplateById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    open(id, isBatch = false, editMode = false) {
      this.$log("EDIT-PROJECT-SPACE OPEN =>", id, "isBatch", isBatch, "editMode", editMode);
      if (id == null) {
        this.selected = {
          projectId: this.projectId,
          name: "",
          spaceNumber: "",
          complexityFactor: 0,
          iavSheetCount: 1,
          scdSheetCount: 1,
          planSheetCount: 1,
          overviewSheetCount: 1,
          detailSheetCount: 1,
          template: null,
          level: null,
          areaGroups: [],
          isBatch: isBatch,
          batchCount: 2,
        };
        if (
          this.$store.getters.personalSettings != null &&
          this.$store.getters.personalSettings.autoCreateDefaultAreaGroupInSpace
        ) {
          //new space?, then add a default area group
          this.selected.areaGroups = [
            {
              id: null,
              tempId: this.$refs.spaceAreaGroupsManager.getTempAreaGroupId(),
              name: "New Area Group 1",
              areaEquipments: [],
            },
          ];
        }
        this.isFetching = false;
        this.readonly = false;
        this.spaceId = null;
        this.expandedAreaGroupPanels = [];
        this.slideouts.update.tab = 0;
        this.$refs.updateForm.resetValidation();
        this.handleFocus();
        this.cementSpace();
      } else {
        this.readonly = !editMode;
        this.spaceId = id;
        this.expandedAreaGroupPanels = [];
        this.fetchSpace();
      }
      this.selectedEquipments = {};
      this.bulk.installerId = null;
      this.bulk.providerId = null;
      this.bulk.count = 0;
      this.$refs.spaceAreaGroupsManager.searchTemplates = "";
      this.$refs.spaceAreaGroupsManager.searchEquipment = "";
      this.slideouts.update.active = true;
    },
    cementSpace() {
      this.$log("cementSpace()");
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    checkForChanges() {
      this.$log("checkForChanges()");
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);

      if (this.slideouts.update.hasChanges) {
        this.$log(
          "%c=>> deepDiff",
          "color: red",
          this.deepDiff(this.selectedCemented, this.selected)
        );
      }

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    handleFocus() {
      if (this.isFetching) return;
      if (this.slideouts.update.tab == 0) {
        setTimeout(() => {
          this.$refs.spaceName.focus();
        }, 350);
      } else if (this.slideouts.update.tab == 1 && !this.readonly) {
        setTimeout(() => {
          if (this.$refs.spaceAreaGroupsManager) {
            this.$refs.spaceAreaGroupsManager.$refs.addAreaGroupBtn.$el.focus();
          }
        }, 350);
      }
    },
    generateTemplateFromSpace() {
      this.modals.generateTemplate.active = true;
      setTimeout(() => {
        this.$refs.generateTemplateForm.resetValidation();
      });
    },
    generateTemplateDiscard() {
      this.modals.generateTemplate.active = false;
      this.modals.generateTemplate.isLoading = false;
      this.modals.generateTemplate.data.name = null;
      this.modals.generateTemplate.data.autoSelect = true;
    },
    generateTemplateConfirmed() {
      this.modals.generateTemplate.isLoading = true;
      let toSend = {
        name: this.modals.generateTemplate.data.name,
        spaceId: this.selected.id,
      };
      spaceTemplatesService
        .GenerateFromSpace(toSend)
        .then((resp) => {
          if (this.$refs.spaceTemplateSelector) {
            this.updateAndLinkTemplate(resp);
          } else {
            this.slideouts.update.tab = 0;
            const changeTabAndWaitForRender = setTimeout(() => {
              this.updateAndLinkTemplate(resp);
              clearTimeout(changeTabAndWaitForRender);
            }, 400);
          }
          this.modals.generateTemplate.isLoading = false;
          this.modals.generateTemplate.active = false;
        })
        .catch((err) => {
          this.modals.generateTemplate.isLoading = false;
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    updateAndLinkTemplate(resp) {
      var message = "Template generated successfully!";
      this.$log("resp.data", resp.data);
      this.$refs.spaceTemplateSelector.unshift(this.cloneDeep(resp.data));
      if (this.modals.generateTemplate.data.autoSelect) {
        this.selected.templateId = resp.data.id;
        message = "Template generated and linked successfully!";
      }
      this.$dialog.notify.success(message, {
        position: "top-right",
        timeout: 3000,
      });
      this.generateTemplateDiscard();
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      // let spaceToSend = JSON.parse(JSON.stringify({ ...this.selected }));
      let spaceToSend = this.cloneDeep(this.selected);
      spaceToSend.levelId = spaceToSend.level.id;
      spaceToSend.areaGroups = spaceToSend.areaGroups.map((areaGroup) => {
        return {
          id: areaGroup.id,
          name: areaGroup.name,
          isRack: areaGroup.isRack,
          areaEquipments: areaGroup.areaEquipments.map((areaEquipment) => {
            return {
              id: areaEquipment.id,
              equipmentId: areaEquipment.equipment.id,
              count: areaEquipment.count,
              installerId: areaEquipment.installerId,
              providerId: areaEquipment.providerId,
            };
          }),
        };
      });
      this.$log("spaceToSend", spaceToSend);
      if (spaceToSend.areaGroups.length == 0) spaceToSend.clearAllEquipments = true;
      else {
        spaceToSend.areaGroups.forEach((areaGroup) => {
          areaGroup.areaEquipments = areaGroup.areaEquipments.filter(
            (areaEquip) => areaEquip.count > 0
          );
        });
      }
      spaceService
        .update(spaceToSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "Space is updated successfully!";
          this.selected = this.cloneDeep(resp.data);
          this.sortAreaGroups();
          this.cementSpace();
          var isNewSpace = false;
          if (!spaceToSend.id) {
            this.spaceId = this.selected.id;
            isNewSpace = true;
            message = "Space is added successfully!";
          }
          this.announceChange(isNewSpace);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    checkForSpaceChanges() {
      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
    },
    closeSpacePanel() {
      this.closeUpdateSlideout();
    },
    openTemplateInNewTab() {
      window.open(`/space-templates/${this.selected.templateId}`, "_blank");
    },
    jumpToRequiredField() {
      if (
        this.findRequiredFieldInFrom(this.$refs.updateForm, () => {
          this.slideouts.update.tab = 0;
        })
      )
        return;

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
  },
  computed: {
    mainPanelSize() {
      return this.slideouts.addEquipments.active || this.slideouts.update.fullWidth
        ? "100%"
        : "78%";
    },
    spaceUrl() {
      if (this.selected == null) return "";
      return `projects/${this.projectId}/spaces/${this.selected.id}`;
    },
    spaceEquipmentCount() {
      return this.selected.equipmentCount || 0;
      if (this.selected?.masterQuotes)
        return this.selected.masterQuotes.reduce((total, cur) => {
          return total + cur;
        }, 0);
      else return 0;
    },
    spaceNameNumberLimitReached() {
      if (this.selected == null) return false;
      return (this.selected.name || "").length + (this.selected.spaceNumber || "").length > 30;
    },
    isSpaceTemplateChanged() {
      return !this.isEqual(this.selected.template, this.selectedCemented.template);
    },
    isSpaceTemplateAdded() {
      return this.selected.templateId != null;
    },
    spaceInfoTabIsValid() {
      return this.slideouts.update.valid;
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
    spaceId(val) {
      if (val) this.getSuggestedComplexity();
    },
    "slideouts.update.tab": {
      handler() {
        this.handleFocus();
      },
      deep: true,
    },
    "slideouts.addEquipments.active": {
      handler() {
        if (this.slideouts.addEquipments.active) {
          setTimeout(() => {
            this.$refs.addEquipmentsControl.focus();
          }, 350);
        }
      },
      deep: true,
    },
  },
  components: {
    SpaceBuildingLevelEditor,
    SpaceTemplateSelector,
    RichTextEditor,
    MasterQuotes,
    PanelSizeControl,
    CloneSpace,
    DrawingDocumentDefinitions,
    SpaceAreaGroupsManager,
  },
};
</script>

<style lang="scss">
.v-menu__content.provider-installer-select-menu {
  z-index: 111 !important;
}
.v-alert.v-alert--dense {
  padding: 0 !important;

  .v-alert__icon {
    &::after {
      border-radius: 0.25rem 0 0 0.25rem !important;
    }
  }
}
.space-area-groups .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}
</style>
