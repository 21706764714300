import Api from "../../../Shared/services/api";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "companyContacts";
export default {
  get() {
    return Api().get(baseUrl);
  },
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    // const qParams = new URLSearchParams(options);
    const qParams = buildParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  queryAll(options) {
    const qParams = buildParams(options);
    // this.$log(">>>>>>>>> qParams", qParams.toString());
    return Api().get(`${baseUrl}/All?` + "?" + qParams.toString());
  },
};
